import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { t } from '../../i18n';
import fymAPI from '../../Helpers/FYMAPI';
import './CreateFYMAccountModal.css';

class CreateFYMAccountModal extends React.Component {
    #ACCOUNT_EXTENSION = '.mnk';

    constructor(props) {
        super(props);
        
        this.state = {
            accountName: '',
            isSettingName: false
        };
        
        this.submitAccountName = this.submitAccountName.bind(this);
    };

    submitAccountName(event) {
        event.preventDefault();
        this.setState({isSettingName: true});

        const accountName = (this.state.accountName.toLowerCase() + this.#ACCOUNT_EXTENSION);

        fymAPI.setAccountName(accountName)
            .then(() => this.props.onAccountNameSet(accountName))
            .catch(error => this.props.onError(error))
            .finally(() => this.setState({isSettingName: false}));
    };

    // React
    componentDidUpdate(prevProps) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (!this.props.isShown) {
                this.setState({accountName: ''});
            }
        }
    };

    render() {
        return (
            <Modal show={this.props.isShown} size="sm" backdrop="static" backdropClassName="modal-backdrop-blurred" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.createInGameAccount.title')}</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <Form id="accountNameForm" onSubmit={this.submitAccountName}>
                        <Form.Group className="mb-3" controlId="accountName">
                            <Form.Label>{t('misc.accountName')}</Form.Label>

                            <Row className="inputsRow">
                                <Col className="col-9" style={{paddingRight: 0}}>
                                    <Form.Control
                                        type="text"
                                        className="accountNameInput"
                                        disabled={this.state.isSettingName}
                                        value={this.state.accountName}
                                        onChange={e => { this.setState({accountName: e.target.value})}}                                    
                                        pattern="[1-5A-Za-z]{1,8}"
                                        maxLength={8}
                                        autoFocus
                                        required
                                    />
                                </Col>

                                <Col style={{paddingLeft: 0}}>
                                    <Form.Control type="text" className="nameExtensionInput" disabled={true} value={this.#ACCOUNT_EXTENSION}/>
                                </Col>
                            </Row>

                            <Form.Text className="text-muted">{t('modals.createInGameAccount.useOnlyAZand15')}</Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onCancel} disabled={this.state.isSettingName}>{t('common.cancel')}</Button>

                    <Button type="submit" form="accountNameForm" variant="primary" disabled={this.state.isSettingName}>
                        { this.state.isSettingName ?
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                <span className="visually-hidden">{t('common.confirm')}</span>
                            </>
                            :
                            t('common.confirm').toLocaleUpperCase()
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
};

export default CreateFYMAccountModal;