import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import { t, Trans } from '../../i18n';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import { formatAmount, formatBananasTokenAmount } from '../../Helpers/Utils';
import { GAME_CONSTANTS } from '../../config';

class StarveMonkeyModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get trxAPI() {
        return this.props.trxAPI;
    }
    
    get isRunningOnNative() {
        return this.sharedState.isRunningOnNative;
    }

    constructor(props) {
        super(props);

        this.state = {
            bananasAmount: '', // Amount to withdraw
            isTransacting: false
        };

        this.onTokenFormSubmitted = this.onTokenFormSubmitted.bind(this);
        this.useAllBalance = this.useAllBalance.bind(this);
        this.close = this.close.bind(this);
    };

    // Handling Form Submission
    onTokenFormSubmitted(event) {
        event.preventDefault();
        this.setState({isTransacting: true});
        
        const bananasAmount = formatBananasTokenAmount(this.state.bananasAmount);

        this.trxAPI.starveMonkey(bananasAmount)
            .then(() => this.onMonkeyStarved(bananasAmount))
            .finally(() => this.setState({isTransacting: false}));
    };

    // Misc
    onMonkeyStarved(bananasAmountFormatted) {
        // Use the formatted amount in other case the Float type might have a lot bigger
        // precision affecting the resulting amount we're setting into the object
        const bananasWithdrawn = parseFloat(bananasAmountFormatted);

        // Change it locally before getting back a confirmation
        // which might take more than 1 sec until data are current
        this.props.monkey.bananasAmount -= bananasWithdrawn;
        this.props.monkey.invalidateStarvePill();
        this.props.onTokensWithdrawn(this.props.monkey);
        this.close();
    };

    withdrawAllTitle() {
        const monkey = this.props.monkey;
        const isOnStarvePill = (monkey?.isOnStarvePill || false);
        const bananasAmountAvailable = this.maxWithdrawalAllowance();
        const bananasAmountFormatted = formatAmount(bananasAmountAvailable);
        const maxStarvingPercentage = (GAME_CONSTANTS.STARVE_PILL_MAX_STARVING * 100);
        let text;

        if (isOnStarvePill) {
            const obj = {percentage: maxStarvingPercentage};
            text = (this.isRunningOnNative ? t('modals.starveYourMonkey.withdrawPerc', obj) : t('modals.starveYourMonkey.starvePerc', obj));
        }
        else {
            text = (this.isRunningOnNative ? t('modals.starveYourMonkey.withdrawAll') : t('modals.starveYourMonkey.starveAll'));
        }

        return `${text}: 🍌 ${bananasAmountFormatted}`;
    };
    
    maxWithdrawalAllowance() {
        const monkey = this.props.monkey;
        const bananasAmount = (monkey?.bananasAmount || 0);
        const didWinFight = (monkey?.didWinFight || false);
        const isOnStarvePill = (monkey?.isOnStarvePill || false);
        const isStarvingAllowed = (!didWinFight || isOnStarvePill);

        if (isStarvingAllowed) {
            if (isOnStarvePill) {
                // Floor it to avoid precision issues when displaying the amount
                return Math.floor(bananasAmount * GAME_CONSTANTS.STARVE_PILL_MAX_STARVING);
            }

            return bananasAmount;
        }

        return null;
    };

    useAllBalance() {
        this.setState({bananasAmount: this.maxWithdrawalAllowance()});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (!this.props.isShown) {
                this.setState({bananasAmount: ''});
            }
            else {
                ReactGA.send({hitType: 'pageview', page: 'starveMonkey-modal'});
            }
        }
    }

    render() {
        const bananasAmountAvailable = this.maxWithdrawalAllowance();
        const isStarvingAllowed = (bananasAmountAvailable !== null);

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.starveYourMonkey.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { isStarvingAllowed &&
                        <Form id="starveMonkeyForm" onSubmit={this.onTokenFormSubmitted}>
                            <Form.Group className="mb-3" controlId="bananasAmount">
                                <Form.Label>{t('misc.amountOfBananas')}</Form.Label>
                                <Form.Control type="number" step="any" disabled={this.state.isTransacting} value={this.state.bananasAmount} onChange={e => { this.setState({bananasAmount: e.target.value})}} autoFocus required/>
                                <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useAllBalance}>{this.withdrawAllTitle()}</Form.Text>
                            </Form.Group>
                        </Form>
                    }

                    { !isStarvingAllowed &&
                        <p style={{textAlign: 'center'}}>
                            <Trans i18nKey="modals.starveYourMonkey.cannotStarveYourMonkey" components={{1: <br/>}}/>
                        </p>
                    }
                </Modal.Body>

                <Modal.Footer>
                    {!this.state.isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    { isStarvingAllowed &&
                        <Button type="submit" form="starveMonkeyForm" variant="primary" disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('common.confirm')}</span>
                                </>
                                :
                                t('common.confirm')
                            }
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };
};

export default commonContextsWrapper(StarveMonkeyModal);
