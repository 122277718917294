import secureLocalStorage from 'react-secure-storage';
import { API_URL, JWT_TOKEN_KEY_NAME, getBlockchainName } from '../config';

class FYMAPI {
    #jsonHeader = {'Content-Type': 'application/json'};

    get #authHeader() {
        const token = secureLocalStorage.getItem(JWT_TOKEN_KEY_NAME);
        return {'Authorization': `Bearer ${token}`};
    }

    async billResourcesSignature(transaction, chain) {
        const body = JSON.stringify({transaction});
        const chainName = getBlockchainName(chain).toLowerCase();
        const response = await this.#fetch({path: `/${chainName}/transaction/bill-resources`, method: 'POST', headers: this.#jsonHeader, body});
        const responseData = await response.json();
        return responseData.signature;
    };

    // Solana
    async getSiwsMessage(address) {
        const url = new URL('/solana/siws-message', API_URL);
        url.searchParams.append('address', address);
                
        const path = url.toString().replace(API_URL, '');
        const response = await this.#fetch({path, method: 'GET'});
        const responseData = await response.json();
        
        return responseData.siws;
    };

    async authenticateSolana(signature) {
        const body = JSON.stringify({signature});
        const response = await this.#fetch({path: '/solana/authenticate', method: 'POST', headers: this.#jsonHeader, body});
        return await response.json();
    };

    async bridgeToNative(trxID) {
        const headers = {...this.#authHeader, ...this.#jsonHeader};
        const body = JSON.stringify({trxID});
        return await this.#fetch({path: '/solana/bridge/native', method: 'POST', headers, body});
    };

    async submitWithdrawalTrx(trxID) {
        const headers = {...this.#authHeader, ...this.#jsonHeader};
        const body = JSON.stringify({trxID});
        return await this.#fetch({path: '/solana/bridge/withdraw', method: 'POST', headers, body});
    };

    async getBridgingFeeToSolana() {
        const response = await this.#fetch({path: '/solana/bridge/fee', method: 'GET'});
        const responseData = await response.json();
        return responseData.fee;
    };

    // EVM
    async getSiweMessage(address, chainID) {
        const url = new URL('/evm/siwe-message', API_URL);
        url.searchParams.append('address', address);
        url.searchParams.append('chainID', chainID);
        
        const path = url.toString().replace(API_URL, '');
        const response = await this.#fetch({path, method: 'GET'});
        const responseData = await response.json();

        return responseData.message;
    };

    async authenticateEVM(signature) {
        const body = JSON.stringify({signature});
        const response = await this.#fetch({path: '/evm/authenticate', method: 'POST', headers: this.#jsonHeader, body});
        return await response.json();
    };

    // Account
    async setKey(encryptedKey, publicKey) {
        const headers = {...this.#authHeader, ...this.#jsonHeader};
        const body = JSON.stringify({encryptedKey, publicKey});
        return await this.#fetch({path: '/account/key', method: 'POST', headers, body});
    };

    async setAccountName(accountName) {
        const headers = {...this.#authHeader, ...this.#jsonHeader};
        const body = JSON.stringify({accountName});
        return await this.#fetch({path: '/account/name', method: 'POST', headers, body});
    };

    async createAccount(trxID) {
        const headers = {...this.#authHeader, ...this.#jsonHeader};
        const body = JSON.stringify({trxID});
        return await this.#fetch({path: '/account/create', method: 'POST', headers, body});
    };

    // Easter Egg Game
    async verifyTelegramUser(initDataRaw, encryptionPublicKey) {
        const headers = {...this.#jsonHeader};
        const body = JSON.stringify({initDataRaw, encryptionPublicKey});
        return await this.#fetch({path: `/eeg/account/verify`, method: 'POST', headers, body});
    };

    async updateEEGAccount(user, totalTaps, totalBananasEarned, signature) {
        const headers = {...this.#jsonHeader};
        const body = JSON.stringify({user, totalTaps, totalBananasEarned, signature});
        return await this.#fetch({path: '/eeg/account/update', method: 'POST', headers, body});
    };

    async setEEGReferrer(userID, referrerID, signature) {
        const headers = {...this.#jsonHeader};
        const body = JSON.stringify({userID, referrerID, signature});
        return await this.#fetch({path: '/eeg/referrer/set', method: 'POST', headers, body});
    };

    async setEEGAddress(userID, address, signature) {
        const headers = {...this.#authHeader, ...this.#jsonHeader};
        const body = JSON.stringify({userID, address, signature});
        return await this.#fetch({path: '/eeg/address/set', method: 'POST', headers, body});
    };

    async retrieveEEGRanking() {
        const headers = {...this.#jsonHeader};
        const response = await this.#fetch({path: '/eeg/ranking', method: 'GET', headers});
        return await response.json();
    };

    // Internal
    async #fetch(options) {
        const {path, method, headers, body} = options;
        const credentials = 'include';
        const url = new URL(path, API_URL);
        const response = await fetch(url, {method, headers, body, credentials});

        if (response.ok) {
            return response;
        }

        const errorMessage = (await response.json())?.errorMessage;
        const error = new Error(errorMessage || `Something went wrong (${response.status})`);
        error.status = response.status;
        throw error;
    };
}

const api = new FYMAPI();
export default api;
