import Button from 'react-bootstrap/Button';
import { t } from '../../i18n';

export default function DetailedError(props) {
    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px'
    };

    return (
        <div className="detailedErrorWrapper" style={styles}>
            <h1 style={{fontWeight: '700'}}>{t('common.error')}</h1>
            <span style={{maxWidth: '280px', textAlign: 'center'}}>{props.message}</span>
            <Button variant="primary" style={{marginTop: '4px'}} onClick={props.onRetry}>{t('common.retry')}</Button>
        </div>
    );
}
