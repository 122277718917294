import React from 'react';
import ReactGA from 'react-ga4';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import PlayerDetailsModal from '../Modals/PlayerDetailsModal';
import BananaAmount from './../Widgets/BananaAmount';
import { SharedStateContext } from './../../Helpers/SharedState';
import { GAME_CONSTANTS } from './../../config';
import { t } from '../../i18n';

import fightIcon from '../../Assets/FightIcon.png';
import skullIcon from '../../Assets/SkullIcon.png';

import './MonkeyListItem.css';

function MonkeyListItem(props) {
    const [isWeightMismatchModalShown, setWeightMismatchModalShown] = React.useState(false);
    const [isInsufficientBananasModalShown, setInsufficientBananasModalShown] = React.useState(false);
    const [isPlayerDetailsModalShown, setPlayerDetailsModalShown] = React.useState(false);
    const [sharedState, {loginAntelope, loginMetaMask}] = React.useContext(SharedStateContext);

    const monkey = props.monkey;
    const myMonkey = props.myMonkey;
    const fight = props.onFightPicked;
    const isUserLoggedIn = sharedState.isUserLoggedIn;
    const hasBananas = (monkey.bananasAmount > 0);
    const canFight = isSameWeightClass(myMonkey, monkey);
    const isMyMonkeyReady = hasBananas;
    const isListItemActive = ((isMyMonkeyReady && monkey.canFight) || !isUserLoggedIn);
    const opacity = isListItemActive ? 1 : 0.33;
    
    function login() {
        window.ethereum ? loginMetaMask() : loginAntelope();
    };

    function onFightButtonClicked(event) {
        event.stopPropagation();

        if (isUserLoggedIn) {
            ReactGA.event('fight');

            if (myMonkey.bananasAmount < GAME_CONSTANTS.MIN_BANANA_REQUIRED_FIGHTING) {
                setInsufficientBananasModalShown(true);
            }
            else if (canFight) {
                fight(monkey);
            }
            else {
                setWeightMismatchModalShown(true);
            }
        }
        else {
            ReactGA.send({hitType: 'pageview', page: 'login-modal'});
            login();
        }
    };

    function status() {
        const userStatus = monkey.status.trim();

        if (isUserLoggedIn) {
            if (monkey.canFight) {
                return (
                    <Stack direction="horizontal" style={{gap: '0.1rem', marginRight: '4px'}}>
                        <div className="MonkeysListItem-status orange">{t('home.monkeysList.fightingStatus')}</div>

                        { userStatus &&
                            <div className="MonkeysListItem-userStatus"> - {userStatus}</div>
                        }
                    </Stack>
                );
            }
        }

        if (userStatus) {
            return <div className="MonkeysListItem-userStatus">{userStatus}</div>;
        }
    };

    function onItemClicked(event) {
        event.stopPropagation();
        setPlayerDetailsModalShown(true);
    };

    const bananaAmountStyle = {
        overflow: 'hidden', // Required for truncating to work
        opacity: opacity,
        flex: '0 1 auto'
    };

    const bananaAmountTextStyle = {
        fontWeight: 300,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    };

    const nameStatusStackStyle = {
        alignSelf: 'center',
        minWidth: 0, // Affects the overflowing text, which then pushes the other stack item out
        flex: '1 1 33%'
    };

    const accountNameStyle = {
        color: 'black',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        opacity: opacity
    };

    const dominanceOrder = (monkey.dominanceOrder > -1) ? (monkey.dominanceOrder + 1) : undefined;
    const isAlpha = (dominanceOrder === 1);
    const isUnderdog = (monkey.dominanceOrder === -1);

    return (
        <>
            <WeightClassMismatchModal
                isShown={isWeightMismatchModalShown}
                onCloseClicked={() => setWeightMismatchModalShown(false)}
            />

            <InsufficientBananasModal
                isShown={isInsufficientBananasModalShown}
                onCloseClicked={() => setInsufficientBananasModalShown(false)}
            />

            <PlayerDetailsModal
                isShown={isPlayerDetailsModalShown}
                monkey={props.monkey}
                onCloseClicked={() => setPlayerDetailsModalShown(false)}
            />

            <div className="MonkeysCard-scrollableListItem" onClick={onItemClicked}>
                <Stack direction="horizontal" style={{height: '100%'}}>
                    <Stack direction="vertical" style={nameStatusStackStyle}>
                        <div style={accountNameStyle}>
                            {monkey.name}
                            
                            { isAlpha &&
                                <sup className="MonkeysListItem-superscriptStatus alpha">{t('home.monkeysList.alphaStatus')}</sup>
                            }

                            { isUnderdog &&
                                <sup className="MonkeysListItem-superscriptStatus">{t('home.monkeysList.preyStatus')}</sup>
                            }

                            { (!isAlpha && dominanceOrder) &&
                                <sup className="MonkeysListItem-superscriptStatus">#{dominanceOrder}</sup>
                            }
                        </div>

                        {status()}
                    </Stack>

                    <BananaAmount amount={monkey.bananasAmount} compactFormatting={true} style={bananaAmountStyle} textStyle={bananaAmountTextStyle}/>

                    <button title={t('home.monkeysList.' + (canFight ? 'fightMonkey' : 'outOfWeightClass'))} className={'MonkeysListItem-actionButton ' + (canFight ? 'green' : 'red')} style={{opacity: opacity}} onClick={onFightButtonClicked} width="40px" height="40px" disabled={!isListItemActive}>
                        <img
                            src={canFight ? fightIcon : skullIcon}
                            className="actionButtonIcon"
                            alt="fight-monkey"
                        />
                    </button>
                </Stack>
            </div>
        </>
    );
};

export default MonkeyListItem;

// MARK: Helpers
function isSameWeightClass(myMonkey, opponentMonkey) {
    if (!myMonkey) {
        return false;
    }

    return (calcWeightDifference(myMonkey, opponentMonkey) <= GAME_CONSTANTS.MAX_WEIGHT_DIFF);
};

function calcWeightDifference(myMonkey, opponentMonkey) {
    const myWeight = myMonkey.bananasAmount;
    const opponentWeight = opponentMonkey.bananasAmount;

    if (myWeight === opponentWeight) {
        return 0;
    }
    else if (myWeight < opponentWeight) {
        return (opponentWeight - myWeight) / myWeight;
    }
    else {
        return (myWeight - opponentWeight) / opponentWeight;
    }
};

// MARK: Modal
function BasicModal(props) {
    return (
        <Modal show={props.isShown} size="sm" backdrop="static" backdropClassName="modal-backdrop-blurred" centered>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p style={{marginBottom: 0, textAlign: 'center'}}>{props.message}</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={props.onCloseClicked}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}

function WeightClassMismatchModal(props) {
    return <BasicModal
        title={t('home.monkeysList.weightMismatch')}
        message={t('home.monkeysList.weightMismatchMessage')}
        {...props}
    />;
};

function InsufficientBananasModal(props) {
    return <BasicModal
        title={t('home.monkeysList.insufficientBananas')}
        message={t('home.monkeysList.insufficientBananasMessage', {bananasRequired: GAME_CONSTANTS.MIN_BANANA_REQUIRED_FIGHTING})}
        {...props}
    />;
};
