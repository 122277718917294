import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import ReactGA from 'react-ga4';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { postEvent, on } from '@tma.js/sdk-react';

import { isMobileDevice } from '../Helpers/Utils';
import { SharedStateContext } from '../Helpers/SharedState';
import { EOSIO_BLOCKCHAINS, getBlockchainName } from '../config';

import './Header.css';
import logoImage from '../Assets/Logo.png';
import phantomLogoImage from '../Assets/PhantomWalletLogo.png';
import wombatLogoImage from '../Assets/WombatLogo.png';
import metaMaskLogoImage from '../Assets/MetaMaskLogo.png';
import antelopeLogoImage from '../Assets/AntelopeLogo.png';
import cryptoWalletImage from '../Assets/CryptoWalletIcon.png';

const antelopeBlockchains = Object
    .values(EOSIO_BLOCKCHAINS)
    .map(chain => getBlockchainName(chain))
    .join(' | ');

function Header() {
    const {t} = useTranslation();
    const [sharedState, {loginAntelope, loginPhantom, loginMetaMask, logout}] = React.useContext(SharedStateContext);
    const iconPath = iconPathForChain(sharedState);
    const isUserLoggedIn = sharedState.isUserLoggedIn;
    const isTMA = sharedState.isTMA;

    // Show a dedicated button allowing TMA uses seamless login
    React.useEffect(() => {
        if (isTMA && isMobileDevice()) {
            // Don't post event if not running inside a TMA
            // as it will crash the entire app. The value
            // itself can only change from [false > true]
            postEvent('web_app_setup_main_button', {
                is_visible: (!isUserLoggedIn && !sharedState.solanaSession), // Hide after connection is established
                text: 'Connect Phantom',
                color: '#AB9FEE',
                text_color: '#1C1C1C'
            });
            
            on('main_button_pressed', () => { sendAnalyticsEvent('tma'); loginPhantom(); });
        }
    }, [isTMA, isUserLoggedIn, sharedState.solanaSession]);

    function sendAnalyticsEvent(type) {
        ReactGA.send({hitType: 'pageview', page: `login-modal-${type}`});
    };

    function downloadWallet() {
        window.open('https://www.greymass.com/anchor#download', '_blank').focus();
    };

    return (
        <header>
            <Stack className="Header-stack col-12 col-md-10 col-xl-8 col-xxl-6 px-3 px-md-0 mx-auto" direction="horizontal" gap={2}>
                <Link to="/">
                    <img src={logoImage} className="Header-logo" alt="logo"/>
                </Link>

                {/* We might get correct `isUserLoggedIn` back with a tiny delay */}
                { (isUserLoggedIn && iconPath) &&
                    <>
                        <img className="Header-blockchainIcon" src={iconPath} alt="blockchain-icon"/>
                        <Button variant="primary" onClick={logout}>{t('home.header.logout')}</Button>
                    </>
                }

                { (!isUserLoggedIn && !isTMA) &&
                    <DropdownButton title={t('home.header.login')} drop="down">
                        <Dropdown.Item onClick={() => { sendAnalyticsEvent('phantom'); loginPhantom(); }}><img src={phantomLogoImage} alt="phantom-logo"/>Phantom</Dropdown.Item>
                        <Dropdown.Item onClick={() => { sendAnalyticsEvent('metamask'); loginMetaMask(); }}><img src={metaMaskLogoImage} alt="metamask-logo"/>MetaMask</Dropdown.Item>
                        <Dropdown.Item onClick={() => { sendAnalyticsEvent('wombat'); loginAntelope(); }}><img src={wombatLogoImage} alt="wombat-logo"/>Wombat</Dropdown.Item>
                        <Dropdown.Item onClick={() => { sendAnalyticsEvent('antelope'); loginAntelope(); }}><img src={antelopeLogoImage} alt="antelope-logo"/>{antelopeBlockchains}</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => { sendAnalyticsEvent('noWallet'); downloadWallet(); }}><img src={cryptoWalletImage} alt="crypto-wallet"/>{t('home.header.noWalletOption')}</Dropdown.Item>
                    </DropdownButton>
                }

                {/* Telegram Mini App only supports Solana */}
                { (!isUserLoggedIn && isTMA) &&
                    <Button variant="primary" onClick={() => { sendAnalyticsEvent('tma'); loginPhantom(); }}>{t('home.header.login')}</Button>
                }
            </Stack>
        </header>
    );
}

export default Header;

// Helpers
function iconPathForChain(sharedState) {
    if (sharedState.isRunningOnNative) {
        return iconPathForNativeChain(sharedState.chain);
    }

    if (sharedState.evmSession) {
        return iconPathForEVMChain(sharedState.evmSession.chainID);
    }

    if (sharedState.solanaSession) {
        return `${process.env.PUBLIC_URL}SOLANA.png`;
    }
}

function iconPathForNativeChain(chain) {
    const name = getBlockchainName(chain);
    return (process.env.PUBLIC_URL + `/${name.toUpperCase()}.png`);
};

function iconPathForEVMChain(chainID) {
    switch (chainID) {
        case 40:
        case 41:
            return `${process.env.PUBLIC_URL}TELOS.png`;
        case 56:
        case 97:
            return `${process.env.PUBLIC_URL}BSC.png`;
        default:
            return;
    }
};
