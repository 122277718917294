import React from 'react';
import secureLocalStorage from 'react-secure-storage';
import { APIClient } from '@wharfkit/antelope';
import { isTMA, postEvent } from '@tma.js/sdk-react';

import ToastMessage from '../Components/ToastMessage';
import FYMSession from './FYMSession';
import FYMSolanaSession from './FYMSolanaSession';
import FYMEVMSession from './FYMEVMSession';
import SolanaTMAProvider from './SolanaTMAProvider';
import FYMRPC from './FYMRPC';
import EVMWalletAPI from './EVMWalletAPI';
import SolanaWalletAPI from './SolanaWalletAPI';
import sessionKit from './SessionKit';
import localSettings from './LocalSettings';
import { isMobileDevice } from './Utils';
import { SESSION_KEY, EOSIO_BLOCKCHAINS, DEFAULT_BLOCKCHAIN, getBlockchainByID } from '../config';

const recentChain = getRecentChain();
const defaultAPIClient = new APIClient({url: recentChain.url});
const defaultRPC = new FYMRPC(defaultAPIClient, recentChain);

const LAST_MAJOR_UPDATE_VERSION = '1.16.0';
export const SharedStateContext = React.createContext();

/* eslint-disable react-hooks/exhaustive-deps */
// Adding inventoryAssets into React.useEffect dependencies
// makes no sense. We don't care about them changing
export const SharedStateProvider = (props) => {
    const [showsNewUpdateMessage, setShowNewUpdateMessage] = React.useState(false);
    const [state, setState] = React.useState({
        session: undefined,
        evmSession: undefined,
        solanaSession: undefined,
        isRunningOnNative: true,
        isUserLoggedIn: false,
        // This is an EOSIO chain. We need to have it at
        // hand before the session is even created/restored
        // since the data must be fetched before that
        chain: getRecentChain(),
        rpc: defaultRPC,
        inventoryAssets: undefined,
        isTMA: false // Running as Telegram Mini App
    });

    const updateState = (newProps, callback) => {
        setState(prevState => ({...prevState, ...newProps}));
    };

    const setAntelopeSession = (session) => {
        updateState({
            session: session,
            chain: session.chain,
            rpc: new FYMRPC(session.client, session.chain) // Set it right away, so the wrong RPC won't get used
        });

        saveRecentChain(session.chain);
    };

    const loginAntelope = async () => {
        const response = await sessionKit.login();
        setAntelopeSession(response.session);
    };

    const loginPhantom = async () => {       
        if (state.isTMA) {
            // A custom TMA provider used specifically for TMA
            window.solana = new SolanaTMAProvider();
        }
        
        if (window.solana) {
            try {
                const walletAPI = new SolanaWalletAPI(window.solana);
                let address;

                // The user could possibly revisit the website with different account
                // selected. The `connect` event won't be triggered unless we force
                // connect it. This is necessary otherwise trx signing will fail
                if (FYMSolanaSession.isUserLoggedIn && !window.solana?.isConnected) {
                    address = await walletAPI.connect();

                    // Should already be logged out in `SolanaWalletAPI` but
                    // handle it in case of event listener failing here toos
                    if (address !== FYMSolanaSession.recentlyUsedAddress) {
                        return logout();
                    }
                }
                else {
                    address = await walletAPI.connect();
                }
                
                const solanaSession = new FYMSolanaSession(walletAPI, address);
                updateState({solanaSession, chain: EOSIO_BLOCKCHAINS['telos']});
            }
            catch (error) {
                // Ignore errors thrown upon the user cancelling the form
                if (error.code !== 4001) {
                    console.log(error);
                }
            }
        }
        else if (isMobileDevice()) {
            window.open('https://phantom.app/ul/browse/https%3A%2F%2Ffeedyourmonkey.today');
        }
        else {
            alert('Phantom Wallet not detected');
        }
    };

    const loginMetaMask = async () => {
        if (window.ethereum) {
            const isConnected = window.ethereum.isConnected();
            const walletAPI = new EVMWalletAPI(window.ethereum);

            // The MetaMask does not seem to establish connection every time
            // the website loads due an error mentioned below, but it is not
            // the only issue causing problems. Calling `eth_requestAccounts`
            // or requesting the current chainID isn't also returning anything
            // or it takes minutes until the response arrives even though
            // `isConnected` returns true.
            // https://community.metamask.io/t/provider-not-getting-connected/27309/2
            // Since the user is logged in already, use their session and compare
            // currently selected address in `EVMLoginModal` when possible
            if (FYMEVMSession.isUserLoggedIn) {
                if (!isConnected) {
                    return logout();
                }

                const selectedAddress = FYMEVMSession.recentlyUsedAddress;
                const chainID = FYMEVMSession.recentlyUsedChainID;
                const evmSession = new FYMEVMSession(walletAPI, selectedAddress, chainID);
                updateState({evmSession, chain: EOSIO_BLOCKCHAINS['telos']});
            }
            else {
                const selectedAddress = await walletAPI.getSelectedAddress();

                if (selectedAddress) {
                    const chainID = await walletAPI.getChainID();
                    const evmSession = new FYMEVMSession(walletAPI, selectedAddress, chainID);
                    updateState({evmSession, chain: EOSIO_BLOCKCHAINS['telos']});
                }
            }
        }
        else if (isMobileDevice()) {
            window.open("https://metamask.app.link/dapp/feedyourmonkey.today"); // Alternatively: dapp://feedyourmonkey.today
        }
        else {
            alert('MetaMask not detected');
        }
    };

    const logout = () => {
        // In case of EVM we might use 2 sessions when fully logged in
        // Furthermore, the session might not exist yet but data yes
        if (state.evmSession || FYMEVMSession.isUserLoggedIn) {
            FYMEVMSession.logout();
        }

        if (state.solanaSession || FYMSolanaSession.isUserLoggedIn) {
            FYMSolanaSession.logout();
        }

        if (state.session) {
            sessionKit.logout(sessionKit.session)
        }

        localSettings.clear();

        updateState({
            session: undefined,
            evmSession: undefined,
            solanaSession: undefined
        });
    };

    const showMajorChangesUpdate = () => {
        localStorage.setItem('changes_update_shown', LAST_MAJOR_UPDATE_VERSION);
        setShowNewUpdateMessage(true);
    };

    const hideMajorChangesUpdate = () => {
        setShowNewUpdateMessage(false);
    };

    if (state.session?.constructor.name !== FYMSession.name) {
        state.session = state.session ? new FYMSession(state.session) : null;
    }

    React.useEffect(() => {
        // Restore the session on page visit. Pass the chainID
        // to avoid restoring incorrect chain that could be used as
        // a secondary login (in case of an IBC transfer for example)
        sessionKit.restore({chain: state.chain.id.toString()})
            .then(restored => updateState({session: restored}))
            .catch(() => {
                // Might be returned if there is no sessions available
                // even though we're trying to restore a particular chain
            });
            
        // New changes pop-up message
        if (shouldShowMajorChangesUpdate()) {
            showMajorChangesUpdate();
        }
        
        // Restore EVM login if available
        if (FYMEVMSession.isUserLoggedIn) {
            loginMetaMask();
        }

        if (FYMSolanaSession.isUserLoggedIn) {
            loginPhantom();
        }

        // Check if the app is running as Telegram Mini App
        (async function checkIfTMA() {
            if (await isTMA()) {
                postEvent('web_app_expand'); // Make the pop-up bigger too
                updateState({isTMA: true});
            }
        })();
    }, []);

    React.useEffect(() => {
        // Must be triggered manually as the `isTMA` is set after
        // we already tried auto logging in with Phantom
        if (state.isTMA && FYMSolanaSession.isUserLoggedIn && !state.session) {
            loginPhantom();
        }
    }, [state.isTMA]);
    
    React.useEffect(() => {
        // Reset on logout
        if (!state.session && state.inventoryAssets !== undefined) {
            updateState({inventoryAssets: undefined});
            secureLocalStorage.removeItem(SESSION_KEY.PK_NAME);
            recordKeyRemoval();
        }
    }, [state.session]);

    React.useEffect(() => {
        updateState({
            isRunningOnNative: (!state.evmSession && !state.solanaSession),
            isUserLoggedIn: !!(state.session || state.evmSession?.isFullyInitialized || state.solanaSession?.isFullyInitialized)
        });
    }, [state.session, state.evmSession, state.solanaSession]);

    const sharedValues = [
        state,
        {updateState, setAntelopeSession, loginAntelope, loginPhantom, loginMetaMask, logout}
    ];

    return (
        <SharedStateContext.Provider value={sharedValues}>
            <ToastMessage.MajorChangesUpdate
                isShown={showsNewUpdateMessage}
                onCloseClicked={hideMajorChangesUpdate}
            />

            {props.children}
        </SharedStateContext.Provider>
    );
};

// Helpers
function recordKeyRemoval() {
    localStorage.setItem('sk_removal_reason', 'logout at ' + new Date());
};

function shouldShowMajorChangesUpdate() {
    if (localStorage.getItem('changes_update_shown') === LAST_MAJOR_UPDATE_VERSION) {
        return false;
    }

    // Avoid showing update changes to a completely new users
    if (!localStorage.getItem('exploreJungleIntroRequired')) {
        localStorage.setItem('changes_update_shown', LAST_MAJOR_UPDATE_VERSION);
        return false;
    }

    return true;
};

function saveRecentChain(chain) {
    localStorage.setItem('fym-chain', chain.id.toString());
};

function getRecentChain() {
    // the chainID might be available, but it might not
    // be found if it was a testnet chainID
    const chainID = localStorage.getItem('fym-chain');
    const chain = (chainID && getBlockchainByID(chainID));
    return (chain || DEFAULT_BLOCKCHAIN);
};
