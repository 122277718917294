import { encodeBase58 } from 'ethers';
import { Transaction, Connection, PublicKey, TransactionInstruction } from '@solana/web3.js';
import { SOLANA_API_URL } from '../config';
import { Buffer } from 'buffer';

const TOKEN_2022_PROGRAM_ID = new PublicKey('TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb');
const MEMO_PROGRAM_ID = new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr');
const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

class SolanaWalletAPI {
    #connection;

    constructor(provider) {
        this.provider = provider;
        this.#connection = new Connection(SOLANA_API_URL);
    };

    async connect() {
        const response = await this.provider.connect({onlyIfTrusted: false});
        return response.publicKey.toString();
    };

    async signIn(siwe) {
        const result = await this.provider.signIn(siwe);
        return encodeBase58(result.signature);
    };

    async signData(data) {
        if (typeof data === 'string') {
            const encodedMessage = new TextEncoder().encode(data);
            const result = await this.provider.signMessage(encodedMessage, 'utf8');
            return encodeBase58(result.signature);
        }
        else {
            throw new Error('Unsupported Data Type');
        }
    };

    // RPC
    async fetchTokenAccount(tokenAddress, ownerAddress) {
        const mintPublicKey = new PublicKey(tokenAddress);
        const ownerPublicKey = new PublicKey(ownerAddress);

        // `getTokenAccountsByOwner` is pretty costly and not many RPCs allow it
        const [address] = PublicKey.findProgramAddressSync(
            [ownerPublicKey.toBuffer(), TOKEN_2022_PROGRAM_ID.toBuffer(), mintPublicKey.toBuffer()],
            ASSOCIATED_TOKEN_PROGRAM_ID
        );
        
        return address;
    };

    async fetchBalanceOf(tokenAddress, ownerAddress) {
        const tokenAccount = await this.fetchTokenAccount(tokenAddress, ownerAddress);

        try {
            const balanceResponse = await this.#connection.getTokenAccountBalance(tokenAccount);
            const balance = balanceResponse?.value.uiAmount;
            return balance;
        }
        catch (error) {
            // Very likely incorrect token account address
            // meaning the account doesn't exist (no balance)
            if (error.code === -32602) {
                return 0;
            }

            throw error;
        }
    };

    async burnTokens(tokenAddress, ownerAddress, amount, memo) {
        const mintPublicKey = new PublicKey(tokenAddress);
        const ownerPublicKey = new PublicKey(ownerAddress);
        const tokenAccount = await this.fetchTokenAccount(mintPublicKey, ownerPublicKey);
        const transaction = new Transaction();
        const burnInstructionData = Buffer.alloc(9);

        burnInstructionData.writeUInt8(8, 0); // Burn instruction number
        burnInstructionData.writeBigUInt64LE(BigInt(amount), 1); // Amount to burn

        const burnInstruction = new TransactionInstruction({
            keys: [
                {pubkey: tokenAccount, isSigner: false, isWritable: true},
                {pubkey: mintPublicKey, isSigner: false, isWritable: true},
                {pubkey: ownerPublicKey, isSigner: true, isWritable: false}
            ],
            data: burnInstructionData,
            programId: TOKEN_2022_PROGRAM_ID
        });

        transaction.add(burnInstruction);

        if (memo) {
            const memoInstruction = new TransactionInstruction({
                keys: [{pubkey: ownerPublicKey, isSigner: true, isWritable: true}],
                data: Buffer.from(memo, 'utf-8'),
                programId: MEMO_PROGRAM_ID
            });

            transaction.add(memoInstruction);
        }

        transaction.recentBlockhash = (await this.#connection.getLatestBlockhash()).blockhash;
        transaction.feePayer = ownerPublicKey;

        const {signature} = await this.provider.signAndSendTransaction(transaction);
        return signature;
    };

    async confirmTransaction(trxID) {
        const result = await this.#connection.confirmTransaction(trxID, 'finalized');
        return result?.value.err;
    };
};

export default SolanaWalletAPI;
