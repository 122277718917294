import { APIClient } from '@wharfkit/antelope';
import { ContractKit } from '@wharfkit/contract';
import {
    FYM_CONTRACT_NAME,
    TOKEN_CONTRACT_NAME,
    STAKING_CONTRACT_NAME,
    AA_CONTRACT_NAME,
    BANANA_TREE_TEMP_ID
} from '../config';

class FYMRPC {
    #client;
    #contractKit;
    #chain;

    constructor(apiClient, chain) {
        // Default to using EOS
        this.#client = apiClient.v1;
        this.#contractKit = new ContractKit({client: apiClient});
        this.#chain = chain;
    };

    async fetchEosioAccount(accountName) {
        return await this.#client.chain.get_account(accountName);
    };

    async fetchMonkeyDetails(accountName) {
        const response = await this.#client.chain.get_table_rows({
            code: FYM_CONTRACT_NAME,
            scope: FYM_CONTRACT_NAME,
            table: 'monkeys',
            lower_bound: accountName,
            upper_bound: accountName,
            limit: 1
        });

        return response.rows[0];
    };

    async fetchExplorerDetails(accountName) {
        const response = await this.#client.chain.get_table_rows({
            code: FYM_CONTRACT_NAME,
            scope: FYM_CONTRACT_NAME,
            table: 'explorers',
            lower_bound: accountName,
            upper_bound: accountName,
            limit: 1
        });

        return response.rows[0];
    };

    async fetchInventoryAssets(accountName) {
        const response = await this.#client.chain.get_table_rows({
            code: FYM_CONTRACT_NAME,
            scope: accountName,
            table: 'inventory',
            limit: 100
        });

        return response.rows;
    };

    async fetchEquipmentAssets(accountName) {
        const response = await this.#client.chain.get_table_rows({
            code: FYM_CONTRACT_NAME,
            scope: accountName,
            table: 'equipment',
            limit: 100
        });

        return response.rows;
    };

    async fetchAllMonkeys() {
        let accounts = [];
        let nextKey = 10_0000; // Fetch only accounts with 10+ BANANA

        do {
            const response = await this.#client.chain.get_table_rows({
                code: FYM_CONTRACT_NAME,
                scope: FYM_CONTRACT_NAME,
                table: 'monkeys',
                index_position: 'secondary',
                key_type: 'i64',
                lower_bound: nextKey,
                limit: 333
            });

            nextKey = response['next_key']; // More results available
            accounts = accounts.concat(response.rows);
        } while (nextKey);
        
        return accounts;
    };

    async fetchAllStakers() {
        let stakers = [];
        let nextKey = null;

        do {
            const response = await this.#client.chain.get_table_rows({
                code: FYM_CONTRACT_NAME,
                scope: FYM_CONTRACT_NAME,
                table: 'farming',
                lower_bound: nextKey,
                limit: 333
            });
            
            nextKey = response['next_key']; // More results available
            stakers = stakers.concat(response.rows);
        } while (nextKey);

        return stakers;
    };

    async fetchLatestFightLogs(accountName) {
        // Hotfix until wharfkit antelope resolves issue
        // regarding response parsing where `head_block_num` should
        // be optional as other node providers do not return it
        const apiClient = new APIClient({url: this.#chain.url});
        const response = await apiClient.call({
            path: '/v1/history/get_actions',
            method: 'POST',
            params: {
                account_name: accountName,
                pos: -1,
                offset: -100,
            },
        });

        // Actions are ordered differently if provided by greymass or others
        const actions = response.actions.sort((ac1, ac2) => {
            const blockTime1 = new Date(ac1['block_time']);
            const blockTime2 = new Date(ac2['block_time']);
            return (blockTime2 - blockTime1);
        });
        
        let fightLogs = [];

        // The array is sorted from the oldest to the newest
        for (let i = 0; i < actions.length; i++) {
            const actionTrace = actions[i]['action_trace'];
            const act = actionTrace['act'];

            // Only return logs since the last fight
            if (act.account === FYM_CONTRACT_NAME && act.name === 'fight') {
                break;
            }

            if (act.account === FYM_CONTRACT_NAME && act.name === 'logduel') {
                fightLogs.push(act.data);
            }
        }

        return fightLogs;
    };

    async fetchStakingDetails(accountName) {
        const response = await this.#client.chain.get_table_rows({
            code: STAKING_CONTRACT_NAME,
            scope: STAKING_CONTRACT_NAME,
            table: 'stakers',
            lower_bound: accountName,
            upper_bound: accountName,
            limit: 1
        });

        return response.rows[0];
    };

    async fetchStakingStats() {
        const response = await this.#client.chain.get_table_rows({
            code: STAKING_CONTRACT_NAME,
            scope: STAKING_CONTRACT_NAME,
            table: 'stats'
        });

        return response.rows[0];
    };

    async fetchBananaSupply() {
        const response = await this.#client.chain.get_table_rows({
            code: TOKEN_CONTRACT_NAME,
            scope: 'BANANA',
            table: 'stat'
        });

        return parseFloat(response.rows?.[0]?.['supply']);
    };

    async fetchPeelSupply() {
        const response = await this.#client.chain.get_table_rows({
            code: TOKEN_CONTRACT_NAME,
            scope: 'PEEL',
            table: 'stat'
        });

        return parseFloat(response.rows?.[0]?.['supply']);
    };

    async fetchPlantationFieldOwners() {
        let owners = [];
        let nextKey = null;

        do {
            const response = await this.#client.chain.get_table_by_scope({
                code: FYM_CONTRACT_NAME,
                table: 'trees',
                lower_bound: nextKey,
                limit: 600
            });

            const filteredOwners = response.rows
                .map(row => ({name: row.scope.toString(), treesCount: row.count}));

            nextKey = response['more']; // More results available
            owners = owners.concat(filteredOwners);
        } while (nextKey);

        return owners;
    };

    async fetchBananaTrees(owners) {
        const contract = await this.#contractKit.load(FYM_CONTRACT_NAME);
        const result = await contract.readonly('gettrees', {owners});

        // The result is actually an array of `pair` type
        return result.reduce((obj, record) => {
            obj[record['first']] = record['second'];
            return obj;
        }, {});
    };
    
    async fetchBananaTreeNFTs(accountName) {
        const chainName = this.#chain.name.toLowerCase();
        const treeTemplateID = BANANA_TREE_TEMP_ID[chainName];
        
        let trees = [];
        let nextKey = null;

        do {
            const response = await this.#client.chain.get_table_rows({
                code: AA_CONTRACT_NAME,
                scope: accountName,
                table: 'assets',
                lower_bound: nextKey,
                limit: 100
            });

            nextKey = response['next_key']; // More results available

            trees = trees.concat(response.rows.filter(r => {
                const collectionName = r['collection_name'];
                const schemaName = r['schema_name'];
                const templateID = r['template_id'];
                const isTree = (collectionName === FYM_CONTRACT_NAME && schemaName === 'trees' && templateID === treeTemplateID);
                return isTree;
            }));
        } while (nextKey);

        return trees;
    };

    async fetchBananasBalance(accountName) {
        return this.#fetchTokenBalance(accountName, TOKEN_CONTRACT_NAME, 'BANANA');
    };

    async fetchPeelsBalance(accountName) {
        return this.#fetchTokenBalance(accountName, TOKEN_CONTRACT_NAME, 'PEEL');
    };

    async fetchEOSIOBalance(accountName) {
        // EOS, WAX, Telos
        return await this.#fetchTokenBalance(accountName, 'eosio.token');
    };

    async fetchBOXCFZBalance(accountName) {
        return this.#fetchTokenBalance(accountName, 'lptoken.defi', 'BOXCFZ');
    };

    // Helpers
    async #fetchTokenBalance(accountName, contractName, symbol) {
        const response = await this.#client.chain.get_table_rows({
            code: contractName,
            scope: accountName,
            table: 'accounts'
        });

        const rows = response.rows;
        const row = symbol ? rows.find(r => r['balance'].endsWith(symbol)) : rows[0];
        
        return parseFloat(row?.['balance'] || 0);
    };
};

export default FYMRPC;