import React from 'react';
import Stack from 'react-bootstrap/Stack';

import { isMobileDevice } from '../Helpers/Utils';
import { SharedStateContext } from '../Helpers/SharedState';

function TMALockScreen() {
    const [sharedState] = React.useContext(SharedStateContext);

    const style = {
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: 100,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: 'rgb(0, 0, 0, 0.8)',
        backdropFilter: 'blur(10px)',
        color: 'rgba(255, 255, 255, 0.8)'
    };

    if (sharedState.isTMA && !isMobileDevice()) {
        document.documentElement.style.overflow = 'hidden'; // Stop scrolling
        
        return (
            <Stack style={style}>
                This Telegram Mini App is available exclusively on mobile devices, but don't worry! You can still play the game directly in your browser at <a href="https://feedyourmonkey.today" target="_blank" rel="noreferrer">www.feedyourmonkey.today</a>
            </Stack>
        );
    }
};

export default TMALockScreen;