import { Asset } from '@wharfkit/antelope';
import { parseUnits } from 'ethers';
import { t } from '../i18n';
import { getExplorerURL } from '../config';

export const formatAmount = function(precision = 4) {
    // Without `roundingMode`, numbers like `1181.8048` will
    // be rounded up and shown as `1181.805`. Furthermore
    // take into account that JS's float type might provide
    // us numbers with more than just 4 decimals
    return Intl.NumberFormat(undefined, {
        roundingMode: 'trunc',
        minimumFractionDigits: 0,
        maximumFractionDigits: precision
    }).format;
}();

export const formatAmountCompactly = function() {
    // 16M, 6k etc
    return Intl.NumberFormat(undefined, {notation: 'compact'}).format;
}();

export const formatGenericAmount = function() {
    return Intl.NumberFormat().format;
}();

export const formatCurrencyAmount = function(amount, currency) {
    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency
    }).format(amount);
};

export const unitsFromAmount = function(amountString, precision) {
    // If not "sanitized" the `parseUnits` may fail in case if
    // the string contains more decimal places than the precision
    const sanitizedAmount = sanitizeAmount(amountString, precision);
    return parseUnits(sanitizedAmount, precision);
};

export const formatPercentages = function() {
    return Intl.NumberFormat(undefined, {style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2}).format;
}();

export const formatBananasTokenAmount = function(amount) {
    return formatTokenAmount(amount, 'BANANA', 4);
};

export const formatPeelsTokenAmount = function(amount) {
    return formatTokenAmount(amount, 'PEEL', 4);
};

export const formatEOSTokenAmount = function(amount) {
    return formatTokenAmount(amount, 'EOS', 4);
};

export const formatTLOSTokenAmount = function(amount) {
    return formatTokenAmount(amount, 'TLOS', 4);
};

export const formatWAXTokenAmount = function(amount) {
    return formatTokenAmount(amount, 'WAX', 8);
};

export const formatTokenAmount = function(amount, symbol, precision) {
    return Asset.from(parseFloat(amount), `${precision},${symbol}`).toString();
};

export const tokenAmountToBigInt = function(amount) {
    const decimalNumberString = amount.split(' ')[0]; // 1.0000 EOS
    const intNumberString = decimalNumberString.replace('.', ''); // 1000
    return BigInt(intNumberString);
};

export const transactionDetailsURL = function(trxHash, chain) {
    const explorerURL = getExplorerURL(chain);
    
    // Hotfix explorer's url
    if (explorerURL.host === 'bloks.io' || explorerURL.host.endsWith('telos.net')) {
        return new URL('transaction/' + trxHash, explorerURL).toString();
    }
    else {
        return new URL('tx/' + trxHash, explorerURL).toString();
    }
};

export const accountDetailsURL = function(accountName, chain) {
    const explorerURL = getExplorerURL(chain);
    return new URL('account/' + accountName, explorerURL).toString();
};

export const randomNumber = function(min, max) {
    return (Math.random() * (max - min) + min);
};

export const calcTimeRemaining = function(endsDate) {
    // `getSeconds`, `getHours` did not report correct
    // times to all users (probably based on the local time)
    const timeRemaining = (endsDate.getTime() - Date.now());
    const seconds = Math.floor((timeRemaining / 1000) % 60);
    const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
    const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

    if (days) {
        return t('utils.daysShort', {days});
    }

    if (hours) {
        if (minutes) {
            return (t('utils.hoursShort', {hours}) + ', ' + t('utils.minutesShort', {minutes}));
        }

        return t('utils.hoursShort', {hours});
    }

    if (minutes) {
        return t('utils.minutesShort', {minutes});
    }

    return t('utils.secondsShort', {seconds});
};

export const isMobileDevice = function() {
    return /iPhone|iPad|iPod|Android|Mobi/i.test(navigator.userAgent);
};

// Private
function sanitizeAmount(amountString, precision) {
    // Make sure the inputed string does not contain
    // more decimals than the token may have
    const match = amountString.match(/^(\d*)([.,]?)(\d*)?$/);
    const separator = match?.[2];

    if (!separator) {
        return amountString;
    }

    const decimals = match[3];

    if (decimals.length <= precision) {
        return amountString;
    }
    
    const wholeNumber = match[1];
    const trimedDecimalNumber = decimals.slice(0, precision);

    return (wholeNumber + '.' + trimedDecimalNumber);
};
