import i18n, { t } from '../i18n';
import { GAME_CONSTANTS } from '../config';

import woodIcon from '../Assets/InventoryAssets/Wood.png';
import rockIcon from '../Assets/InventoryAssets/Rock.png';
import waterIcon from '../Assets/InventoryAssets/Water.png';
import mushroomIcon from '../Assets/InventoryAssets/Mushroom.png';
import figIcon from '../Assets/InventoryAssets/Fig.png';
import fireStarterKitIcon from '../Assets/InventoryAssets/FireStarterKit.png';
import starvePillIcon from '../Assets/InventoryAssets/StarvePill.png';
import ironIcon from '../Assets/InventoryAssets/Iron.png';

const InventoryAssetTypes = function() {
    let types = {
        BANANA_PEEL: 1, /* reserve the spot */
        WOOD: 2,
        ROCK: 3,
        WATER: 4,
        MUSHROOM: 5,
        FIRE_STARTER_KIT: 6,
        FIG: 7,
        STARVE_PILL: 8,
        IRON: 9
    };
    
    // Make it possible to initialize the enum with the value too
    Object.keys(types).forEach(type => {
        const rawValue = types[type];
        types[rawValue] = type;
    });

    return Object.freeze(types);
}();

const assetDetails = {
    [InventoryAssetTypes.WOOD]: {
        name: t('assets.wood.name'),
        icon: woodIcon
    },
    [InventoryAssetTypes.ROCK]: {
        name: t('assets.rock.name'),
        icon: rockIcon
    },
    [InventoryAssetTypes.WATER]: {
        name: t('assets.water.name'),
        icon: waterIcon
    },
    [InventoryAssetTypes.MUSHROOM]: {
        name: t('assets.mushroom.name'),
        icon: mushroomIcon
    },
    [InventoryAssetTypes.FIG]: {
        name: t('assets.fig.name'),
        icon: figIcon
    },
    [InventoryAssetTypes.FIRE_STARTER_KIT]: {
        name: t('assets.fireStarterKit.name'),
        description: t('assets.fireStarterKit.description'),
        icon: fireStarterKitIcon,
        recipe: {
            energyRequired: 2.5,
            assetsRequired: [
                [InventoryAssetTypes.WOOD, 2],
                [InventoryAssetTypes.ROCK, 2]
            ]
        }
    },
    [InventoryAssetTypes.STARVE_PILL]: {
        name: t('assets.starvePill.name'),
        description: { // Translations must be set once the files got downloaded
            tName: t('assets.starvePill.description'),
            tParams: {percentage: (GAME_CONSTANTS.STARVE_PILL_MAX_STARVING * 100)},
        },
        icon: starvePillIcon,
        price: 125,
        recipe: {
            energyRequired: 40,
            assetsRequired: [
                [InventoryAssetTypes.WATER, 1],
                [InventoryAssetTypes.FIG, 2]
            ]
        }
    },
    [InventoryAssetTypes.IRON]: {
        name: t('assets.iron.name'),
        description: t('assets.iron.description'),
        icon: ironIcon,
        recipe: {
            energyRequired: 5,
            assetsRequired: [
                [InventoryAssetTypes.FIRE_STARTER_KIT, 1],
                [InventoryAssetTypes.ROCK, 3]
            ]
        }
    }
};

const craftableAssets = Object
    .keys(assetDetails)
    .filter(id => !!assetDetails[id].recipe)
    .map(id => InventoryAssetTypes[id]);

const InventoryAssetsTypesObject = {
    Types: InventoryAssetTypes,
    craftableAssets: craftableAssets,
    assetDetails(type) {
        return assetDetails[InventoryAssetTypes[type]];
    },
    assetIDFromType(type) {
        return InventoryAssetTypes[type];
    },
    typeFromAssetID(assetID) {
        return (InventoryAssetTypes[assetID] || InventoryAssetTypes[-1]);
    }
};

// A workaround, triggered when the resources get loaded
i18n.on('loaded', function(loaded) {
    if (Object.keys(loaded).length) {
        Object
            .values(assetDetails)
            .forEach(asset => {
                asset.name = t(asset.name);

                // As the translations has not been initilised
                // `description` will default to a translation key
                // In some cases we however must use parameters too
                if (asset.description) {
                    const description = asset.description;
                    asset.description = t(description.tName || description, description.tParams);
                }
            });
    }
});

export default InventoryAssetsTypesObject;
