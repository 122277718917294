import { formatBananasTokenAmount } from './Utils';

import {
    FYM_CONTRACT_NAME,
    TOKEN_CONTRACT_NAME,
    STAKING_CONTRACT_NAME,
    WRAP_LOCK_CONTRACT_NAME,
    WRAPPED_TOKEN_CONTRACT_NAME,
    EVM_ANTELOPE_BRIDGE_CONTRACT_ADDRESS,
    SOLANA_BRIDGE_ACCOUNT,
    EVM_BRIDGE_ACCOUNT,
    SESSION_KEY,
    getBlockchainName,
    AA_CONTRACT_NAME
} from '../config';

export const fight = function(monkey, opponent) {
    const trx = {
        actions: [{
             account: FYM_CONTRACT_NAME,
             name: 'fight',
             authorization: [this.session.permissionLevel],
             data: {
                 'monkey': monkey.name,
                 'opponent': opponent.name,
                 'my_weight': formatBananasTokenAmount(monkey.bananasAmount),
                 'opponent_weight': formatBananasTokenAmount(opponent.bananasAmount)
             }
         }]
    };

    return this.session.transact(trx);
};

export const purchaseBanana = function(contractName, quantity, memo) {    
    const trx = {
        actions: [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': contractName,
                'quantity': quantity,
                'memo': memo
            }
        }]
    };

    return this.session.transact(trx);
};

export const bridgeNativeTokens = function(quantity, destinationChain, destinationAccountName) {
    const isNativeChain = (getBlockchainName(this.session.chain) === 'EOS');
    const destinationChainName = getBlockchainName(destinationChain).toLowerCase();
    let trx;

    if (isNativeChain) {
        trx = {
            actions: [{
                account: TOKEN_CONTRACT_NAME,
                name: 'transfer',
                authorization: [this.session.permissionLevel],
                data: {
                    'from': this.session.accountName,
                    'to': WRAP_LOCK_CONTRACT_NAME[destinationChainName],
                    'quantity': quantity,
                    'memo': destinationAccountName
                }
            }]
        };
    }
    else {
        trx = {
            actions: [{
                account: WRAPPED_TOKEN_CONTRACT_NAME,
                name: 'retire',
                authorization: [this.session.permissionLevel],
                data: {
                    'owner': this.session.accountName,
                    'quantity': quantity,
                    'beneficiary': destinationAccountName
                }
            }]
        };
    }

    return this.session.transact(trx);
};

export const submitProof = function(actions) {
    return this.session.transact({actions});
};

export const withdrawFromBridgeIntoGame = function(quantity) {
    const trx = {
        actions: [{
            account: EVM_BRIDGE_ACCOUNT,
            name: 'reqnotify',
            authorization: [this.session.permissionLevel],
            data: {}
        }, {
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': FYM_CONTRACT_NAME,
                'quantity': quantity,
                'memo': ''
            }
        }]
    };

    return this.session.transact(trx);
};

export const notifyBridge = function() {
    const trx = {
        actions: [{
            account: EVM_BRIDGE_ACCOUNT,
            name: 'reqnotify',
            authorization: [this.session.permissionLevel],
            data: {}
        }]
    };

    return this.session.transact(trx);
};

export const withdrawBananaFromAccountToEVM = function(evmAddress, bananasAmount) {
    const trx = {
        actions: [{
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': EVM_BRIDGE_ACCOUNT,
                'quantity': bananasAmount,
                'memo': evmAddress
            }
        }]
    };

    return this.session.transact(trx);
};

export const withdrawBananaFromAccountToSolana = function(toSolanaAddress, bananasAmount, bananaWithdrawalFee) {
    const trx = {
        actions: [{
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': 'bridgingfees',
                'quantity': bananaWithdrawalFee,
                'memo': ''
            }
        }, {
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': SOLANA_BRIDGE_ACCOUNT,
                'quantity': bananasAmount,
                'memo': toSolanaAddress
            }
        }]
    };

    return this.session.transact(trx);
};

export const withdrawBananaFromAccountToOFT = function(toAddress, bananasAmount, params) {
    const {bridgeAmountFee, bridgeAmountWeiFee, destinationChainID} = params;
    const {swapContractName, swapQuantity, swapMemo} = params;
    const isSwappingRequired = !!(swapContractName || swapQuantity || swapMemo);

    // - Swap $BANANA into $TLOS (if required)
    // - Bridge $TLOS to pay for the briding fee
    // - Bridge $BANANA
    const trx = {
        actions: [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': 'eosio.evm',
                'quantity': bridgeAmountFee,
                'memo': EVM_ANTELOPE_BRIDGE_CONTRACT_ADDRESS
            }
        }, {
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': EVM_BRIDGE_ACCOUNT,
                'quantity': bananasAmount,
                'memo': `${toAddress}:${bridgeAmountWeiFee}:${destinationChainID}`
            }
        }]
    };

    if (isSwappingRequired) {
        trx.actions.unshift({
            account: 'banana.moon',
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': swapContractName,
                'quantity': swapQuantity,
                'memo': swapMemo
            }
        });
    }

    return this.session.transact(trx);
};

export const exploreJungle = function(bananasAmount) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'expljungle',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'energy': bananasAmount
            }
        }]
    };

    return this.session.transact(trx);
};

export const showFoundAssets = function() {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'foundassets',
            authorization: [this.session.permissionLevel],
            data: {'monkey': this.session.accountName}
        }]
    };

    return this.session.transact(trx);
};

export const depositTokens = function(quantity) {
    const trx = {
        actions: [{
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': FYM_CONTRACT_NAME,
                'quantity': quantity,
                'memo': ''
            }
        }]
    };

    return this.session.transact(trx);
};

export const stakeLPTokens = function(quantity) {
    const trx = {
        actions: [{
            account: 'lptoken.defi',
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': FYM_CONTRACT_NAME,
                'quantity': quantity,
                'memo': ''
            }
        }]
    };

    return this.session.transact(trx);
};

export const unstakeLPTokens = function() {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'unstakelp',
            authorization: [this.session.permissionLevel],
            data: {'monkey': this.session.accountName}
        }]
    };

    return this.session.transact(trx);
};

export const stakeBananas = function(quantity, stakingPeriod) {
    const trx = {
        actions: [{
            account: TOKEN_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': STAKING_CONTRACT_NAME,
                'quantity': quantity,
                'memo': stakingPeriod
            }
        }]
    };

    return this.session.transact(trx);
};

export const unstakeBananas = function() {
    const trx = {
        actions: [{
            account: STAKING_CONTRACT_NAME,
            name: 'unstake',
            authorization: [this.session.permissionLevel],
            data: {'account': this.session.accountName}
        }]
    };

    return this.session.transact(trx);
};

export const claimDividends = function() {
    const trx = {
        actions: [{
            account: STAKING_CONTRACT_NAME,
            name: 'reqdividends',
            authorization: [this.session.permissionLevel],
            data: {'account': this.session.accountName}
        }]
    };

    return this.session.transact(trx);
};

export const starveMonkey = function(bananasAmount) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'starve',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'bananas': bananasAmount
            }
        }]
    };

    return this.session.transact(trx);
};

export const withdrawPeels = function(peelsAmount) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'getpeels',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'peels': peelsAmount
            }
        }]
    };

    return this.session.transact(trx);
};

export const setStatus = function(status) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'setstatus',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'status': status
            }
        }]
    };

    return this.session.transact(trx);
};

export const craftInvAsset = function(asset) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'craftasset',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'asset_id': asset.id
            }
        }]
    };

    return this.session.transact(trx);
};

export const purchaseInvAsset = function(asset) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'purchasset',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'asset_id': asset.id
            }
        }]
    };

    return this.session.transact(trx);
};

export const deleteInvAsset = function(asset, quantity) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'deleteasset',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'asset_id': asset.id,
                'quantity': quantity
            }
        }]
    };

    return this.session.transact(trx);
};

export const craftEquipment = function(equip) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'craftequip',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'equip_id': equip.id
            }
        }]
    };

    return this.session.transact(trx);
};

export const deleteEquipment = function(equip, quantity) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'deleteequip',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'equip_id': equip.id,
                'quantity': quantity
            }
        }]
    };

    return this.session.transact(trx);
};

export const setWeapons = function(weapons) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'setweapons',
            authorization: [this.session.permissionLevel],
            data: {
                'monkey': this.session.accountName,
                'weapons': weapons
            }
        }]
    };

    return this.session.transact(trx);
};

export const plantTree = function(assetID) {
    const trx = {
        actions: [{
            account: AA_CONTRACT_NAME,
            name: 'transfer',
            authorization: [this.session.permissionLevel],
            data: {
                'from': this.session.accountName,
                'to': FYM_CONTRACT_NAME,
                'asset_ids': [assetID],
                'memo': 'plant this tree'
            }
        }]
    };

    return this.session.transact(trx);
};

export const digupTree = function(treeID) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'diguptree',
            authorization: [this.session.permissionLevel],
            data: {
                'id': treeID,
                'owner': this.session.accountName
            }
        }]
    };

    return this.session.transact(trx);
};

export const harvestTree = function(treeID) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'harvesttree',
            authorization: [this.session.permissionLevel],
            data: {
                'id': treeID,
                'owner': this.session.accountName
            }
        }]
    };

    return this.session.transact(trx);
};

export const fertilizeTree = function(treeID, peelsAmount) {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'fertlztree',
            authorization: [this.session.permissionLevel],
            data: {
                'id': treeID,
                'owner': this.session.accountName,
                'peels': peelsAmount
            }
        }]
    };

    return this.session.transact(trx);
};

export const takeStarvePill = function() {
    const trx = {
        actions: [{
            account: FYM_CONTRACT_NAME,
            name: 'tkstarvepill',
            authorization: [this.session.permissionLevel],
            data: {'monkey': this.session.accountName}
        }]
    };

    return this.session.transact(trx);
};

export const setSessionKey = function(publicKey, actions) {
    const updateAuthAction = {
        account: 'eosio',
        name: 'updateauth',
        authorization: [this.session.permissionLevel],
        data: {
            account: this.session.accountName,
            permission: SESSION_KEY.PERM_NAME,
            parent: 'active',
            auth: {
                threshold: 1,
                accounts: [],
                keys: [{key: publicKey, weight: 1}],
                waits: []
            }
        }
    }

    const linkAuths = actions.map(actionName => {
        return {
            account: 'eosio',
            name: 'linkauth',
            authorization: [this.session.permissionLevel],
            data: {
                account: this.session.accountName,
                requirement: SESSION_KEY.PERM_NAME,
                code: FYM_CONTRACT_NAME,
                type: actionName
            }
        }
    });

    const trx = {actions: [updateAuthAction].concat(linkAuths)};
    return this.session.transact(trx);
};

export const deleteSessionKey = function(linkedActions) {
    const linkedAuths = linkedActions.map(actionName => {
        return {
            account: 'eosio',
            name: 'unlinkauth',
            authorization: [this.session.permissionLevel],
            data: {
                account: this.session.accountName,
                code: FYM_CONTRACT_NAME,
                type: actionName
            }
        }
    });

    const deleteAuthAction = {
        account: 'eosio',
        name: 'deleteauth',
        authorization: [this.session.permissionLevel],
        data: {
            account: this.session.accountName,
            permission: SESSION_KEY.PERM_NAME
        }
    };

    const trx = {actions: linkedAuths.concat(deleteAuthAction)};
    return this.session.transact(trx);
};

export const powerUp = function() {
    const trx = {
        actions: [{
            account: 'eosio',
            name: 'powerup',
            authorization: [this.session.permissionLevel],
            data: {
                cpu_frac: 209524943, // 8k
                net_frac: 104762472, // 1k
                max_payment: '0.0020 EOS', // 0.0016 EOS
                days: 1,
                payer: this.session.accountName,
                receiver: this.session.accountName
            }
        }]
    };

    return this.session.transact(trx);
};
