import Stack from 'react-bootstrap/Stack';
import { formatAmount, formatAmountCompactly } from '../../Helpers/Utils';
import bananaCoinLogo from '../../Assets/BananaLogo.png';

export default function BananaAmount(props) {
    const amountFormatted = props.compactFormatting ? formatAmountCompactly(props.amount) : formatAmount(props.amount);

    return (
        <Stack direction="horizontal" style={{...{gap: '4px'}, ...props.style}}>
            <img src={bananaCoinLogo} width="20" height="20" alt="banana-logo" style={{height: '1.3rem'}}/>
            <div ref={props.innerTextRef} style={props.textStyle}>{amountFormatted}</div>
        </Stack>
    );
}
