import Asset from './Asset';
import { formatTokenAmount } from '../Helpers/Utils';
import equipmentAssetTypes from '../Helpers/EquipmentAssetTypes';

class EquipmentAsset extends Asset {
    // Static
    static fromID(equipID, quantity) {
        return new EquipmentAsset({
            'id': equipID,
            'quantity': formatTokenAmount((quantity || 1), 'EQP', 2),
            'crafting_details': undefined
        });
    }

    // Getters
    constructor(obj) {
        const equipID = obj['id'];
        const equipType = equipmentAssetTypes.typeFromEquipmentID(equipID);
        const equipDetails = equipmentAssetTypes.equipmentDetails(equipType);

        super({
            id: equipID,
            type: equipType,
            name: equipDetails.name,
            icon: equipDetails.icon,
            quantity: obj['quantity'],
            craftingDetails: obj['crafting_details']
        });
    }
}

export default EquipmentAsset;
