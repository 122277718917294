import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import { BananaPricing, ExchangeType } from '../../Helpers/BananaPricing';
import { t, Trans } from '../../i18n';
import { EXCHANGE_URLS, getExchangeURL } from '../../config';
import { formatEOSTokenAmount, formatTLOSTokenAmount, formatWAXTokenAmount } from '../../Helpers/Utils';

import './BuyBananaModal.css';

import bananaLogo from '../../Assets/BananaLogo.png';
import telosLogo from '../../Assets/TelosTokenLogo.png';
import defiboxLogo from '../../Assets/DefiboxLogo.png';
import alcorLogo from '../../Assets/AlcorLogo.png';

class BuyBananaModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }

    get isRunningOnNative() {
        return this.sharedState.isRunningOnNative;
    }

    get chainName() {
        return this.session?.chain.name.toUpperCase();
    }

    get trxAPI() {
        return this.props.trxAPI;
    }
    
    get rpc() {
        return this.sharedState.rpc;
    }

    get exchangeType() {
        return this.bananaPricing?.defaultExchangeType;
    }

    constructor(props) {
        super(props);

        this.reserveRefreshInterval = undefined;
        this.bananaPricing = undefined; // Must be set in the `componentDidUpdate`

        this.state = {
            eosioAmount: '',
            bananasAmount: '',
            eosioBalance: undefined,
            isFetchingData: undefined,
            isTransacting: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.useAllBalance = this.useAllBalance.bind(this);
        this.close = this.close.bind(this);
    }

    // Transactions
    purchaseBanana(eosioAmount) {
        this.setState({isTransacting: true});

        const contractName = this.bananaPricing.getExchangeContract(this.exchangeType);
        const quantity = this.formatNativeCurrency(eosioAmount);
        const memo = this.bananaPricing.getBuyBananaMemo(this.exchangeType, eosioAmount);

        this.trxAPI.purchaseBanana(contractName, quantity, memo, {showTransactionSucceeded: true})
            .then(() => {
                this.close();
                
                ReactGA.event('bananas_purchase', {
                    'monkey': this.session.accountName,
                    'chain': this.chainName,
                    'amount': eosioAmount
                });
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    // Inputs Amount Updating
    updateBananasAmount(eosioAmount) {
        const bananasAmount = this.bananaPricing.calcBananasAmount(eosioAmount) || '';
        this.setState({bananasAmount, eosioAmount})
    };

    updateEOSIOAmount(bananasAmount) {
        const eosioAmount = this.bananaPricing.calcEOSIOAmount(bananasAmount) || '';
        this.setState({eosioAmount, bananasAmount});
    };

    // Data Fetching
    fetchData() {
        this.setState({isFetchingData: true});

        const fetchUserBalance = this.rpc.fetchEOSIOBalance(this.session.accountName);
        const loadBananaReserves = this.bananaPricing.loadBananaReserve();

        Promise.all([fetchUserBalance, loadBananaReserves])
            .then(([eosioBalance,]) => this.setState({isFetchingData: false, eosioBalance}))
            .catch(() => this.close());
    };

    // Components
    exchangeLogo() {
        if (!this.chainName) {
            return null; // The component is being inserted too early
        }

        const exchangeURL = EXCHANGE_URLS[this.chainName.toLowerCase()];
        const isDefibox = (this.exchangeType === ExchangeType.DEFIBOX);
        const logoImage = isDefibox ? defiboxLogo : alcorLogo;
        const altName = isDefibox ? 'defibox-logo' : 'alcor-logo';

        return (
            <Stack className="poweredByExchangeStack" direction="horizontal">
                <div style={{fontSize: '0.75rem', fontWeight: 300}}>Powered by</div>

                <a href={exchangeURL} style={{fontSize: 0}} target="_blank" rel="noreferrer">
                    <img src={logoImage} className="exchangeLogo" alt={altName}/>
                </a>
            </Stack>
        );
    };

    // Misc
    handleFormSubmit(event) {
        const form = event.target;
        const amount = form['eosioAmount'].value.trim();
        event.preventDefault();
        this.purchaseBanana(amount);
    };

    formatNativeCurrency(amount) {
        switch (this.chainName) {
            case 'EOS':
                return formatEOSTokenAmount(amount);
            case 'TELOS':
                return formatTLOSTokenAmount(amount);
            case 'WAX':
                return formatWAXTokenAmount(amount);
            default:
                break;
        }
    };

    useAllBalance() {
        this.updateBananasAmount(this.state.eosioBalance);
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'buyBanana-modal'});

                if (this.isRunningOnNative) {
                    this.bananaPricing = new BananaPricing(this.session);
                    this.fetchData();

                    // Auto-refresh the reserves
                    this.reserveRefreshInterval = setInterval(() => {
                        this.bananaPricing.loadBananaReserve();
                    }, 2500);
                }
            }
            else {
                this.bananaPricing = undefined;
                clearInterval(this.reserveRefreshInterval);
                this.setState({eosioAmount: '', bananasAmount: '', reserveRefreshInterval: undefined});
            }
        }
    };

    componentWillUnmount() {
        clearInterval(this.reserveRefreshInterval);
    };

    render() {
        const invalidAmountsEntered = !(this.state.eosioAmount > 0); // `eosioAmount` is a string
        const chainName = this.chainName;
        const tokenLogoPath = (chainName === 'TELOS') ? telosLogo : `${chainName}.png`;

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.buyBananas.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { this.state.isFetchingData &&
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                        </div>
                    }

                    { !this.isRunningOnNative &&
                        <p style={{textAlign: 'center', marginBottom: 0}}>
                            <Trans i18nKey="modals.buyBananas.canBePurchasedOnExchange">
                                <b>$BANANA</b> can be purchased on the DEX. <a href={getExchangeURL(this.sharedState)} target="_blank" rel="noreferrer">Click here</a>&nbsp;
                                to open the swap.
                            </Trans>
                        </p>
                    }

                    { (!this.state.isFetchingData && this.isRunningOnNative) &&
                        <Form id="buyBananasForm" onSubmit={this.handleFormSubmit}>
                            <Form.Group className="mb-2" controlId="eosioAmount">
                                <Form.Control
                                    type="number"
                                    step="any"
                                    className="tokenIcon"
                                    placeholder={t('modals.buyBananas.enterNativeCurrencyAmount', {tokenSymbol: chainName})}
                                    style={{background: `url(${tokenLogoPath}) no-repeat 6px`}}
                                    disabled={this.state.isTransacting}
                                    onChange={e => { this.updateBananasAmount(e.target.value) }}
                                    value={this.state.eosioAmount}
                                    autoFocus required
                                />

                                { (this.state.eosioBalance !== undefined) &&
                                    <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useAllBalance}>{t('modals.buyBananas.balance')}: {this.formatNativeCurrency(this.state.eosioBalance)}</Form.Text>
                                }
                            </Form.Group>

                            <div className="arrowContainer">
                                <div className="arrow"></div>
                            </div>

                            <Form.Group className="mb-2">
                                <Form.Control
                                    type="number"
                                    step="any"
                                    className="tokenIcon"
                                    placeholder={t('modals.buyBananas.enterBananaAmount')}
                                    style={{background: `url(${bananaLogo}) no-repeat 6px`}}
                                    disabled={this.state.isTransacting}
                                    onChange={e => { this.updateEOSIOAmount(e.target.value) }}
                                    value={this.state.bananasAmount}
                                />
                            </Form.Group>

                            {this.exchangeLogo()}
                        </Form>
                }
                </Modal.Body>

                <Modal.Footer>
                    {!this.state.isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    <Button type="submit" form="buyBananasForm" variant="primary" disabled={(this.state.isTransacting || invalidAmountsEntered)}>
                        {this.state.isTransacting ?
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                <span className="visually-hidden">{t('common.buy')}</span>
                            </>
                            :
                            t('common.buy')
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default commonContextsWrapper(BuyBananaModal);
