import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import BananaAmount from '../Widgets/BananaAmount';
import { t } from '../../i18n';
import { formatPercentages, formatAmount, formatBananasTokenAmount, formatEOSTokenAmount } from '../../Helpers/Utils';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';

class StakeBananaModal extends React.Component {
    #MIN_DAYS_STAKING = 90;
    #MAX_DAYS_STAKING = 365;
    #MAX_WEIGHT_MULTIPLIER = 2.66;

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    get session() {
        return this.sharedState?.session;
    }

    constructor(props) {
        super(props);

        this.state = {
            bananasAmount: '', // Amount to withdraw
            bananasBalance: undefined, // User's account balance
            stakingDuration: this.#MIN_DAYS_STAKING,
            stakingDetails: undefined,
            stakingStats: undefined,
            isTransacting: false,
            isFetchingData: false
        };

        this.onTokenFormSubmitted = this.onTokenFormSubmitted.bind(this);
        this.claimDividends = this.claimDividends.bind(this);
        this.unstakeBananas = this.unstakeBananas.bind(this);
        this.useAllBalance = this.useAllBalance.bind(this);
        this.close = this.close.bind(this);
    };

    // Actions
    onTokenFormSubmitted(event) {
        event.preventDefault();
        this.stakeBananas();
    };

    claimDividends() {
        this.setState({isTransacting: true});

        this.trxAPI.claimDividends()
            .then(() => {
                const stakingDetails = this.state.stakingDetails;
                stakingDetails.dividends = 0;
                this.setState({stakingDetails});
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    stakeBananas() {
        this.setState({isTransacting: true});

        const quantity = formatBananasTokenAmount(this.state.bananasAmount);
        const stakingDuration = this.state.stakingDuration;

        this.trxAPI.stakeBananas(quantity, stakingDuration)
            .then(() => {
                const stakingStats = this.state.stakingStats;
                const bananasAmount = this.state.bananasAmount;
                const stakingWeight = (bananasAmount * this.calcStakingWeightRatio(stakingDuration));

                // Must be updated so the subsequent share calc. is correct
                stakingStats.totalWeight += stakingWeight;

                this.setState({
                    stakingDetails: StakingDetails.fromProperties({
                        bananas: bananasAmount,
                        dividends: 0,
                        stakingStarted: new Date(),
                        stakingDuration: stakingDuration
                    }),
                    stakingStats: stakingStats
                });
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    unstakeBananas() {
        this.setState({isTransacting: true});

        this.trxAPI.unstakeBananas()
            .then(() => this.setState({stakingDetails: undefined}))
            .finally(() => this.setState({isTransacting: false}));
    };
    
    // Fetching
    fetchData() {
        this.setState({isFetchingData: true});
        
        const accountName = this.session.accountName;

        Promise.all([
            this.rpc.fetchBananasBalance(accountName),
            this.rpc.fetchStakingDetails(accountName),
            this.rpc.fetchStakingStats(),
        ])
        .then(([bananasBalance, stakingDetails, stakingStats]) => {
            this.setState({
                bananasBalance: bananasBalance,
                stakingDetails: StakingDetails.fromResponse(stakingDetails),
                stakingStats: StakingStats.fromResponse(stakingStats)
            });
        })
        .catch(() => this.close())
        .finally(() => this.setState({isFetchingData: false}));
    };

    // Staking Related Details
    estimatePotentialDividends() {
        const stakingStats = this.state.stakingStats;
        const bananasAmount = this.state.bananasAmount;
        const stakingDuration = this.state.stakingDuration;

        if (!bananasAmount || !stakingStats) {
            return 0;
        }

        const stakingWeight = (bananasAmount * this.calcStakingWeightRatio(stakingDuration));
        const shareSize = (stakingWeight / (stakingStats.totalWeight + stakingWeight));

        const DAY = 86400000;
        const PRESET_AMOUNT = 2000; // We've distributed 2k at fixed rate
        const DIVIDENDS_ACCUM_SINCE = new Date('2024-02-25T06:52:00Z'); // Fixed rate distr. ended
        const daysPassed = Math.max(((new Date() - DIVIDENDS_ACCUM_SINCE) / DAY), 1);
        const dailyRelease = ((stakingStats.totalDividends - PRESET_AMOUNT) / daysPassed);
        const potentialDividends = ((dailyRelease * stakingDuration) * shareSize);

        return potentialDividends;
    };

    getDividendsShareSize() {
        const bananasStaked = this.state.stakingDetails.bananas;
        const totalWeightStaked = this.state.stakingStats.totalWeight;
        const stakingDuration = this.state.stakingDetails.stakingDuration;

        const stakingWeight = (bananasStaked * this.calcStakingWeightRatio(stakingDuration));
        const shareSize = (stakingWeight / totalWeightStaked);
        const shareSizeFormatted = formatPercentages(shareSize);

        return shareSizeFormatted;
    };

    // Staking Weight Calculation
    calcStakingWeightRatio(daysStaking) {
        const simpleRange = this.convertRange(daysStaking, this.#MIN_DAYS_STAKING, this.#MAX_DAYS_STAKING); // [0, 1]
        const easeInRange = this.easeInSine(simpleRange); // [0, 1]
        const weight = ((easeInRange * (this.#MAX_WEIGHT_MULTIPLIER - 1)) / 1) + 1; // [1, MAX_WEIGHT_MULTIPLIER]
        return weight;
    };

    convertRange(value, minRangeValue, maxRangeValue) {
        return ((value - minRangeValue) / (maxRangeValue - minRangeValue));
    };

    easeInSine(x) {
        return (1 - Math.cos((x * Math.PI) / 2));
    };

    // Misc
    useAllBalance() {
        this.setState({bananasAmount: this.state.bananasBalance});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps) {
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'stakeBanana-modal'});
                this.fetchData();
            }
            else {
                // Make the modal disappear and then change
                // props which affect content that is shown
                setTimeout(() => {
                    this.setState({
                        bananasAmount: '',
                        bananasBalance: undefined,
                        stakingDuration: this.#MIN_DAYS_STAKING,
                        stakingDetails: undefined,
                        stakingStats: undefined
                    });
                }, 800);
            }
        }
    };

    render() {        
        const isTransacting = this.state.isTransacting;
        const isFetchingData = this.state.isFetchingData;
        const stakingDetails = this.state.stakingDetails;
        const isStaking = (stakingDetails !== undefined);
        const dividendsEarned = stakingDetails?.dividends;
        const stakingEndsDate = stakingDetails?.stakingEndsDate;
        const bananasBalance = this.state.bananasBalance;
        const dividendsEstimated = this.estimatePotentialDividends();
        const isStakingPeriodOver = (stakingEndsDate < new Date());

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal.Title>{t('modals.stakeBanana.title')}</Modal.Title>
                    <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.stakeBanana.subtitle')}</div>
                </Modal.Header>

                <Modal.Body>
                    { isFetchingData &&
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                        </div>
                    }

                    { (isStaking && !isFetchingData) &&
                        <Stack>
                            <RowDetails title={`${t('modals.stakeBanana.stakedAmount')}:`}>
                                <BananaAmount amount={stakingDetails.bananas} textStyle={{fontWeight: 300}}/>
                            </RowDetails>

                            <RowDetails title={`${t('modals.stakeBanana.share')}:`} subdetail={this.getDividendsShareSize()}/>
                            <RowDetails title={`${t('modals.stakeBanana.stakingUntil')}:`} detailStyle={{color: isStakingPeriodOver ? 'var(--red)' : 'inherit'}} subdetail={stakingEndsDate.toLocaleString()}/>
                            <RowDetails title={`${t('modals.stakeBanana.dividendsToClaim')}:`} subdetail={formatEOSTokenAmount(dividendsEarned)}/>
                        </Stack>
                    }
                    
                    { (!isStaking && !isFetchingData) &&
                        <Form id="stakeBananasForm" onSubmit={this.onTokenFormSubmitted}>
                            <Form.Group className="mb-3" controlId="bananasAmount">
                                <Form.Label>{t('misc.amountOfBananas')}</Form.Label>
                                <Form.Control type="number" step="any" disabled={isTransacting} value={this.state.bananasAmount} onChange={e => { this.setState({bananasAmount: e.target.value})}} autoFocus required/>
                                
                                <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useAllBalance}>
                                    {t('modals.stakeBanana.stakeAll')}: 🍌 {formatAmount(this.state.bananasBalance)}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="stakingDuration">
                                <Form.Label>{t('modals.stakeBanana.stakingPeriod')}</Form.Label>

                                <Form.Select disabled={isTransacting} onChange={e => { this.setState({stakingDuration: Number(e.target.value)})}}>
                                    <option value={this.#MIN_DAYS_STAKING}>{this.#MIN_DAYS_STAKING} {t('modals.stakeBanana.days')}</option>
                                    <option value="183">183 {t('modals.stakeBanana.days')}</option>
                                    <option value={this.#MAX_DAYS_STAKING}>{this.#MAX_DAYS_STAKING} {t('modals.stakeBanana.days')}</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>{t('modals.stakeBanana.estimatedDividends')}</Form.Label>
                                <h4 style={{textAlign: 'center', marginTop: '1rem'}}>~{formatEOSTokenAmount(dividendsEstimated)}</h4>
                                <div style={{fontSize: '0.6rem', textAlign: 'center', color: 'darkgray', marginTop: '2rem'}}>Please make sure you abide by the simple rule of not being detrimental in any way to the project to avoid slashing.</div>
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>

                <Modal.Footer>
                    { !isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    { (!isStaking && !isFetchingData) &&
                        <Button type="submit" form="stakeBananasForm" variant="primary" disabled={isTransacting || !bananasBalance}>
                            { isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.stakeBanana.stake')}</span>
                                </>
                                :
                                t('modals.stakeBanana.stake')
                            }
                        </Button>
                    }

                    { (isStaking && dividendsEarned > 0) &&
                        <Button variant="primary" disabled={isTransacting} onClick={this.claimDividends}>
                            { isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.stakeBanana.claim')}</span>
                                </>
                                :
                                t('modals.stakeBanana.claim')
                            }
                        </Button>
                    }

                    { (isStakingPeriodOver && !dividendsEarned) &&
                        <Button variant="primary" disabled={isTransacting} onClick={this.unstakeBananas}>
                            { isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.stakeBanana.unstake')}</span>
                                </>
                                :
                                t('modals.stakeBanana.unstake')
                            }
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };
};

export default commonContextsWrapper(StakeBananaModal);

// Helper Classes
class StakingDetails {
    get stakingEndsDate() {
        const stakingDuration = this.stakingDuration;
        const stakingStarted = new Date(this.stakingStarted); // Must be copied so `setDate` won't affect it
        const stakingEndsEpoch = stakingStarted.setDate(stakingStarted.getDate() + stakingDuration);
        return new Date(stakingEndsEpoch);
    };

    constructor(props) {
        this.bananas = props.bananas;
        this.dividends = props.dividends;
        this.stakingStarted = props.stakingStarted;
        this.stakingDuration = props.stakingDuration;
    };

    static fromProperties(props) {
        return new StakingDetails(props);
    };

    static fromResponse(response) {
        if (!response) {
            return undefined;
        }

        const obj = {
            bananas: parseFloat(response['bananas']),
            dividends: parseFloat(response['dividends']),
            stakingStarted: new Date(response['staking_started'] + 'Z'),
            stakingDuration: response['staking_duration']
        };

        return new StakingDetails(obj);
    };
};

class StakingStats {
    constructor(response) {
        const maxRelease = response['max_release'];
        const precision = Math.pow(10, 4); // $BANANA & EOS have both 4 precision

        this.totalDividends = parseFloat(response['total_dividends']);
        this.totalWeight = (response['total_weight'] / precision);
        this.maxRelease = (maxRelease > 0) ? (maxRelease / precision) : maxRelease; // -1 or a value
    };

    static fromResponse(response) {
        if (!response) {
            return undefined;
        }

        return new StakingStats(response);
    };
};

// Components
function RowDetails(props) {
    return (
        <Stack direction="horizontal" style={{gap: '4px'}}>
            {props.title}

            { (props.subdetail !== undefined) &&
                <div style={{...{fontWeight: 300}, ...props.detailStyle}}>{props.subdetail}</div>
            }

            { props.children && props.children }
        </Stack>
    );
};
