import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactGA from 'react-ga4';
import { APIClient } from '@wharfkit/antelope';
import { sha256 } from 'hash.js';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Chains } from '@wharfkit/session';
import { Name, Asset, UInt64, VarUInt, Checksum256, ABIEncoder, Serializer } from '@wharfkit/antelope';

import { t, Trans } from '../../i18n';
import ToastMessage from '../ToastMessage';
import sessionKit from '../../Helpers/SessionKit';
import FYMSession from '../../Helpers/FYMSession';
import { formatAmount, formatBananasTokenAmount, formatPeelsTokenAmount } from '../../Helpers/Utils';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';

import {
    EOSIO_BLOCKCHAINS,
    BRIDGE_CONTRACT_NAME,
    IBC_PROOF_URLS,
    HYPERION_URLS,
    TOKEN_CONTRACT_NAME,
    WRAPPED_TOKEN_CONTRACT_NAME,
    WRAP_LOCK_CONTRACT_NAME,
    getBlockchainName
} from '../../config';

import 'react-circular-progressbar/dist/styles.css';
import './BridgeTokensModal.css';

class BridgeTokensModal extends React.Component {
    #SUPPORTED_TOKENS = {BANANA: 0, PEEL: 1};

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }

    get sourceChain() {
        return this.sharedState.chain; // session might not be available yet
    }

    get destinationChain() {
        return this.destinationSession?.chain;
    }

    get destinationSession() {
        return this.state.destinationSession;
    }

    get tokenName() {
        return this.getTokenName(this.state.selectedToken);
    };

    get possibleDestinationChains() {
        // For now we only allow bridging to/from the native chain (EOS)
        const currentChainName = getBlockchainName(this.sourceChain);

        if (currentChainName === 'EOS') {
            return Object
                .keys(EOSIO_BLOCKCHAINS)
                .filter(chainName => (chainName !== 'eos'))
                .map(chainName => EOSIO_BLOCKCHAINS[chainName]);
        }

        return [EOSIO_BLOCKCHAINS['eos']];
    }

    get isNativeChain() {
        return (getBlockchainName(this.sourceChain) === 'EOS');
    };

    get ibcProofURL() {
        const chainName = getBlockchainName(this.sourceChain).toLowerCase();
        return IBC_PROOF_URLS[chainName];
    }

    get hyperionURL() {
        const chainName = getBlockchainName(this.sourceChain).toLowerCase();
        return HYPERION_URLS[chainName];
    }

    get wrapLockContractName() {
        const chain = this.isNativeChain ? this.destinationChain : this.sourceChain;
        const chainName = getBlockchainName(chain).toLowerCase();
        return WRAP_LOCK_CONTRACT_NAME[chainName];
    }

    get isLoggedIn() {
        return !!this.destinationSession;
    }
    
    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    constructor(props) {
        super(props);

        this.trxFinalityProgressInterval = undefined;

        this.state = {
            selectedToken: this.#SUPPORTED_TOKENS.BANANA,
            tokenAmount: '',
            tokenBalance: undefined,
            destinationSession: undefined,
            proofWaitingProgress: undefined,
            allowTrxRetry: undefined,
            errorMessage: undefined,
            proofTrxID: undefined,
            isFetchingData: false,
            isTransacting: false
        };

        this.login = this.login.bind(this);
        this.bridgeTokens = this.bridgeTokens.bind(this);
        this.useEntireBalance = this.useEntireBalance.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.retryProofSubmission = this.retryProofSubmission.bind(this);
        this.close = this.close.bind(this);
    };

    // Transactions
    bridgeTokens(event) {
        event.preventDefault();
        this.setState({isTransacting: true});

        const quantity = this.formatTokenAmount(this.state.tokenAmount);
        const destinationAccountName = this.destinationSession.accountName;
        const destinationChain = this.destinationChain;

        this.trxAPI.bridgeNativeTokens(quantity, destinationChain, destinationAccountName)
            .then(result => {
                this.setState({isTransacting: false});
                return result.response.processed;
            })
            .then(trx => this.waitForTransactionFinality(trx))
            .then(trx => this.prepareProofs(trx))
            .catch(error => {
                this.setState({isTransacting: false});
                this.close();
            });
    };

    submitProof(scheduleProofs, emitxferProof) {
        this.setState({isTransacting: true});

        const actions = [...scheduleProofs, emitxferProof];
        const chainID = this.destinationChain.id.toString();

        // We must restore the secondary session again to sign the
        // transaction otherwise correct keys won't be provided as
        // the current session is the sourceChain based session
        sessionKit.restore({chain: chainID})
            .then(session => {
                this.trxAPI.submitProof(actions, {session})
                    .then(result => {
                        this.setState({
                            proofTrxID: result.response.processed.id,
                            proofWaitingProgress: undefined // If done in `finally` it might not be effective
                        });
                    })
                    .catch(error => {
                        this.setState({
                            allowTrxRetry: {args: [scheduleProofs, emitxferProof]},
                            proofWaitingProgress: undefined // If done in `finally` it might not be effective
                        });
                    })
                    .finally(() => this.setState({isTransacting: false}));
            })
    };

    // IBC
    prepareProofs(trx) {
        let emitxferAction = trx.action_traces.find(action => (action.act.name === 'emitxfer'));

        // `global_sequence` in the receipt might not be current and in
        // such a case, the proof server will not return back amproofpaths
        this.fetchCurrentGlobalSequence(trx.id, trx.block_num)
            .then(result => {
                emitxferAction.receipt = result.receipt;
                return result.blockNumber;
            })
            .then(blockNumber => {
                return Promise.all([
                    this.scheduleProofs(blockNumber),
                    this.fetchProof(blockNumber, emitxferAction)
                ]);
            })
            .then(([scheduleProofs, emitxferProof]) => {
                this.submitProof(scheduleProofs, emitxferProof);
            })
            .catch(error => {
                this.showErrorMessage(error);
                this.close();
            });
    };

    waitForTransactionFinality(trx) {
        const that = this;

        // Start the loading already
        this.setState({proofWaitingProgress: 0});

        return new Promise((resolve, reject) => {
            const checkTrxFinality = () => {
                that.session.client.v1.chain
                    .get_transaction_status(trx.id)
                    .then(response => {
                        const blockNumber = trx.block_num;
                        const lastIrreversibleBlockNum = Number(response.irreversible_number);
                        const isTrxFinal = (lastIrreversibleBlockNum > blockNumber)
                        const isIrreversible = (response.state === 'IRREVERSIBLE');

                        if (isTrxFinal && isIrreversible) {
                            resolve(trx);
                        }
                        else if (!isTrxFinal) {
                            const finalityTimeLength = ((blockNumber - lastIrreversibleBlockNum) * 0.5) * 1000;
                            setTimeout(checkTrxFinality, finalityTimeLength);
                            that.trxFinalityProgress(finalityTimeLength);    
                        }
                        else {
                            // Might be returned when trying to check too old of a transaction
                            // (Restoring an interrupted transfer hours/days old)
                            if (response.state === 'UNKNOWN') {
                                that.trxFinalityProgress(0);
                                resolve(trx);
                            }
                            else {
                                reject(new Error('Transaction not irreversible: "' + response.state + '"'));
                            }
                        }
                    })
                    .catch(reject);
            };

            checkTrxFinality();
        });
    };

    // Fetching
    async scheduleProofs(currentBlockNumber) {
        const proofs = [];
        const lastProvenScheduleVersion = await this.fetchLastProvenScheduleVersion();
        const schedule = await this.session.client.v1.chain.get_producer_schedule()
        let scheduleVersion = schedule.active.version;
        let scheduleBlock = await this.fetchHeadBlockNum();

        if (!lastProvenScheduleVersion) {
            return proofs;
        }

        while (scheduleVersion > lastProvenScheduleVersion) {
            const blockNum = this.fetchProducerScheduleBlock(scheduleBlock);
            const proof = await this.fetchProof(blockNum);

            scheduleVersion = proof.data.blockproof.blocktoprove.block.header.schedule_version;
            scheduleBlock = blockNum;

            proofs.unshift(proof);
        }

        // Check for pending schedule and prove pending schedule if found
        if (schedule.pending) {
            let newPendingBlockHeader;

            while (!newPendingBlockHeader) {
                const response = await this.session.client.v1.chain.get_block(currentBlockNumber);
                
                if (response['new_producer_schedule']) {
                    newPendingBlockHeader = response;
                }
                else {
                    currentBlockNumber--;
                }
            }

            const pendingProof = await this.fetchProof(Number(newPendingBlockHeader.block_num));
            proofs.push(pendingProof); //push pending after proving active
        }

        return proofs;
    };

    async fetchLastProvenScheduleVersion() {
        const response = await this.destinationSession.client.v1.chain.get_table_rows({
            code: BRIDGE_CONTRACT_NAME,
            table: 'schedules',
            scope: scopeName(this.sourceChain),
            reverse: true,
            limit: 1
        });
        
        return response.rows?.[0]?.version;
    };

    async fetchLastBlockProved() {
        const response = await this.destinationSession.client.v1.chain.get_table_rows({
            code: BRIDGE_CONTRACT_NAME,
            table: 'lastproofs',
            scope: scopeName(this.sourceChain),
            reverse: true,
            limit: 1
        });

        return response.rows?.[0];
    };

    async fetchHeadBlockNum() {
        const response = await this.session.client.v1.chain.get_info();
        return Number(response.head_block_num);
    };

    async fetchProducerScheduleBlock(blockNum) {
        let headerResponse = await this.session.client.v1.chain.get_block(blockNum);
        const targetSchedule = headerResponse.schedule_version;
        let minBlock = (await this.fetchLastBlockProved()?.block_height) || 2;
        let maxBlock = blockNum;

        // Detect active schedule change
        while ((maxBlock - minBlock) > 1) {
            blockNum = Math.round((maxBlock + minBlock) / 2);
            headerResponse = this.session.client.v1.chain.get_block(blockNum);

            if (headerResponse.schedule_version < targetSchedule) {
                minBlock = blockNum;
            }
            else {
                maxBlock = blockNum;
            }
        }

        if (blockNum > 337) {
            blockNum -= 337;
        }

        while (blockNum < maxBlock && !headerResponse.new_producers) {
            headerResponse = this.session.client.v1.chain.get_block(blockNum);
            blockNum++;
        }

        return Number(headerResponse.block_num);
    };

    fetchProof(blockToProve, emitxferAction, lastProvenBlock) {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(this.ibcProofURL);
            const isLightProof = !!lastProvenBlock;
            
            ws.addEventListener('open', () => {
                const query = {
                    type: isLightProof ? 'lightProof' : 'heavyProof',
                    block_to_prove: blockToProve,
                    last_proven_block: lastProvenBlock,
                    action_receipt: emitxferAction?.receipt
                };

                ws.send(JSON.stringify(query));
            });

            ws.addEventListener('error', reject);

            ws.addEventListener('message', event => {
                const response = JSON.parse(event.data);

                if (response.type === 'error') {
                    ws.close();
                    reject(new Error('WebSocket error while fetching block actions: ' + response));
                }
                else if (response.type === 'progress') {
                    this.incrProofFetchingProgress(response.progress);
                }
                else if (response.type === 'proof') {
                    const action = this.createSubmitProofAction(response.proof, isLightProof, emitxferAction);
                    ws.close();
                    resolve(action);
                }
            });
        });
    };

    fetchCurrentGlobalSequence(trxID, blockNumber) {
        return new Promise((resolve, reject) => {
            const ws = new WebSocket(this.ibcProofURL);

            const sendQuery = () => {
                const query = {
                    type: 'getBlockActions',
                    block_to_prove: blockNumber
                };

                ws.send(JSON.stringify(query));
            };

            ws.addEventListener('open', sendQuery);
            ws.addEventListener('error', reject);

            ws.addEventListener('message', (event) => {
                const response = JSON.parse(event.data);

                // Most likely an error of some kind
                if (response.type === 'error') {
                    reject(new Error('WebSocket error while fetching block actions: ' + response));
                    return;
                }

                trxID = trxID.toUpperCase(); // Originally lowercased

                const firehoseTrx = response.txs.find(trx => {
                    return trx.find(act => (act.transactionId === trxID));
                });

                if (firehoseTrx) {
                    const emitxferAction = firehoseTrx.find(act => (act.action.name === 'emitxfer'));

                    // Convert firehose authSequence to expected format for IBC-proof server
                    const authSequence = emitxferAction.receipt.auth_sequence;
                    emitxferAction.receipt.auth_sequence = authSequence.map(auth => [auth.account, auth.sequence]);
                    resolve({receipt: emitxferAction.receipt, blockNumber});
                }
                else {
                    // Sometimes the blockNumber provided might be 1 behind in
                    // which case the response will not contain the correct trxs
                    blockNumber++;
                    sendQuery();
                }
            });
        });
    };

    async fetchProcessedReceiptDigests() {
        const response = await this.destinationSession.client.v1.chain.get_table_rows({
            code: this.isNativeChain ? WRAPPED_TOKEN_CONTRACT_NAME: this.wrapLockContractName,
            table: 'processed',
            scope: this.isNativeChain ? WRAPPED_TOKEN_CONTRACT_NAME: this.wrapLockContractName,
            reverse: true,
            limit: 300
        });

        return response.rows.map(r => r['receipt_digest']);
    };

    async fetchTransferActionsHistory() {
        if (this.isNativeChain) {
            const response = await this.fetchActions({
                accountName: this.wrapLockContractName,
                filter: `${TOKEN_CONTRACT_NAME}:transfer&transfer.from=${this.session.accountName}&transfer.memo=${this.destinationSession.accountName}`,
                limit: 3
            });

            return response.actions;
        }
        else {
            const response = await this.fetchActions({
                accountName: this.session.accountName,
                filter: `${WRAPPED_TOKEN_CONTRACT_NAME}:retire`,
                limit: 3
            });

            return response.actions;
        }
    };

    async fetchTransaction(trxID) {
        const apiClient = new APIClient({url: this.hyperionURL});

        // v2/history is not supported by the Antelope lib, but must be
        // used as v1 returns different `act_digest` that cannot be used
        return await apiClient.call({
            path: `/v2/history/get_transaction?id=${trxID}`,
            method: 'GET'
        });
    };

    async fetchActions(options) {
        const apiClient = new APIClient({url: this.hyperionURL});

        // v2/history is not supported by the Antelope lib
        return await apiClient.call({
            path: `/v2/history/get_actions?account=${options.accountName}&filter=${options.filter}&limit=${options.limit}`,
            method: 'GET'
        });
    };

    fetchInterruptedTransfer() {
        return Promise
            .all([
                this.fetchProcessedReceiptDigests(),
                this.fetchTransferActionsHistory()
            ])
            .then(async ([processedDigests, actions]) => {
                for (let i = 0; i < actions.length; i++) {
                    const trxID = actions[i]['trx_id'];                    
                    const trx = await this.fetchTransaction(trxID);
                    const emitxferAction = trx.actions.find(action => action.act.name === 'emitxfer');
                    const receiptDigest = this.getReceiptDigest(emitxferAction);
                    
                    if (!processedDigests.includes(receiptDigest)) {
                        this.retryInterruptedTrxs(trx);
                        break;
                    }
                }
            })
            .catch(error => {
                // These errors can be silenced as the func
                // is running in the background
                console.log(error);
            });
    };

    fetchTokensBalance() {
        this.setState({isFetchingData: true});

        const accountName = this.session.accountName;
        const isBananaSelected = (this.state.selectedToken === this.#SUPPORTED_TOKENS.BANANA);
        const rpcCallName = isBananaSelected ? 'fetchBananasBalance' : 'fetchPeelsBalance';

        this.rpc[rpcCallName](accountName)
            .then(tokenBalance => this.setState({tokenBalance}))
            .catch(() => this.close())
            .finally(() => this.setState({isFetchingData: false}));
    };

    // Components
    selectTokenView() {
        const {BANANA, PEEL} = this.#SUPPORTED_TOKENS;
        const currentChainName = getBlockchainName(this.sourceChain);
        const possibleDestinationChains = this.possibleDestinationChains
            .map(chain => getBlockchainName(chain))
            .join(' or ');
            
        return (
            <Tabs defaultActiveKey={this.state.selectedToken} className="SelectTokenTabs mb-3" onSelect={t => this.setState({selectedToken: +t})} justify>
                <Tab eventKey={BANANA} title={this.getTokenName(BANANA)} className="TabContent">
                    <Trans i18nKey="modals.bridgeTokens.bridgeFromChainToChain" values={{tokenName: this.getTokenName(BANANA), currentChainName, possibleDestinationChains}}/>
                </Tab>
                
                <Tab eventKey={PEEL} title={this.getTokenName(PEEL)} className="TabContent">
                    <Trans i18nKey="modals.bridgeTokens.bridgeFromChainToChain" values={{tokenName: this.getTokenName(PEEL), currentChainName, possibleDestinationChains}}/>
                </Tab>
            </Tabs>
        );
    };

    // Misc
    async login() {
        const chainIDs = this.possibleDestinationChains.map(chain => chain.id);
        const response = await sessionKit.login({chains: chainIDs});
        const fymSession = new FYMSession(response.session);

        this.setState({destinationSession: fymSession}, () => {
            this.fetchInterruptedTransfer();
        });

        // Logging in with another blockchain changes the default chain used
        // and persisted in the localStorage by the Wharfkit
        sessionKit.restore({chain: this.sourceChain.id.toString()});
    };

    useEntireBalance() {
        this.setState({tokenAmount: this.state.tokenBalance});
    };

    showErrorMessage(error) {
        this.setState({errorMessage: error.message});
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    };

    retryProofSubmission() {
        const args = this.state.allowTrxRetry.args;
        this.submitProof(...args);
        this.setState({allowTrxRetry: undefined});  
    };

    incrProofFetchingProgress(progress) {
        const BASIC_PROGRESS = 67; // Starts at 67% (finality progress until then)
        const remainingProgress = Math.min(Math.floor((progress / 100) * 33), 100);
        this.setState({proofWaitingProgress: (BASIC_PROGRESS + remainingProgress)});
    };

    trxFinalityProgress(expectedTrxFinality) {
        if (this.trxFinalityProgressInterval) {
            clearInterval(this.trxFinalityProgressInterval);
        }

        const MAX_PROGRESS = 66; // Finality will only go up to 33% the rest is proof waiting
        const MAX_DURATION = (3 * 60); // Should be final within 3 mins
        let expectedFinalitySeconds = Math.ceil(expectedTrxFinality / 1000);

        const incrPercentageProgress = () => {
            if (expectedFinalitySeconds <= 1) {
                clearInterval(this.trxFinalityProgressInterval);
            }

            expectedFinalitySeconds -= 1;
            const progress = Math.round((1 - (expectedFinalitySeconds / MAX_DURATION)) * MAX_PROGRESS);
            this.setState({proofWaitingProgress: progress});
        };

        incrPercentageProgress();

        if (expectedTrxFinality > 0) {
            this.trxFinalityProgressInterval = setInterval(incrPercentageProgress, 1000);
        }
    };

    createSubmitProofAction(proof, isLightProof, emitxferAction) {
        const checkProof = !emitxferAction;
        const contractName = this.isNativeChain ? WRAPPED_TOKEN_CONTRACT_NAME : this.wrapLockContractName;
        let actionName = this.isNativeChain ? 'issuea' : 'withdrawa';

        if (isLightProof) {
            actionName = this.isNativeChain ? 'issueb' : 'withdrawb';
        }
        else {
            actionName = checkProof ? 'checkproofd' : actionName;
        }

        // Handle issue/withdraw if proving transfer/retire's emitxfer action
        // else submit block proof to bridge directly (for schedules)
        const action = {
            authorization: [this.destinationSession.permissionLevel],
            name: actionName,
            account: checkProof ? BRIDGE_CONTRACT_NAME : contractName,
            data: {...proof, prover: this.destinationSession.accountName} 
        };

        // If proving an action, add action and formatted receipt to `actionproof` object
        if (emitxferAction) {
            const auth_sequence = emitxferAction.receipt.auth_sequence.map(authSeq => {
                // We might have 2 types of emitxferAction with different auth_sequence formatting
                return Array.isArray(authSeq) ? {account: authSeq[0], sequence: authSeq[1]} : authSeq;
            });

            action.data.actionproof = {
                ...proof.actionproof,
                action: {
                    account: emitxferAction.act.account,
                    name: emitxferAction.act.name,
                    authorization: emitxferAction.act.authorization,
                    data: emitxferAction.act.hex_data
                },
                receipt: {...emitxferAction.receipt, auth_sequence}
            };
        }

        return action;
    };

    getReceiptDigest(action) {
        const abiEncoder = new ABIEncoder();
        let receipt = action.receipts[0];

        if (!receipt.act_digest) {
            // Luckily, the correct digest is attached to the action
            // but `hex_data` is not and must be computed
            receipt.act_digest = action.act_digest;

            // Typed actions helps avoiding to fetch or store the ABI
            const actionData = action.act.data.xfer;
            const typedAction = {'xfer': {
                'owner': Name.from(actionData.owner),
                'quantity': {
                    'quantity': Asset.from(actionData.quantity.quantity),
                    'contract': Name.from(actionData.quantity.contract)
                },
                'beneficiary': Name.from(actionData.beneficiary)
            }};

            action.act.hex_data = Serializer.encode({object: typedAction}).hexString;
        }

        const receiverEncoded = Serializer.encode({object: receipt.receiver, type: Name});
        const digestEncoded = Serializer.encode({object: receipt.act_digest, type: Checksum256});
        const globalSequenceEncoded = Serializer.encode({object: receipt.global_sequence, type: UInt64});
        const recvSequenceEncoded = Serializer.encode({object: receipt.recv_sequence, type: UInt64});

        abiEncoder.writeArray(receiverEncoded.array);
        abiEncoder.writeArray(digestEncoded.array);
        abiEncoder.writeArray(globalSequenceEncoded.array);
        abiEncoder.writeArray(recvSequenceEncoded.array);

        if (receipt.auth_sequence) {
            const authSequenceLengthEncoded = Serializer.encode({object: receipt.auth_sequence.length, type: VarUInt});
            abiEncoder.writeArray(authSequenceLengthEncoded.array);

            for (const auth of receipt.auth_sequence) {
                const accountNameEncoded = Serializer.encode({object: auth.account, type: Name});
                const sequenceEncoded = Serializer.encode({object: auth.sequence, type: UInt64});
                abiEncoder.writeArray(accountNameEncoded.array);
                abiEncoder.writeArray(sequenceEncoded.array);
            }
        }
        else {
            abiEncoder.writeVaruint32(0);
        }

        if (action.code_sequence) {
            const codeSequenceEncoded = Serializer.encode({object: action.code_sequence, type: VarUInt});
            abiEncoder.writeArray(codeSequenceEncoded.array);
        }
        else {
            abiEncoder.writeVaruint32(0);
        }

        if (action.abi_sequence) {
            const abiSequenceEncoded = Serializer.encode({object: action.abi_sequence, type: VarUInt});
            abiEncoder.writeArray(abiSequenceEncoded.array);
        }
        else {
            abiEncoder.writeVaruint32(0);
        }

        const bytes = abiEncoder.getBytes().array;
        const digest = sha256().update(bytes).digest('hex');

        return digest;
    };

    retryInterruptedTrxs(trx) {
        const emitxferAction = trx.actions.find(action => action.act.name === 'emitxfer');

        trx.id = trx['trx_id'];
        trx.block_num = emitxferAction.block_num;
        trx.action_traces = trx.actions.map(act => {
            act.receipt = act.receipts[0]; // Expects a single receipt
            return act;
        });

        emitxferAction.receipt.code_sequence = emitxferAction.code_sequence;
        emitxferAction.receipt.abi_sequence = emitxferAction.abi_sequence;

        Promise
            .all([
                this.waitForTransactionFinality(trx),
                this.fetchLastBlockProved()
            ])
            .then(([, lastBlockProvedResponse]) => {
                const lastBlockProved = lastBlockProvedResponse?.block_height;
                const blockToProve = emitxferAction.block_num;
                const isLightProof = (lastBlockProved > blockToProve);

                return Promise.all([
                    this.fetchProof(blockToProve, emitxferAction, isLightProof ? lastBlockProved : undefined),
                    isLightProof,
                    lastBlockProvedResponse
                ]);
            })
            .then(([proof, isLightProof, lastBlockProvedResponse]) => {
                if (isLightProof) {
                    proof.data.blockproof.root = lastBlockProvedResponse.block_merkle_root;
                }

                return this.submitProof([], proof);
            })
            .catch(error => console.log('Error occured when checking interrupted transactions: ' + error.message));
    };

    getTokenName(tokenID) {
        switch (tokenID) {
            case this.#SUPPORTED_TOKENS.BANANA:
                return '$BANANA';
            case this.#SUPPORTED_TOKENS.PEEL:
                return '$PEEL';
            default:
                return '$UNKNOWN';
        }
    };

    formatTokenAmount(amount) {
        switch (this.state.selectedToken) {
            case this.#SUPPORTED_TOKENS.BANANA:
                return formatBananasTokenAmount(amount);
            case this.#SUPPORTED_TOKENS.PEEL:
                return formatPeelsTokenAmount(amount);
            default:
                throw new Error('Unknown token selected');
        }
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'bridgeTokens-modal'});
            }
            else {
                clearInterval(this.trxFinalityProgressInterval);

                // Make the modal disappear and then change
                // props which affect content that is shown
                setTimeout(() => {
                    this.setState({
                        selectedToken: this.#SUPPORTED_TOKENS.BANANA,
                        tokenAmount: '',
                        tokenBalance: undefined,
                        destinationSession: undefined,
                        allowTrxRetry: undefined,
                        proofWaitingProgress: undefined,
                        proofTrxID: undefined
                    });
                }, 800);
            }
        }

        // Fetch user's token balance once logged in (got passed the select token screen)
        if (this.isLoggedIn && this.state.tokenBalance === undefined && !this.state.isFetchingData) {
            this.fetchTokensBalance();
        }
    };

    close() {
        this.props.onCloseClicked();
    };

    render() {
        const currentDestinationChainName = (this.destinationChain && getBlockchainName(this.destinationChain));
        const proofWaitingProgress = this.state.proofWaitingProgress;
        const isLoggedIn = this.isLoggedIn;
        const isFetchingData = this.state.isFetchingData;
        const isTransacting = this.state.isTransacting;
        const isProofWaiting = (proofWaitingProgress >= 0);
        const isProofTrxSubmitted = (!!this.state.proofTrxID);
        const canRetryTrx = (!!this.state.allowTrxRetry);
        const isReadyToBridge = (isLoggedIn && !isProofWaiting && !canRetryTrx && !isProofTrxSubmitted);

        return (
            <>
                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />

                <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title>{t('modals.bridgeTokens.title')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {/* Also, shown if retrying a failed transaction */}
                        { (isFetchingData || (!isReadyToBridge && isTransacting)) &&
                            <div style={{textAlign: 'center'}}>
                                <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                            </div>
                        }

                        { (!isLoggedIn && !isFetchingData) && this.selectTokenView() }

                        { isReadyToBridge &&
                            <Form id="bridgeTokensForm" onSubmit={this.bridgeTokens}>
                                <Form.Group controlId="tokenAmount">
                                    <Form.Label>{t('modals.bridgeTokens.amountToBridge')}</Form.Label>
                                    <Form.Control type="number" step="any" min="1" disabled={isTransacting} value={this.state.tokenAmount} onChange={e => { this.setState({tokenAmount: e.target.value})}} autoFocus required/>

                                    { (this.state.tokenBalance !== undefined) &&
                                        <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useEntireBalance}>
                                            {t('modals.bridgeTokens.totalBalance')}: {formatAmount(this.state.tokenBalance)}
                                        </Form.Text>
                                    }
                                </Form.Group>
                            </Form>
                        }

                        { isProofTrxSubmitted &&
                            <div style={{textAlign: 'center', fontWeight: 300}}>
                                <Trans
                                    i18nKey="modals.bridgeTokens.tokensBridgedSuccessfully"
                                    values={{tokenName: this.tokenName, currentDestinationChainName}}
                                    components={{1: <b></b>}}
                                />
                            </div>
                        }

                        { canRetryTrx &&
                            <div style={{textAlign: 'center', fontWeight: 300}}>
                                {t('modals.bridgeTokens.transactionFailedMessage')} 
                            </div>
                        }

                        { (isProofWaiting && !isTransacting) &&
                            <Stack style={{alignItems: 'center'}} gap={3}>
                                <div style={{width: '33%'}}>
                                    <CircularProgressbar value={proofWaitingProgress} text={`${proofWaitingProgress}%`} strokeWidth="4"/>
                                </div>

                                <div style={{textAlign: 'center'}}>
                                    {t('modals.bridgeTokens.busyBridgingTokensTo', {chainName: this.destinationChain.name, tokenName: this.tokenName})}
                                </div>

                                <div style={{textAlign: 'center', fontWeight: 300, fontSize: '0.75rem'}}>
                                    {t('modals.bridgeTokens.processMightTakeCoupleOfMinutes')} 
                                </div>
                            </Stack>
                        }
                    </Modal.Body>

                    { !isProofWaiting &&
                        <Modal.Footer>
                            { (!isTransacting || isProofTrxSubmitted) &&
                                <Button variant="secondary" onClick={this.close}>
                                    {isProofTrxSubmitted ? t('common.ok') : t('common.cancel')}
                                </Button>
                            }

                            { !isLoggedIn && 
                                <Button variant="primary" onClick={this.login}>{t('modals.bridgeTokens.logIn')}</Button>
                            }

                            { isReadyToBridge &&
                                <Button type="submit" form="bridgeTokensForm" variant="primary" disabled={isTransacting}>
                                    {isTransacting ?
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                            <span className="visually-hidden">{t('modals.bridgeTokens.bridge')}</span>
                                        </>
                                        :
                                        t('modals.bridgeTokens.bridge')
                                    }
                                </Button>
                            }

                            { (canRetryTrx || (!isReadyToBridge && isTransacting))  &&
                                <Button type="submit" variant="primary" onClick={this.retryProofSubmission} disabled={isTransacting}>
                                    {isTransacting ?
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                            <span className="visually-hidden">{t('modals.bridgeTokens.retry')}</span>
                                        </>
                                        :
                                        t('modals.bridgeTokens.retry')
                                    }
                                </Button>
                            }
                        </Modal.Footer>
                    }
                </Modal>
            </>
        );
    };
}

export default commonContextsWrapper(BridgeTokensModal);

// Helpers
function scopeName(chain) {
    const chainID = chain.id.toString();

    switch (chainID) {
        case Chains.EOS.id.toString():
            return 'eos';
        case Chains.Jungle4.id.toString():
            return 'jungle4';
        case Chains.Telos.id.toString():
            return 'tlos';
        case Chains.TelosTestnet.id.toString():
            return 'telostestnet';
        case Chains.WAX.id.toString():
            return 'wax';
        case Chains.WAXTestnet.id.toString():
            return 'waxtestnet';
        default:
            return '';
    }
};
