import InventoryAssetTypesObject from './InventoryAssetTypes';
import i18n, { t } from '../i18n';

import axeIcon from '../Assets/EquipmentAssets/Axe.png';
import baseballBatIcon from '../Assets/EquipmentAssets/BaseballBat.png';
import baseballBatWithNailsIcon from '../Assets/EquipmentAssets/BaseballBatWithNails.png';
import knifeIcon from '../Assets/EquipmentAssets/Knife.png';
import stickIcon from '../Assets/EquipmentAssets/Stick.png';
import swordIcon from '../Assets/EquipmentAssets/Sword.png';

const InventoryAssetTypes = InventoryAssetTypesObject.Types;

const EquipmentTypes = function() {
    let types = {
        STICK: 1,
        BASEBALL_BAT: 2,
        BASEBALL_BAT_WITH_NAILS: 3,
        SWORD: 4,
        KNIFE: 5,
        AXE: 6
    };
    
    // Make it possible to initialize the enum with the value too
    Object.keys(types).forEach(type => {
        const rawValue = types[type];
        types[rawValue] = type;
    });

    return Object.freeze(types);
}();

const equipmentDetails = {
    [EquipmentTypes.STICK]: {
        name: t('equipment.stick.name'),
        description: t('equipment.stick.description'),
        icon: stickIcon,
        maxDamagePoints: 5,
        maxFights: 6,
        recipe: {
            energyRequired: 10,
            xpRequired: 200,
            assetsRequired: [
                [InventoryAssetTypes.WOOD, 2]
            ]
        }
    },
    [EquipmentTypes.BASEBALL_BAT]: {
        name: t('equipment.baseballBat.name'),
        description: t('equipment.baseballBat.description'),
        icon: baseballBatIcon,
        maxDamagePoints: 12,
        maxFights: 26,
        recipe: {
            energyRequired: 25,
            xpRequired: 500,
            assetsRequired: [
                [InventoryAssetTypes.WOOD, 4]
            ]
        }
    },
    [EquipmentTypes.BASEBALL_BAT_WITH_NAILS]: {
        name: t('equipment.baseballBatWithNails.name'),
        description: t('equipment.baseballBatWithNails.description'),
        icon: baseballBatWithNailsIcon,
        maxDamagePoints: 17,
        maxFights: 26,
        recipe: {
            energyRequired: 30,
            xpRequired: 500,
            assetsRequired: [
                [InventoryAssetTypes.WOOD, 4],
                [InventoryAssetTypes.IRON, 1]
            ]
        }
    },
    [EquipmentTypes.SWORD]: {
        name: t('equipment.sword.name'),
        description: t('equipment.sword.description'),
        icon: swordIcon,
        maxDamagePoints: 25,
        maxFights: 40,
        recipe: {
            energyRequired: 50,
            xpRequired: 3000,
            assetsRequired: [
                [InventoryAssetTypes.FIRE_STARTER_KIT, 1],
                [InventoryAssetTypes.WOOD, 1],
                [InventoryAssetTypes.IRON, 4]
            ]
        }
    },
    [EquipmentTypes.KNIFE]: {
        name: t('equipment.knife.name'),
        description: t('equipment.knife.description'),
        icon: knifeIcon,
        maxDamagePoints: 20,
        maxFights: 40,
        recipe: {
            energyRequired: 37.5,
            xpRequired: 1500,
            assetsRequired: [
                [InventoryAssetTypes.FIRE_STARTER_KIT, 1],
                [InventoryAssetTypes.WOOD, 1],
                [InventoryAssetTypes.IRON, 2]
            ]
        }
    },
    [EquipmentTypes.AXE]: {
        name: t('equipment.axe.name'),
        description: t('equipment.axe.description'),
        icon: axeIcon,
        maxDamagePoints: 15,
        maxFights: 26,
        recipe: {
            energyRequired: 25,
            xpRequired: 800,
            assetsRequired: [
                [InventoryAssetTypes.FIRE_STARTER_KIT, 1],
                [InventoryAssetTypes.WOOD, 3],
                [InventoryAssetTypes.IRON, 2]
            ]
        }
    }
};

const allEquipment = Object
    .keys(equipmentDetails)
    .map(id => EquipmentTypes[id]);

const EquipmentTypesObject = {
    Types: EquipmentTypes,
    allEquipment: allEquipment,
    equipmentDetails(type) {
        return equipmentDetails[EquipmentTypes[type]];
    },
    equipmentIDFromType(type) {
        return EquipmentTypes[type];
    },
    typeFromEquipmentID(assetID) {
        return (EquipmentTypes[assetID] || EquipmentTypes[-1]);
    }
};

// A workaround, triggered when the resources get loaded
i18n.on('loaded', function(loaded) {
    if (Object.keys(loaded).length) {
        Object
            .values(equipmentDetails)
            .forEach(eqp => {
                eqp.name = t(eqp.name);
                eqp.description = t(eqp.description);
            });
    }
});

export default EquipmentTypesObject;
