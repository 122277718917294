import FYMSideChainSession from './FYMSidechainSession';
import { formatUnits } from 'ethers';

import {
    getEVMChainSlug,
    EVM_BLOCKCHAINS,
    EVM_TOKEN_CONTRACT_ADDRESS
} from '../config';

class FYMEVMSession extends FYMSideChainSession {
    #walletAPI;

    static get isUserLoggedIn() {
        return (FYMSideChainSession.isUserLoggedIn && (FYMSideChainSession.chainType === FYMSideChainSession.CHAIN_TYPE.EVM));
    }

    get isUserLoggedIn() {
        return FYMEVMSession.isUserLoggedIn;
    }

    get isFullyInitialized() {
        return (this.isNetworkSupported && this.isAccountKeySet && this.isUserLoggedIn && this.isAccountNameSet);
    }

    get isNetworkSupported() {
        return !!getEVMChainSlug(this.chainID); // If it exists then it must be supported
    }

    get isOFTBridgeBased() {
        return (this.chainID !== EVM_BLOCKCHAINS['tevm'].chainID);
    }

    get chainID() {
        // Persisting chainID helps us avoid doing async fetch
        // which may not always be working as due to a bug
        // described in the SharedSession
        return FYMEVMSession.recentlyUsedChainID;
    }

    set chainID(chainID) {
        // Must be present to avoid error of setting
        // a property without getter
        super.chainID = chainID;
    }

    get provider() {
        return this.#walletAPI.provider;
    }

    constructor(walletAPI, address, chainID) {
        super(FYMSideChainSession.CHAIN_TYPE.EVM);

        this.#walletAPI = walletAPI;
        this.address = address;
        this.chainID = chainID;
    };

    // Wallet APIs
    async switchChain(chainID) {
        try {
            await this.#walletAPI.switchChain(chainID);
            this.chainID = chainID;
        }
        catch(error) {
            if (error.code === 4902) {
                await this.#walletAPI.addChain(chainID);
                return this.switchChain();
            }
            
            throw error;
        }
    };
    
    async signData(data) {
        return this.#walletAPI.signData(data, this.address);
    };

    async fetchBananasBalance() {
        const chainSlug = getEVMChainSlug(this.chainID);
        const tokenAddress = EVM_TOKEN_CONTRACT_ADDRESS[chainSlug];
        const balance = await this.#walletAPI.fetchBalanceOf(tokenAddress, this.address);
        const balanceFormatted = formatUnits(balance, 4);
        return parseFloat(balanceFormatted);
    };

    async sendTransaction(params) {
        return this.#walletAPI.sendTransaction(params);
    };

    async getTransactionReceipt(trxID) {
        return this.#walletAPI.getTransactionReceipt(trxID);
    };
    
    async getBlockNumber() {
        return this.#walletAPI.getBlockNumber();
    };
}

export default FYMEVMSession;
