import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import EquipmentAsset from '../../Models/EquipmentAsset';
import { GAME_CONSTANTS } from '../../config';
import { t } from '../../i18n';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';

import './ChooseWeaponModal.css';

class ChooseWeaponModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }
    
    get trxAPI() {
        return this.props.trxAPI;
    }
    
    get rpc() {
        return this.sharedState.rpc;
    }

    constructor(props) {
        super(props);
        
        this.state = {
            weapons: undefined,
            selectedWeapons: [],
            isFetchingData: false,
            isTransacting: false
        };
        
        this.setWeapons = this.setWeapons.bind(this);
        this.close = this.close.bind(this);
    };

    // Transactions
    setWeapons() {        
        // If nothing really changed, no trx needed
        if (!this.newWeaponsSelected()) {
            this.close();
            return;
        }

        this.setState({isTransacting: true});

        const selectedWeapons = this.state.selectedWeapons;
        const selectedWeaponsIDs = selectedWeapons.map(weapon => weapon.id);

        this.trxAPI.setWeapons(selectedWeaponsIDs)
            .then(() => {
                this.props.monkey.useWeapons(selectedWeapons);
                this.props.onWeaponsChanged(this.props.monkey);
                this.close();
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    // Fetching
    fetchWeapons() {
        this.setState({isFetchingData: true});

        this.rpc.fetchEquipmentAssets(this.session.accountName)
            .then(equipmentRaw => {
                // Include all rows (weapons) regardless of the available amount
                const availableWeapons = equipmentRaw.map(obj => new EquipmentAsset(obj));
                this.setState({isFetchingData: false, weapons: availableWeapons});
            })
            .catch(() => this.close());
    };

    // Components
    weaponsList() {
        if (!this.state.weapons) {
            return;
        }

        const weapons = this.state.weapons.filter(weapon => weapon.availableQuantity > 0);
        const selectedWeapons = this.state.selectedWeapons;

        const WeaponItem = (weapon, index, isSelected) => {
            const itemClassName = 'WeaponsGridItem' + (isSelected ? ' selected' : '');
            const iconClassName = 'WeaponsGridItem-icon' + (isSelected ? ' selected' : '');

            const onItemClick = () => {
                isSelected ? this.deselectWeapon(weapon) : this.selectWeapon(weapon);
            };

            return (
                <Col xs={4} key={index} className={index > 2 ? 'pt-2' : ''}>
                    <Stack className={itemClassName} onClick={onItemClick}>
                        <img src={weapon.icon} className={iconClassName} alt={weapon.name + '-icon'} draggable="false"/>
                    </Stack>
                </Col>
            );
        };

        const SelectedWeaponItem = (weapon, index) => {
            return WeaponItem(weapon, index, true);
        };

        const DeselectedWeaponItem = (weapon, index) => {
            return WeaponItem(weapon, (index + selectedWeapons.length), false);
        };

        return (
            <Stack>
                <Container className="WeaponsGrid" fluid>
                    <Row>
                        {selectedWeapons.map(SelectedWeaponItem)}
                        {weapons.map(DeselectedWeaponItem)}
                    </Row>
                </Container>

                <div className="WeaponsGridItem-footerMessage">{t('modals.chooseWeapon.clickOnWeaponToSelectIt')}</div>
            </Stack>
        );
    };
    
    // Misc
    newWeaponsSelected() {
        const selectedWeapons = this.state.selectedWeapons.map(weapon => weapon.id);
        const usedWeapons = this.props.monkey.equipmentUsed.map(weapon => weapon.id);

        // A quick fix as the intersecting doesn't work well if
        // using [2, 2] - [2]. It returns zero new weapons set
        // but in such a case we don't even need checking
        if (selectedWeapons.length !== usedWeapons.length) {
            return true;
        }

        const diffWeapons = selectedWeapons
            .filter(e => !usedWeapons.includes(e))
            .concat(usedWeapons.filter(e => !selectedWeapons.includes(e)))
            .length;

        return (diffWeapons > 0);
    };

    currentlyUsedWeapons() {
        // The monkey object might not exist if the user
        // just logged in without depositing any tokens
        if (!this.props.monkey) {
            return [];
        }

        return this.props.monkey.equipmentUsed
            .map(eqp => EquipmentAsset.fromID(eqp.id, eqp.lifespan));
    };

    selectWeapon(weapon) {
        const weapons = this.state.weapons;
        const selectedWeapons = this.state.selectedWeapons;
        const lifespan = Math.min(weapon.quantity, 1);

        selectedWeapons.push(EquipmentAsset.fromID(weapon.id, lifespan));
        weapon.quantity -= lifespan;

        if (selectedWeapons.length > GAME_CONSTANTS.MAX_WEAPONS_COUNT) {
            const deselectedWeapon = selectedWeapons.shift();
            const weaponDetails = weapons.find(w => w.id === deselectedWeapon.id);
            weaponDetails.quantity += deselectedWeapon.quantity;
        }

        this.setState({selectedWeapons, weapons});
    };

    deselectWeapon(deselectedWeapon) {
        const weapons = this.state.weapons;
        const selectedWeapons = this.state.selectedWeapons;
        const weapon = weapons.find(w => w.id === deselectedWeapon.id);
        const deselectedWeaponIndex = selectedWeapons.findIndex(w => w.id === deselectedWeapon.id);

        weapon.quantity += deselectedWeapon.quantity;
        selectedWeapons.splice(deselectedWeaponIndex, 1);

        this.setState({selectedWeapons});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                this.setState({selectedWeapons: this.currentlyUsedWeapons()});
                this.fetchWeapons();
                ReactGA.send({hitType: 'pageview', page: 'chooseWeapon-modal'});
            }
            else {
                // Make the modal disappear and then change
                // props which affect content that is shown
                setTimeout(() => {
                    if (!this.props.isShown) {
                        this.setState({weapons: undefined, selectedWeapons: []});
                    }
                }, 800);
            }
        }
    };

    render() {
        const weapons = this.state.weapons?.filter(weapon => weapon.availableQuantity > 0);
        const selectedWeapons = this.state.selectedWeapons;
        const hasWeapons = (selectedWeapons.length > 0 || weapons?.length > 0);
        
        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.chooseWeapon.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { this.state.isFetchingData &&
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                        </div>
                    }

                    { (!this.state.isFetchingData && !hasWeapons) &&
                        <p style={{textAlign: 'center', marginBottom: 0}}>{t('modals.chooseWeapon.noWeaponsInPossession')}</p>
                    }

                    { hasWeapons &&
                        this.weaponsList()
                    }
                </Modal.Body>

                <Modal.Footer>
                    {!this.state.isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    { hasWeapons &&
                        <Button variant="primary" onClick={this.setWeapons} disabled={this.state.isFetchingData || this.state.isTransacting}>
                            { this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('common.save')}</span>
                                </>
                                :
                                t('common.save')
                            }
                        </Button>
                    }

                    { !hasWeapons &&
                        <Button variant="primary" onClick={this.close}>{t('common.ok')}</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };
}

export default commonContextsWrapper(ChooseWeaponModal);
