import {
    formatTokenAmount,
    tokenAmountToBigInt,
    formatBananasTokenAmount,
    formatEOSTokenAmount,
    formatTLOSTokenAmount,
    formatWAXTokenAmount
} from "./Utils";

export const ExchangeType = {
    DEFIBOX: 0,
    ALCOR: 1,
};

export class BananaPricing {
    #session;
    #bananaReserve;
    #SWAP_FEE = 30n; // 0.3%

    #DEFIBOX_CONTRACT_NAME = 'swap.defi'; // WAX: swap.box
    #ALCOR_CONTRACT_NAME = 'swap.alcor';

    #DEFIBOX_PAIR_ID = 2210; // WAX: 1014
    #ALCOR_PAIR_ID = {'TELOS': 73, 'WAX': 1166};

    get client() {
        return this.#session.client.v1;
    }

    get chainName() {
        return this.#session?.chain.name.toUpperCase();
    }

    get nativeTokenPrecision() {
        return (this.chainName === 'WAX') ? 8 : 4;
    }

    get defaultExchangeType() {
        return (this.chainName === 'EOS') ? ExchangeType.DEFIBOX : ExchangeType.ALCOR;
    }

    constructor(session) {
        this.#session = session;
    };

    // Price Fetching (off-chain)
    async fetchBananaUSDPrice() {
        const ALCOR_API_URL = 'https://alcor.exchange/api/v2/tokens/banana-banana.moon';
        const response = await fetch(ALCOR_API_URL, {method: 'GET'});
        const jsonData = await response.json();
        return jsonData['usd_price'];
    };

    // Reserve Fetching
    async loadBananaReserve() {
        switch (this.defaultExchangeType) {
            case ExchangeType.DEFIBOX:
                return await this.loadBananaReserveDefibox();
            case ExchangeType.ALCOR:
                return await this.loadBananaReserveAlcor();
            default:
                break;
        }
    };

    async loadBananaReserveDefibox() {
        const response = await this.client.chain.get_table_rows({
            code: this.#DEFIBOX_CONTRACT_NAME,
            scope: this.#DEFIBOX_CONTRACT_NAME,
            table: 'pairs',
            lower_bound: this.#DEFIBOX_PAIR_ID,
            upper_bound: this.#DEFIBOX_PAIR_ID,
            key_type: 'i64',
            limit: 1
        });

        const row = response.rows[0];

        this.#updateBananaReserve({
            reserveIn: row['reserve0'], // EOS
            reserveOut: row['reserve1'] // BANANA
        });
    };

    async loadBananaReserveAlcor() {
        const pairID = this.#ALCOR_PAIR_ID[this.chainName];
        const response = await this.client.chain.get_table_rows({
            code: this.#ALCOR_CONTRACT_NAME,
            scope: this.#ALCOR_CONTRACT_NAME,
            table: 'pools',
            lower_bound: pairID,
            upper_bound: pairID,
            key_type: 'i64',
            limit: 1
        });

        const row = response.rows[0];

        this.#updateBananaReserve({
            reserveIn: row['tokenB'].quantity, // TLOS
            reserveOut: row['tokenA'].quantity // BANANA
        });
    };
    
    // Details Getter
    getExchangeContract(exchangeType) {
        switch (exchangeType) {
            case ExchangeType.DEFIBOX:
                return this.#DEFIBOX_CONTRACT_NAME;
            case ExchangeType.ALCOR:
                return this.#ALCOR_CONTRACT_NAME;
            default:
                return undefined;
        }
    };

    getBuyBananaMemo(exchangeType, eosioAmount) {
        const bananasExpected = (this.calcBananasAmount(eosioAmount) * (1 - 0.03)); // Allow max 3% price change
        
        if (exchangeType === ExchangeType.DEFIBOX) {    
            const minToReceiveBigInt = this.#decimalToBigInt(bananasExpected, 4);
            const pairID = this.#DEFIBOX_PAIR_ID;
            return `swap,${minToReceiveBigInt},${pairID}`;
        }
        else {
            const bananasAmount = formatBananasTokenAmount(bananasExpected);
            const pairID = this.#ALCOR_PAIR_ID[this.chainName];
            return `swapexactin#${pairID}#${this.#session.accountName}#${bananasAmount}@banana.moon#0`;
        }
    };

    getSellBananaMemo(bananaAmount, slippageProtection = 0.03) { // Allow max 3% price change
        const eosioExpected = (this.calcEOSIOAmount(bananaAmount) * (1 - slippageProtection));
        return this.getSellBananaMemoUsingEosioAmount(eosioExpected);
    };

    getSellBananaMemoUsingEosioAmount(expectedEosioAmount) {
        const eosioAmountFormatted = this.#formatNativeCurrency(expectedEosioAmount);
        const pairID = this.#ALCOR_PAIR_ID[this.chainName];
        return `swapexactin#${pairID}#${this.#session.accountName}#${eosioAmountFormatted}@eosio.token#0`;
    };

    // Amounts / Prices Calculations
    calcBananasAmount(eosioAmount) {
        if (!eosioAmount || eosioAmount <= 0 || !this.#bananaReserve) {
            return 0;
        }
        
        const precision = this.nativeTokenPrecision;
        const bigNumPre = BigInt(10 ** precision);
        const amountWithFee = (this.#decimalToBigInt(eosioAmount, precision) * (bigNumPre - this.#SWAP_FEE));
        const n = (amountWithFee * this.#bananaReserve.out);
        const d = ((this.#bananaReserve.in * bigNumPre) + amountWithFee);
        const bananasAmount = this.#bigIntToDecimal(n / d); // The amount of BANANA worth 1 EOS

        return bananasAmount;
    };

    calcEOSIOAmount(bananasAmount) {
        if (!bananasAmount || bananasAmount <= 0 || !this.#bananaReserve) {
            return 0;
        }
        
        const precision = this.nativeTokenPrecision;
        const bigNumPre = BigInt(10 ** precision);
        const amountBigInt = this.#decimalToBigInt(bananasAmount, 4);
        const n = (this.#bananaReserve.in * amountBigInt * 10000n);
        const d = ((this.#bananaReserve.out - amountBigInt) * (bigNumPre - this.#SWAP_FEE));
        const eosioAmount = this.#bigIntToDecimal((n / d) + 1n);
        
        return eosioAmount < 0 ? 0 : eosioAmount;
    };

    // Utils
    #bigIntToDecimal(bigInt) {
        return (Number(bigInt) / 10000);
    };

    #decimalToBigInt(decimal, precision) {
        const tokenAmountString = formatTokenAmount(decimal, '', precision).trim();
        return tokenAmountToBigInt(tokenAmountString);
    };

    #updateBananaReserve({reserveIn, reserveOut}) {
        // Must be converted to BigInt for accurate calculations
        this.#bananaReserve = {
            in: tokenAmountToBigInt(reserveIn),
            out: tokenAmountToBigInt(reserveOut)
        };
    };

    #formatNativeCurrency(amount) {
        switch (this.chainName) {
            case 'EOS':
                return formatEOSTokenAmount(amount);
            case 'TELOS':
                return formatTLOSTokenAmount(amount);
            case 'WAX':
                return formatWAXTokenAmount(amount);
            default:
                break;
        }
    };
};
