import {
    Contract,
    JsonRpcProvider,
    Network,
    solidityPacked,
    parseUnits,
    formatUnits,
    zeroPadValue,
    encodeBytes32String
} from 'ethers';

import {
    getEVMChainSlug,
    EVM_BLOCKCHAINS,
    EVM_OFT_PROXY_ADDRESS,
    EVM_TOKEN_CONTRACT_ADDRESS,
    EVM_BRIDGE_CONTRACT_ADDRESS
} from '../config';

class OFTContract {
    #DEFAULT_GAS_LIMIT = 200_000; // Min gas required by the dest. contact (releasing/locking up tokens)
    #DEST_CONTRACT_GAS_LIMIT = 325_000; // Min gas required by the contract to be called (TokenBridge.bridge))
    #SEND_AND_CALL_ADAPTER_PARAMS = solidityPacked(['uint16', 'uint256'], [1, (this.#DEFAULT_GAS_LIMIT + this.#DEST_CONTRACT_GAS_LIMIT)]);
    #SEND_ONLY_ADAPTER_PARAMS = solidityPacked(['uint16', 'uint256'], [1, this.#DEFAULT_GAS_LIMIT]);
    
    #evmSession;
    #provider;
    #telosProvider;

    constructor(evmSession) {
        this.#evmSession = evmSession;
        this.#provider = evmSession.provider;
        this.#telosProvider = this.#getTEVMProvider(); // We also need to query the opposite blockchain
    };

    // Simplified Methods
    getBridgeTokensOntoNativeData(amount) {
        const destinationChainID = EVM_BLOCKCHAINS['tevm'].chainID;

        // Depositing into the game calls the TokenBridge contract which
        // then automatically bridges tokens further onto the native end
        return this.#getSendAndCallData(
            OFTContract.layerZeroChainID(destinationChainID),
            EVM_BRIDGE_CONTRACT_ADDRESS,
            this.#evmSession.accountName,
            parseUnits(amount.toString(), 4)
        );
    };

    async estimateDepositFee(amount) {
        const destinationChainID = EVM_BLOCKCHAINS['tevm'].chainID;

        // Depositing into the game calls the TokenBridge contract which
        // then automatically bridges tokens further onto the native end
        return this.#estimateSendAndCallFee(
            OFTContract.layerZeroChainID(destinationChainID),
            EVM_BRIDGE_CONTRACT_ADDRESS,
            this.#evmSession.accountName,
            parseUnits(amount.toString(), 4)
        );
    };

    async estimateWithdrawFee(amount) {
        const destinationChainID = EVM_BLOCKCHAINS['bsc'].chainID;
        const sourceChainID = EVM_BLOCKCHAINS['tevm'].chainID;

        return this.#estimateSendFee(
            this.#telosProvider,
            sourceChainID,
            OFTContract.layerZeroChainID(destinationChainID),
            this.#evmSession.address,
            parseUnits(amount.toString(), 4)
        );
    };

    // Static
    static layerZeroChainID(evmChainID) {
        switch(evmChainID) {
            // Mainnet
            case 40: return 199;
            case 56: return 102;

            // Testnet
            case 41: return 10199;
            case 97: return 10102;

            default:
                throw new Error(`Unknown chainID: ${evmChainID}`);
        }
    };

    // Private
    #getSendAndCallData(destinationChainID, toAddress, accountName, amount) {
        const abi = ['function sendAndCall(address,uint16,bytes32,uint256,bytes,uint64,(address,address,bytes))'];
        const oftContractAddress = this.#oftContractAddressForChainID(this.#evmSession.chainID);
        const oftContract = new Contract(oftContractAddress, abi, this.#provider);
        const bytesAddress = zeroPadValue(toAddress, 32);
        const bytesPayload = encodeBytes32String(accountName);

        const lzParams = [
            this.#evmSession.address, // Refund address
            '0x0000000000000000000000000000000000000000', // ZRO payment address
            this.#SEND_AND_CALL_ADAPTER_PARAMS
        ];

        const calldata = oftContract.interface.encodeFunctionData('sendAndCall', [
            this.#evmSession.address,
            destinationChainID,
            bytesAddress,
            amount,
            bytesPayload,
            this.#DEST_CONTRACT_GAS_LIMIT,
            lzParams
        ]);

        return {calldata, oftContractAddress};
    };

    async #estimateSendAndCallFee(destinationChainID, toAddress, accountName, amount) {
        const abi = ['function estimateSendAndCallFee(uint16,bytes32,uint256,bytes,uint64,bool,bytes) view returns (uint, uint)'];
        const oftContractAddress = this.#oftContractAddressForChainID(this.#evmSession.chainID);
        const oftContract = new Contract(oftContractAddress, abi, this.#provider);
        const bytesAddress = zeroPadValue(toAddress, 32);
        const bytesPayload = encodeBytes32String(accountName);

        const result = await oftContract.estimateSendAndCallFee(
            destinationChainID,
            bytesAddress,
            amount,
            bytesPayload,
            this.#DEST_CONTRACT_GAS_LIMIT,
            false, // Use ZRO to pay L0 fees
            this.#SEND_AND_CALL_ADAPTER_PARAMS
        );

        return result[0]; // {0: nativeFee, 1: zroFee}
    };

    async #estimateSendFee(provider, sourceChainID, destinationChainID, toAddress, amount) {
        const abi = ['function estimateSendFee(uint16,bytes32,uint256,bool,bytes) view returns (uint, uint)'];
        const oftContractAddress = this.#oftContractAddressForChainID(sourceChainID);
        const oftContract = new Contract(oftContractAddress, abi, provider);
        const bytesAddress = zeroPadValue(toAddress, 32);

        const result = await oftContract.estimateSendFee(
            destinationChainID,
            bytesAddress,
            amount,
            false, // Use ZRO to pay L0 fees
            this.#SEND_ONLY_ADAPTER_PARAMS
        );

        return result[0]; // {0: nativeFee, 1: zroFee}
    };

    async getAllowance() {
        // Only works (used) with a token contract (OFTV2
        // contract). Won't work on the native side
        if (!this.#evmSession.isOFTBridgeBased) {
            throw new Error('Not an OFTV2 based chain');
        }

        const abi = ['function allowance(address owner, address spender) external view returns (uint256 remaining)'];
        const oftContractAddress = this.#oftContractAddressForChainID(this.#evmSession.chainID);
        const oftContract = new Contract(oftContractAddress, abi, this.#provider);
        const weiAmount = await oftContract.allowance(this.#evmSession.address, oftContractAddress);

        return parseFloat(formatUnits(weiAmount, 4));
    };

    #oftContractAddressForChainID(chainID) {
        const slug = getEVMChainSlug(chainID);
        return (slug === 'tevm') ? EVM_OFT_PROXY_ADDRESS : EVM_TOKEN_CONTRACT_ADDRESS[slug];
    };

    #getTEVMProvider() {
        const chain = EVM_BLOCKCHAINS['tevm'];
        const network = Network.from({name: chain.name, chainId: chain.chainID});
        return new JsonRpcProvider(chain.rpcURL.toString(), network, {staticNetwork: network});
    };
}

export default OFTContract;
