import { SessionKit, Chains } from '@wharfkit/session'
import WebRenderer from '@wharfkit/web-renderer'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat';
import { WalletPluginTokenPocket } from '@wharfkit/wallet-plugin-tokenpocket';
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet';
import { TransactPluginResourceProvider } from '@wharfkit/transact-plugin-resource-provider';
import { EOSIO_BLOCKCHAINS } from '../config';

const options = {
    transactPlugins: [new TransactPluginResourceProvider()]
};

const sessionKit = new SessionKit({
    appName: 'Feed Your Monkey',
    chains: Object.values(EOSIO_BLOCKCHAINS),
    ui: new WebRenderer({minimal: true}),
    walletPlugins: [
        new WalletPluginAnchor(),
        new WalletPluginWombat(),
        new WalletPluginTokenPocket(),
        new WalletPluginCloudWallet({
            supportedChains: [Chains.WAX.id.toString()]
        })
    ]
}, options);

export default sessionKit;
