class BananaTreePlot {
    get isTreeGrown() {
        return !!this.tree;
    }

    get isTreeGrowing() {
        return (this.treeGrowthState >= 0 && this.treeGrowthState < 1);
    }

    get isEmpty() {
        return (!this.isTreeGrowing && !this.isTreeGrown);
    }

    static withTree(tree) {
        return new BananaTreePlot(tree);
    };

    static withUngrownTree(growthState) {
        const plot = BananaTreePlot.empty();
        plot.treeGrowthState = (growthState || 0);
        return plot;
    };

    static empty() {
        return new BananaTreePlot();
    };
    
    constructor(tree) {
        this.tree = tree;
        this.treeGrowthState = undefined;
    }
}

export default BananaTreePlot;