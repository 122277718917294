import Modal from 'react-bootstrap/Modal';
import battleReadyMonkeyVideo from '../../Assets/BattleReadyMonkey.mp4';

import { t, Trans } from '../../i18n';
import './AboutGameModal.css';

function AboutGameModal(props) {
    return (
        <Modal show={props.isShown} onHide={props.onCloseClicked} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('modals.aboutGame.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h6>{t('modals.aboutGame.choosingOpponentSubtitle')}</h6>
                <p>{t('modals.aboutGame.choosingOpponentDescription')}</p>
                <p>{t('modals.aboutGame.choosingOpponentDescription2')}</p>

                <h6>{t('modals.aboutGame.fightingConsumesEnergySubtitle')}</h6>
                <p>{t('modals.aboutGame.fightingConsumesEnergyDescription')}</p>

                <h6>{t('modals.aboutGame.dominanceRankingSubtitle')}</h6>
                <p>
                    {t('modals.aboutGame.ranksExplainedMessage')}:
                    <br/><br/>
                    - <Trans i18nKey="modals.aboutGame.alphaExplained"><b>Alpha</b>: this is the top and the most dominant monkey.</Trans><br/>
                    - <Trans i18nKey="modals.aboutGame.subordinateExplained"><b>Subordinate</b>: can be disguised by seeing a number next to their name, which indicates position in the social structure after the alpha.</Trans><br/>
                    - <Trans i18nKey="modals.aboutGame.preyExplained"><b>Prey</b> are primates whose record consist primarily or only of losses.</Trans><br/>
                    - <Trans i18nKey="modals.aboutGame.noTitleExplained"><b>No title</b> is visible if the monkey has not done any fighting yet and so their capabilities are unknown.</Trans>
                </p>

                {t('modals.aboutGame.goodLuckToAllOfYou')}

                <video src={battleReadyMonkeyVideo} className="fight-ready-video" alt="fight-ready-monkey" autoPlay muted playsInline loop/>
            </Modal.Body>
        </Modal>
    );
}

export default AboutGameModal;
