import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import { t, Trans } from '../../../i18n';
import { formatTokenAmount, formatGenericAmount } from '../../../Helpers/Utils';
import { FYM_CONTRACT_NAME, AA_CONTRACT_NAME, BANANA_TREE_TEMP_ID } from '../../../config';
import commonContextsWrapper from '../../../Helpers/commonContextsWrapper';

class StakeBoxLPModal extends React.Component {
    #MIN_LP_PER_TREE_REQUIRED = 80_000_000; // 80M ≈ 641 EOS (24H/1TREE)
    #E = 60; // Increases LP required to grow a tree if the market is flooded

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    get session() {
        return this.sharedState?.session;
    }

    get isStaking() {
        return !!this.props.stakingDetails;
    }

    constructor(props) {
        super(props);

        this.state = {
            lpAmount: '', // Amount to stake (deposit)
            lpBalance: undefined, // User's account balance
            lpPerTree: undefined,
            isTransacting: false,
            isFetchingData: false
        };

        this.onTokenFormSubmitted = this.onTokenFormSubmitted.bind(this);
        this.useAllBalance = this.useAllBalance.bind(this);
        this.close = this.close.bind(this);
    }

    // Handling Form Submission
    onTokenFormSubmitted(event) {
        event.preventDefault();
        this.setState({isTransacting: true});

        const quantity = this.formatLPTokenAmount(this.state.lpAmount);

        this.trxAPI.stakeLPTokens(quantity)
            .then(() => {
                this.props.onTokensStaked(parseInt(this.state.lpAmount));
                this.close();
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    // Fetching
    fetchData() {
        this.setState({isFetchingData: true});

        Promise.all([
            this.rpc.fetchBOXCFZBalance(this.session.accountName),
            this.calcLPPerTree()
        ])
        .then(([lpBalance, lpPerTree]) => {
            this.setState({lpBalance, lpPerTree});
        })
        .catch((err) => console.log(err))
        .finally(() => this.setState({isFetchingData: false}));
    };

    // Tree Cost Fetching
    async calcLPPerTree() {
        return this.getTreesExistingCount().then(treesExisting => {
            // We assume the rest is available for sale even though
            // some might be simply be in the wallet. There isn't
            // a way to check the number of NFTs for sale without
            // using a dedicated AtomicHub API.
            const treesForSale = (treesExisting.totalCount - treesExisting.inGameCount);
            const lpPerTreeRequired = (this.#MIN_LP_PER_TREE_REQUIRED * (1 + (treesForSale / this.#E)));
            return lpPerTreeRequired;
        });
    };

    async fetchInGameTreesCount() {
        let treesCount = 0;
        let nextKey = null;

        do {
            const response = await this.session.client.v1.chain.get_table_by_scope({
                code: FYM_CONTRACT_NAME,
                table: 'trees',
                lower_bound: nextKey,
                limit: 1000
            });

            nextKey = response['more']; // More results available

            treesCount += response.rows
                .map(row => row.count)
                .reduce((totalCount, ownerCount) => (totalCount + parseInt(ownerCount)), 0);
        } while (nextKey);

        return treesCount;
    };

    async fetchTreesNFTCount() {
        const response = await this.session.client.v1.chain.get_table_rows({
            code: AA_CONTRACT_NAME,
            scope: FYM_CONTRACT_NAME,
            table: 'templates',
            index_position: 'primary',
            key_type: 'i64',
            lower_bound: this.getBananaTreeTemplID(),
            upper_bound: this.getBananaTreeTemplID(),
            limit: 1
        });

        return response.rows[0]['issued_supply'];
    };

    async fetchNFTsCountInGame() {
        let treeNFTsCount = 0;
        let nextKey = null;

        do {
            const response = await this.session.client.v1.chain.get_table_rows({
                code: AA_CONTRACT_NAME,
                scope: FYM_CONTRACT_NAME,
                table: 'assets',
                lower_bound: nextKey,
                limit: 1000
            });

            nextKey = response['more']; // More results available

            treeNFTsCount += response.rows
                .filter(obj => (obj['schema_name'] === 'trees') && obj['template_id'] === this.getBananaTreeTemplID())
                .length;
        } while (nextKey);

        return treeNFTsCount;
    };

    async getTreesExistingCount() {
        return Promise.all([
            this.fetchInGameTreesCount(),
            this.fetchTreesNFTCount(),
            this.fetchNFTsCountInGame()
        ])
        .then(([inGameCount, nftsCount, inGameNFTsCount]) => {
            return {
                inGameCount: inGameCount,
                // In game count includes both, NFTs but also pure
                // in game trees not yet exported to NFTs. Since they
                // are also scoped per user this is the most efficient
                // way to check the count
                totalCount: ((inGameCount - inGameNFTsCount) + nftsCount),
            };
        });
    };

    // Misc
    useAllBalance() {
        this.setState({lpAmount: this.state.lpBalance});
    };

    getPotentialTreesGrowth() {
        const stakedAmount = (this.props.stakingDetails?.lpStaked || 0);
        const lpAmountParsed = parseInt(this.state.lpAmount); // Will return wrong value otherwise as a string
        const lpAmount = (lpAmountParsed + stakedAmount);
        const lpPerTree = this.state.lpPerTree;

        if (!lpAmount) {
            return t('modals.farming.stakeBoxLP.treeGrowthCurrentPrice', {currentPrice: this.formatLPTokenAmount(lpPerTree)});
        }
       
        const daysRequired = (lpPerTree / lpAmount);
        let daysNumberFormatted;

        if (daysRequired > 1000) {
            daysNumberFormatted = '1000+';
        }
        else if (daysRequired < 1) {
            daysNumberFormatted = '<1'; // <1
        }
        else {
            daysNumberFormatted = Math.round(daysRequired);
        }

        // Without the escaping being disabled the "<" will be
        // rendered incorrectly and manual escaping doesn't work
        return t('modals.farming.stakeBoxLP.treeGrowthSpeed', {
            daysNumber: daysNumberFormatted,
            interpolation: {escapeValue: false}
        });
    };

    formatLPTokenAmount(amount) {
        return formatTokenAmount(amount, 'BOXCFZ', 0);
    };

    getBananaTreeTemplID() {
        // EOS only is supported when comes to staking
        return BANANA_TREE_TEMP_ID['eos'];
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'stakeBoxLP-modal'});
                this.fetchData();
            }
            else {
                this.setState({
                    lpAmount: '',
                    lpBalance: undefined,
                    lpPerTree: undefined
                });
            }
        }
    };

    render() {
        const isBalanceAvailable = (this.state.lpBalance !== undefined);
        const isFetchingData = this.state.isFetchingData;
        const isTransacting = this.state.isTransacting;
        const isStaking = this.isStaking;

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdropClassName="ModalWithTintedBackdrop" backdrop="static" centered>
                <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal.Title>{t('modals.farming.stakeBoxLP.title')}</Modal.Title>
                    <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{ isStaking
                        ? t('modals.farming.stakeBoxLP.stakingSubtitle')
                        : t('modals.farming.stakeBoxLP.defaultSubtitle') }
                    </div>
                </Modal.Header>

                <Modal.Body>
                    { isFetchingData &&
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                        </div>
                    }
                    
                    { isBalanceAvailable &&
                        <Stack>
                            <Form id="stakeLPForm" onSubmit={this.onTokenFormSubmitted}>
                                <Form.Group className="mb-3" controlId="lpAmount">
                                    <Form.Label>{t('modals.farming.stakeBoxLP.lpTokenAmount')}</Form.Label>
                                    <Form.Control type="number" step="any" min="1" disabled={isTransacting} value={this.state.lpAmount} onChange={e => { this.setState({lpAmount: e.target.value})}} autoFocus required/>

                                    <Stack>
                                        <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useAllBalance}>
                                            {t('modals.farming.stakeBoxLP.stakeAll')}: {formatGenericAmount(this.state.lpBalance)}
                                        </Form.Text>

                                        { isStaking &&
                                            <Form.Text className="text-muted mt-0">
                                                {t('modals.farming.stakeBoxLP.currentlyStaking')}: {formatGenericAmount(this.props.stakingDetails.lpStaked)}
                                            </Form.Text>
                                        }
                                        
                                        <Form.Text className="text-muted mt-0">{this.getPotentialTreesGrowth()}</Form.Text>
                                    </Stack>
                                </Form.Group>
                            </Form>

                            { !isStaking &&
                                <p style={{textAlign: 'center', fontWeight: 300, fontSize: '0.8rem', margin: 0}}>
                                    <Trans i18nKey="modals.farming.stakeBoxLP.lpTokenExplaination">
                                        <b>$BOXCFZ</b> is a token that can be acquired by providing EOS/BANANA liquidity <a href="https://defibox.io/addMarket?pairId=2210" target="_blank" rel="noreferrer">here</a>. The more you stake, the faster you can grow trees.
                                    </Trans>
                                </p>
                            }
                        </Stack>
                    }
                </Modal.Body>

                <Modal.Footer>
                    { !isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    { isBalanceAvailable &&
                        <Button type="submit" form="stakeLPForm" variant="primary" disabled={isTransacting}>
                            { isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('common.confirm')}</span>
                                </>
                                :
                                t('common.confirm')
                            }
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };
}

export default commonContextsWrapper(StakeBoxLPModal);
