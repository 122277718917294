import {t} from '../i18n';

class FightResult {
    get status() {
        if (!this.myDamage && !this.opponentDamage) {
            return t('fightResult.draw');
        }
        
        return t(!this.myDamage ? 'fightResult.win' : 'fightResult.lose');
    }

    constructor(obj) {
        this.myDamage = parseFloat(obj['my_damage']);
        this.opponentDamage = parseFloat(obj['opponent_damage']);
        this.myEnergyUsed = parseFloat(obj['my_energy_used']);
        this.opponentEnergyUsed = parseFloat(obj['opponent_energy_used']);
        this.totalBananas = parseFloat(obj['total_bananas']);
        this.xpEarned = obj['xp_earned'];
    }
}

export default FightResult;
