import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, Trans } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        fallbackLng: (code) => {
            if (code?.startsWith('zh')) {
                return ['zh'];
            }

            if (code?.startsWith('ko')) {
                return ['ko'];
            }

            return ['en'];
        }
    });

export default i18n;
export const t = i18n.t;
export {Trans};
