import { GAME_CONSTANTS } from '../config';

class BananaTree {
    #DAY = 86400000;

    get hasBananas() {
        return (this.bananas > 0);
    }

    get bananas() {
        if (!this.fertilizer) {
            return 0;
        }

        if ((this.fertilizedAt.getTime() + this.#DAY) > Date.now()) {
            return 0;
        }

        return (this.fertilizer * GAME_CONSTANTS.PEELS_TO_BANANAS_PERC);
    }

    get isFertilized() {
        return (this.fertilizer > 0);
    }

    get bananasHarvestableAt() {
        if (!this.isFertilized || this.hasBananas) {
            return undefined;
        }

        return new Date(this.fertilizedAt.getTime() + this.#DAY);
    };

    constructor(obj) {
        this.id = obj['id'].toNumber();
        this.nftID = obj['nft_id'] ? parseInt(obj['nft_id']) : null;
        this.grown = new Date(obj['grown'] + 'Z');
        this.fertilizer = parseFloat(obj['fertilizer']);
        this.fertilizedAt = new Date(obj['fertilized_at'] + 'Z');
        this.totalFertilizerUsed = parseFloat(obj['total_fertilizer_used']);
        this.harvests = obj['harvests'].toNumber();
        this.cropHarvested = parseFloat(obj['crop_harvested']);
    }
}

export default BananaTree;
