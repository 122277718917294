import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';

import XPAmount from '../Widgets/XPAmount';
import BananaAmount from '../Widgets/BananaAmount';
import equipmentAssetTypes from '../../Helpers/EquipmentAssetTypes';
import { SharedStateContext } from '../../Helpers/SharedState';
import { accountDetailsURL } from '../../Helpers/Utils';

function PlayerDetailsModal(props) {
    const monkey = props.monkey;
    const [sharedState] = React.useContext(SharedStateContext);
    const { t } = useTranslation();

    return (
        <Modal show={props.isShown} size="sm" backdrop="static" centered>
            <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <Modal.Title>{t('modals.playerDetails.title')}</Modal.Title>

                <a href={accountDetailsURL(monkey.name, sharedState.chain)} target="_blank" rel="noreferrer">
                    <div style={{color: 'black', fontWeight: 300}}>{monkey.name}</div>
                </a>
            </Modal.Header>

            <Modal.Body>
                <Stack>
                    <RowDetails title={`${t('modals.playerDetails.fights')}:`} subdetail={`${monkey.totalFights}`}/>
                    <RowDetails title={`${t('modals.playerDetails.winsLosses')}:`} subdetail={`${monkey.wins}/${monkey.losses}`}/>

                    <RowDetails title={`${t('modals.playerDetails.weight')}:`} style={{marginTop: '1rem'}}>
                        <BananaAmount amount={monkey.bananasAmount} textStyle={{fontWeight: 300}}/>
                    </RowDetails>

                    <RowDetails title={`${t('common.xp')}:`}>
                        <XPAmount amount={monkey.xp} textStyle={{fontWeight: 300}}/>
                    </RowDetails>

                    <RowDetails title={`${t('modals.playerDetails.weapons')}:`} style={{marginTop: '1rem'}}>
                        <WeaponsList equipmentUsed={monkey.equipmentUsed}/>
                    </RowDetails>
                </Stack>
            </Modal.Body>
                
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onCloseClicked}>{t('common.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PlayerDetailsModal;

// Components
function RowDetails(props) {
    return (
        <Stack direction="horizontal" style={{...{gap: '4px', fontWeight: 300}, ...props.style}}>
            <b>{props.title}</b>
            {props.subdetail || props.children}
        </Stack>
    );
};

function WeaponsList(props) {
    const { t } = useTranslation();
    const equipmentUsed = props.equipmentUsed;

    if (!equipmentUsed.length) {
        return t('common.none');
    }

    return (
        <Stack direction="horizontal">
            {equipmentUsed.map(({id}, index) => {
                const type = equipmentAssetTypes.typeFromEquipmentID(id);
                const image = equipmentAssetTypes.equipmentDetails(type).icon;
                return <img src={image} style={{height: '1.66rem'}} alt="weapon" draggable="false" key={index}/>
            })}
        </Stack>
    );
}