import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';
import secureLocalStorage from 'react-secure-storage';
import { initHapticFeedback, retrieveLaunchParams } from '@tma.js/sdk-react';
import { Buffer } from 'buffer';
import bs58 from 'bs58';
import nacl from 'tweetnacl';

import fymAPI from '../../Helpers/FYMAPI';
import BananaAmount from '../Widgets/BananaAmount';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import { t } from '../../i18n';

import './EasterEggModal.css';
import bananaLogo from '../../Assets/BananaLogoLarge.png';
import rankingIcon from '../../Assets/RankingIcon.png';
import cheatingMonkey from '../../Assets/CheatingMonkey.jpg';

const SECOND = 1000;
const STORAGE_DATA_KEY = 'eeg';
const SECRET_KEY_DATA_KEY = 'eeg_sc';
const ENERGY_COST = 3;

class EasterEggModal extends React.Component {
    #isTouchDevice = ('ontouchstart' in window);
    #MAX_TAPS_STRIKE = 600;
    #MAX_TIME_RECOVERING = (660 * SECOND); // ~12 mins
    #haptic;
    #tgInited = false;
    #DEFAULT_STRENGTH = 0.01;

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.solanaSession;
    }

    get userID() {
        return this.state.user.id;
    };

    get login() {
        return this.props.sharedState?.[1]?.loginPhantom;
    }

    get isUserLoggedIn() {
        return this.sharedState?.isUserLoggedIn;
    }

    get tapsRemaining() {
        return Math.max(0, (this.state.maxTapsAvailable - this.state.totalTaps));
    }

    get energyLevel() {
        return (this.tapsRemaining / this.#MAX_TAPS_STRIKE);
    }

    get bananasAmount() {
        return this.state.totalBananasEarned;
    }

    get #secretKey() {
        // Try to avoid keeping the key stored in the memory
        const secretKey = secureLocalStorage.getItem(SECRET_KEY_DATA_KEY);

        if (secretKey) {
            return nacl.sign.keyPair.fromSecretKey(bs58.decode(secretKey));
        }

        const newKeyPair = nacl.sign.keyPair();
        secureLocalStorage.setItem(SECRET_KEY_DATA_KEY, bs58.encode(newKeyPair.secretKey));
        return newKeyPair;
    };

    constructor(props) {
        super(props);

        this.state = {
            user: undefined,
            isCheating: false,
            totalTaps: 0,
            totalBananasEarned: 0,
            animateBananasDeduction: false,
            maxTapsAvailable: this.#MAX_TAPS_STRIKE,
            onPressDown: undefined,
            isEarnBananasViewShown: false,
            isGainEnergyOptionsShown: false,
            isRankingViewShown: false
        };

        this.modalBodyRef = React.createRef();
        this.bananaAmountOffset = undefined;
        this.movingBananaImages = [];
        this.checkEnergyInterval = undefined;
        this.periodicDataSavingInterval = undefined;
        this.lastTapTimestamp = undefined;
        this.lastTapEnergyLevel = undefined;
        this.accountPushedTimestamp = 0;
        this.isAddressSet = false;
        this.isUserVerified = false;

        this.showMainScreen = this.showMainScreen.bind(this);
        this.increaseTaps = this.increaseTaps.bind(this);
        this.onImagePressed = this.onImagePressed.bind(this);
        this.onClickLifted = this.onClickLifted.bind(this);
        this.onTouchLifted = this.onTouchLifted.bind(this);
        this.onEatBananaOptionSelected = this.onEatBananaOptionSelected.bind(this);
        this.onLoginOptionSelected = this.onLoginOptionSelected.bind(this);
        this.isGainEnergyOptionsShown = this.isGainEnergyOptionsShown.bind(this);
        this.recuperateEnergyLevel = this.recuperateEnergyLevel.bind(this);
        this.goBackToMainView = this.goBackToMainView.bind(this);
        this.saveDataPeriodically = this.saveDataPeriodically.bind(this);
        this.pushAccountDetails = this.pushAccountDetails.bind(this);
        this.pushAddress = this.pushAddress.bind(this);
        this.close = this.close.bind(this);
    };

    // Animated Elements
    showMovingBananas(touchX, touchY) {
        const imageSize = 10;
        const animation = `appear_and_move_coin 900ms ease-out`;
        const boundingRect = this.modalBodyRef?.current?.getBoundingClientRect();
        let newMovingBananaImages = [...this.movingBananaImages.slice(-16)]; // Keep max 16+1 elements alive
        const lastKey = Number(newMovingBananaImages.at(-1)?.key || 0);

        touchX = (touchX - boundingRect.left - (imageSize / 2));
        touchY = (touchY - boundingRect.top - (imageSize / 2));

        newMovingBananaImages.push(<img
            src={bananaLogo}
            key={lastKey + 1}
            style={{width: imageSize, top: touchY, left: touchX, animation: animation}}
            className="bananaImageMoving"
            alt="banana-icon"
        />);

        this.movingBananaImages = newMovingBananaImages;
    };

    showMainScreen() {
        // The ref is null when the main screen gets hidden and
        // it then takes too long for it to be set up again, which
        // delays animation. Also, avoid using getBoundingClientRect
        // as it may be changing depending on the screen height (content)
        const setBananaAmountRef = (ref) => {
            this.bananaAmountOffset = ref ? {top: ref.offsetTop, left: ref.offsetLeft} : this.bananaAmountOffset;
        };

        return (
            <>
                <Stack style={{alignItems: 'center'}}>
                   {/*
                        Setting width & height is not only important for the UX but it also
                        affects values provided via ref in the amount which keep changing
                        depending on rendering (top 88 then top 300 when img appears)
                    */
                    }

                    { this.state.isCheating &&
                        <img src={cheatingMonkey} alt="cheating-monkey" width="212" height="212" style={{width: '80%'}}/>
                    }

                    { !this.state.isCheating &&
                        <img
                            src={bananaLogo}
                            width="212"
                            height="212"
                            onMouseDown={() => (!this.#isTouchDevice && this.onImagePressed(true))}
                            onMouseUp={(e) => (!this.#isTouchDevice && this.onClickLifted(e))}
                            onMouseLeave={() => (!this.#isTouchDevice && this.onImagePressed(false))}
                            onTouchStart={() => this.onImagePressed(true)}
                            onTouchEnd={(e) => this.onTouchLifted(e)}
                            style={{
                                animation: this.state.onPressDown ? undefined : 'press_up 130ms ease-out',
                                opacity: this.state.onPressDown ? 0.5 : 1
                            }}
                            className="bananaImage"
                            alt="banana-icon"
                        />
                    }

                    <Stack style={{height: '2rem', marginTop: '2rem', justifyContent: 'center', gap: '4px'}} direction="horizontal">
                        <img src={rankingIcon} className="rankingIcon" alt="ranking-icon" onClick={() => this.setState({isRankingViewShown: true})} width="32" height="32"/>
                        <EnergyProgressBar energyLevel={this.energyLevel} isBananaPressed={this.state.onPressDown} onClick={() => this.isGainEnergyOptionsShown(true)}/>
                        <div style={{width: '32px'}}/>
                    </Stack>
                    
                    <BananaAmount innerTextRef={setBananaAmountRef} amount={this.bananasAmount} style={{justifyContent: 'center', marginTop: '8px'}}/>
                    <button className="earnBananasButtonTitle buttonTextLink" onClick={() => this.setState({isEarnBananasViewShown: true})}>Going bananas for more bananas? 🍌</button>
                </Stack>

                {this.movingBananaImages}
                { this.state.animateBananasDeduction && this.showBananasDeducted() }
            </>
        );
    };

    showBananasDeducted() {
        if (!this.bananaAmountOffset) {
            return
        }

        const top = this.bananaAmountOffset.top;
        const left = this.bananaAmountOffset.left;
        const destTop = (top - 100);
        const animation = this.state.animateBananasDeduction && 'fade_out_amount_deducted 1200ms ease';

        return (
            <span
                className="deductedAmountLabel"
                onAnimationEnd={() => this.setState({animateBananasDeduction: false})}
                style={{top, left, animation, '--amountDeductedDestTop': `${destTop}px`}}
            >
                -{ENERGY_COST}
            </span>
        );
    };

    // Events Handling
    onImagePressed(isPressed) {
        this.setState({onPressDown: isPressed});
    };

    onClickLifted(event) {
        if (this.tapsRemaining > 0) {
            const {pageX, pageY} = event;
            this.increaseTaps(1);
            this.showMovingBananas(pageX, pageY);
        }

        this.onImagePressed(false);
    };

    onTouchLifted(event) {
        const {touches, changedTouches} = event;
        const tapsAllowed = Math.min(this.tapsRemaining, changedTouches.length);

        this.onImagePressed(!!touches.length);
        this.increaseTaps(tapsAllowed);

        for (let i = 0; i < tapsAllowed; i++) {
            this.showMovingBananas(
                changedTouches.item(i).clientX,
                changedTouches.item(i).clientY
            );

            this.#haptic?.impactOccurred('medium');
        }
    };

    // Energy Options Menu
    onEatBananaOptionSelected() {
        if (this.bananasAmount < 1) {
            return;
        }

        const maxTapsAvailable = this.state.maxTapsAvailable;
        const missingTaps = (this.#MAX_TAPS_STRIKE - (maxTapsAvailable - this.state.totalTaps));

        this.setState({maxTapsAvailable: (maxTapsAvailable + missingTaps)});
        this.deductBananas(ENERGY_COST);
    };

    onLoginOptionSelected() {
        this.login();
    };

    // Storage
    saveCurrentData() {
        const data = {
            user: this.state.user,
            isUserVerified: this.isUserVerified,
            isAddressSet: this.isAddressSet,
            totalTaps: this.state.totalTaps,
            totalBananasEarned: this.state.totalBananasEarned,
            maxTapsAvailable: this.state.maxTapsAvailable,
            lastTapTimestamp: this.lastTapTimestamp,
            lastTapEnergyLevel: this.lastTapEnergyLevel
        };

        // Just a primitive check in case someone modifies
        // the state property directly and the system tries
        // saving a fake (possibly huge) amounts
        // *Moreover, the user can use a clicking software
        // doing taps automatically
        const currentlySavedData = this.fetchSaveData();

        if (currentlySavedData) {
            // Take into account the user can use 5+ fingers to tap
            // and data are being saved every ~1+ second. 30+ taps
            // is relatively easily achievable
            if ((data.totalTaps - currentlySavedData.totalTaps) > 40) {
                return this.setState({isCheating: true});
            }
        }

        secureLocalStorage.setItem(STORAGE_DATA_KEY, data);
    };

    fetchSaveData() {
        return secureLocalStorage.getItem(STORAGE_DATA_KEY);
    };

    loadSavedData() {
        const data = this.fetchSaveData();

        if (data) {
            this.isUserVerified = data.isUserVerified;
            this.isAddressSet = data.isAddressSet;
            this.lastTapTimestamp = data.lastTapTimestamp;
            this.lastTapEnergyLevel = data.lastTapEnergyLevel;

            this.setState({
                user: data.user,
                totalTaps: data.totalTaps,
                totalBananasEarned: data.totalBananasEarned,
                maxTapsAvailable: data.maxTapsAvailable
            });
        }
    };

    saveDataPeriodically() {
        // Locally
        this.saveCurrentData();
        
        // There is no way for us to check closing of the web
        // app that would work across platforms, but  especially
        // on mobile. So push this periodically or when 1 sec
        // without the usr clicking and data has changed
        const timePassed = (Date.now() - this.lastTapTimestamp);

        if (timePassed > 400 && this.accountPushedTimestamp <= this.lastTapTimestamp) {
            this.pushAccountDetails();
        }
    };

    // Remote
    pushAccountDetails() {
        // Fetch from the store rather than using state for better security
        const {user, totalTaps, totalBananasEarned} = (this.fetchSaveData() || {});
        const signature = this.signData([user, totalTaps, totalBananasEarned]);

        fymAPI.updateEEGAccount(user, totalTaps, totalBananasEarned, signature)
            .then(() => (this.accountPushedTimestamp = Date.now()))
            .catch((error) => console.log(`Pushing Account Details Failed ${error.message}`)); // Silence the error
    };

    pushReferrerID(id) {
        // Passed by the user (link) so might be invalid
        if (!id || typeof id !== 'number') {
            return;
        }

        const signature = this.signData([this.userID, id]);

        fymAPI.setEEGReferrer(this.userID, id, signature)
            .catch((error) => console.log(`Setting EEG Referrer Failed ${error.message}`)); // Silence the error
    };

    async verifyUser(initDataRaw) {
        await fymAPI.verifyTelegramUser(initDataRaw, bs58.encode(this.#secretKey.publicKey));
        this.isUserVerified = true;
    };

    async pushAddress() {
        if (!this.isAddressSet && this.isUserVerified && this.isUserLoggedIn) {
            const address = this.session?.address;
            const signature = this.signData([this.userID, address]);

            fymAPI.setEEGAddress(this.userID, address, signature)
                .then(() => (this.isAddressSet = true))
                .catch((error) => console.log(`Setting EEG Address Failed ${error.message}`)); // Silence the error
        }
    };

    // Data Signing
    signData(data) {
        const message = Buffer.from(this.messageFrom(data));
        const signature = nacl.sign.detached(message, this.#secretKey.secretKey);
        return bs58.encode(signature);
    };

    messageFrom(value) {
        // Ordered JSON, pretty much 😅
        const toString = (value, contained = false) => {
            if (Array.isArray(value)) {
                return JSON.stringify(value.map(value => toString(value, true)));
            }

            if (typeof value === 'object') {
                const entries = Object.entries(value).sort().map(([key, value]) => [key, toString(value, true)]);
                const sortedObject = Object.fromEntries(entries);
                return contained ? sortedObject : JSON.stringify(sortedObject);
            }

            if (typeof value === 'string' || contained) {
                return value;
            }

            return JSON.stringify(value);
        };

        return toString(value);
    };

    // Misc
    deductBananas(amount) {
        this.setState({
            totalBananasEarned: (this.state.totalBananasEarned - amount),
            animateBananasDeduction: true
        });
    };

    increaseTaps(taps = 1) {
        const tapStrength = (this.isUserLoggedIn ? (this.#DEFAULT_STRENGTH * 2) : this.#DEFAULT_STRENGTH);
        const totalTaps = (this.state.totalTaps + taps);
        const totalBananasEarned = +(this.state.totalBananasEarned + (taps * tapStrength)).toFixed(4); // (0.05 + 0.01) produces 0.0600...0005
        
        this.lastTapTimestamp = Date.now();

        this.setState({totalTaps, totalBananasEarned}, () => {
            this.lastTapEnergyLevel = this.energyLevel;
        });
    };

    recuperateEnergyLevel() {
        const timePassed = (Date.now() - this.lastTapTimestamp)

        if (timePassed >= SECOND && this.tapsRemaining < this.#MAX_TAPS_STRIKE) {
            const energyLeftPerc = Math.cbrt(this.lastTapEnergyLevel); // Opposite of `easeInCubic`
            const timePassedPerc = (timePassed / this.#MAX_TIME_RECOVERING);
            const recuperatingProgress = Math.min(1, (energyLeftPerc + timePassedPerc));
            const tapsAvailableTotal = Math.ceil(this.easeInCubic(recuperatingProgress) * this.#MAX_TAPS_STRIKE);
            const tapsGained = (tapsAvailableTotal - (this.state.maxTapsAvailable - this.state.totalTaps));

            this.setState({maxTapsAvailable: (this.state.maxTapsAvailable + tapsGained)});
        }
    };

    easeInCubic(x) {
        return (x * x * x);
    };

    isGainEnergyOptionsShown(isShown) {
        this.setState({isGainEnergyOptionsShown: isShown});
    };

    modalTitle() {
        if (this.state.isGainEnergyOptionsShown) {
            return 'Energy';
        }

        if (this.state.isEarnBananasViewShown) {
            return 'Earn Bananas';
        }

        if (this.state.isRankingViewShown) {
            return 'Ranking';
        }

        return 'Baanaanaaa'
    };

    goBackToMainView() {
        this.setState({
            isGainEnergyOptionsShown: false,
            isEarnBananasViewShown: false,
            isRankingViewShown: false
        });
    };

    setIntervals() {
        this.checkEnergyInterval = setInterval(this.recuperateEnergyLevel, 600);
        this.periodicDataSavingInterval = setInterval(this.saveDataPeriodically, 1000);
    };

    stopIntervals() {
        clearInterval(this.checkEnergyInterval);
        clearInterval(this.periodicDataSavingInterval);
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'easterEgg-modal'});
                this.recuperateEnergyLevel(); // Run it for the current data and avoid any delay
                this.setIntervals();
            }
            else {
                this.saveCurrentData();
                this.pushAccountDetails();
                this.stopIntervals();

                // Reset the properties or the score will not be getting saved
                if (this.state.isCheating) {
                    const currentlySavedData = this.fetchSaveData();

                    this.setState({
                        totalTaps: currentlySavedData.totalTaps,
                        totalBananasEarned: currentlySavedData.totalBananasEarned,
                        isCheating: false
                    });
                }
            }
        }

        // Push the address as soon as the user logged in
        const loggedInChanged = (prevProps.sharedState[0].isUserLoggedIn !== this.isUserLoggedIn);

        if (loggedInChanged && this.isUserLoggedIn && !this.isAddressSet) {
            this.pushAddress();
        }

        // We must wait for `isTMA` to be set and then
        // run TG related actions in othercase calling
        // the relevant data may crash the app
        if (this.sharedState.isTMA && !this.#tgInited) {
            const {initData, initDataRaw} = retrieveLaunchParams();
            const currentUser = this.state.user;

            // The user can switch accounts, but the localStorage has
            // persisted and is offering previously saved data. Make
            // sure we stick to the original user and push their details
            // to the remote once available
            if (!currentUser || currentUser.id === initData.user.id) {
                this.setState({user: initData.user}, () => {
                    if (this.isUserVerified) {
                        this.pushAccountDetails();
                    }
                    else {
                        // Must be trigged ASAP since the initDataRaw has expiration
                        this.verifyUser(initDataRaw)
                            .then(() => {
                                this.pushAddress();

                                // Referrer's ID was passed into
                                initData.startParam && this.pushReferrerID(Number(initData.startParam));
                            })
                            .catch(error => console.log(error)); // Silence the error
                    }
                });
            }
            else {
                this.pushAccountDetails();
            }

            // Can only be inited once we are inside TMA
            // in othercase it will throw an error
            this.#haptic = initHapticFeedback();

            // Avoid re-running it
            this.#tgInited = true;
        }
    };

    componentDidMount() {
        this.loadSavedData(); // Run as soon as possible
    };

    componentWillUnmount() {
        this.stopIntervals();
    };

    render() {
        const isGainEnergyOptionsShown = this.state.isGainEnergyOptionsShown;
        const isEarnBananasViewShown = this.state.isEarnBananasViewShown;
        const isRankingViewShown = this.state.isRankingViewShown;
        const isMainViewShown = (!isGainEnergyOptionsShown && !isEarnBananasViewShown && !isRankingViewShown);

        if (!isMainViewShown) {
            // If not removed the animation might be repeated
            // especially if the view got closed while still
            // animating
            this.movingBananaImages = [];
        }

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{this.modalTitle()}</Modal.Title>
                </Modal.Header>

                <Modal.Body ref={this.modalBodyRef}>
                    { isGainEnergyOptionsShown &&
                        <GainEnergyOptions
                            bananasAmount={this.bananasAmount}
                            isUserLoggedIn={this.isUserLoggedIn}
                            onEatBananaOptionSelected={this.onEatBananaOptionSelected}
                            onLoginOptionSelected={this.onLoginOptionSelected}
                            onCloseClicked={() => this.isGainEnergyOptionsShown(false)}
                        />
                    }

                    { isEarnBananasViewShown &&
                        <EarnBananasView userID={this.userID}/>
                    }
                    
                    { isRankingViewShown &&
                        <RankingView userID={this.userID} onCloseClicked={this.goBackToMainView}/>
                    }

                    { isMainViewShown && this.showMainScreen() }
                </Modal.Body>

                <Modal.Footer>
                    { isMainViewShown ?
                        <Button variant="secondary" onClick={this.close}>{t('common.close')}</Button> :
                        <Button variant="secondary" onClick={this.goBackToMainView}>{t('common.back')}</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };
}

export default commonContextsWrapper(EasterEggModal);

// Components
function EnergyProgressBar(props) {
    const progress = (props.energyLevel * 100);
    const background = (!props.energyLevel && props.isBananaPressed) ? 'var(--red)' : `linear-gradient(to right, var(--yellow-primary) ${progress}%, white ${progress}%)`;
    const animation = (!props.energyLevel && !props.isBananaPressed) && `energy_bar_warning 1200ms ease`;

    // Without `stopPropagation`, onClick handlers in the
    // subsequently displayed view might not be fired instantly
    return <div className="energyProgressBar" onClick={(e) => { e.stopPropagation(); props.onClick() }} style={{background, animation}}>ENERGY &gt;</div>;
};

function GainEnergyOptions(props) {
    const Item = (props) => {
        const isEnabled = props.isEnabled;
        const className = `energyOptionItem ${!isEnabled && 'disabled'}`;

        return (
            <Stack directon="vertical" className={className} onClick={props.onClick}>
                <div className="title">{props.title}</div>
                <div className="description">{props.description}</div>
            </Stack>
        );
    };

    return (
        <Stack style={{gap: '0.5rem'}}>
            <Item
                title="Banana Feast Time"
                description={`Restore all your energy by eating ${ENERGY_COST} bananas`}
                onClick={() => { props.onCloseClicked(); props.onEatBananaOptionSelected(); }}
                isEnabled={props.bananasAmount >= ENERGY_COST}
            />
            
            <Item
                title="Log in"
                description="Let your monkey take over and achieve double the strength!"
                onClick={() => { props.onLoginOptionSelected(); }}
                isEnabled={!props.isUserLoggedIn}
            />
        </Stack>
    )
};

function EarnBananasView(props) {
    const link = `https://t.me/feedyourmonkey_bot/fym?startapp=${props.userID}`;
    const textAreaRef = React.createRef();

    const copyLink = async () => {
        textAreaRef.current.select();
        await navigator.clipboard.writeText(link);
    };

    const share = () => {
        const params = new URLSearchParams({
            text: 'Join this wild Telegram game on Solana and start earning $BANANA!\n\n',
            url: link
        });
        
        window.open(`https://twitter.com/intent/tweet?${params.toString()}`);
    };

    return (
        <Stack style={{gap: '1rem'}}>
            <span style={{textAlign: 'center', fontWeight: 300}}>
                Share the link and earn bananas for every new monkey you bring on board!
            </span>

            <textarea ref={textAreaRef} className="tgLinkTextArea" defaultValue={link} readOnly/>

            <Stack direction="horizontal" style={{alignSelf: 'end', gap: '4px'}}>
                <Button variant="secondary" onClick={share}>Share</Button>
                <Button variant="primary" onClick={copyLink}>Copy</Button>
            </Stack>
       </Stack>
    );
};

function RankingView(props) {
    const [ranking, setRanking] = React.useState(undefined);

    const Row = (user) => {
        return (
            <Stack direction="horizontal" className="rankingView tableRow" style={{marginTop: (user.rank === 4) ? '0.5rem' : ''}}>
                <Stack direction="horizontal" style={{gap: '4px', overflow: 'hidden'}}>
                    <span style={{fontWeight: (user.rank > 3) ? 400 : 600}}>#{user.rank}</span>
                    <span className="rankingView name">{user.firstName}</span>
                </Stack>

                <BananaAmount amount={user.totalBananasEarned}/>
            </Stack>
        );
    };

    React.useEffect(() => {
        fymAPI.retrieveEEGRanking()
            .then(ranking => setRanking(ranking.sort()))
            .catch(() => props.onCloseClicked());
    }, []);

    return (
        <>
            { !ranking &&
                <div style={{textAlign: 'center'}}>
                    <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                </div>
            }
            
            { ranking &&
                <Stack>{ranking.map(user => Row(user))}</Stack>
            }
        </>
    )
};