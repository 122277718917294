import FYMSideChainSession from './FYMSidechainSession';
import { SOLANA_TOKEN_CONTRACT_ADDRESS } from '../config';

class FYMSolanaSession extends FYMSideChainSession {
    #walletAPI;

    static get isUserLoggedIn() {
        return (FYMSideChainSession.isUserLoggedIn && (this.chainType === FYMSideChainSession.CHAIN_TYPE.SOLANA));
    }

    get isUserLoggedIn() {
        return FYMSolanaSession.isUserLoggedIn;
    }

    get isFullyInitialized() {
        return (this.isAccountKeySet && this.isUserLoggedIn && this.isAccountNameSet);
    }

    constructor(walletAPI, address) {
        super(FYMSideChainSession.CHAIN_TYPE.SOLANA);

        this.#walletAPI = walletAPI;
        this.address = address;
        this.chainID = 1;
    };

    async signIn(siwe) {
        return this.#walletAPI.signIn(siwe);
    };

    async signData(data) {
       return this.#walletAPI.signData(data);
    };

    async sendTransaction(transaction) {
        return this.#walletAPI.sendTransaction(transaction);
    };

    async fetchBananasTokenAccount() {
        return this.#walletAPI.fetchTokenAccount(SOLANA_TOKEN_CONTRACT_ADDRESS, this.address);
    };

    async fetchBananasBalance() {
        return this.#walletAPI.fetchBalanceOf(SOLANA_TOKEN_CONTRACT_ADDRESS, this.address);
    };
    
    async burnBananas(amount) {
        const memo = `deposit:${this.accountName}`;
        return this.#walletAPI.burnTokens(SOLANA_TOKEN_CONTRACT_ADDRESS, this.address, amount, memo);
    };

    async confirmTransaction(trxID) {
        return this.#walletAPI.confirmTransaction(trxID);
    };
};

export default FYMSolanaSession;