import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import BananaAmount from '../Widgets/BananaAmount';
import XPAmount from '../Widgets/XPAmount';
import InventoryAsset from '../../Models/InventoryAsset';
import EquipmentAsset from '../../Models/EquipmentAsset';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import inventoryAssetTypes from '../../Helpers/InventoryAssetTypes';
import equipmentAssetTypes from '../../Helpers/EquipmentAssetTypes';
import { t } from '../../i18n';
import { formatTokenAmount, calcTimeRemaining } from '../../Helpers/Utils';
import { GAME_CONSTANTS } from '../../config';

import './InventoryModal.css';

class InventoryModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get setSharedState() {
        const [, {updateState}] = this.props.sharedState;
        return updateState;
    }

    get session() {
        return this.sharedState?.session;
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    get isTransacting() {
        return (
            this.state.isTransactingCraftAsset ||
            this.state.isTransactingDeleteAsset ||
            this.state.isTransactingTakeStarvePill ||
            this.state.isTransactingPurchase
        );
    };

    get allAssetsCombined() {
        const inventoryAssets = (this.sharedState?.inventoryAssets || []);
        const equipmentAssets = (this.state.equipment || []);
        return [...inventoryAssets, ...equipmentAssets];
    };

    constructor(props) {
        super(props);

        this.state = {
            equipment: undefined,
            assetOptions: undefined,
            craftableAssetOptions: undefined,
            isFetchingData: false,
            isTransactingPurchase: false,
            isTransactingCraftAsset: false,
            isTransactingDeleteAsset: false,
            isTransactingTakeStarvePill: false
        };

        this.showAssetOptions = this.showAssetOptions.bind(this);
        this.hideAssetOptions = this.hideAssetOptions.bind(this);
        this.showCraftableAssetOptions = this.showCraftableAssetOptions.bind(this);
        this.hideCraftableAssetOptions = this.hideCraftableAssetOptions.bind(this);
        this.close = this.close.bind(this);
    }

    // Transactions
    craftAsset(asset) {
        this.setState({isTransactingCraftAsset: true});

        const action = asset.isWeapon ? this.trxAPI.craftEquipment : this.trxAPI.craftInvAsset;

        action(asset, {responseCallback: true})
            .then(([,responseCallback]) => {
                this.deductBananas(asset.recipe?.energyRequired, true);

                this.fetchUntilCraftingStarted()
                    .catch(() => this.close())
                    .finally(() => {
                        this.setState({isTransactingCraftAsset: false});
                        this.hideCraftableAssetOptions();
                        responseCallback();
                    });
            })
            .catch(() => this.setState({isTransactingCraftAsset: false}));
    }

    purchaseAsset(asset) {
        this.setState({isTransactingPurchase: true});

        this.trxAPI.purchaseInvAsset(asset)
            .then(() => {
                this.deductBananas(asset.price);
                this.addAssetLocally(asset, 1); // Refresh the UI without re-fetching
                this.hideCraftableAssetOptions();
            })
            .finally(() => this.setState({isTransactingPurchase: false}));
    };

    deleteAsset(asset) {
        this.setState({isTransactingDeleteAsset: true});

        const isEquipment = (asset instanceof EquipmentAsset);
        const amount = isEquipment ? (asset.quantity % 1) : 1; // Removes the leftover rather than entire EQP
        const symbol = isEquipment ? 'EQP' : 'ASSET';
        const precision = isEquipment ? 2 : 0;
        const action = isEquipment ? this.trxAPI.deleteEquipment : this.trxAPI.deleteInvAsset;
        const quantity = formatTokenAmount(amount, symbol, precision);

        action(asset, quantity)
            .then(() => {
                this.removeAssetLocally(asset, amount); // Refresh the UI without re-fetching
                this.hideAssetOptions();
            })
            .finally(() => this.setState({isTransactingDeleteAsset: false}));
    };

    takeStarvePill(asset) {
        this.setState({isTransactingTakeStarvePill: true});

        this.trxAPI.takeStarvePill(asset)
            .then(() => {
                this.useAsset(asset);
                this.removeAssetLocally(asset, 1); // Refresh the UI without re-fetching
                this.hideAssetOptions();
            })
            .finally(() => this.setState({isTransactingTakeStarvePill: false}));
    };

    // Data Fetching
    fetchAssets() {
        const accountName = this.session.accountName;
        const fetchRequests = [
            this.rpc.fetchInventoryAssets(accountName),
            this.rpc.fetchEquipmentAssets(accountName)
        ];

        return Promise.all(fetchRequests)
            .then(([invAssetRaw, equipmentRaw]) => {
                // RPC also returns 0 quantity. Make sure to NOT
                // check `availableQuantity` as it might be zero
                // when crafting one item, but we need the asset
                const inventoryAssets = invAssetRaw
                    .map(obj => new InventoryAsset(obj))
                    .filter(asset => asset.quantity > 0);

                const equipment = equipmentRaw
                    .map(obj => new EquipmentAsset(obj))
                    .filter(asset => asset.quantity > 0);

                this.setSharedState({inventoryAssets}); // Make sure they're up to date
                this.setState({equipment});
            });
    };

    fetchAssetsWithLoading() {
        this.setState({isFetchingData: true});

        this.fetchAssets()
            .then(() => this.setState({isFetchingData: false}))
            .catch(() => this.close())
    };

    fetchUntilCraftingStarted() {
        const that = this;

        return new Promise((resolve, reject) => {
            function fetch() {
                that.fetchAssets()
                    .then(() => {
                        that.isCrafting() ? resolve() : setTimeout(fetch, 600);
                    })
                    .catch(reject);
            }

            setTimeout(fetch, 800);
        });
    };

    // Asset Options
    showAssetOptions(asset) {
        this.setState({assetOptions: asset});
    };

    hideAssetOptions() {
        this.setState({assetOptions: undefined});
    };

    // Craftable Asset Options
    showCraftableAssetOptions(asset) {
        this.setState({craftableAssetOptions: asset});
    };

    hideCraftableAssetOptions() {
        this.setState({craftableAssetOptions: undefined});
    };

    // Components
    assetsList() {
        const allAssets = this.allAssetsCombined;
        const hasAssets = (allAssets.length > 0);

        const GenericAssetItem = (asset, index) => {
            let quantity = asset.availableQuantity;

            // Might just be crafting, so hide it for now
            if (!quantity) {
                return null;
            }

            // Equipment might have quantities of less than 1 depending
            // on their lifespan. Round it up to whole number
            if (asset instanceof EquipmentAsset) {
                quantity = Math.ceil(quantity);
            }

            return (
                <Col xs={3} key={asset.type} className={index > 3 ? 'pt-2' : ''}>
                    <Stack className="InventoryAssetGridItem" onClick={() => {this.showAssetOptions(asset)}}>
                        <img src={asset.icon} className="InventoryAssetGridItem-icon" alt={asset.name + '-icon'} draggable="false"/>
                        <div className="InventoryAssetGridItem-quantity">{quantity}</div>
                    </Stack>
                </Col>
            );
        };

        const CraftableAssetItem = (assetDetails, index) => {
            const usersAsset = this.getUsersAsset(assetDetails);
            const isBeingCrafted = (usersAsset?.isBeingCrafted || false);

            let backgroundStyle = 'none';
            let iconClassName = 'InventoryAssetGridItem-icon';
            let craftingUntil = usersAsset?.craftingUntil;

            if (isBeingCrafted) {
                const timeRemaining = (craftingUntil.getTime() - Date.now());
                const craftingTimeTotal = (assetDetails.recipe.energyRequired / 10) * GAME_CONSTANTS.CRAFTING_TIME_10;
                const craftingProgress = ((craftingTimeTotal - timeRemaining) / craftingTimeTotal) * 100;
                backgroundStyle = `linear-gradient(to right, rgb(216, 212, 118) ${craftingProgress}%, white ${craftingProgress}%)`;
            }

            if (!this.canCraftAsset(assetDetails) && !isBeingCrafted) {
                iconClassName += ' disabled';
            }

            return (
                <Col xs={3} key={assetDetails.name} className={index > 3 ? 'pt-2' : ''}>
                    <Stack className="InventoryAssetGridItem" style={{background: backgroundStyle}} onClick={() => {this.showCraftableAssetOptions(assetDetails)}}>
                        <img src={assetDetails.icon} className={iconClassName} alt={assetDetails.name + '-icon'} draggable="false"/>

                        { isBeingCrafted &&
                            <div className="CraftableInventoryAssetGridItem-timeRemaining">{calcTimeRemaining(craftingUntil)}</div>
                        }
                    </Stack>
                </Col>
            );
        };

        const CraftableInvAssetItem = (assetType, index) => {
            // Merge details with additional parameters we need
            const asset = {
                id: inventoryAssetTypes.assetIDFromType(assetType),
                ...inventoryAssetTypes.assetDetails(assetType)
            };

            return CraftableAssetItem(asset, index);
        };

        const CraftableEquipAssetItem = (assetType, index) => {
            // Merge details with additional parameters we need
            const assetDetails = {
                id: equipmentAssetTypes.equipmentIDFromType(assetType),
                isWeapon: true,
                ...equipmentAssetTypes.equipmentDetails(assetType)
            };

            return CraftableAssetItem(assetDetails, index);
        };

        return (
            <Stack>
                <div className="InventoryAssetsList-sectionHeader">{t('modals.inventory.assets')}</div>
                 { hasAssets &&
                    <>
                        <Container className="InventoryAssetsGrid" fluid>
                            { hasAssets && <Row>{allAssets.map(GenericAssetItem)}</Row> }
                        </Container>

                        <span className="InventoryAssetsList-assetsCount">({this.allAssetsCount()}/{GAME_CONSTANTS.MAX_INVENTORY_ASSETS})</span>
                    </>
                }

                { !hasAssets &&
                    <div className="InventoryAssetsList-noAssetsMessage">{t('modals.inventory.noAssets')}</div>
                }

                <div className="InventoryAssetsList-sectionHeader" style={{marginTop: '1.7rem'}}>{t('modals.inventory.crafting')}</div>
                <div className="InventoryAssetsList-sectionSubtitle">{t('modals.inventory.consumables')}</div>

                <Container className="InventoryAssetsGrid" fluid>
                    <Row>{inventoryAssetTypes.craftableAssets.map(CraftableInvAssetItem)}</Row>
                </Container>

                <div className="InventoryAssetsList-sectionSubtitle" style={{marginTop: '1rem'}}>{t('modals.inventory.weapons')}</div>

                <Container className="InventoryAssetsGrid" fluid>
                    <Row>{equipmentAssetTypes.allEquipment.map(CraftableEquipAssetItem)}</Row>
                </Container>

                <div className="InventoryAssetsList-footerMessage">{t('modals.inventory.clickToSeeOptions')}</div>
            </Stack>
        );
    };

    genericAssetOptionsModal() {
        const assetOptions = this.state.assetOptions;
        const monkey = this.props.monkey;
        const isTransacting = this.isTransacting;
        const isModalShown = (!!assetOptions);

        return (
            <Modal show={isModalShown} onHide={this.hideAssetOptions} dialogClassName="OptionsPopupModal" backdropClassName="ModalWithTintedBackdrop" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{assetOptions?.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Stack style={{gap: '6px'}}>
                        <div style={{textAlign: 'center', marginBottom: '8px', fontWeight: 300}}>{t('common.options')}:</div>

                        { (assetOptions?.id === inventoryAssetTypes.Types.STARVE_PILL) &&
                            <Button variant="primary" onClick={() => this.takeStarvePill(assetOptions)} disabled={isTransacting || monkey?.isOnStarvePill}>
                                {this.state.isTransactingTakeStarvePill ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                        <span className="visually-hidden">{t('modals.inventory.takeStarvePill')}</span>
                                    </>
                                    :
                                    t('modals.inventory.takeStarvePill')
                                }
                            </Button>
                        }

                        <Button variant="danger" onClick={() => this.deleteAsset(assetOptions)} disabled={isTransacting}>
                            {this.state.isTransactingDeleteAsset ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.inventory.throwAway1')}</span>
                                </>
                                :
                                t('modals.inventory.throwAway1')
                            }
                        </Button>

                        <Button variant="secondary" onClick={this.hideAssetOptions} disabled={isTransacting}>{t('common.cancel')}</Button>
                    </Stack>
                </Modal.Body>
            </Modal>
        );
    };

    craftableAssetOptionsModal() {
        const monkey = this.props.monkey;
        const assetOptions = this.state.craftableAssetOptions;
        const isModalShown = (!!assetOptions);
        const canBeCrafted = this.canCraftAsset(assetOptions);
        const isTransacting = this.isTransacting;
        const isWeapon = (!!assetOptions?.isWeapon);
        const isCraftingDisabled = (isTransacting || !canBeCrafted || this.isCrafting());
        const isPurchasingDisabled = (isTransacting || monkey?.bananasAmount < assetOptions?.price);

        return (
            <Modal show={isModalShown} onHide={this.hideCraftableAssetOptions} size="sm" backdropClassName="ModalWithTintedBackdrop" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{assetOptions?.name}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Stack style={{gap: '1rem'}}>
                        <div style={{fontWeight: 300}}>{assetOptions?.description}</div>

                        { isWeapon && this.getWeaponStatsInfo(assetOptions) }

                        <div>
                            <div>{t('modals.inventory.requiredIngredients')}:</div>

                            <div style={{whiteSpace: 'pre-wrap', fontWeight: 300}}>
                                {
                                    assetOptions?.recipe.assetsRequired.map(item => {
                                        const [assetID, quantity] = item;
                                        const assetType = inventoryAssetTypes.typeFromAssetID(assetID);
                                        const name = inventoryAssetTypes.assetDetails(assetType).name;
                                        return `${name} x ${quantity}\n`
                                    })
                                }
                            </div>
                        </div>
                            
                        <div>
                            <div>{t('modals.inventory.energyCost')}:</div>
                            <BananaAmount amount={assetOptions?.recipe.energyRequired}/>
                        </div>

                        { assetOptions?.recipe.xpRequired &&
                            <div>
                                <div>{t('modals.inventory.xpNeeded')}:</div>
                                <XPAmount amount={assetOptions?.recipe.xpRequired}/>
                            </div>
                        }
                    </Stack>
                </Modal.Body>

                <Modal.Footer>
                    <Stack direction="vertical" style={{gap: '6px'}}>
                        <Button variant="primary" onClick={() => this.craftAsset(assetOptions)} disabled={isCraftingDisabled}>
                            {this.state.isTransactingCraftAsset ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">Craft</span>
                                </>
                                :
                                'Craft'
                            }
                        </Button>

                        { assetOptions?.price && // Might not be for sale
                            <Button variant="primary" onClick={() => this.purchaseAsset(assetOptions)} disabled={isPurchasingDisabled}>
                                {this.state.isTransactingPurchase ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                        <span className="visually-hidden">{t('common.buy')}</span>
                                    </>
                                    :
                                    <BananaAmount amount={assetOptions?.price} style={{display: 'inline-flex', verticalAlign: 'top'}}/>
                                }
                            </Button>
                        }

                        <Button variant="secondary" onClick={this.hideCraftableAssetOptions} disabled={isTransacting}>{t('common.cancel')}</Button>
                    </Stack>
                </Modal.Footer>
            </Modal>
        );
    };

    getWeaponStatsInfo(assetOptions) {
        // Might be called without a value when the Asset
        // Options Modal gets inserted into the UI
        if (!assetOptions) {
            return;
        }

        const allWeapons = equipmentAssetTypes.allEquipment.map(e => equipmentAssetTypes.equipmentDetails(e));
        const maxDamagePossible = Math.max.apply(undefined, allWeapons.map(e => e.maxDamagePoints));
        const maxFightsPossible = Math.max.apply(undefined, allWeapons.map(e => e.maxFights));

        const weaponMaxDamagePerc = (assetOptions.maxDamagePoints / maxDamagePossible) * 100
        const weaponMaxFightsPerc = (assetOptions.maxFights / maxFightsPossible) * 100;

        const damageBg = `linear-gradient(to right, var(--yellow-primary) ${weaponMaxDamagePerc}%, white ${weaponMaxDamagePerc}%)`;
        const lifespanBg = `linear-gradient(to right, var(--yellow-primary) ${weaponMaxFightsPerc}%, white ${weaponMaxFightsPerc}%)`;

        return (
            <Stack direction="horizontal" style={{gap: '10px'}}>
                <div className="AssetOptionsModal-weaponStatsBar" style={{background: damageBg}}>{t('modals.inventory.maxDamage')}</div>
                <div className="AssetOptionsModal-weaponStatsBar" style={{background: lifespanBg}}>{t('modals.inventory.lifespan')}</div>
            </Stack>
        );
    };

    // Asset Operations
    getUsersAsset(assetDetails) {
        // Might be called without a value when the Asset
        // Options Modal gets inserted into the UI
        if (!assetDetails) {
            return;
        }

        const assets = assetDetails.isWeapon ? this.state.equipment : this.sharedState.inventoryAssets;
        return assets?.find(a => a.id === assetDetails.id);
    };

    isCrafting() {
        const craftedAsset = this.allAssetsCombined.find(asset => asset.isBeingCrafted);
        return (craftedAsset !== undefined);
    };

    canCraftAsset(asset) {
        // Might be called without a value when the Asset
        // Options Modal gets inserted into the UI
        if (!asset) {
            return;
        }

        const recipe = asset.recipe;
        const inventoryAssets = this.sharedState.inventoryAssets;
        const hasRequiredEnergy = (recipe.energyRequired <= this.props.monkey?.bananasAmount);
        const hasRequiredXP = (!recipe.xpRequired || (recipe.xpRequired <= this.props.monkey?.xp));

        if (!hasRequiredEnergy || !hasRequiredXP) {
            return false;
        }

        for (const [assetID, requiredQuantity] of recipe.assetsRequired) {
            const assetFound = inventoryAssets?.find(asset => {
                return (asset.id === assetID && asset.availableQuantity >= requiredQuantity);
            });

            if (!assetFound) {
                return false;
            }
        }

        return true;
    };

    removeAssetLocally(asset, quantity) {
        const isEquipment = (asset instanceof EquipmentAsset);
        const allAssets = isEquipment ? this.state.equipment : this.sharedState.inventoryAssets;
        const index = allAssets?.findIndex(el => el.id === asset.id);
        
        if (index > -1) {
            allAssets[index].quantity -= quantity;

            if (allAssets[index].quantity <= 0) {
                allAssets.splice(index, 1);
            }

            if (isEquipment) {
                this.setState({equipment: allAssets});
            }
            else {
                this.setSharedState({inventoryAssets: allAssets});
            }
        }
    };

    addAssetLocally(asset, quantity) {
        const inventoryAssets = (this.sharedState.inventoryAssets || []);
        const index = inventoryAssets.findIndex(el => el.id === asset.id);

        if (index > -1) {
            inventoryAssets[index].quantity += quantity;
            this.setSharedState({inventoryAssets});
        }
        else {
            const newAsset = InventoryAsset.fromID(asset.id);
            inventoryAssets.push(newAsset);
            this.setSharedState({inventoryAssets});
        }
    };

    allAssetsCount() {
        // Use `ceil` to round up values like `0.33 EQP` which
        // is actually considered being 1 asset (equipment)
        return this.allAssetsCombined.reduce((count, asset) => (Math.ceil(asset.quantity) + count), 0);
    };

    // Monkey Account
    deductBananas(amount, usedAsEnergy = false) {
        this.props.monkey.bananasAmount -= amount;

        if (usedAsEnergy) {
            this.props.monkey.bananaPeels += amount;
        }

        this.props.onMonkeyDetailsChanged(this.props.monkey);
    };

    useAsset(asset) {
        this.props.monkey.useAsset(asset);
        this.props.onMonkeyDetailsChanged(this.props.monkey);
    };

    // Misc
    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                this.fetchAssetsWithLoading();
                ReactGA.send({hitType: 'pageview', page: 'inventory-modal'});
            }
            else {
                this.setState({equipment: undefined});
            }
        }
    };

    render() {
        return (
            <>
                {/* Asset Options Modals */}
                {this.genericAssetOptionsModal()}
                {this.craftableAssetOptionsModal()}

                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} dialogClassName="InventoryModal" backdrop="static" centered>
                    <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Modal.Title>{t('modals.inventory.title')}</Modal.Title>
                        <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.inventory.subtitle')}</div>
                    </Modal.Header>

                    <Modal.Body>
                        { this.state.isFetchingData &&
                            <div style={{textAlign: 'center'}}>
                                <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                            </div>
                        }

                        { !this.state.isFetchingData &&
                            this.assetsList()
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.close} disabled={(this.state.isFetchingData || this.isTransacting)}>{t('common.close')}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default commonContextsWrapper(InventoryModal);
