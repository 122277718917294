import React from 'react';
import Stack from 'react-bootstrap/Stack';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';

import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Components/Home/Home';
import PageNotFound from './Components/PageNotFound';
import TMALockScreen from './Components/TMALockScreen';

import { SharedStateProvider } from './Helpers/SharedState';
import { TransactionsAPIContextProvider } from './Helpers/TransactionsAPIContext';

import './App.css';

function App() {
    const location = useLocation();

    React.useEffect(() => {
        ReactGA.send({hitType: 'pageview', page: (location.pathname + location.search)});
    }, [location]);

    return (
        <SharedStateProvider>
            <TransactionsAPIContextProvider>
                <TMALockScreen/>
                
                <Stack className="App">
                    <Header/>
                        <div className="App-content">
                            <Routes>
                                <Route exact path="/" element={<Home/>}/>
                                <Route path="*" element={<PageNotFound/>}/>
                            </Routes>
                        </div>
                    <Footer/>
                </Stack>
            </TransactionsAPIContextProvider>
        </SharedStateProvider>
    );
}

export default App;
