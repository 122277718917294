import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import CryptoJS from 'crypto-js';
import { PrivateKey, KeyType } from '@wharfkit/antelope';
import { Session } from '@wharfkit/session';

import fymAPI from '../../Helpers/FYMAPI';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import ToastMessage from '../../Components/ToastMessage';
import CreateFYMAccountModal from './CreateFYMAccountModal';
import FYMSolanaSession from '../../Helpers/FYMSolanaSession';
import { t } from '../../i18n';
import { EOSIO_BLOCKCHAINS } from '../../config';

class SolanaLoginModal extends React.Component {
    #encryptedKey;
    
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get logout() {
        return this.props.sharedState?.[1]?.logout;
    }

    get setAntelopeSession() {
        return this.props.sharedState?.[1]?.setAntelopeSession;
    }

    get session() {
        return this.sharedState?.solanaSession;
    }

    get isTMA() {
        return this.sharedState.isTMA;
    }

    get antelopeSession() {
        return this.sharedState?.session;
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoggingIn: false,
            isRequestingSignature: false,
            wasAccountNameSet: false,
            errorMessage: undefined
        };

        this.onConnected = this.onConnected.bind(this);
        this.onDisconnected = this.onDisconnected.bind(this);
        this.requestWalletSignature = this.requestWalletSignature.bind(this);
        this.loginBackend = this.loginBackend.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
    };

    // Actions
    loginBackend() {
        if (this.state.isLoggingIn) {
            return;
        }

        this.setState({isLoggingIn: true});

        fymAPI.getSiwsMessage(this.session.address)
            .then(siws => this.session.signIn(siws))
            .then(signature => fymAPI.authenticateSolana(signature))
            .then(response => {
                // accountName & encryptedKey might not be
                // available for a brand new user
                this.session.jwtToken = response.token;
                this.session.accountName = response.accountName;
                this.session.accountCreated = response.accountCreated;
                this.#encryptedKey = response.encryptedKey;
            })
            .catch(error => {
                this.showErrorMessage(error);
                this.logout();
            })
            .finally(() => this.setState({isLoggingIn: false}));
    };

    requestWalletSignature() {
        if (this.state.isRequestingSignature) {
            return;
        }

        this.setState({isRequestingSignature: true});
        const message = 'Your wallet signature is used to encrypt your game related data. Signing is safe, gas-less and does not give the game permission to perform any transactions with your wallet.\n\nSIGN THIS MESSAGE INSIDE FEED-YOUR-MONKEY GAME ONLY!';
        
        this.session.signData(message)
            .then(signature => {
                if (this.#encryptedKey) {
                    this.session.privateKey = this.decryptKey(this.#encryptedKey, signature);
                }
                else {
                    const {
                        privateKey,
                        publicKey,
                        encryptedKey
                    } = this.generateAccountKey(signature);

                    return fymAPI.setKey(encryptedKey, publicKey)
                        .then(() => { this.session.privateKey = privateKey; });
                }
            })
            .catch(error => {
                if (error.code !== 4001) { // CANCELLED
                    this.showErrorMessage(error);
                    this.logout();
                }
            })
            .finally(() => this.setState({isRequestingSignature: false}));
    };

    initAntelopeSession() {
        // A dummy session. We use the SessionKey
        // to actually sign any transactions
        const session = new Session({
            actor: this.session.accountName,
            permission: 'efym',
            chain: EOSIO_BLOCKCHAINS['telos']
        });

        this.setAntelopeSession(session);
    };

    // Components
    modalBodyContent() {
        if (!this.session) {
            return null;
        }

        if (!this.session.isUserLoggedIn) {
            // TMA requires confirmation by the user
            if (this.isTMA && !this.state.isLoggingIn) {
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>{t('modals.solanaLogin.proceedByLoggingIn')}</p>
                );
            }

            return (
                <Stack style={{alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem'}}>
                    <Spinner animation="border" role="status" aria-hidden="true" style={{width: '2.75rem', height: '2.75rem'}}/>
                    <div style={{fontWeight: 300}}>{t('modals.solanaLogin.loggingIn')}</div>
                </Stack>
            );
        }

        if (!this.session.isAccountKeySet) {
            return (
                <p style={{textAlign: 'center', marginBottom: 0}}>{t('modals.solanaLogin.walletSignatureRequiredForEncryption')}</p>
            );
        }
    };

    modalTitle() {
        if (!this.session) {
            return null;
        }

        if (!this.session.isUserLoggedIn) {
            return t('modals.solanaLogin.loginTitle');
        }

        if (!this.session.isAccountKeySet) {
            return t('modals.solanaLogin.signatureRequestTitle');
        }
    };

    // Error Message Toast
    showErrorMessage(error) {
        if (error.code === 4001) { // CANCELLED
            return;
        }
        
        const errorMessage = (error.info?.error?.message || error.message)
        this.setState({errorMessage: errorMessage});
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    };

    // Account Private Key
    decryptKey(encryptedKey, password) {
        const decrypted = CryptoJS.AES.decrypt(encryptedKey, password);
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    generateAccountKey(password) {
        const privateKey = PrivateKey.generate(KeyType.K1);
        const encryptedKey = CryptoJS.AES.encrypt(privateKey.toString(), password);
        
        return {
            privateKey: privateKey.toString(),
            publicKey: privateKey.toPublic().toString(),
            encryptedKey: encryptedKey.toString()
        };
    };

    // Misc
    onConnected() {
        const recentlyUsedAddress = (this.session?.address || FYMSolanaSession.recentlyUsedAddress);
        
        // Make sure the user is logged in otherwise we'll be logging
        // out before the user is fully signed in
        if (recentlyUsedAddress && FYMSolanaSession.isUserLoggedIn) {
            const selectedAddress = window.solana?.publicKey?.toString();

            if (selectedAddress && (selectedAddress !== recentlyUsedAddress)) {
                this.logout();
            }
        }
    };

    onDisconnected() {
        if (this.session) {
            this.logout();
        }
    };

    // React
    componentDidMount() {
        window.solana?.on('connect', this.onConnected);
        window.solana?.on('accountChanged', this.onConnected);
        window.solana?.on('disconnect', this.onDisconnected);
    };

    componentWillUnmount() {
        window.solana?.removeListener('connect', this.onConnected);
        window.solana?.on('accountChanged', this.onConnected);
        window.solana?.removeListener('disconnect', this.onDisconnected);
    };

    componentDidUpdate() {
        if (this.session) {
            // TMA (Telegram Mini App) backend request must be triggered
            // manually by the user. The issue is that TG may not automatically
            // open the URL (wallet) and might be actually running in the
            // background in case of mobile app when this is requested
            if (!this.session.isUserLoggedIn && !this.isTMA) {
                this.loginBackend();
            }
            else if (!this.antelopeSession && this.session.isFullyInitialized) {
                this.initAntelopeSession();
            }
        }
    };

    render() {
        const session = this.session;
        const isMainModalShown = (session && !session.isAccountKeySet);
        const isCreateAccountModalShown = (session && (!isMainModalShown && !session.isAccountNameSet && !this.state.wasAccountNameSet));

        return (
            <>
                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />

                <CreateFYMAccountModal
                    isShown={isCreateAccountModalShown}
                    onAccountNameSet={accountName => {
                        this.session.accountName = accountName;
                        this.setState({wasAccountNameSet: true});
                    }}
                    onCancel={() => {
                        this.logout();
                    }}
                    onError={error => {
                        // Could be a name taken error too
                        this.showErrorMessage(error);
                    }}
                />

                <Modal show={isMainModalShown} size="sm" backdrop="static" backdropClassName="modal-backdrop-blurred" centered>
                    <Modal.Header>
                        <Modal.Title>{this.modalTitle()}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.modalBodyContent()}
                    </Modal.Body>

                    { (session?.isUserLoggedIn && !session?.isAccountKeySet) &&
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.requestWalletSignature} disabled={this.state.isRequestingSignature}>
                                { this.state.isRequestingSignature ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                        <span className="visually-hidden">{t('modals.solanaLogin.sign')}</span>
                                    </>
                                    :
                                    t('modals.solanaLogin.sign')
                                }
                            </Button>
                        </Modal.Footer>
                    }

                    { (!session?.isUserLoggedIn && this.isTMA && !this.state.isLoggingIn) &&
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.loginBackend} disabled={this.state.isLoggingIn}>
                                {t('modals.solanaLogin.logIn')}
                            </Button>
                        </Modal.Footer>
                    }
                </Modal>
            </>
        );
    };
}

export default commonContextsWrapper(SolanaLoginModal);
