import Asset from './Asset';
import inventoryAssetTypes from '../Helpers/InventoryAssetTypes';

class InventoryAsset extends Asset {
    // Static
    static fromID(assetID) {
        return new InventoryAsset({
            'asset_id': assetID,
            'quantity': '1 ASSET',
            'crafting_details': undefined
        });
    }

    constructor(obj) {
        const assetID = obj['asset_id'];
        const assetType = inventoryAssetTypes.typeFromAssetID(assetID);
        const assetDetails = inventoryAssetTypes.assetDetails(assetType);
        
        super({
            id: assetID,
            type: assetType,
            name: assetDetails.name,
            icon: assetDetails.icon,
            quantity: obj['quantity'],
            craftingDetails: obj['crafting_details']
        });
    }
}

export default InventoryAsset;
