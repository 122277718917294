import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';
import { formatUnits } from 'ethers';

import { t, Trans } from '../../i18n';
import OFTContract from '../../Helpers/OFTContract';
import fymAPI from '../../Helpers/FYMAPI';
import { EVM_BLOCKCHAINS } from '../../config';
import { BananaPricing, ExchangeType } from '../../Helpers/BananaPricing';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';

import {
    formatAmount,
    formatPeelsTokenAmount,
    formatBananasTokenAmount,
    formatTLOSTokenAmount,
    transactionDetailsURL
} from '../../Helpers/Utils';

class WithdrawTokensModal extends React.Component {
    #SUPPORTED_TOKENS = {BANANA: 0, PEEL: 1};
    
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    get session() {
        return this.sharedState?.session;
    }

    get evmSession() {
        return this.sharedState?.evmSession;
    }

    get solanaSession() {
        return this.sharedState?.solanaSession;
    }

    get oftContract() {
        return new OFTContract(this.evmSession);
    }

    get maxWithdrawAmount() {
        if (this.isPeelToken) {
            return this.state.totalBalanceAmount;
        }

        return Math.max((this.state.totalBalanceAmount - this.state.bananaWithdrawalFee), 0);
    }

    get token() {
        return this.#SUPPORTED_TOKENS[this.props.token];
    };

    get isPeelToken() {
        return (this.token === this.#SUPPORTED_TOKENS.PEEL);
    }

    get isBananaToken() {
        return (this.token === this.#SUPPORTED_TOKENS.BANANA);
    }

    constructor(props) {
        super(props);

        this.bananaPricing = undefined; // Must be set in the `componentDidUpdate`

        this.state = {
            tokenAmount: '', // Amount to withdraw
            totalBalanceAmount: undefined, // User's account balance
            bananaWithdrawalFee: 0,
            withdrawalTrxID: undefined,
            eosioTotalFeeRequired: undefined,
            eosioAmountMissing: undefined,
            bridgeAmountWeiFee: undefined,
            enteredAmountExceedsBalance: false,
            isTransacting: false,
            isFetchingData: false
        };

        this.onTokenFormSubmitted = this.onTokenFormSubmitted.bind(this);
        this.useAllBalance = this.useAllBalance.bind(this);
        this.close = this.close.bind(this);
    };

    // Handling Form Submission
    onTokenFormSubmitted(event) {
        event.preventDefault();

        const tokenAmount = parseFloat(this.state.tokenAmount);

        if (this.isBananaToken) {
            const bananaWithdrawalFee = this.state.bananaWithdrawalFee;
            const bananasBalance = this.state.totalBalanceAmount;

            if ((tokenAmount + bananaWithdrawalFee) > bananasBalance) {
                this.setState({enteredAmountExceedsBalance: true});
            }
            else {
                this.withdrawBananas(tokenAmount);
            }
        }
        else {
            this.withdrawPeels(tokenAmount);
        }
    };

    // Withdrawal
    withdrawBananas(bananasAmount) {
        this.setState({
            isTransacting: true,
            enteredAmountExceedsBalance: false
        });

        const bananasAmountFormatted = formatBananasTokenAmount(bananasAmount);

        if (this.evmSession) {
            if (this.evmSession.isOFTBridgeBased) {
                this.withdrawBananaFromAccountToOFT(bananasAmountFormatted)
                    .then(() => this.close())
                    .finally(() => this.setState({isTransacting: false}));
            }
            else {
                this.withdrawBananaFromAccountToEVM(bananasAmountFormatted)
                    .then(() => this.close())
                    .finally(() => this.setState({isTransacting: false}));
            }
        }

        if (this.solanaSession) {
            this.withdrawBananaFromAccountToSolana(bananasAmountFormatted)
                .then(result => result.response['transaction_id'])
                .then(trxID => this.submitWithdrawalTrx(trxID))
                .then(trxID => this.setState({withdrawalTrxID: trxID}))
                .finally(() => this.setState({isTransacting: false}));
        }
    }

    withdrawPeels(peelsAmount) {
        this.setState({isTransacting: true});
        const peelsAmountFormatted = formatPeelsTokenAmount(peelsAmount);

        this.trxAPI.withdrawPeels(peelsAmountFormatted)
            .then(() => {
                // Use the formatted amount in other case the Float type might have a lot bigger
                // precision affecting the resulting amount we're setting into the object
                const peelsWithdrawn = parseFloat(peelsAmountFormatted);
                this.props.monkey.bananaPeels -= peelsWithdrawn;
                this.close();
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    async withdrawBananaFromAccountToOFT(bananasAmountFormatted) {
        const evmAddress = this.evmSession.address;
        const bananaWithdrawalFee = this.state.bananaWithdrawalFee;
        const bridgeAmountWeiFee = this.state.bridgeAmountWeiFee;
        const eosioTotalFeeRequired = this.state.eosioTotalFeeRequired;
        const eosioAmountMissing = this.state.eosioAmountMissing;

        let params = {
            bridgeAmountFee: formatTLOSTokenAmount(eosioTotalFeeRequired), // WARN: TLOS only
            bridgeAmountWeiFee: bridgeAmountWeiFee,
            destinationChainID: OFTContract.layerZeroChainID(this.evmSession.chainID)
        };

        // Perhaps, no swapping is required
        if (bananaWithdrawalFee > 0) {
            params.swapContractName = this.bananaPricing.getExchangeContract(ExchangeType.ALCOR);
            params.swapQuantity = formatBananasTokenAmount(bananaWithdrawalFee);

            // Allow no slippage as we are already sending a little more $BANANA than expected
            params.swapMemo = this.bananaPricing.getSellBananaMemoUsingEosioAmount(eosioAmountMissing);
        }

        return this.trxAPI.withdrawBananaFromAccountToOFT(evmAddress, bananasAmountFormatted, params);
    };
    
    async withdrawBananaFromAccountToEVM(bananasAmountFormatted) {
        const evmAddress = this.evmSession.address;
        return this.trxAPI.withdrawBananaFromAccountToEVM(evmAddress, bananasAmountFormatted);
    };

    async withdrawBananaFromAccountToSolana(bananasAmountFormatted) {
        const solanaAddress = this.solanaSession.address;
        const bananaWithdrawalFee = formatBananasTokenAmount(this.state.bananaWithdrawalFee);
        return this.trxAPI.withdrawBananaFromAccountToSolana(solanaAddress, bananasAmountFormatted, bananaWithdrawalFee);
    };

    // Fetching
    fetchData() {
        // Only supported on native
        if (this.isPeelToken) {
            const bananaPeels = this.props.monkey.bananaPeels;
            this.setState({totalBalanceAmount: bananaPeels});
        }
        else {
            const accountName = this.session.accountName;
            this.setState({isFetchingData: true});

            if (this.evmSession) {
                // Bridging via OFT (to BSC) or running "almost"
                // natively on TelosEVM and so just withdrawing back
                if (this.evmSession.isOFTBridgeBased) {
                    Promise.all([
                        this.rpc.fetchEOSIOBalance(accountName),
                        this.rpc.fetchBananasBalance(accountName),
                        this.oftContract.estimateWithdrawFee(0),
                        this.bananaPricing.loadBananaReserveAlcor()
                    ])
                    .then(([eosioBalance, bananasBalance, withdrawalFee]) => {
                        this.processUserBalances(eosioBalance, bananasBalance, withdrawalFee);
                    })
                    .catch(() => this.close())
                    .finally(() => this.setState({isFetchingData: false}));
                }
                else {
                    this.rpc.fetchBananasBalance(accountName)
                        .then(balance => this.setState({totalBalanceAmount: balance}))
                        .catch(() => this.close())
                        .finally(() => this.setState({isFetchingData: false}));
                }
            }
            
            if (this.solanaSession) {
                Promise.all([
                    this.rpc.fetchBananasBalance(accountName),
                    fymAPI.getBridgingFeeToSolana()
                ])
                .then(([bananasBalance, withdrawalFee]) => {
                    this.setState({
                        totalBalanceAmount: bananasBalance,
                        bananaWithdrawalFee: withdrawalFee
                    });
                })
                .catch(() => this.close())
                .finally(() => this.setState({isFetchingData: false}));
            }
        }
    };

    // Processing
    async submitWithdrawalTrx(trxID) {
        await fymAPI.submitWithdrawalTrx(trxID);
        return trxID; // Return value expected by the next Promise case
    };

    // Misc
    processUserBalances(eosioBalance, bananasBalance, bridgeAmountWeiFee) {
        const decimals = EVM_BLOCKCHAINS['tevm'].nativeCurrency.decimals;
        const bridgeAmountFee = parseFloat(formatUnits(bridgeAmountWeiFee), decimals);
        const eosioTotalFeeRequired = (bridgeAmountFee + 0.16); // Add GAS required (in TLOS) into the bridging fee
        const eosioAmountMissing = (eosioTotalFeeRequired - eosioBalance);
        const calcBananasAmount = this.bananaPricing.calcBananasAmount.bind(this.bananaPricing);
        let bananaFeeAmount = 0;

        if (eosioAmountMissing > 0) {
            // Increase the amount charged by 2% just in case of
            // if the swap returns slightly less back
            bananaFeeAmount = calcBananasAmount(eosioAmountMissing * 1.02);

            // Make sure the resulting value doesn't have more than 4
            // dec. as that may cause UI issues as well as other issues
            bananaFeeAmount = Number(bananaFeeAmount.toFixed(4));
        }

        this.setState({
            totalBalanceAmount: bananasBalance,
            bridgeAmountWeiFee: bridgeAmountWeiFee, // A fee required by the LayerZero to bridge tokens
            eosioTotalFeeRequired: eosioTotalFeeRequired, // A total amount inc. gas required that needs to be sent
            eosioAmountMissing: eosioAmountMissing,
            bananaWithdrawalFee: bananaFeeAmount
        });
    };

    useAllBalance() {
        this.setState({tokenAmount: this.maxWithdrawAmount});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'withdrawTokens-modal'});
                this.bananaPricing = new BananaPricing(this.session);
                this.fetchData();
            }
            else {
                this.bananaPricing = undefined;

                this.setState({
                    tokenAmount: '',
                    totalBalanceAmount: undefined,
                    eosioTotalFeeRequired: undefined,
                    eosioAmountMissing: undefined,
                    bridgeAmountWeiFee: undefined,
                    bananaWithdrawalFee: 0,
                    withdrawalTrxID: undefined,
                    enteredAmountExceedsBalance: false
                });
            }
        }
    };

    render() {
        const isBalanceAvailable = (this.state.totalBalanceAmount > 0);
        const isTokenAmountInsufficient = (this.maxWithdrawAmount === 0);
        const isFetchingData = this.state.isFetchingData;
        const isTransacting = this.state.isTransacting;
        const hasRequestedWithdrawal = this.state.withdrawalTrxID;
        const enteredAmountExceedsBalance = this.state.enteredAmountExceedsBalance;

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal.Title>
                        { this.isBananaToken
                            ? t('modals.withdrawTokens.withdrawBananaTitle')
                            : t('modals.withdrawTokens.withdrawPeelTitle')
                        }
                    </Modal.Title>

                    <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.withdrawTokens.subtitle')}</div>
                </Modal.Header>

                <Modal.Body>
                    { isFetchingData &&
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                        </div>
                    }

                    { (isBalanceAvailable && !hasRequestedWithdrawal) &&
                        <Form id="withdrawTokensForm" onSubmit={this.onTokenFormSubmitted}>
                            <Form.Group className="mb-3" controlId="tokenAmount">
                                <Form.Label>{this.isBananaToken ? t('misc.amountOfBananas') : t('misc.amountOfPeels')}</Form.Label>
                                <Form.Control type="number" step="any" disabled={isTransacting} value={this.state.tokenAmount} onChange={e => { this.setState({tokenAmount: e.target.value})}} autoFocus required/>

                                { isBalanceAvailable &&
                                    <Stack>
                                        <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useAllBalance}>
                                            {t('modals.withdrawTokens.withdrawAll')}: {formatAmount(this.maxWithdrawAmount)}
                                        </Form.Text>

                                        { (this.isBananaToken && this.state.bananaWithdrawalFee > 0) &&
                                            <Form.Text className="text-muted mt-0">
                                                {t('misc.bridgingFee')}: {formatAmount(this.state.bananaWithdrawalFee)}
                                            </Form.Text>
                                        }

                                        { (this.isBananaToken && (isTokenAmountInsufficient || enteredAmountExceedsBalance)) &&
                                            <Form.Text className="text-muted mt-4 red" style={{textAlign: 'center'}}>
                                                {t('modals.withdrawTokens.notEnoughBananaForFeeAndWithdrawal')}
                                            </Form.Text>
                                        }
                                    </Stack>
                                }
                            </Form.Group>
                        </Form>
                    }

                    { (hasRequestedWithdrawal && !isFetchingData) &&
                        <p style={{textAlign: 'center', marginBottom: 0}}>
                            {/* Break lines set in the translation files */}
                            <Trans i18nKey="modals.withdrawTokens.processingYourWithdrawal">
                                Monkeys are processing your withdrawal. Please allow up to 5 minutes.
                                You can find your transaction <a href={transactionDetailsURL(this.state.withdrawalTrxID, this.sharedState.chain)} target="_blank" rel="noreferrer">here</a>.
                                In case of any issues reach out to us on Telegram.
                            </Trans>
                        </p>
                    }

                    { (!isBalanceAvailable && !isFetchingData) &&
                        <p style={{textAlign: 'center', marginBottom: 0}}>
                            {t('modals.withdrawTokens.nothingToWithdraw')}
                        </p>
                    }
                </Modal.Body>

                <Modal.Footer>
                    { !isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{hasRequestedWithdrawal ? t('common.ok') : t('common.cancel')}</Button>
                    }

                    { (isBalanceAvailable && !hasRequestedWithdrawal) &&
                        <Button type="submit" form="withdrawTokensForm" variant="primary" disabled={isTransacting || isTokenAmountInsufficient}>
                            { isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('common.confirm')}</span>
                                </>
                                :
                                t('common.confirm')
                            }
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    };
};

export default commonContextsWrapper(WithdrawTokensModal);
