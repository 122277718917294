class JungleExplorerDetails {
    get isExploring() {
        if (!this.exploringUntil) {
            return false;
        }

        return (this.exploringUntil.getTime() > Date.now());
    }

    get isExplorationCompleted() {
        // `exploringUntil` must exist and be older to
        // to verify whether exploration is completed
        return (this.exploringUntil && !this.isExploring);
    }

    constructor(obj) {
        // In case of `undefined` (no record being available)
        if (obj) {
            this.exploringUntil = new Date(obj['exploring_until'] + 'Z');
        }
    }
}

export default JungleExplorerDetails;
