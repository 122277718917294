import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';
import { Serializer, Bytes } from "@wharfkit/session";

import BananaTree from '../../../Models/BananaTree';
import ToastMessage from '../../ToastMessage';
import StakeBoxLPModal from './StakeBoxLPModal';
import FertilizeTreeModal from './FertilizeTreeModal';
import BananaAmount from '../../Widgets/BananaAmount';
import commonContextsWrapper from '../../../Helpers/commonContextsWrapper';
import { formatAmount, calcTimeRemaining } from '../../../Helpers/Utils';
import { t, Trans } from '../../../i18n';
import { FYM_CONTRACT_NAME, getBlockchainName } from '../../../config';

import bananaTreeYellow from '../../../Assets/BananaTreeYellowTopDown.png';
import bananaTreeGreen from '../../../Assets/BananaTreeGreenTopDown.png';
import bananaCoinLogo from '../../../Assets/BananaLogo.png';
import './TreePlotOptionsModal.css';

class TreePlotOptionsModal extends React.Component {
    #HARVESTING_ANIMATION_DURATION = 1000; // ~1 sec

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    get session() {
        return this.sharedState.session;
    }
    
    get rpc() {
        return this.sharedState.rpc;
    }

    get isRunningOnEOS() {
        const chainName = getBlockchainName(this.sharedState.chain);
        return (chainName === 'EOS');
    }

    get harvestAnimationInterval() {
        const cycles = Math.max(1, this.state.bananasHarvested); // 1 increase per $BANANA
        return (this.#HARVESTING_ANIMATION_DURATION / cycles);
    };

    constructor(props) {
        super(props);
        
        this.state = {
            isUnstaking: false,
            isPlantingTree: false,
            isDiggingUpTree: false,
            isHarvesting: false,
            isStakeLPModalShown: false,
            isFertilizeTreeModalShown: false,
            bananasHarvested: 0,
            bananasHarvestedCounter: 0,
            alertDetails: undefined
        };

        this.bananasHarvestedCounterInterval = undefined;

        this.showTreeFertilizationModal = this.showTreeFertilizationModal.bind(this);
        this.hideTreeFertilizationModal = this.hideTreeFertilizationModal.bind(this);

        this.showStakeLPModal = this.showStakeLPModal.bind(this);
        this.hideStakeLPModal = this.hideStakeLPModal.bind(this);

        this.hideAlert = this.hideAlert.bind(this);
        this.unstakeLPTokens = this.unstakeLPTokens.bind(this);
        this.plantTree = this.plantTree.bind(this);
        this.digUpTree = this.digUpTree.bind(this);
        this.hideHarvestAnimation = this.hideHarvestAnimation.bind(this);
        this.close = this.close.bind(this);
    }

    // Transactions
    unstakeLPTokens() {
        this.setState({isUnstaking: true});
        
        this.trxAPI.unstakeLPTokens()
            .then(() => this.props.onTokensUnstaked())
            .finally(() => this.setState({isUnstaking: false}));
    };

    plantTree() {
        this.setState({isPlantingTree: true});

        this.rpc.fetchBananaTreeNFTs(this.session.accountName)
            .then(trees => {
                if (trees.length) {
                    const assetID = trees[0]['asset_id'];
                    return this.trxAPI.plantTree(assetID);
                }

                this.showNoTreesAlert();
            })
            .then(result => {
                if (!result) {
                    return;
                }
                
                const actionTraces = result.response.processed['action_traces'];
                const plantTreeAction = actionTraces.find(action => (action.receiver === FYM_CONTRACT_NAME));
                const returnValueData = Bytes.from(plantTreeAction['return_value_hex_data']);

                return this.session
                    .abiFor(FYM_CONTRACT_NAME)
                    .then(abi => {
                        const treeObj = Serializer.decode({data: returnValueData, type: 'trees_row', abi: abi});
                        const tree = new BananaTree(treeObj);

                        this.props.onTreePlanted(tree);
                        this.close();
                    });
            })
            .finally(() => this.setState({isPlantingTree: false}));
    };

    digUpTree(tree) {
        this.setState({isDiggingUpTree: true});

        this.trxAPI.digupTree(tree.id)
            .then(() => {
                this.props.onTreeDiggedUp(tree);
                this.close();
            })
            .finally(() => this.setState({isDiggingUpTree: false}));
    };

    harvestTree(tree) {
        this.setState({isHarvesting: true});

        this.trxAPI.harvestTree(tree.id)
            .then(() => {
                const bananasHarvested = tree.bananas;
                setTimeout(() => this.setState({bananasHarvested}), 100); // Run in a little timeout for better experience
                this.props.onTreeHarvested(tree);
            })
            .finally(() => this.setState({isHarvesting: false}));
    };

    // Modals
    showStakeLPModal() {
        this.setState({isStakeLPModalShown: true});
    };

    hideStakeLPModal() {
        this.setState({isStakeLPModalShown: false});
    };

    showTreeFertilizationModal() {
        this.setState({isFertilizeTreeModalShown: true});
    };

    hideTreeFertilizationModal() {
        this.setState({isFertilizeTreeModalShown: false});
    };

    // Alert
    showNoTreesAlert() {
        this.setState({alertDetails: {
            title: t('modals.farming.noTrees'),
            message: (
                <Trans i18nKey="modals.farming.noTreeMessage">
                    Oh no, seems like you don't have any trees to plant. You can buy some on <a href={this.getMarketplaceURL()} target="_blank" rel="noreferrer">AtomicHub.io</a> or you can grow them yourself.
                </Trans>
            )
        }});
    };

    hideAlert() {
        this.setState({alertDetails: undefined});
    };

    // Harvesting Animation
    setBananasHarvestedCounter() {
        if (this.bananasHarvestedCounterInterval) {
            return;
        }

        const counter = () => {
            const bananasHarvested = this.state.bananasHarvested;
            const bananasCounter = this.state.bananasHarvestedCounter;

            if (bananasCounter === bananasHarvested) {
                clearInterval(this.bananasHarvestedCounterInterval);
            }
            else {
                const newValue = Math.min((bananasCounter + 1), bananasHarvested);
                this.setState({bananasHarvestedCounter: newValue});
            }
        };

        counter(); // Run the 1st one right away
        this.bananasHarvestedCounterInterval = setInterval(counter, this.harvestAnimationInterval);
    };

    hideHarvestAnimation() {
        this.setState({bananasHarvested: 0, bananasHarvestedCounter: 0});
        clearInterval(this.bananasHarvestedCounterInterval)
        this.bananasHarvestedCounterInterval = undefined
    };

    // Misc
    getMarketplaceURL() {
        const chainName = getBlockchainName(this.sharedState.chain);
        
        switch (chainName) {
            case 'EOS':
                return 'https://eos.atomichub.io/market?collection_name=feedurmonkey&schema_name=trees&template_id=11329';
            case 'WAX':
                return 'https://wax.atomichub.io/market?collection_name=feedurmonkey&schema_name=trees&template_id=784347';
            case 'TELOS':
                return 'https://telos.neftyblocks.com/marketplace/listing?page=1&collection_name=feedurmonkey';
            default:
                return;
        }
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'treePlotOptions-modal'});
            }
            else {
                this.hideHarvestAnimation();
            }
        }

        if (this.state.bananasHarvested && !this.bananasHarvestedCounterInterval) {
            this.setBananasHarvestedCounter();
        }
    };

    render() {
        const treePlot = this.props.treePlot;
        const tree = treePlot?.tree;
        const isPlotEmpty = treePlot?.isEmpty;
        const isTreeGrowing = treePlot?.isTreeGrowing;
        const isTreeGrown = treePlot?.isTreeGrown;
        const isTransacting = (this.state.isUnstaking || this.state.isPlantingTree || this.state.isDiggingUpTree || this.state.isHarvesting);
        const isStakeMature = this.props.stakingDetails?.isStakeMature;
        const isRunningOnEOS = this.isRunningOnEOS;
        const bananasHarvested = this.state.bananasHarvested;
        const showsBananasHarvestedInfo = !!bananasHarvested;
        const hasBananas = tree?.hasBananas;
        
        return (
            <>
                {/* Options Modals */}
                <ToastMessage.GenericAlert
                    isShown={!!this.state.alertDetails}
                    title={this.state.alertDetails?.title}
                    message={this.state.alertDetails?.message}
                    onCloseClicked={this.hideAlert}
                />

                <FertilizeTreeModal
                    monkey={this.props.monkey}
                    tree={this.props.treePlot?.tree}
                    isShown={this.state.isFertilizeTreeModalShown}
                    onCloseClicked={this.hideTreeFertilizationModal}
                    onTreeFertilized={this.props.onTreeFertilized}
                />

                <StakeBoxLPModal
                    isShown={this.state.isStakeLPModalShown}
                    stakingDetails={this.props.stakingDetails}
                    onCloseClicked={this.hideStakeLPModal}
                    onTokensStaked={this.props.onTokensStaked}
                />
                
                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} dialogClassName="OptionsPopupModal" backdropClassName="ModalWithTintedBackdrop" backdrop="static" centered>
                    <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Modal.Title>{t('modals.farming.treePlotOptions.title')}</Modal.Title>

                        { (isTreeGrown && hasBananas && !showsBananasHarvestedInfo) &&
                            <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.treePlotOptions.amountOfBananasToHarvest', {bananasAmount: formatAmount(tree.bananas)})}</div>
                        }
                        
                        { (isTreeGrown && !hasBananas && tree.isFertilized) &&
                            <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.treePlotOptions.harvestableCountdown', {timeRemaining: calcTimeRemaining(tree.bananasHarvestableAt)})}</div>
                        }
                        
                        { (isTreeGrown && !hasBananas && !tree.isFertilized && !showsBananasHarvestedInfo) &&
                            <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.treePlotOptions.notFertilizedTree')}</div>
                        }

                        {/*
                            The tree might be growing but also the stake maturing
                            in case when the stake has been additional increased
                        */}
                        { (isTreeGrowing && isStakeMature) &&
                            <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.treePlotOptions.growthState', {percentage: (treePlot.treeGrowthState * 100)})}</div>
                        }

                        { (isTreeGrowing && !isStakeMature) &&
                            <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.treePlotOptions.seedMaturing')}</div>
                        }
                    </Modal.Header>

                    { !showsBananasHarvestedInfo &&
                        <Modal.Body>
                            <Stack style={{gap: '6px'}}>
                                <div style={{textAlign: 'center', marginBottom: '8px', fontWeight: 300}}>{t('common.options')}:</div>

                                { isPlotEmpty &&
                                    <>
                                        <Button variant="primary" onClick={this.showStakeLPModal} disabled={isTransacting || !isRunningOnEOS}>
                                            {t('modals.farming.treePlotOptions.growTree')}
                                        </Button>

                                        <Button variant="primary" onClick={this.plantTree} disabled={isTransacting}>
                                            { this.state.isPlantingTree ?
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                    <span className="visually-hidden">{t('modals.farming.treePlotOptions.plantTree')}</span>
                                                </>
                                                :
                                                t('modals.farming.treePlotOptions.plantTree')
                                            }
                                        </Button>
                                    </>
                                }
                                
                                { isTreeGrowing &&
                                    <>
                                        <Button variant="primary" onClick={this.plantTree} disabled={isTransacting}>
                                            { this.state.isPlantingTree ?
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                    <span className="visually-hidden">{t('modals.farming.treePlotOptions.plantNewTree')}</span>
                                                </>
                                                :
                                                t('modals.farming.treePlotOptions.plantNewTree')
                                            }
                                        </Button>

                                        <Button variant="primary" onClick={this.showStakeLPModal} disabled={isTransacting || !isRunningOnEOS}>
                                            {t('modals.farming.treePlotOptions.speedUpGrowth')}
                                        </Button>

                                        <Button variant="danger" onClick={this.unstakeLPTokens} disabled={isTransacting || !isRunningOnEOS}>
                                            { this.state.isUnstaking ?
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                    <span className="visually-hidden">{t('modals.farming.treePlotOptions.unstake')}</span>
                                                </>
                                                :
                                                t('modals.farming.treePlotOptions.unstake')
                                            }
                                        </Button>
                                    </>
                                }

                                { isTreeGrown &&
                                    <>
                                        <Button variant="primary" onClick={this.showTreeFertilizationModal} disabled={isTransacting || tree.isFertilized}>
                                            {t('modals.farming.treePlotOptions.fertilize')}
                                        </Button>

                                        <Button variant="primary" onClick={() => this.harvestTree(tree)} disabled={isTransacting || !hasBananas}>
                                            { this.state.isHarvesting ?
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                    <span className="visually-hidden">{t('modals.farming.treePlotOptions.harvest')}</span>
                                                </>
                                                :
                                                t('modals.farming.treePlotOptions.harvest')
                                            }
                                        </Button>

                                        <Button variant="primary" onClick={() => this.digUpTree(tree)} disabled={isTransacting || hasBananas}>
                                            { this.state.isDiggingUpTree ?
                                                <>
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                    <span className="visually-hidden">{t('modals.farming.treePlotOptions.digUpTree')}</span>
                                                </>
                                                :
                                                t('modals.farming.treePlotOptions.digUpTree')
                                            }
                                        </Button>

                                        <div style={{fontSize: '0.6rem', fontWeight: 300, textAlign: 'center'}}>
                                            {t('modals.farming.treePlotOptions.digUpTreeExplainedMessage', {energyAmount: 100})}
                                        </div>
                                    </>
                                }

                                <Button variant="secondary" onClick={this.close} disabled={isTransacting}>{t('common.cancel')}</Button>
                            </Stack>
                        </Modal.Body>
                    }

                    { showsBananasHarvestedInfo &&
                        <Modal.Body>
                            <Stack style={{gap: '6px', alignItems: 'center'}}>
                                <div className="TreeContainer">
                                    <img src={bananaTreeGreen} width="100%" height="100%" alt="banana-tree-green" draggable="false"/>
                                    <img src={bananaTreeYellow} width="100%" height="100%" alt="banana-tree-yellow" draggable="false"
                                        style={{animation: `${this.#HARVESTING_ANIMATION_DURATION}ms linear 0s 1 normal running forwards disappear`}}
                                    />
                                </div>

                                {
                                    new Array(Math.ceil(bananasHarvested)).fill(undefined).map((x, y) => {
                                        const delay = (this.harvestAnimationInterval * y);
                                        const animation = `appear_and_move_coin 330ms ease ${delay}ms`;
                                        return <img src={bananaCoinLogo} key={y} className="BananaCoinIcon roll-out" alt="banana-logo" style={{animation}}/>
                                    })
                                }
                                
                                <BananaAmount amount={this.state.bananasHarvestedCounter} style={{alignSelf: 'center', marginTop: '1rem'}}/>
                            </Stack>
                        </Modal.Body>
                    }

                    { showsBananasHarvestedInfo &&
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.hideHarvestAnimation}>{t('common.close')}</Button>
                        </Modal.Footer>
                    }
            </Modal>
            </>
        );
    }
}

export default commonContextsWrapper(TreePlotOptionsModal);
