import secureLocalStorage from 'react-secure-storage';

import {
    SESSION_KEY,
    JWT_TOKEN_KEY_NAME,
    SIDECHAIN_ACCOUNT
} from '../config';

class FYMSideChainSession {
    static CHAIN_TYPE = {EVM: 1, SOLANA: 2};

    static get isUserLoggedIn() {
        return (!!secureLocalStorage.getItem(JWT_TOKEN_KEY_NAME) && !!FYMSideChainSession.recentlyUsedAddress);
    }

    static get recentlyUsedAddress() {
        return secureLocalStorage.getItem(SIDECHAIN_ACCOUNT.ADDRESS);
    }

    static get recentlyUsedChainID() {
        return secureLocalStorage.getItem(SIDECHAIN_ACCOUNT.CHAIN_ID);
    }

    static get chainType() {
        return secureLocalStorage.getItem(SIDECHAIN_ACCOUNT.CHAIN_TYPE);
    }

    get #privateKey() {
        return secureLocalStorage.getItem(SESSION_KEY.PK_NAME);
    }

    set privateKey(key) {
        secureLocalStorage.setItem(SESSION_KEY.PK_NAME, key);
    }

    set jwtToken(token) {
        secureLocalStorage.setItem(JWT_TOKEN_KEY_NAME, token);
        secureLocalStorage.setItem(SIDECHAIN_ACCOUNT.ADDRESS, this.address);
        secureLocalStorage.setItem(SIDECHAIN_ACCOUNT.CHAIN_TYPE, this.chainType);
    }

    set accountName(accountName) {
        this._accountName = accountName;
        secureLocalStorage.setItem(SIDECHAIN_ACCOUNT.ACCOUNT_NAME, accountName);
    }

    set accountCreated(accountCreated) {
        this._accountCreated = accountCreated;
        secureLocalStorage.setItem(SIDECHAIN_ACCOUNT.ACCOUNT_CREATED, accountCreated);
    }

    set chainID(value) {
        if (value !== undefined) {
            secureLocalStorage.setItem(SIDECHAIN_ACCOUNT.CHAIN_ID, Number(value));
        }
    }

    get accountName() {
        if (this._accountName) {
            return this._accountName;
        }

        const accountName = secureLocalStorage.getItem(SIDECHAIN_ACCOUNT.ACCOUNT_NAME);
        this._accountName = accountName;

        return accountName;
    }
    
    get antelopeAccountCreated() {
        if (this._accountCreated) {
            return this._accountCreated;
        }
        
        const accountCreated = secureLocalStorage.getItem(SIDECHAIN_ACCOUNT.ACCOUNT_CREATED);
        this._accountCreated = !!accountCreated;

        return this._accountCreated;
    }
    
    get isAccountKeySet() {
        return !!this.#privateKey;
    }

    get isAccountNameSet() {
        return !!secureLocalStorage.getItem(SIDECHAIN_ACCOUNT.ACCOUNT_NAME);
    }

    constructor(chainType) {
        this.chainType = chainType;
    };

    // Misc
    static logout() {
        secureLocalStorage.removeItem(SESSION_KEY.PK_NAME);
        secureLocalStorage.removeItem(JWT_TOKEN_KEY_NAME);
        secureLocalStorage.removeItem(SIDECHAIN_ACCOUNT.ADDRESS);
        secureLocalStorage.removeItem(SIDECHAIN_ACCOUNT.CHAIN_ID);
        secureLocalStorage.removeItem(SIDECHAIN_ACCOUNT.CHAIN_TYPE);
        secureLocalStorage.removeItem(SIDECHAIN_ACCOUNT.ACCOUNT_NAME);
        secureLocalStorage.removeItem(SIDECHAIN_ACCOUNT.ACCOUNT_CREATED);
    };
};

export default FYMSideChainSession;