import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';
import { PieChart } from 'react-minimal-pie-chart';
import { APIClient } from '@wharfkit/antelope';
import { Contract, JsonRpcProvider, Network, formatUnits } from 'ethers';

import SolanaWalletAPI from '../../Helpers/SolanaWalletAPI';
import FYMRPC from '../../Helpers/FYMRPC';
import { BananaPricing } from '../../Helpers/BananaPricing';
import { formatAmount, formatAmountCompactly, formatCurrencyAmount } from '../../Helpers/Utils';
import { t } from '../../i18n';

import {
    EOSIO_BLOCKCHAINS,
    EVM_BLOCKCHAINS,
    EVM_TOKEN_CONTRACT_ADDRESS,
    WRAP_LOCK_CONTRACT_NAME,
    SOLANA_BRIDGE_ACCOUNT,
    SOLANA_TOKEN_CONTRACT_ADDRESS,
    EVM_BRIDGE_ACCOUNT
} from '../../config';

class AboutBananaModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetchingData: false,
            hoveredSegmentIndex: undefined,
            pieData: []
        };

        this.close = this.close.bind(this);
    };

    // Fetching
    fetchData() {
        this.setState({isFetchingData: true});

        const bananaPricing = new BananaPricing(this.session);

        Promise
            .all([
                this.fetchPrimarySupplyInfo(),
                this.fetchSecondarySupplyInfo(),
                bananaPricing.fetchBananaUSDPrice()
            ])
            .then(([primaryInfo, secondaryInfo, bananaUSDPrice]) => {                
                const solanaSupply = secondaryInfo.solanaSupply;
                const bscSupply = secondaryInfo.bscSupply;
                const waxSupply = primaryInfo.waxSupply;
                const eosSupply = (primaryInfo.circulatingSupply - primaryInfo.telosSupply - waxSupply);
                const telosSupply = (primaryInfo.telosSupply - bscSupply - solanaSupply); // Includes tEVM
                const marketCap = (bananaUSDPrice * primaryInfo.circulatingSupply);

                this.setState({
                    circulatingSupply: primaryInfo.circulatingSupply,
                    maxSupply: primaryInfo.maxSupply,
                    peelsSupply: primaryInfo.peelsSupply,
                    burntAmount: primaryInfo.burntAmount,
                    marketCap: marketCap,
                    pieData: [
                        {title: 'EOS', value: eosSupply, color: '#6FA6DF'},
                        {title: 'Telos', value: telosSupply, color: '#A179BF'},
                        {title: 'WAX', value: waxSupply, color: '#FE7676'},
                        {title: 'BSC', value: bscSupply, color: '#F4CF28'},
                        {title: 'SOLANA', value: solanaSupply, color: '#5DBEAA'}
                    ]
                });
            })
            .catch(() => this.close())
            .finally(() => this.setState({isFetchingData: false}))
    };

    async fetchPrimarySupplyInfo() {
        const eosChain = EOSIO_BLOCKCHAINS['eos'];
        const fymRPC = this.rpcForChain(eosChain);
        const solanaAPI = new SolanaWalletAPI();

        const telosWrapLockContract = WRAP_LOCK_CONTRACT_NAME['telos'];
        const waxWrapLockContract = WRAP_LOCK_CONTRACT_NAME['wax'];

        const response = await Promise.all([
            fymRPC.fetchBananaSupply(),
            fymRPC.fetchPeelSupply(),
            fymRPC.fetchBananasBalance(telosWrapLockContract),
            fymRPC.fetchBananasBalance(waxWrapLockContract),
            fymRPC.fetchBananasBalance('eosio.null'),
            solanaAPI.fetchBalanceOf(SOLANA_TOKEN_CONTRACT_ADDRESS, '11111111111111111111111111111111')
        ]);

        return {
            circulatingSupply: response[0],
            maxSupply: 1000_000_000.0000,
            peelsSupply: response[1],
            telosSupply: response[2],
            waxSupply: response[3],
            burntAmount: (response[4] + response[5])
        };
    };

    async fetchSecondarySupplyInfo() {
        const telosChain = EOSIO_BLOCKCHAINS['telos'];
        const fymRPC = this.rpcForChain(telosChain);

        const response = await Promise.all([
            fymRPC.fetchBananasBalance(SOLANA_BRIDGE_ACCOUNT),
            fymRPC.fetchBananasBalance(EVM_BRIDGE_ACCOUNT),
            this.fetchBSCBananasSupply()
        ]);

        return {
            solanaSupply: response[0],
            tevmSupply: (response[1] - response[2]),
            bscSupply: response[2]
        };
    };

    async fetchBSCBananasSupply() {
        const chain = EVM_BLOCKCHAINS['bsc'];
        const network = Network.from({name: chain.name, chainId: chain.chainID});
        const abi = ['function totalSupply() external view returns (uint256)'];
        const tokenAddress = EVM_TOKEN_CONTRACT_ADDRESS['bsc'];
        const provider = new JsonRpcProvider(chain.rpcURL.toString(), network, {staticNetwork: network});
        const tokenContract = new Contract(tokenAddress, abi, provider);
        const weiSupply = await tokenContract.totalSupply();
        return parseFloat(formatUnits(weiSupply, 4));
    };

    // Misc
    rpcForChain(chain) {
        const client = new APIClient({url: chain.url});
        return new FYMRPC(client, chain);
    };

    close() {
        this.props.onCloseClicked();
    };

    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'aboutBanana-modal'});
                this.fetchData();
            }
            else {
                this.setState({});
            }
        }
    };

    // React
    render() {
        return (
            <Modal show={this.props.isShown} onHide={this.close} size="md" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.aboutBanana.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { this.state.isFetchingData &&
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                        </div>
                    }

                    { !this.state.isFetchingData &&
                        <Stack style={{alignItems: 'center', gap: '2rem'}}>
                            <div style={{width: '66%'}}>
                                <PieChart
                                    totalValue={this.state.circulatingSupply}
                                    labelStyle={{fontSize: '4px', fill: 'white'}}
                                    radius={47}
                                    segmentsStyle={{transition: 'stroke .3s', cursor: 'pointer'}}
                                    segmentsShift={(index) => (index === this.state.hoveredSegmentIndex ? 3 : 0)}
                                    label={({dataEntry}) => {
                                        const index = this.state.hoveredSegmentIndex;
                                        const isHovered = (index !== undefined && dataEntry.title === this.state.pieData[index].title);
                                        return isHovered ? (Math.round(dataEntry.percentage) + '%') : dataEntry.title;
                                    }}
                                    onMouseOver={(_, index) => this.setState({hoveredSegmentIndex: index})}
                                    onMouseOut={() => this.setState({hoveredSegmentIndex: undefined})}
                                    data={this.state.pieData}
                                />
                            </div>
                            
                            <div style={{textAlign: 'center', fontWeight: 300}}>{t('modals.aboutBanana.aboutBanana')}</div>
                            
                            <Stack>
                                <RowDetails title={`${t('modals.aboutBanana.maxSupply')}:`} subdetail={`${formatAmount(this.state.maxSupply)} (${formatAmountCompactly(this.state.maxSupply)})`}/>
                                <RowDetails title={`${t('modals.aboutBanana.circulatingSupply')}:`} subdetail={`${formatAmount(this.state.circulatingSupply)} (${formatAmountCompactly(this.state.circulatingSupply)})`}/>
                                <RowDetails title={`${t('modals.aboutBanana.burntAmount')}:`} subdetail={`${formatAmount(this.state.burntAmount)} (${formatAmountCompactly(this.state.burntAmount)})`}/>
                                <RowDetails title={`${t('modals.aboutBanana.peelsSupply')}:`} subdetail={`${formatAmount(this.state.peelsSupply)} (${formatAmountCompactly(this.state.peelsSupply)})`}/>
                                <RowDetails title={`${t('modals.aboutBanana.marketCap')}:`} subdetail={`${formatCurrencyAmount(this.state.marketCap, 'USD')}`}/>
                            </Stack>

                            <Stack>
                                <RowDetails title={`${t('modals.aboutBanana.teamAllocation')}:`} subdetail="15%"/>
                                <RowDetails title={`${t('modals.aboutBanana.liquidityExchanges')}:`} subdetail="~30%"/>
                                <RowDetails title={`${t('modals.aboutBanana.investorsPool')}:`} subdetail="20%"/>
                                <RowDetails title={`${t('modals.aboutBanana.operations')}:`} subdetail="2%"/>
                                <RowDetails title={`${t('modals.aboutBanana.burnt')}:`} subdetail="33%"/>
                            </Stack>

                            <div>{t('modals.aboutBanana.noIcoNoPresale')}</div>

                            <Stack direction="vertical" style={{gap: '1rem'}}>
                                <h3>{t('modals.aboutBanana.faq')}</h3>
                                
                                <div>
                                    <div>{t('modals.aboutBanana.whyIsMintEnabled')}</div>
                                    <div style={{fontWeight: 300}}>{t('modals.aboutBanana.whyIsMintEnabledExplained')}</div>
                                </div>

                                <div>
                                    <div>{t('modals.aboutBanana.whatsPeel')}</div>
                                    <div style={{fontWeight: 300}}>{t('modals.aboutBanana.whatsPeelExplained')}</div>
                                </div>
                            </Stack>
                        </Stack>
                    }
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.close}>{t('common.close')}</Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default AboutBananaModal;

// Components
function RowDetails(props) {
    return (
        <Stack direction="horizontal" style={{...{gap: '4px', fontWeight: 300, justifyContent: 'center'}, ...props.style}}>
            <b>{props.title}</b>
            {props.subdetail || props.children}
        </Stack>
    );
};