import { PulseLoader } from 'react-spinners';

export default function WrappedPulseLoader(props) {
    return <PulseLoader
        color="black"
        size="8px"
        margin="12px"
        speedMultiplier="0.8"
        role="status"
        {...props}
    />
};
