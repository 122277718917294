import './Footer.css';
import { TG_URL, TWITTER_URL } from '../config';

function Footer() {
    return (
        <footer className="Footer">
            Copyright © {new Date().getFullYear()} Baboon. All rights reserved | <a href={TG_URL} target="_blank" rel="noreferrer" className="Footer-link">Telegram</a> | <a href={TWITTER_URL} target="_blank" rel="noreferrer" className="Footer-link">Twitter</a>
        </footer>
    );
}

export default Footer;
