import { useTranslation } from 'react-i18next';
import './PageNotFound.css';

function PageNotFound() {
    const {t} = useTranslation();

    return (
        <div className="PageNotFound-container">
            <h1 className="PageNotFound-404-header">404</h1>
            <h6 className="PageNotFound-message">{t('misc.pageCannotBeFound')}</h6>
        </div>
    );
}

export default PageNotFound;
