import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import { t } from '../../i18n';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';

class SetStatusModal extends React.Component {
    get trxAPI() {
        return this.props.trxAPI;
    }

    constructor(props) {
        super(props);

        this.state = {
            isTransacting: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.close = this.close.bind(this);
    }

    // Transactions
    setStatus(status) {
        this.setState({isTransacting: true});

        this.trxAPI.setStatus(status)
            .then(() => this.close())
            .finally(() => this.setState({isTransacting: false}));
    };

    // Misc
    handleFormSubmit(event) {
        const form = event.target;
        const status = form['status'].value.trim() ?? ''; // Must be specified
        event.preventDefault();
        this.setStatus(status);
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'setStatus-modal'});
            }
        }
    };

    render() {
        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.setStatus.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form id="setStatusForm" onSubmit={this.handleFormSubmit}>
                        <Form.Group className="mb-3" controlId="status">
                            <Form.Label>{t('modals.setStatus.status')}</Form.Label>
                            <Form.Control type="text" disabled={this.state.isTransacting} defaultValue={this.props.monkey?.status} maxLength="40" autoFocus/>
                            <Form.Text className="text-muted">{t('modals.setStatus.willBeShownBelowYourName')}</Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    {!this.state.isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    <Button type="submit" form="setStatusForm" variant="primary" disabled={this.state.isTransacting}>
                        {this.state.isTransacting ?
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                <span className="visually-hidden">{t('common.save')}</span>
                            </>
                            :
                            t('common.save')
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default commonContextsWrapper(SetStatusModal);
