import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Joyride from 'react-joyride';
import { initInitData } from '@tma.js/sdk-react';

import PulseLoader from '../Widgets/PulseLoader';
import DetailedError from '../Widgets/DetailedError';
import BananaAmount from '../Widgets/BananaAmount';
import XPAmount from '../Widgets/XPAmount';
import PeelAmount from '../Widgets/PeelAmount';
import AboutGameModal from '../Modals/AboutGameModal';
import FeedMonkeyModal from '../Modals/FeedMonkeyModal';
import StarveMonkeyModal from '../Modals/StarveMonkeyModal';
import FightDetailsModal from '../Modals/FightDetailsModal';
import SetStatusModal from '../Modals/SetStatusModal';
import SetSessionKeyModal from '../Modals/SetSessionKeyModal';
import AboutBananaModal from '../Modals/AboutBananaModal';
import BuyBananaModal from '../Modals/BuyBananaModal';
import StakeBananaModal from '../Modals/StakeBananaModal';
import BridgeTokensModal from '../Modals/BridgeTokensModal';
import ExploreJungleModal from '../Modals/ExploreJungleModal';
import InventoryModal from '../Modals/InventoryModal';
import FarmingModal from '../Modals/FarmingModal';
import EVMLoginModal from '../Modals/EVMLoginModal';
import SolanaLoginModal from '../Modals/SolanaLoginModal';
import WithdrawTokensModal from '../Modals/WithdrawTokensModal';
import PlayerDetailsModal from '../Modals/PlayerDetailsModal';
import EasterEggModal from '../Modals/EasterEggModal';
import MonkeyAccount from '../../Models/MonkeyAccount';
import InventoryAsset from '../../Models/InventoryAsset';
import FightResult from '../../Models/FightResult';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import { t, Trans } from '../../i18n';

import MonkeyListItem from './MonkeyListItem';
import MonkeySilhouette from './MonkeySilhouette';

import { accountDetailsURL, isMobileDevice, randomNumber } from '../../Helpers/Utils';
import { getBlockchainName } from '../../config';

import './Home.css';

import bananaCoinLogo from '../../Assets/BananaLogo.png';
import sadMonkeyImage from '../../Assets/SadMonkey.png';

class Home extends React.Component {
    #REFRESH_INTERVAL = 2500;

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get setSharedState() {
        const [, {updateState}] = this.props.sharedState;
        return updateState;
    }

    get session() {
        return this.sharedState?.session;
    }

    get evmSession() {
        return this.sharedState?.evmSession;
    }
    
    get solanaSession() {
        return this.sharedState?.solanaSession;
    }

    get isUserLoggedIn() {
        return this.sharedState?.isUserLoggedIn;
    }

    get isRunningOnNative() {
        return this.sharedState?.isRunningOnNative;
    }

    get isRunningOnEOS() {
        const chainName = getBlockchainName(this.sharedState.chain);
        return (chainName === 'EOS');
    }
    
    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    get isJoyrideRequired() {
        const value = localStorage.getItem('homeJoyrideRequired');
        return (value === null || value === 'true'); // Stored as a String
    }

    set isJoyrideRequired(value) {
        localStorage.setItem('homeJoyrideRequired', value);
    }

    get isEasterEggGameShown() {
        return this.state.modals.isEasterEggGameShown;
    }

    constructor(props) {
        super(props);

        this.refreshMonkeysInterval = undefined;
        this.monkeysList = undefined;
        this.lastKnownMonkeysScrollPosition = undefined;
        this.isFetchingFightLogs = false;
        this.tgInitDataChecked = false;

        this.state = {
            monkey: undefined, // `null` in case of when logged in, but no monkey registered yet
            monkeys: [],
            modals: {
                isFeedMonkeyShown: false,
                isStarveMonkeyShown: false,
                isFightDetailsShown: false,
                isSetStatusShown: false,
                isSetSessionKeyShown: false,
                isAboutBananaShown: false,
                isBuyBananaShown: false,
                isStakeBananaShown: false,
                isBridgeTokensShown: false,
                isExploreJungleShown: false,
                isInventoryShown: false,
                isFarmingShown: false,
                isAboutGameShown: false,
                isWithdrawTokensModalShown: false,
                isPlayerDetailsModalShown: false,
                isEasterEggGameShown: false
            },
            joyrideSteps: [{
                target: '.feedMonkeyButton',
                content: t('home.joyride.feedYourMonkey'),
                disableBeacon: true
            }, {
                target: '.showMoreOptionsButton',
                content: t('home.joyride.seeMoreOptions')
            }, {
                target: '.MonkeysCard-scrollableList',
                content: t('home.joyride.clickFistButtonToFight')
            }, {
                target: '.readMoreButton',
                content: t('home.joyride.readMoreOrJoinTelegram')
            }],
            shouldJoyrideStart: false,
            isFetchingAccountDetails: false,
            isFetchingMonkeysList: false,
            fetchingAccountDetailsError: undefined,
            fetchingMonkeysListError: undefined,
            showsFullXPAmount: false,
            fightFailedReason: undefined,
            fightResult: undefined,
            latestFightLogs: undefined
        };

        this.fightMonkey = this.fightMonkey.bind(this);
        this.fetchAccountDetails = this.fetchAccountDetails.bind(this);
        this.fetchLatestFightLogs = this.fetchLatestFightLogs.bind(this);
        this.fetchMonkeys = this.fetchMonkeys.bind(this);
        this.resetFightDetails = this.resetFightDetails.bind(this);
        this.replaceOutdatedAccount = this.replaceOutdatedAccount.bind(this);
        this.openPlayerDetails = this.openPlayerDetails.bind(this);
        this.setNewMonkeyAccount = this.setNewMonkeyAccount.bind(this);
        this.setModalState = this.setModalState.bind(this);
    };

    // Fetching
    fetchAccountDetails() {
        const accountName = this.session.accountName;

        // Avoid displaying the loading indicator when auto-refreshed
        if (this.state.fetchingAccountDetailsError || this.state.monkey === undefined) {
            this.setState({
                isFetchingAccountDetails: true,
                fetchingAccountDetailsError: undefined // The function is called on retry too
            });
        }

        this.rpc.fetchMonkeyDetails(accountName)
            .then(obj => {
                if (obj) {
                    const monkey = new MonkeyAccount(obj);

                    this.setNewMonkeyAccount(monkey, () => {
                        monkey.lostFight && this.fetchLatestFightLogs();
                    });

                    this.setState({isFetchingAccountDetails: false});
                }
                else {
                    this.setState({monkey: null, isFetchingAccountDetails: false});
                }
            })
            .catch(error => {
                this.setState({
                    isFetchingMonkeysList: false,
                    fetchingAccountDetailsError: error
                });
            });
    };

    fetchMonkeys() {
        // Avoid displaying the loading indicator when auto-refreshed
        if (this.state.fetchingMonkeysListError || !this.state.monkeys.length) {
            this.setState({
                isFetchingMonkeysList: true,
                fetchingMonkeysListError: undefined // The function is called on retry too
            });
        }
        
        this.rpc.fetchAllMonkeys()
            .then(monkeys => monkeys.map(obj => new MonkeyAccount(obj)))
            .then(this.replaceOutdatedAccount)
            .then(setDominanceRanks)
            .then(monkeys => { this.setState({monkeys}); return monkeys; })
            .then(monkeys => this.setMonkeyUsingAllMonkeys(monkeys)) // We'll use this opportunity to update our own account too
            .catch(error => this.setState({fetchingMonkeysListError: error}))
            .finally(() => this.setState({isFetchingMonkeysList: false}));
    };

    fetchInventoryAssets() {
        const accountName = this.session.accountName;

        this.rpc.fetchInventoryAssets(accountName)
            .then(assetsRaw => {
                const inventoryAssets = assetsRaw
                    .map(obj => new InventoryAsset(obj))
                    .filter(asset => asset.quantity > 0);

                this.setSharedState({inventoryAssets});
            })
            .catch((err) => console.log("Could not fetch inventory assets", err));
    };

    fetchLatestFightLogs() {
        if (this.state.monkey && !this.isFetchingFightLogs) {
            this.isFetchingFightLogs = true; // Might get triggered mutliple times

            this.rpc.fetchLatestFightLogs(this.state.monkey.name)
                .then(logs => {
                    // Logs contain also successful fights
                    const lossesOnly = logs.filter(log => (parseFloat(log['damage']) > 0));

                    // Set an empty array to prevent indefinite fetching in case of when
                    // the lost is too far in the history (no relevant data returned)
                    this.setState({latestFightLogs: lossesOnly});
                })
                .finally(() => {
                    this.isFetchingFightLogs = false;
                });
        }
    }

    // Transactions
    fightMonkey(opponent) {
        if (!this.state.monkey?.bananasAmount) {
            alert(t('home.misc.feedMonkeyBeforeFighting'));
            return
        }

        this.setModalState({isFightDetailsShown: true});

        this.trxAPI.fight(this.state.monkey, opponent, {isErrorSupressable: true})
            .then(result => {
                // Execute with a little delay as we're showing a fight gif
                // but it also takes a time for the node to reply with the
                // current data
                setTimeout(() => {
                    // The 1st action could be a noop (e.g. greymass fuel)
                    const returnValueData = result.response.processed.action_traces.find(action => {
                        return (action['return_value_data'] !== undefined)
                    })['return_value_data'];

                    const fightResult = new FightResult(returnValueData);

                    this.setState({fightResult: fightResult, latestFightLogs: undefined});
                    this.fetchAccountDetails(); // refresh the score
                }, randomNumber(2500, 4000));
            })
            .catch(([error, showDefaultError]) => {
                if (error.message.includes('weight mismatches')) {
                    this.setState({fightFailedReason: 'WEIGHT_MISMATCH'});
                }
                else {
                    this.setModalState({isFightDetailsShown: false});
                    showDefaultError();
                }
            });
    };

    // Components
    getProfileContent() {
        const session = this.session;

        if (session) {
            // The user can be logged in, but their monkey might not exist yet
            const monkey = this.state.monkey;
            const bananasAmount = (monkey?.bananasAmount ?? 0);
            const bananaPeels = (monkey?.bananaPeels ?? 0);
            const showsFullXPAmount = this.state.showsFullXPAmount;
            const xpAmount = (monkey?.xp ?? 0);
            const canFight = (monkey?.canFight || false);
            const fightSummary = extractFightsSummary(this.state.latestFightLogs);
            
            return (
                <Stack style={{gap: '3vh'}}>
                    <Stack className="ProfileCard-layoutStack">
                        <Stack style={{alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}>
                            <MonkeySilhouette monkey={monkey} onMonkeyDetailsChanged={this.setNewMonkeyAccount}/>

                            { canFight &&
                                <span className="ProfileCard-greenStatus">{t('home.profile.openToFight')}</span>
                            }

                            { fightSummary &&
                                <div className="ProfileCard-lastFightInfoContainer">
                                    <Stack direction="horizontal" className="detailRow">
                                        <div className="detailTitle">{t('home.profile.lastOpponent')}:</div><a href={accountDetailsURL(fightSummary.opponent, session.chain)} target="_blank" rel="noreferrer">{fightSummary.opponent}</a>
                                    </Stack>

                                    <Stack direction="horizontal" className="detailRow">
                                        <div className="detailTitle">{t('home.profile.damage')}:</div><BananaAmount amount={fightSummary.damage} style={{display: 'inline-flex'}}/>
                                    </Stack>
                                </div>
                            }
                        </Stack>

                        <Stack style={{flex: 0, alignItems: 'center'}}>
                            <h3 className="ProfileCard-accountName" style={{cursor: 'pointer'}} onClick={this.openPlayerDetails}>{session.accountName}</h3>

                            <div className="ProfileCard-accountAmounts">
                                <BananaAmount amount={bananasAmount}/>
                            </div>
                            
                            <div className="ProfileCard-accountAmounts">
                                <PeelAmount amount={bananaPeels}/>
                            </div>

                            <div className="ProfileCard-accountAmounts" onClick={() => this.setState({showsFullXPAmount: !showsFullXPAmount})} style={{cursor: 'pointer'}}>
                                <XPAmount amount={xpAmount} compactFormatting={!showsFullXPAmount}/>
                            </div>
                        </Stack>
                    </Stack>

                    <Stack style={{flex: 0}}>
                        <Stack direction="horizontal" style={{gap: '6px'}}>
                            <Button variant="primary" className="ProfileCard-actionButton feedMonkeyButton" onClick={() => this.setModalState({isFeedMonkeyShown: true})}>{t('home.profile.feed')}</Button>
                            <Button variant="primary" className="ProfileCard-actionButton" disabled={!bananasAmount} onClick={() => this.setModalState({isStarveMonkeyShown: true})}>{t('home.profile.starve')}</Button>
                            
                            {this.getDropdownMoreMenu()}
                        </Stack>

                        <div className="ProfileCard-footerSubtitle">
                            <Trans i18nKey="home.profile.clickHereToAcquireBananas">
                                <button className="buttonTextLink" onClick={() => this.setModalState({isBuyBananaShown: true})}>Click here</button> to acquire bananas
                            </Trans>
                        </div>
                    </Stack>
                </Stack>
            );
        }
        else {
            return (
                <>
                    <img src={sadMonkeyImage} className="col-6 col-md-8 col-xxl-8" alt="sad-monkey"/>

                    <div style={{textAlign: 'center'}}>
                        <Trans i18nKey="home.profile.loginAndFeedYourMonkey">
                            <b>Log in</b> and let your poor monkey eat some bananas. Don't be like that.
                        </Trans>
                    </div>

                    <div style={{textAlign: 'center'}}>
                        <Trans i18nKey="home.profile.onlyHereForBananaTokenInfo">
                            Or are you here solely for information on the tasty <img src={bananaCoinLogo} alt="banana-logo" style={{height: '1.3rem', verticalAlign: 'middle'}}/> <b>$BANANA</b>? In that case <button className="buttonTextLink" onClick={() => { this.setModalState({isAboutBananaShown: true})}}>click here</button>!
                        </Trans>
                    </div>
                </>
            );
        }
    };

    getDropdownMoreMenu() {
        return (
            <DropdownButton title={t('home.profile.more')} drop="up" className="ProfileCard-actionButton showMoreOptionsButton">
                <Dropdown.Item onClick={() => this.setModalState({isAboutBananaShown: true})}>{t('home.profile.aboutBanana')}</Dropdown.Item>

                { (this.sharedState.isTMA && isMobileDevice()) &&
                    <Dropdown.Item onClick={() => this.setModalState({isEasterEggGameShown: true})}>Play Baanaanaaa 🍌</Dropdown.Item>
                }

                <Dropdown.Divider/>
                <Dropdown.Item onClick={() => this.setModalState({isBuyBananaShown: true})}>{t('home.profile.buyBanana')}</Dropdown.Item>
                
                { this.isRunningOnEOS &&
                    <Dropdown.Item onClick={() => this.setModalState({isStakeBananaShown: true})}>{t('home.profile.stakeBanana')}</Dropdown.Item>
                }
                
                { this.isRunningOnNative &&
                    <Dropdown.Item onClick={() => this.setModalState({isWithdrawTokensModalShown: true})}>{t('home.profile.withdrawPeel')}</Dropdown.Item>
                }

                { this.isRunningOnNative &&
                    <Dropdown.Item onClick={() => this.setModalState({isBridgeTokensShown: true})}>{t('home.profile.bridgeTokens')}</Dropdown.Item>
                }

                { !this.isRunningOnNative &&
                    <Dropdown.Item onClick={() => this.setModalState({isWithdrawTokensModalShown: true})}>{t('home.profile.withdrawBanana')}</Dropdown.Item>
                }
                
                <Dropdown.Divider/>
                
                <Dropdown.Item onClick={() => this.setModalState({isFarmingShown: true})}>{t('home.profile.farming')}</Dropdown.Item>
                <Dropdown.Item onClick={() => this.setModalState({isInventoryShown: true})}>{t('home.profile.inventory')}</Dropdown.Item>
                <Dropdown.Item onClick={() => this.setModalState({isExploreJungleShown: true})}>{t('home.profile.exploreJungle')}</Dropdown.Item>

                { (this.isRunningOnNative || this.state.monkey) &&
                    <Dropdown.Divider/>
                }

                { this.isRunningOnNative &&
                   <Dropdown.Item onClick={() => this.setModalState({isSetSessionKeyShown: true})}>Session Key</Dropdown.Item>
                }

                {/*
                    Only allow the user to set a status if the monkey account already exists in the table.
                    This is especially important in case of an EVM since an account must be created for
                    the status to be set. For native users it doesn't matter, but the player won't be
                    visible in the list of all players regardless (as no balance is available)
                */}
                { this.state.monkey &&
                    <Dropdown.Item onClick={() => this.setModalState({isSetStatusShown: true})}>{t('home.profile.setMyStatus')}</Dropdown.Item>
                }
            </DropdownButton>
        );
    };

    getMonkeysListContent() {
        const myMonkey = this.state.monkey;
        const listItems = this.state.monkeys
            .sort((m, m2) => { return (m2.bananasAmount - m.bananasAmount) })
            .flatMap(monkey => {
                // The user might be logged out so make it optional. Also,
                // filter zero balances out
                if (monkey.name === myMonkey?.name || !monkey.bananasAmount) {
                    return [];
                }

                return (
                    <MonkeyListItem
                        key={monkey.name}
                        myMonkey={myMonkey}
                        monkey={monkey}
                        onFightPicked={this.fightMonkey}
                    />
                );
            });

        this.lastKnownMonkeysScrollPosition = this.monkeysList?.scrollTop;

        return (
            <>
                <div style={{textAlign: 'center', position: 'relative'}}>
                    <h5 style={{fontWeight: 700}}>{t('home.monkeysList.chooseYourOpponent')}</h5>
                    <div style={{fontSize: '0.8rem', padding: '0 1rem 0 1rem'}}>
                        <Trans i18nKey="home.monkeysList.fightAndGetTheirBananas">
                            Fight a monkey and get their bananas! <button className="buttonTextLink readMoreButton" onClick={() => this.setModalState({isAboutGameShown: true})}>Read more...</button>
                        </Trans>
                    </div>
                </div>

                <div className="MonkeysCard-scrollableList" ref={el => this.monkeysList = el}>
                    {listItems}
                </div>
            </>
        );
    };

    // Monkey State Changes
    setMonkeyUsingAllMonkeys(monkeys) {
        // Prefer accepting the new array of monkeys rather than
        // using `state.monkeys`, which might not be current yet.
        // Issues arise especially when dealing with various chains
        if (this.isUserLoggedIn) {
            const accountName = this.session.accountName;
            const myMonkey = monkeys.find(monkey => (monkey.name === accountName));

            // Avoid setting up `undefined` and possibly overriding `null`
            // for the user that is logged in without any monkey registered yet
            if (myMonkey && !this.state.monkey?.isNewerThan(myMonkey)) {
                this.setState({monkey: myMonkey});
            }
        }
    };

    setNewMonkeyAccount(monkey, cb) {
        this.setState({monkey}, () => {
            // New details could be fetched which are not in sync with the details
            // shown in the list. Since we're utilising the list data, prevent
            // using outdated details
            const monkeys = this.replaceOutdatedAccount(this.state.monkeys);
            this.setState({monkeys: setDominanceRanks(monkeys)});
            cb?.()
        });
    };

    replaceOutdatedAccount(monkeys) {
        const myMonkey = this.state.monkey;

        if (!myMonkey) {
            return monkeys;
        }

        const index = monkeys.findIndex(monkey => (monkey.name === myMonkey.name));

        if (index > -1 && myMonkey.isNewerThan(monkeys[index])) {
            monkeys[index] = myMonkey;
        }

        return monkeys;
    };

    // Misc
    setModalState(obj, cb) {
        this.setState(prevState => ({
            ...prevState,
            modals: {
                ...this.state.modals,
                ...obj
            }
        }), cb);
    };

    resetFightDetails() {
        this.setModalState({isFightDetailsShown: false}, () => {
            // Change once hidden, so the content won't change back while still present
            setTimeout(() => this.setState({fightFailedReason: undefined, fightResult: undefined}), 600);
        });
    };

    openPlayerDetails() {
        if (this.state.monkey) {
            this.setModalState({isPlayerDetailsModalShown: true});
        }
        else {
            const url = accountDetailsURL(this.session.accountName, this.session.chain);
            window.open(url);
        }
    };

    // React
    componentDidMount() {
        // Fetch list
        this.fetchMonkeys();

        // Auto-refresh
        this.refreshMonkeysInterval = setInterval(() => {
            if (!this.state.fetchingMonkeysListError) {
                this.fetchMonkeys();
            }
        }, this.#REFRESH_INTERVAL);
    };

    componentDidUpdate() {
        const monkey = this.state.monkey;

        // The user logged out
        if (!this.isUserLoggedIn && (monkey || monkey === null)) {
            this.setState({
                monkey: undefined,
                latestFightLogs: undefined
            });
        }
        else if (this.isUserLoggedIn && monkey === undefined && !this.state.isFetchingAccountDetails) { // Logged back in
            this.fetchInventoryAssets(); // Required by certain sub-components
            this.fetchAccountDetails();
            this.fetchMonkeys(); // Blockchains could change
        }

        if (this.state.monkey?.lostFight && !this.state.latestFightLogs) {
            this.fetchLatestFightLogs();
        }

        // Joyride (introduction) start
        if (this.isJoyrideRequired && this.isUserLoggedIn && !this.state.shouldJoyrideStart) {
            const existingPlayer = !!monkey; // Ignore already existing users
            const isFetchingData = (this.state.isFetchingAccountDetails || monkey === undefined); // The UI components will be missing
            const isJoyrideReady = (this.isRunningOnNative || this.evmSession?.isFullyInitialized || this.solanaSession?.isFullyInitialized);

            if (!existingPlayer && isJoyrideReady && !isFetchingData) {
                this.isJoyrideRequired = false;
                //Set a little timeout to make sure the components are mounted
                this.setState({shouldJoyrideStart: true});
            }
        }

        // Keep the scroll position of the monkeys list
        // up to date when data updates
        if (this.lastKnownMonkeysScrollPosition && this.monkeysList) {
            this.monkeysList.scrollTop = this.lastKnownMonkeysScrollPosition;
        }

        // We can't checked the data before we know the app runs
        // as TMA or it could cash crash
        if (this.sharedState.isTMA && isMobileDevice() && !this.tgInitDataChecked) {
            if (initInitData().startParam) {
                this.setModalState({isEasterEggGameShown: true});
            }

            this.tgInitDataChecked = true;
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshMonkeysInterval);
    };

    render() {
        return (
            <>
                {/* Modals */}
                <FeedMonkeyModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isFeedMonkeyShown}
                    onCloseClicked={() => this.setModalState({isFeedMonkeyShown: false})}
                    onTokensDeposited={this.setNewMonkeyAccount}
                />

                <StarveMonkeyModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isStarveMonkeyShown}
                    onCloseClicked={() => this.setModalState({isStarveMonkeyShown: false})}
                    onTokensWithdrawn={this.setNewMonkeyAccount}
                />

                <FightDetailsModal
                    isShown={this.state.modals.isFightDetailsShown}
                    onCloseClicked={this.resetFightDetails}
                    fightFailedReason={this.state.fightFailedReason}
                    fightResult={this.state.fightResult}
                />

                <SetStatusModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isSetStatusShown}
                    onCloseClicked={() => this.setModalState({isSetStatusShown: false})}
                />

                <SetSessionKeyModal
                    isShown={this.state.modals.isSetSessionKeyShown}
                    onCloseClicked={() => this.setModalState({isSetSessionKeyShown: false})}
                />
                
                <AboutBananaModal
                    isShown={this.state.modals.isAboutBananaShown}
                    onCloseClicked={() => this.setModalState({isAboutBananaShown: false})}
                />

                <BuyBananaModal
                    isShown={this.state.modals.isBuyBananaShown}
                    onCloseClicked={() => this.setModalState({isBuyBananaShown: false})}
                />
                
                <StakeBananaModal
                    isShown={this.state.modals.isStakeBananaShown}
                    onCloseClicked={() => this.setModalState({isStakeBananaShown: false})}
                />
                
                <BridgeTokensModal
                    isShown={this.state.modals.isBridgeTokensShown}
                    onCloseClicked={() => this.setModalState({isBridgeTokensShown: false})}
                />

                <ExploreJungleModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isExploreJungleShown}
                    onCloseClicked={() => this.setModalState({isExploreJungleShown: false})}
                />

                <InventoryModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isInventoryShown}
                    onCloseClicked={() => this.setModalState({isInventoryShown: false})}
                    onMonkeyDetailsChanged={this.setNewMonkeyAccount}
                />
                
                <FarmingModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isFarmingShown}
                    onCloseClicked={() => this.setModalState({isFarmingShown: false})}
                />

                <AboutGameModal
                    isShown={this.state.modals.isAboutGameShown}
                    onCloseClicked={() => this.setModalState({isAboutGameShown: false})}
                />
                
                <WithdrawTokensModal
                    monkey={this.state.monkey}
                    isShown={this.state.modals.isWithdrawTokensModalShown}
                    token={this.isRunningOnNative ? 'PEEL' : 'BANANA'}
                    onCloseClicked={() => this.setModalState({isWithdrawTokensModalShown: false})}
                />
                
                { this.state.monkey &&
                    <PlayerDetailsModal
                        isShown={this.state.modals.isPlayerDetailsModalShown}
                        monkey={this.state.monkey}
                        onCloseClicked={() => this.setModalState({isPlayerDetailsModalShown: false})}             
                    />
                }
                
                <EasterEggModal
                    isShown={this.isEasterEggGameShown}
                    onCloseClicked={() => {
                        this.setModalState({isEasterEggGameShown: false});
                    }}             
                />

                <EVMLoginModal/>
                <SolanaLoginModal/>

                {/* Joyride */}
                <Joyride
                    steps={this.state.joyrideSteps}
                    run={this.state.shouldJoyrideStart}
                    continuous
                    disableOverlayClose
                    disableScrolling
                    hideBackButton
                    showProgress
                    showSkipButton
                    hideCloseButton
                    styles={{
                        tooltip: {
                            borderRadius: '8px'
                        },
                        buttonNext: {
                            backgroundColor: 'var(--yellow-primary)',
                            color: 'black',
                            border: '1px solid black',
                            borderRadius: '3rem',
                            textTransform: 'uppercase',
                            paddingRight: '1rem',
                            paddingLeft: '1rem'
                        },
                        buttonSkip: {
                            textTransform: 'uppercase'
                        }
                    }}
                />

                {/* Main Content */}
                <Stack className="Home-mainStack col-12 col-md-10 col-xl-8 col-xxl-6 px-3 px-md-0 mx-auto" gap={3}>
                    <div className="Home-profileCard cardContainer">
                        <Stack direction="row">
                            { this.state.isFetchingAccountDetails && <PulseLoader loading={true}/> }
                            { this.state.fetchingAccountDetailsError && <DetailedError message={this.state.fetchingAccountDetailsError.message} onRetry={this.fetchAccountDetails}/> }
                            { (!this.state.isFetchingAccountDetails && !this.state.fetchingAccountDetailsError) && this.getProfileContent() }
                        </Stack>
                    </div>

                    <Stack className="Home-monkeysCard cardContainer">
                        { (this.state.isFetchingMonkeysList || this.state.fetchingMonkeysListError) &&
                            <Stack style={{placeItems: 'center', justifyContent: 'center'}}>
                                { this.state.isFetchingMonkeysList && <PulseLoader loading={true}/> }
                                { this.state.fetchingMonkeysListError && <DetailedError message={this.state.fetchingMonkeysListError.message} onRetry={this.fetchMonkeys}/> }
                            </Stack>
                        }

                        { (!this.state.isFetchingMonkeysList && !this.state.fetchingMonkeysListError) && this.getMonkeysListContent() }
                    </Stack>
                </Stack>
            </>
        );
    };
}

// Helpers
function setDominanceRanks(monkeys) {
    const ranking = monkeys
        .flatMap(monkey => {
            // Exclude monkeys without bananas, which aren't even shown in the list
            if (!monkey.bananasAmount) {
                return [];
            }

            const wlRatio = monkey.wlRatio;
            let rank = undefined;

            if (wlRatio < 0.5 && monkey.totalFights > 0) {
                rank = -1; // underdog
            }
            else if (wlRatio) {
                rank = wlRatio;
            }
            else {
                rank = 0; // no record yet
            }

            return {
                monkeyName: monkey.name,
                rank: rank,
                xp: monkey.xp,
                order: (rank === -1) ? -1 : undefined // Automatically out of actual order
            };
        })
        .sort((a, b) => {
            if (a.rank === b.rank && a.rank > 0) {
                // If ranks happens to be the same, let the xp decide on order
                return (b.xp - a.xp);
            }
            else {
                return (b.rank - a.rank);
            }
        });

        // Set dominance order based on ranking
        for (let i = 0; i < ranking.length; i++) {
            if (ranking[i].rank <= 0) {
                break;
            }

            ranking[i].order = i;
        }

        // Set dominance for each monkey
        const ranksObject = ranking.reduce((obj, rankInfo) => {
            obj[rankInfo.monkeyName] = rankInfo.order;
            return obj;
        }, {});

        monkeys.forEach(monkey => {
            monkey.dominanceOrder = ranksObject[monkey.name];
        });

    return monkeys;
};

function extractFightsSummary(fightLogs) {
    if (!fightLogs || !fightLogs.length) {
        return undefined;
    }

    // We only allow 1 duel
    return {
        damage: parseFloat(fightLogs[0]['damage']),
        opponent: fightLogs[0]['opponent']
    }
};

export default commonContextsWrapper(Home);
