import Stack from 'react-bootstrap/Stack';
import { formatAmount, formatAmountCompactly } from '../../Helpers/Utils';
import bananaPeelIcon from '../../Assets/BananaPeelIcon.png';

export default function PeelAmount(props) {
    const amountFormatted = props.compactFormatting ? formatAmountCompactly(props.amount) : formatAmount(props.amount);
    
    return (
        <Stack direction="horizontal" style={{...{gap: '4px'}, ...props.style}}>
            <img src={bananaPeelIcon} alt="banana-peel-icon" style={{height: '1.3rem'}}/>
            <div style={props.textStyle}>{amountFormatted}</div>
        </Stack>
    );
}
