import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import { t, Trans } from '../../i18n';
import { BananaRangeSlider } from '../Widgets/TokenRangeSlider';
import JungleExplorerDetails from '../../Models/JungleExplorerDetails';
import InventoryAsset from '../../Models/InventoryAsset';
import EquipmentAsset from '../../Models/EquipmentAsset';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import inventoryAssetTypes from '../../Helpers/InventoryAssetTypes';
import { formatBananasTokenAmount } from '../../Helpers/Utils';
import { GAME_CONSTANTS } from '../../config';

class ExploreJungleModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }

    get trxAPI() {
        return this.props.trxAPI;
    }
    
    get rpc() {
        return this.sharedState.rpc;
    }

    get isIntroRequired() {
        const value = localStorage.getItem('exploreJungleIntroRequired');
        return (value === null || value === 'true'); // Stored as a String
    }

    set introRequired(value) {
        localStorage.setItem('exploreJungleIntroRequired', value);
        this.setState({showIntro: value});
    }

    get allAssetsCombined() {
        const inventoryAssets = (this.sharedState?.inventoryAssets || []);
        const equipmentAssets = (this.state.equipment || []);
        return [...inventoryAssets, ...equipmentAssets];
    };

    constructor(props) {
        super(props);

        this.refreshTimeRemainingInterval = undefined;

        this.state = {
            bananasAmount: undefined,
            inventoryAssets: undefined,
            equipment: undefined,
            explorerDetails: undefined,
            foundAssets: undefined,
            showIntro: undefined,
            isFetchingData: false,
            isTransacting: false
        };

        this.BodyContentTypes = {
            FETCHING_DATA: 0,
            INTRO: 1,
            EXPLORING_JUNGLE: 2,
            XP_OR_BANANA_REQUIRED: 3,
            EXPLORATION_COMPLETED: 4,
            SHOW_FOUND_ASSETS: 5,
            INVENTORY_FULL: 6,
            CRAFTING_IN_PROGRESS: 7,
            SELECT_BANANA_AMOUNT: 8
        };

        this.setBananaAmount = this.setBananaAmount.bind(this);
        this.exploreJungle = this.exploreJungle.bind(this);
        this.showFoundAssets = this.showFoundAssets.bind(this);
        this.refreshTimeRemaining = this.refreshTimeRemaining.bind(this);
        this.showMainPage = this.showMainPage.bind(this);
        this.close = this.close.bind(this);
    }

    // Transactions
    showFoundAssets() {
        this.setState({isTransacting: true});

        // Make it loading for a second longer as the data then fetched
        // (for a refresh) will not reflect the real values yet
        this.trxAPI.showFoundAssets()
            .then(result => {
                // The 1st action could be a noop (e.g. greymass fuel)
                const foundAssets = result.response.processed
                    .action_traces
                    .find(action => { return (action['return_value_data'] !== undefined) })['return_value_data']
                    .map(inventoryAssetTypes.typeFromAssetID);

                this.setState({
                    foundAssets: foundAssets,
                    isTransacting: false
                });
            })
            .catch(() => this.setState({isTransacting: false}));
    }

    exploreJungle() {
        this.setState({isTransacting: true});

        const bananasAmount = (this.state.bananasAmount || GAME_CONSTANTS.MIN_ENERGY_EXPLORING);
        const bananasAmountFormatted = formatBananasTokenAmount(bananasAmount);

        this.trxAPI.exploreJungle(bananasAmountFormatted, {responseCallback: true})
            .then(([,responseCallback]) => {
                // The current data might not be available right away so
                // keep checking until they are (1-3s)
                this.fetchExplorerDetailsUntilAvailable()
                    .then(explorerDetails => this.setState({explorerDetails}))
                    .catch(() => this.close())
                    .finally(() => {
                        responseCallback();
                        this.setState({isTransacting: false});
                    });
            })
            .catch(() => this.setState({isTransacting: false}));
    };

    // Data Fetching
    fetchExplorerDetailsUntilAvailable() {
        const accountName = this.session.accountName;
        const rpc = this.rpc;

        return new Promise((resolve, reject) => {
            function fetch() {
                rpc.fetchExplorerDetails(accountName)
                    .then(obj => {
                        obj ? resolve(new JungleExplorerDetails(obj)) : setTimeout(fetch, 600);
                    })
                    .catch(reject);
            }

            setTimeout(fetch, 800);
        });
    };

    fetchData() {
        this.setState({isFetchingData: true});

        const accountName = this.session.accountName;
        const fetchRequests = [
            this.rpc.fetchInventoryAssets(accountName),
            this.rpc.fetchEquipmentAssets(accountName),
            this.rpc.fetchExplorerDetails(accountName)
        ];

        Promise.all(fetchRequests)
            .then(([assetsRaw, equipmentRaw, explorerDetails]) => {
                const inventoryAssets = assetsRaw.map(obj => new InventoryAsset(obj));
                const equipment = equipmentRaw.map(obj => new EquipmentAsset(obj));

                this.setState({
                    isFetchingData: false,
                    inventoryAssets: inventoryAssets,
                    equipment: equipment,
                    explorerDetails: new JungleExplorerDetails(explorerDetails)
                });
            })
            .catch(() => this.close());
    };

    // Components
    modalBodyContent() {
        const ContentType = this.BodyContentTypes;
        const currentType = this.modalBodyContentType();

        switch (currentType) {
            case ContentType.FETCHING_DATA:
                return (
                    <div style={{textAlign: 'center'}}>
                        <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                    </div>
                );
            case ContentType.INTRO:
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        <Trans i18nKey="modals.exploreJungle.explorationExplainedMessage" components={{1: <br/>}}/>
                    </p>
                );
            case ContentType.EXPLORING_JUNGLE:
                return (
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontWeight: 300}}>{t('modals.exploreJungle.backFromExplorationIn')}:</div>
                        <div style={{marginTop: '0.75rem', fontSize: '1.5rem'}}>{calcTimeRemaining(this.state.explorerDetails.exploringUntil)}</div>
                    </div>
                );
            case ContentType.XP_OR_BANANA_REQUIRED:
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        <Trans
                            i18nKey="modals.exploreJungle.minRequirementsForExplorationMessage"
                            values={{bananaRequired: GAME_CONSTANTS.MIN_BANANA_REQUIRED_EXPL, xpRequired: GAME_CONSTANTS.MIN_XP_REQUIRED_EXPL}}
                            components={{1: <b></b>}}
                        />
                    </p>
                );
            case ContentType.EXPLORATION_COMPLETED:
                return (
                    <Stack style={{alignItems: 'center'}}>
                        <p style={{textAlign: 'center', marginBottom: 0}}>
                            {t('modals.exploreJungle.monkeyBackFromExplorationMessage')}
                        </p>

                        <Button variant="primary" onClick={this.showFoundAssets} disabled={this.state.isTransacting} style={{width: '75%', fontSize: '0.8rem', marginTop: '2rem'}}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.exploreJungle.showFoundAssets')}</span>
                                </>
                                :
                                t('modals.exploreJungle.showFoundAssets')
                            }
                        </Button>
                    </Stack>
                );
            case ContentType.SHOW_FOUND_ASSETS:
                if (this.state.foundAssets.length) {
                    return this.foundAssetsListed();
                }

                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        {t('modals.exploreJungle.nothingFoundMessage')}
                    </p>
                );
            case ContentType.INVENTORY_FULL:
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        {t('modals.exploreJungle.inventoryFullMessage')}
                    </p>
                );
            case ContentType.CRAFTING_IN_PROGRESS:
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        {t('modals.exploreJungle.mustFinishCraftingBeforeExploringMessage')}
                    </p>
                );
            case ContentType.SELECT_BANANA_AMOUNT:
                return (
                    <Stack direction="vertical" style={{alignItems: 'center'}}>
                        <div style={{textAlign: 'center', fontWeight: '300'}}>{t('modals.exploreJungle.selectBananasConsumedForExploration')}:</div>
                        <BananaRangeSlider min={GAME_CONSTANTS.MIN_ENERGY_EXPLORING} max="10" step="1" defaultValue={GAME_CONSTANTS.MIN_ENERGY_EXPLORING} onValueChanged={this.setBananaAmount} style={{width: '80%', marginTop: '48px'}}/>
                    </Stack>
                );
            default:
                return <></>;
        }
    };

    foundAssetsListed() {
        // There might be the same type of asset listed multiple times
        const assetsCount = this.state.foundAssets
            .reduce((obj, assetType) => {
                obj[assetType] = (obj[assetType] ?? 0) + 1;
                return obj;
            }, {});

        let foundAssetsFormatted = [];

        for (const assetType in assetsCount) {
            const assetCount = assetsCount[assetType];
            const assetDetails = inventoryAssetTypes.assetDetails(assetType);

            foundAssetsFormatted.push({
                name: assetDetails.name,
                icon: assetDetails.icon,
                count: assetCount
            });
        }

        return (
            <Stack style={{alignItems: 'center', gap: '8px'}}>
                <div style={{fontWeight: 700, marginBottom: '16px'}}>Found Assets:</div>

                {
                    foundAssetsFormatted.map(asset => {
                        return (
                            <Stack direction="horizontal" style={{alignSelf: 'center', gap: '12px'}} key={asset.name}>
                                <img src={asset.icon} alt={asset.name + '-icon'} style={{width: '50px'}}/>
                                <div style={{fontWeight: 400}}>{asset.name} x {asset.count}</div>
                            </Stack>
                        );
                    })
                }
            </Stack>
        );
    };

    // Inventory Assets Operations
    allAssetsCount() {
        // Use `ceil` to round up values like `0.33 EQP` which
        // is actually considered being 1 asset (equipment)
        return this.allAssetsCombined.reduce((count, asset) => (Math.ceil(asset.quantity) + count), 0);
    };

    isCrafting() {
        const craftedAsset = this.allAssetsCombined.find(asset => asset.isBeingCrafted);
        return (craftedAsset !== undefined);
    };

    // Misc
    modalBodyContentType() {
        const isExploringJungle = (this.state.explorerDetails?.isExploring || false);
        const isExplorationCompleted = this.state.explorerDetails?.isExplorationCompleted;
        const showFoundAssets = (this.state.foundAssets !== undefined);

        // The order matters!
        if (this.state.isFetchingData) {
            return this.BodyContentTypes.FETCHING_DATA;
        }

        if (showFoundAssets) {
            return this.BodyContentTypes.SHOW_FOUND_ASSETS;
        }

        if (isExploringJungle) {
            return this.BodyContentTypes.EXPLORING_JUNGLE;
        }

        if (isExplorationCompleted) {
            return this.BodyContentTypes.EXPLORATION_COMPLETED;
        }

        // Warnings/info shown when not exploring and exploration not completed
        const hasRequiredAmountOfBananas = (this.props.monkey?.bananasAmount >= GAME_CONSTANTS.MIN_BANANA_REQUIRED_EXPL);
        const hasRequiredXP = (this.props.monkey?.xp >= GAME_CONSTANTS.MIN_XP_REQUIRED_EXPL);
        const isInventoryFull = (this.allAssetsCount() >= GAME_CONSTANTS.MAX_INVENTORY_ASSETS);

        if (this.state.showIntro) {
            return this.BodyContentTypes.INTRO;
        }

        if (!hasRequiredAmountOfBananas || !hasRequiredXP) {
            return this.BodyContentTypes.XP_OR_BANANA_REQUIRED;
        }

        if (isInventoryFull) {
            return this.BodyContentTypes.INVENTORY_FULL;
        }

        if (this.isCrafting()) {
            return this.BodyContentTypes.CRAFTING_IN_PROGRESS;
        }

        // If none of the condition have been met, we let the monke explore
        return this.BodyContentTypes.SELECT_BANANA_AMOUNT
    };

    showMainPage() {
        this.introRequired = false;
    };

    refreshTimeRemaining() {
        // The property is not actually used, but it makes sure
        // that the render func gets called and data is reloaded
        const timeRemaining = calcTimeRemaining(this.state.explorerDetails.exploringUntil);
        this.setState({refreshTimeRemaining: timeRemaining});
    };

    setBananaAmount(amount) {
        this.setState({bananasAmount: amount});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                this.fetchData();
                this.setState({showIntro: this.isIntroRequired});
                ReactGA.send({hitType: 'pageview', page: 'exploreJungle-modal'});
            }
            else {
                // Make the modal disappear and then change
                // props which affect content that is shown
                setTimeout(() => {
                    this.setState({
                        bananasAmount: undefined,
                        inventoryAssets: undefined,
                        equipment: undefined,
                        explorerDetails: undefined,
                        foundAssets: undefined
                    });
                }, 800);
            }
        }

        if (this.modalBodyContentType() === this.BodyContentTypes.EXPLORING_JUNGLE) {
            if (!this.refreshTimeRemainingInterval) {
                this.refreshTimeRemainingInterval = setInterval(this.refreshTimeRemaining, 1000);
            }
        }
        else if (this.refreshTimeRemainingInterval) {
            this.refreshTimeRemainingInterval = clearInterval(this.refreshTimeRemainingInterval);
        }
    };

    render() {
        const ContentType = this.BodyContentTypes;
        const contentType = this.modalBodyContentType();
        const showsCancel = [ContentType.INTRO, ContentType.EXPLORATION_COMPLETED, ContentType.SELECT_BANANA_AMOUNT].includes(contentType);

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.exploreJungle.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.modalBodyContent()}
                </Modal.Body>

                <Modal.Footer>
                    { !this.state.isFetchingData &&
                        <Button variant="secondary" onClick={this.close} disabled={this.state.isTransacting}>
                            {showsCancel ? t('common.cancel') : t('common.ok')}
                        </Button>
                    }

                    { (contentType === ContentType.SELECT_BANANA_AMOUNT) &&
                        <Button variant="primary" onClick={this.exploreJungle} disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.exploreJungle.explore')}</span>
                                </>
                                :
                                t('modals.exploreJungle.explore')
                            }
                        </Button>
                    }

                    { (contentType === ContentType.INTRO) &&
                        <Button variant="primary" onClick={this.showMainPage}>{t('modals.exploreJungle.next')}</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default commonContextsWrapper(ExploreJungleModal);

// Helpers
function calcTimeRemaining(endsDate) {
    // `getSeconds`, `getHours` did not report correct
    // times to all users (probably based on the local time)
    const timeRemaining = (endsDate.getTime() - Date.now());
    const seconds = Math.floor((timeRemaining / 1000) % 60);
    const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
    const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
    const padZero = (num) => String(num).padStart(2, '0');

    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
};
