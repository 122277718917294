import React from 'react';
import BananaAmount from './BananaAmount';
import PeelAmount from './PeelAmount';
import './TokenRangeSlider.css';

const SliderType = {
    BANANA: 0,
    PEEL: 1
};

export const BananaRangeSlider = function(props) {
    return RangeSlider(props, SliderType.BANANA);
};

export const PeelRangeSlider = function(props) {
    return RangeSlider(props, SliderType.PEEL);
};

// Generic
function RangeSlider(props, sliderType) {
    const [value, setValue] = React.useState(props.defaultValue || 0);
    const min = props.min;
    const max = props.max;
    const progress = (((value - min) / (max - min)) * 100);
    const bubbleOffset = (8 - (progress * 0.16));
    const TokenAmount = getTokenAmount(sliderType);
    const primaryColor = getPrimaryColor(sliderType);
    const sliderClassName = getStyleClass(sliderType);

    const onRangeInput = (event) => {
        setValue(event.target.value);
        props.onValueChanged(event.target.value);
    };

    return (
        <div style={{...{position: 'relative'}, ...props.style}}>
            <div className="bubble-wrapper" style={{left: `calc(${progress}% + (${bubbleOffset}px))`}}>
                <div className={`bubble-container ${sliderClassName}`}>
                    <TokenAmount amount={value} style={{justifyContent: 'center', height: '100%'}}/>
                </div>
            </div>

            <input
                type="range"
                className={`range-slider ${sliderClassName}`}
                min={props.min}
                max={props.max}
                step={props.step}
                defaultValue={value}
                onInput={onRangeInput}
                style={{background: `linear-gradient(to right, ${primaryColor} ${progress}%, lightgray ${progress}%)`}}
            />
        </div>
    );
}

// Styling
function getPrimaryColor(type) {
    switch (type) {
    case SliderType.BANANA:
        return 'var(--yellow-primary)';
    case SliderType.PEEL:
        return 'var(--brown-peel)';
    default:
        return 'var(--yellow-primary)';
    }
}

function getStyleClass(type) {
    switch (type) {
    case SliderType.BANANA:
        return 'banana';
    case SliderType.PEEL:
        return 'peel';
    default:
        return '';
    }
}

function getTokenAmount(type) {
    switch (type) {
    case SliderType.BANANA:
        return BananaAmount;
    case SliderType.PEEL:
        return PeelAmount;
    default:
        return undefined;
    }
}