import { Chains, ChainDefinition } from '@wharfkit/session';

/* EOSIO Blockchains Config */
const eosioBlockchains = {
    'production': {
        'eos': Chains.EOS,
        'telos': Chains.Telos,
        'wax': ChainDefinition.from({
            // Use different endpoint until wax.greymass resolves issue with get_transaction_status
            // https://t.me/antelopedevs/333769
            id: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
            name: 'WAX',
            url: 'https://wax.eosusa.io'
        })
    },
    'development': {
        'eos': Chains.Jungle4,
        'telos': ChainDefinition.from({ // Must be added manually as the provided one uses wrong url (mainnet's url)
            id: '1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f',
            name: 'Telos (Testnet)',
            url: 'https://telostestnet.greymass.com'
        }),
        'wax': Chains.WAXTestnet
    }
};

/* EVM Blockchains Config */
const evmBlockchains = {
    'production': {
        'tevm': {
            name: 'Telos EVM Mainnet',
            chainID: 40, // 0x28
            nativeCurrency: {name: 'TLOS', decimals: 18, symbol: 'TLOS'},
            rpcURL: new URL('https://mainnet.telos.net/evm'),
            explorerURL: new URL('https://teloscan.io')
        },
        'bsc': {
            name: 'BNB Chain',
            chainID: 56, // 0x38
            nativeCurrency: {name: 'BNB', decimals: 8, symbol: 'BNB'},
            rpcURL: new URL('https://rpc.ankr.com/bsc'),
            explorerURL: new URL('https://bscscan.com')
        }
    },
    'development': {
        'tevm': {
            name: 'Telos EVM Testnet',
            chainID: 41, // 0x29
            nativeCurrency: {name: 'TLOS', decimals: 18, symbol: 'TLOS'},
            rpcURL: new URL('https://testnet15a.telos.net/evm'),
            explorerURL: new URL('https://testnet.teloscan.io')
        },
        'bsc': {
            name: 'BNB Smart Chain Testnet',
            chainID: 97, // 0x61
            nativeCurrency: {name: 'tBNB', decimals: 18, symbol: 'tBNB'},
            rpcURL: new URL('https://bsc-testnet.publicnode.com'),
            explorerURL: new URL('https://testnet.bscscan.com')
        }
    }
};

/* Contract Accounts Config */
const contracts = {
    'production': 'feedurmonkey',
    'development': 'feedmamonkey'
};

/* Token Contract Accounts Config */
const tokenContracts = {
    'production': 'banana.moon',
    'development': 'bananatokan1'
};

/* Atomic Assets Contract Accounts */
const atomicAssetsContracts = {
    'production': 'atomicassets',
    'development': 'atomicassets'
};

/* EVM Token Contract Addresses */
const evmTokenContracts = {
    'production': {
        'tevm': '0x7097Ee02465FB494841740B1a2b63c21Eed655E7',
        'bsc': '0x7097Ee02465FB494841740B1a2b63c21Eed655E7'
    },
    'development': {
        'tevm': '0x30d944075AC6B50c79F679632F00f05406C726fA',
        'bsc': '0x304cACa1f1827D705d884Fa63506e963b9eE91f0'
    }
};

/* EVM Bridge Accounts */
const evmBridgeAccounts = {
    'production': 'bananaevmbrg',
    'development': 'bananaevmbrg'
};

/* Solana Token Addresses */
const solanaTokenContracts = {
    'production': 'GAbRt1gE7Y5nBhhe95bxE2TaRySm8hMA6syjiUQ7q1Er',
    'development': '5Amd1bai4oSbKMSgiqS7aau7gkZuLSvBzcZ4MYaDQ5p4'
};

/* Solana API URLs */
const solanaAPIURLs = {
    'production': 'https://aged-holy-firefly.solana-mainnet.quiknode.pro/110cf93b6ed4a68eb3eb22ed824c9c2afaff7b0d', // `mainnet-beta` cannot query token balances
    'development': 'http://127.0.0.1:8899'
};

/* Solana Bridge Accounts */
const solanaBridgeAccounts = {
    'production': 'bananasonsol',
    'development': 'bananasonsol'
};

/* EVM Bridge Contract Addresses */
const evmBridgeContracts = {
    'production': '0x9a21e20313fc5f5eCd3cf45ED636b2381E9642d1',
    'development': '0x2994543586071deB13796D406F4B1769ebA54dcD'
};

/* EVM Antelope Bridge Contract Addresses */
const evmAntelopeBridgeContracts = {
    'production': '0xa0fA33fE2CD0358475ED8C2714c7d56690d5DEcf',
    'development': '0xa0fA33fE2CD0358475ED8C2714c7d56690d5DEcf'
};

/* EVM OFT Proxy Contract Addresses */
const oftProxyContract = {
    'production': '0x46893403C4aD778d7FDA0CdFCe355a0A7dba3333',
    'development': '0x2ca217d45327e4f03e7a53f92d919e8c0d0e6a2c'
};

/* Blockchain Bridge Contracts Config */
const bridgeContracts = {
    'production': 'ibc.prove',
    'development': 'antelopeibc2'
};

/* Token Wrap Lock Contract Accounts Config */
const wrapLockContracts = {
    'production': {
        'telos': 'bananalocked',
        'wax': 'bananasonwax',
    },
    'development': {
        'telos': 'bananalocked',
        'wax': 'bananasonwax',
    }
};

/* Wrapped Token Contract Accounts Config */
const wrappedTokenContracts = {
    'production': 'banana.moon',
    'development': 'bananawrappd'
};

/* Resource Manager Accounts */
const resMgrContracts = {
    'production': 'bananapowerd',
    'development': 'bananapowerd'
};

/* Staking Accounts */
const stakingContracts = {
    'production': 'bananastaked',
    'development': 'bananastaked'
};

/* Explorers Config */
const explorers = {
    'production': {
        'eos': new URL('https://bloks.io'),
        'telos': new URL('https://explorer.telos.net'),
        'wax': new URL('https://wax.bloks.io')
    },
    'development': {
        'eos': new URL('https://jungle4.eosq.eosnation.io'),
        'telos': new URL('https://explorer-test.telos.net'),
        'wax': new URL('https://wax-test.bloks.io')
    }
};

/* Swap Exchanges URLs */
const exchangeURLs = {
    'eos': new URL('https://defibox.io/marketDetail/2210'),
    'telos': new URL('https://telos.alcor.exchange/swap?input=TLOS-eosio.token&output=BANANA-banana.moon'),
    'wax': new URL('https://wax.alcor.exchange/swap?input=WAX-eosio.token&output=BANANA-banana.moon'),
    'tevm': new URL('https://telos.swapsicle.io/swap?inputCurrency=TLOS&outputCurrency=0x7097Ee02465FB494841740B1a2b63c21Eed655E7'),
    'bsc': new URL('https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0x7097Ee02465FB494841740B1a2b63c21Eed655E7'),
    'solana': new URL('https://jup.ag/swap/SOL-GAbRt1gE7Y5nBhhe95bxE2TaRySm8hMA6syjiUQ7q1Er')
};

/* API URLs */
const apiURL = {
    'production': new URL('https://api.feedyourmonkey.today'),
    'development': new URL('http://localhost:3001')
};

const hyperionURLs = {
    'production': {
        'eos': 'https://eos.eosusa.io',
        'telos': 'https://telos.eosusa.io',
        'wax': 'https://wax.eosusa.io'
    },
    'development': {
        'eos': undefined,
        'telos': undefined,
        'wax': undefined
    }
};

/* IBC Proof Socket URLs */
const ibcProofURLs = {
    'production': {
        'eos': new URL('wss://eos.eosusa.io/ibc'),
        'telos': new URL('wss://telos.eosusa.io/ibc'),
        'wax': new URL('wss://wax.eosusa.io/ibc')
    },
    'development':{
        'eos': new URL('wss://jungle4-ibc.goldenplatform.com'),
        'telos': new URL('wss://telos-testnet-ibc.goldenplatform.com'),
        'wax': new URL('wss://wax-testnet-ibc.goldenplatform.com')
    }
};

/* Session Key Config */
const sessionKey = {
    'production': {
        PK_NAME: 'wicked_pk',
        PERM_NAME: 'fym',
        SUPPORTED_ACTIONS: [
            'fight',
            'expljungle',
            'foundassets',
            'deleteasset',
            'craftasset',
            'purchasset',
            'craftequip',
            'deleteequip',
            'setweapons',
            'tkstarvepill',
            'starve',
            'setstatus'
        ]
    },
    'development': {
        PK_NAME: 'dev_wicked_pk',
        PERM_NAME: 'fym',
        SUPPORTED_ACTIONS: [
            'fight',
            'expljungle',
            'foundassets',
            'deleteasset',
            'craftasset',
            'purchasset',
            'craftequip',
            'deleteequip',
            'setweapons',
            'tkstarvepill',
            'starve',
            'setstatus'
        ]
    },
};

/* JWT Token Config */
const jwtToken = {
    'production': 'wicked_jwt',
    'development': 'dev_wicked_jwt'
};

/* Side Chain Account Related Config */
const sideChainAccountConfig = {
    'production': {
        ACCOUNT_NAME: 'wicked_accname',
        ADDRESS: 'wicked_address',
        CHAIN_ID: 'wicked_chainID',
        CHAIN_TYPE: 'wicked_chainType',
        ACCOUNT_CREATED: 'wicked_acccreated'
    },
    'development': {
        ACCOUNT_NAME: 'dev_wicked_accname',
        ADDRESS: 'dev_wicked_address',
        CHAIN_ID: 'dev_wicked_chainID',
        CHAIN_TYPE: 'dev_wicked_chainType',
        ACCOUNT_CREATED: 'dev_wicked_acccreated'
    }
};

/* In Game Constants */
const gameConstants = {
    MAX_WEIGHT_DIFF: 1,
    CRAFTING_TIME_10: (30 * 1000), // Crafting time per 10 BANANA (30sec)
    MIN_BANANA_REQUIRED_EXPL: 300,
    MIN_BANANA_REQUIRED_FIGHTING: 10,
    MAX_INVENTORY_ASSETS: 30,
    MIN_ENERGY_EXPLORING: 1,
    STARVE_PILL_VALIDITY: (60 * 60 * 1000), // 1 hour
    STARVE_PILL_MAX_STARVING: 0.7, // 70%
    MIN_XP_REQUIRED_EXPL: 50,
    MAX_WEAPONS_USED: 2,
    PEELS_TO_BANANAS_PERC: 0.64
};

/* Banana Tree NFT ID */
const bananaTreeTemplateID = {
    'production': {
        'eos': 11329,
        'telos': 284,
        'wax': 784347
    },
    'development': {
        'eos': 162,
        'telos': 162,
        'wax': 162,
    }
};

/* Exports */
export const DEFAULT_BLOCKCHAIN = eosioBlockchains[process.env.NODE_ENV]['eos'];
export const EOSIO_BLOCKCHAINS = eosioBlockchains[process.env.NODE_ENV];
export const EVM_BLOCKCHAINS = evmBlockchains[process.env.NODE_ENV];
export const EXPLORERS = explorers[process.env.NODE_ENV];
export const FYM_CONTRACT_NAME = contracts[process.env.NODE_ENV];
export const TOKEN_CONTRACT_NAME = tokenContracts[process.env.NODE_ENV];
export const AA_CONTRACT_NAME = atomicAssetsContracts[process.env.NODE_ENV];
export const EVM_TOKEN_CONTRACT_ADDRESS = evmTokenContracts[process.env.NODE_ENV];
export const EVM_BRIDGE_ACCOUNT = evmBridgeAccounts[process.env.NODE_ENV];
export const SOLANA_TOKEN_CONTRACT_ADDRESS = solanaTokenContracts[process.env.NODE_ENV];
export const SOLANA_API_URL = solanaAPIURLs[process.env.NODE_ENV];
export const SOLANA_BRIDGE_ACCOUNT = solanaBridgeAccounts[process.env.NODE_ENV];
export const EVM_BRIDGE_CONTRACT_ADDRESS = evmBridgeContracts[process.env.NODE_ENV];
export const EVM_OFT_PROXY_ADDRESS = oftProxyContract[process.env.NODE_ENV];
export const BRIDGE_CONTRACT_NAME = bridgeContracts[process.env.NODE_ENV];
export const WRAP_LOCK_CONTRACT_NAME = wrapLockContracts[process.env.NODE_ENV];
export const WRAPPED_TOKEN_CONTRACT_NAME = wrappedTokenContracts[process.env.NODE_ENV];
export const RES_MGR_CONTRACT_NAME = resMgrContracts[process.env.NODE_ENV];
export const STAKING_CONTRACT_NAME = stakingContracts[process.env.NODE_ENV];
export const SESSION_KEY = sessionKey[process.env.NODE_ENV];
export const JWT_TOKEN_KEY_NAME = jwtToken[process.env.NODE_ENV];
export const SIDECHAIN_ACCOUNT = sideChainAccountConfig[process.env.NODE_ENV];
export const HYPERION_URLS = hyperionURLs[process.env.NODE_ENV];
export const EVM_ANTELOPE_BRIDGE_CONTRACT_ADDRESS = evmAntelopeBridgeContracts[process.env.NODE_ENV];
export const API_URL = apiURL[process.env.NODE_ENV];
export const IBC_PROOF_URLS = ibcProofURLs[process.env.NODE_ENV];
export const BANANA_TREE_TEMP_ID = bananaTreeTemplateID[process.env.NODE_ENV];
export const EXCHANGE_URLS = exchangeURLs;
export const GAME_CONSTANTS = gameConstants;

export const TG_URL = new URL('https://t.me/feedyourmonkey');
export const TWITTER_URL = new URL('https://twitter.com/feedurmonkey');

export const getBlockchainName = function(chain) {
    // The Chains config provided by the `SessionKit` uses
    // different names for testnets e.g. "Telos (Testnet)"
    // or simply just "Jungle". This causes issues when using
    // particular images, paths and so on
    const blockchains = Object.entries(EOSIO_BLOCKCHAINS);

    for (let i = 0; i < blockchains.length; i++) {
        const [name, currChain] = blockchains[i];

        if (currChain.id.equals(chain.id)) {
            return name.toUpperCase();
        }
    }
};

export const getExplorerURL = function(chain) {
    const chainName = getBlockchainName(chain).toLowerCase();
    return EXPLORERS[chainName];
};

export const getBlockchainByID = function(chainID) {
    if (typeof chainID !== 'string') {
        chainID = chainID?.toString(); // Might be null as well (hopefully not)
    }

    return Object.values(EOSIO_BLOCKCHAINS).find(chain => {
        return (chain.id.toString() === chainID);
    });
};

export const getEVMChainSlug = function(chainID) {
    const blockchains = Object.entries(EVM_BLOCKCHAINS);

    for (let i = 0; i < blockchains.length; i++) {
        const [slug, chain] = blockchains[i];

        if (chain.chainID === chainID) {
            return slug;
        }
    }
};

export const getExchangeURL = function(sharedState) {
    if (sharedState.isRunningOnNative) {
        const chainName = sharedState.session.chain.name;
        return EXCHANGE_URLS[chainName.toLowerCase()];
    }

    if (sharedState.evmSession) {
        const slug = getEVMChainSlug(sharedState.evmSession.chainID);
        return EXCHANGE_URLS[slug];
    }

    if (sharedState.solanaSession) {
        return EXCHANGE_URLS['solana'];
    }
};