class LocalSettings {
    #prefix = 'ls_';

    set(keyName, value) {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(this.#prefix + keyName, value);
    }

    get(keyName) {
        const value = localStorage.getItem(this.#prefix + keyName);

        try {
            return JSON.parse(value);
        }
        catch (error) {
            return value;
        }
    }

    has(keyName) {
        return (this.get(keyName) !== null);
    }

    clear() {
        Object.keys(localStorage).forEach((key) => {
            if (key.startsWith(this.#prefix)) {
                localStorage.removeItem(key);
            }
        });
    };
};

const localSettings = new LocalSettings();
export default localSettings;
