import React from 'react';
import ToastMessage from '../Components/ToastMessage';
import { SharedStateContext } from './SharedState';

import {
    fight,
    purchaseBanana,
    bridgeNativeTokens,
    submitProof,
    withdrawFromBridgeIntoGame,
    withdrawBananaFromAccountToEVM,
    withdrawBananaFromAccountToOFT,
    withdrawBananaFromAccountToSolana,
    notifyBridge,
    exploreJungle,
    showFoundAssets,
    depositTokens,
    stakeLPTokens,
    unstakeLPTokens,
    stakeBananas,
    unstakeBananas,
    claimDividends,
    starveMonkey,
    withdrawPeels,
    setStatus,
    craftInvAsset,
    purchaseInvAsset,
    deleteInvAsset,
    craftEquipment,
    deleteEquipment,
    setWeapons,
    plantTree,
    digupTree,
    harvestTree,
    fertilizeTree,
    takeStarvePill,
    setSessionKey,
    deleteSessionKey,
    powerUp
} from './TransactionsAPI';

export const TransactionsAPIContext = React.createContext();

export const TransactionsAPIContextProvider = (props) => {
    const [state, setState] = React.useState({
        errorMessage: undefined,
        trxHash: undefined,
        hasInsufficientResources: false
    });

    const [sharedStateContext] = React.useContext(SharedStateContext);
    const sharedSession = sharedStateContext.session;
    const isRunningOnNative = sharedStateContext.isRunningOnNative;

    const createSignTransaction = function() {
        const trxName = arguments[0];
        const lastElement = arguments[arguments.length - 1];
        const includesOptions = isOptionsObject(lastElement);
        const defaultOptions = {responseCallback: false, isErrorSupressable: false, showTransactionSucceeded: false};
        const options = includesOptions ? lastElement : defaultOptions;
        const otherArguments = Array.prototype.slice.call(arguments, 1, includesOptions ? -1 : undefined);
        const session = (options.session || sharedSession);
        
        return new Promise(async function (resolve, reject) {
            let result = undefined;

            try {
                switch (trxName) {
                    case 'fight':
                        result = await fight.apply({session}, otherArguments);
                        break;
                    case 'purchaseBanana':
                        result = await purchaseBanana.apply({session}, otherArguments);
                        break;
                    case 'bridgeNativeTokens':
                        result = await bridgeNativeTokens.apply({session}, otherArguments);
                        break;
                    case 'submitProof':
                        result = await submitProof.apply({session}, otherArguments);
                        break;
                    case 'withdrawFromBridgeIntoGame':
                        result = await withdrawFromBridgeIntoGame.apply({session}, otherArguments);
                        break;
                    case 'withdrawBananaFromAccountToEVM':
                        result = await withdrawBananaFromAccountToEVM.apply({session}, otherArguments);
                        break;
                    case 'withdrawBananaFromAccountToOFT':
                        result = await withdrawBananaFromAccountToOFT.apply({session}, otherArguments);
                        break;
                    case 'withdrawBananaFromAccountToSolana':
                        result = await withdrawBananaFromAccountToSolana.apply({session}, otherArguments);
                        break;    
                    case 'notifyBridge':
                        result = await notifyBridge.apply({session}, otherArguments);
                        break;    
                    case 'exploreJungle':
                        result = await exploreJungle.apply({session}, otherArguments);
                        break;
                    case 'showFoundAssets':
                        result = await showFoundAssets.apply({session}, otherArguments);
                        break;
                    case 'depositTokens':
                        result = await depositTokens.apply({session}, otherArguments);
                        break;
                    case 'stakeLPTokens':
                        result = await stakeLPTokens.apply({session}, otherArguments);
                        break;
                    case 'unstakeLPTokens':
                        result = await unstakeLPTokens.apply({session}, otherArguments);
                        break;
                    case 'stakeBananas':
                        result = await stakeBananas.apply({session}, otherArguments);
                        break;
                    case 'unstakeBananas':
                        result = await unstakeBananas.apply({session}, otherArguments);
                        break;
                    case 'claimDividends':
                        result = await claimDividends.apply({session}, otherArguments);
                        break;
                    case 'starveMonkey':
                        result = await starveMonkey.apply({session}, otherArguments);
                        break;
                    case 'withdrawPeels':
                        result = await withdrawPeels.apply({session}, otherArguments);
                        break;
                    case 'setStatus':
                        result = await setStatus.apply({session}, otherArguments);
                        break;
                    case 'craftInvAsset':
                        result = await craftInvAsset.apply({session}, otherArguments);
                        break;
                    case 'purchaseInvAsset':
                        result = await purchaseInvAsset.apply({session}, otherArguments);
                        break;
                    case 'deleteInvAsset':
                        result = await deleteInvAsset.apply({session}, otherArguments);
                        break;
                    case 'craftEquipment':
                        result = await craftEquipment.apply({session}, otherArguments);
                        break;
                    case 'deleteEquipment':
                        result = await deleteEquipment.apply({session}, otherArguments);
                        break;    
                    case 'setWeapons':
                        result = await setWeapons.apply({session}, otherArguments);
                        break;
                    case 'plantTree':
                        result = await plantTree.apply({session}, otherArguments);
                        break;
                    case 'digupTree':
                        result = await digupTree.apply({session}, otherArguments);
                        break;
                    case 'harvestTree':
                        result = await harvestTree.apply({session}, otherArguments);
                        break;
                    case 'fertilizeTree':
                        result = await fertilizeTree.apply({session}, otherArguments);
                        break;
                    case 'takeStarvePill':
                        result = await takeStarvePill.apply({session}, otherArguments);
                        break;
                    case 'setSessionKey':
                        result = await setSessionKey.apply({session}, otherArguments);
                        break;
                    case 'deleteSessionKey':
                        result = await deleteSessionKey.apply({session}, otherArguments);
                        break;
                    case 'powerUp':
                        result = await powerUp.apply({session}, otherArguments);
                        break;
                    default:
                        throw Error('Unknown transaction name: ' + trxName);
                }

                if (options.responseCallback) {
                    resolve([result, () => {
                        options.showTransactionSucceeded && setState({trxHash: result.response['transaction_id']});
                    }]);
                }
                else {
                    resolve(result);

                    if (options.showTransactionSucceeded) {
                        setState({trxHash: result.response['transaction_id']});
                    }
                }
            }
            catch (error) {
                const showError = () => {
                    // No need to show an error when the Wharf modal was closed by the user
                    if (error.message.includes('Modal closed')) {
                        return
                    }

                    // Insufficient resources (CPU / NET). Make sure to skip showing the error
                    // if running on EVM and for whatever reason the resmgr is out of resources
                    if (isRunningOnNative && (error.code === 3080004 || error.code === 3080002)) {
                        setState({hasInsufficientResources: true});
                    }
                    else {
                        const cause = error?.cause?.message;
                        const includeExtendedDetails = (cause && cause !== error.message);

                        if (includeExtendedDetails) {
                            // Scatter & Anchor produce different errors
                            const cause = ('[' + (error?.cause?.message) + ']')
                            setState({errorMessage: error.message + ' ' + cause});
                        }
                        else {
                            setState({errorMessage: error.message});
                        }

                        console.error(error?.cause || error);
                    }
                };

                if (options.isErrorSupressable) {
                    reject([error, showError]);
                }
                else {
                    reject(error);
                    showError();
                }
            }
        });
    };

    const transactions = {
        fight: createSignTransaction.bind(null, 'fight'),
        purchaseBanana: createSignTransaction.bind(null, 'purchaseBanana'),
        bridgeNativeTokens: createSignTransaction.bind(null, 'bridgeNativeTokens'),
        submitProof: createSignTransaction.bind(null, 'submitProof'),
        withdrawFromBridgeIntoGame: createSignTransaction.bind(null, 'withdrawFromBridgeIntoGame'),
        withdrawBananaFromAccountToEVM: createSignTransaction.bind(null, 'withdrawBananaFromAccountToEVM'),
        withdrawBananaFromAccountToOFT: createSignTransaction.bind(null, 'withdrawBananaFromAccountToOFT'),
        withdrawBananaFromAccountToSolana: createSignTransaction.bind(null, 'withdrawBananaFromAccountToSolana'),
        notifyBridge: createSignTransaction.bind(null, 'notifyBridge'),
        exploreJungle: createSignTransaction.bind(null, 'exploreJungle'),
        showFoundAssets: createSignTransaction.bind(null, 'showFoundAssets'),
        depositTokens: createSignTransaction.bind(null, 'depositTokens'),
        stakeLPTokens: createSignTransaction.bind(null, 'stakeLPTokens'),
        unstakeLPTokens: createSignTransaction.bind(null, 'unstakeLPTokens'),
        stakeBananas: createSignTransaction.bind(null, 'stakeBananas'),
        unstakeBananas: createSignTransaction.bind(null, 'unstakeBananas'),
        claimDividends: createSignTransaction.bind(null, 'claimDividends'),
        starveMonkey: createSignTransaction.bind(null, 'starveMonkey'),
        withdrawPeels: createSignTransaction.bind(null, 'withdrawPeels'),
        setStatus: createSignTransaction.bind(null, 'setStatus'),
        craftInvAsset: createSignTransaction.bind(null, 'craftInvAsset'),
        purchaseInvAsset: createSignTransaction.bind(null, 'purchaseInvAsset'),
        deleteInvAsset: createSignTransaction.bind(null, 'deleteInvAsset'),
        craftEquipment: createSignTransaction.bind(null, 'craftEquipment'),
        deleteEquipment: createSignTransaction.bind(null, 'deleteEquipment'),
        setWeapons: createSignTransaction.bind(null, 'setWeapons'),
        plantTree: createSignTransaction.bind(null, 'plantTree'),
        digupTree: createSignTransaction.bind(null, 'digupTree'),
        harvestTree: createSignTransaction.bind(null, 'harvestTree'),
        fertilizeTree: createSignTransaction.bind(null, 'fertilizeTree'),
        takeStarvePill: createSignTransaction.bind(null, 'takeStarvePill'),
        setSessionKey: createSignTransaction.bind(null, 'setSessionKey'),
        deleteSessionKey: createSignTransaction.bind(null, 'deleteSessionKey'),
        powerUp: createSignTransaction.bind(null, 'powerUp')
    };

    const hideErrorMessage = () => setState({errorMessage: undefined});
    const hideSuccessMessage = () => setState({trxHash: undefined});
    const hideResourcesMessage = () => setState({hasInsufficientResources: false});

    return (
        <TransactionsAPIContext.Provider value={transactions}>
            {/* ToasMessage */}
            <ToastMessage.Error
                isShown={!!state.errorMessage}
                message={state.errorMessage}
                onAutoClosed={hideErrorMessage}
            />

            <ToastMessage.InsufficientResources
                isShown={!!state.hasInsufficientResources}
                onCloseClicked={hideResourcesMessage}
            />

            <ToastMessage.TransactionSigned
                isShown={!!state.trxHash}
                trxHash={state.trxHash}
                onAutoClosed={hideSuccessMessage}
            />

            {/* Children */}
            {props.children}
        </TransactionsAPIContext.Provider>
    );
};

// Helpers
function isOptionsObject(obj) {
    if (obj && typeof obj === 'object') {
        return (
            obj.hasOwnProperty('session') ||
            obj.hasOwnProperty('responseCallback') ||
            obj.hasOwnProperty('isErrorSupressable') ||
            obj.hasOwnProperty('showTransactionSucceeded')
        );
    }

    return false;
};
