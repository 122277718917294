import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import ReactGA from 'react-ga4';
import { Interface, formatUnits, toBeHex } from 'ethers';
import { CircularProgressbar } from 'react-circular-progressbar';

import { t, Trans } from '../../i18n';
import BananaAmount from '../Widgets/BananaAmount';
import BuyBananaModal from '../Modals/BuyBananaModal';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import MonkeyAccount from '../../Models/MonkeyAccount';
import ToastMessage from '../../Components/ToastMessage';
import OFTContract from '../../Helpers/OFTContract';
import fymAPI from '../../Helpers/FYMAPI';
import localSettings from '../../Helpers/LocalSettings';

import {
    formatAmount,
    unitsFromAmount,
    formatBananasTokenAmount,
} from '../../Helpers/Utils';

import {
    getEVMChainSlug,
    getExchangeURL,
    EVM_TOKEN_CONTRACT_ADDRESS,
    EVM_BRIDGE_CONTRACT_ADDRESS,
    EVM_BLOCKCHAINS
} from '../../config';

class FeedMonkeyModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }

    get evmSession() {
        return this.sharedState?.evmSession;
    }

    get solanaSession() {
        return this.sharedState?.solanaSession;
    }

    get isRunningOnNative() {
        return this.sharedState.isRunningOnNative;
    }

    get trxAPI() {
        return this.props.trxAPI;
    }
    
    get rpc() {
        return this.sharedState.rpc;
    }

    get oftContract() {
        return new OFTContract(this.evmSession);
    }

    set bridgingStartedAt(date) {
        localSettings.set('bridging_started_at', date?.getTime());
    }

    get bridgingStartedAt() {
        const startedAt = localSettings.get('bridging_started_at');
        return startedAt ? new Date(startedAt) : undefined;
    }

    get isDepositTipRequired() {
        const value = localStorage.getItem('depositTipRequired');
        return (value === null || value === 'true'); // Stored as a String
    }

    get isSolanaBurningExplanationRequired() {
        const value = localStorage.getItem('solanaBurningExplained');
        return (value === null || value === 'true'); // Stored as a String
    }

    set depositTipRequired(value) {
        localStorage.setItem('depositTipRequired', value);
    }

    set solanaBurningExplanationRequired(value) {
        localStorage.setItem('solanaBurningExplained', value);
    }

    constructor(props) {
        super(props);

        this.finishBridgingTimeout = undefined;

        this.state = {
            bananasAmount: '', // Amount to deposit
            bananasBalance: undefined, // User's account balance
            bridgingFeeWeiAmount: undefined,
            errorMessage: undefined,
            blockConfirmationsProgress: undefined,
            isAmountApproved: false, // EVM only
            isDepositingFromNative: false,
            isBuyBananaModalShown: false,
            isTipModalShown: false,
            isSolanaBurningModalShown: false,
            isConfirmingTrx: false,
            isUnlockingFunds: false,
            isCompletingBridging: false,
            isTransacting: false,
            isFetchingData: false
        };

        this.onTokenFormSubmitted = this.onTokenFormSubmitted.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.useAllBalance = this.useAllBalance.bind(this);
        this.close = this.close.bind(this);
    };

    // Handling Form Submission
    onTokenFormSubmitted(event) {
        event.preventDefault();
        this.setState({isTransacting: true});

        if (this.isRunningOnNative || this.state.isDepositingFromNative) {
            this.depositTokens();
        }
        else if (this.state.isAmountApproved) {
            this.bridgeTokensFromEVM();
        }
        else if (this.evmSession) {
            this.approveAmount();
        }
        else if (this.solanaSession) {
            this.bridgeTokensFromSolana();
        }
    };

    // Antelope Depositing
    depositTokens() {
        const quantity = formatBananasTokenAmount(this.state.bananasAmount);

        this.trxAPI.depositTokens(quantity)
            .then(() => this.onTokensDeposited())
            .finally(() => this.setState({isTransacting: false}));
    };

    // Solana Depositing
    async bridgeTokensFromSolana() {
        if (this.isSolanaBurningExplanationRequired) {
            return this.setSolanaBurningModalShown(true);
        }

        const session = this.solanaSession;
        const amount = unitsFromAmount(this.state.bananasAmount.toString(), 4);

        try {
            const trxID = await session.burnBananas(amount);
            this.setState({isConfirmingTrx: true});
            const trxError = await session.confirmTransaction(trxID);

            // The transaction very likely failed
            if (trxError) {
                this.showErrorMessage(trxError);
            }
            else {
                this.setState({isConfirmingTrx: false, isUnlockingFunds: true});
                await fymAPI.bridgeToNative(trxID);

                // Tokens are bridged, but must still be deposited into the game
                const bananasAmountFormatted = formatBananasTokenAmount(this.state.bananasAmount);
                this.trxAPI.depositTokens(bananasAmountFormatted);
                this.onTokensDeposited();
            }
        }
        catch (error) {
            this.showErrorMessage(error);
        }

        this.setState({
            isTransacting: false,
            isConfirmingTrx: false,
            isUnlockingFunds: false
        });
    };

    // EVM Depositing
    approveAmount() {
        if (this.isDepositTipRequired) {
            return this.setTipModalShown(true);
        }

        const amount = unitsFromAmount(this.state.bananasAmount.toString(), 4);
        const abi = ['function approve(address, uint256) external returns (bool)'];
        const abiInterface = new Interface(abi);
        const chainSlug = getEVMChainSlug(this.evmSession.chainID);
        const tokenAddress = EVM_TOKEN_CONTRACT_ADDRESS[chainSlug];
        const bridgeContract = this.evmSession.isOFTBridgeBased ? tokenAddress : EVM_BRIDGE_CONTRACT_ADDRESS;
        const data = abiInterface.encodeFunctionData('approve', [bridgeContract, amount]);
        const params = {to: tokenAddress, from: this.evmSession.address, data: data};

        this.evmSession.sendTransaction(params)
            .then(() => this.setState({isAmountApproved: true}))
            .catch(error => this.showErrorMessage(error))
            .finally(() => this.setState({isTransacting: false}));
    };

    bridgeTokensFromEVM() {
        if (this.evmSession.isOFTBridgeBased) {
            // Bridges from a 3rd party chain (could be BSC)
            // which then calls tEVM contract which then
            // automatically bridges tokens to Telos Native
            this.bridgeTokensFromOFT();
        }
        else {
            // Bridges from tEVM to Telos Native
            this.bridgeTokensFromTEVM();
        }
    };

    async withdrawFromBridgeIntoGame() {
        const quantity = formatBananasTokenAmount(this.state.bananasAmount);

        // Error must be suppressed as we are handling it in here
        // additionally so 2 modals would pop up otherwise
        return this.trxAPI.withdrawFromBridgeIntoGame(quantity, {isErrorSupressable: true});
    };

    // EVM Account Creation
    async createAntelopeAccount(trxID) {
        if (this.evmSession.antelopeAccountCreated) {
            return;
        }

        try {
            await fymAPI.createAccount(trxID);
        }
        catch (error) {
            // The account already exists. Could be created by the
            // backend in the meantime or some interruption happened
            if (error.status !== 409) {
                throw error;
            }
        }

        this.evmSession.accountCreated = true;
    };

    // Bridging
    bridgeTokensFromOFT() {
        // We need to know the amount user approved and bridge that
        // instead of just bridging the amount that was entered in
        this.oftContract.getAllowance()
            .then(allowance => {
                const {calldata, oftContractAddress} = this.oftContract.getBridgeTokensOntoNativeData(allowance);
                this.bridgingStartedAt = new Date();

                return this.evmSession.sendTransaction({
                    from: this.evmSession.address,
                    to: oftContractAddress,
                    value: toBeHex(this.state.bridgingFeeWeiAmount),
                    data: calldata
                });
            })
            .then(trxID => this.waitUntilTransactionMint(trxID))
            .then(trxID => {
                // LayerZero requires 15 block confirmations, but increase it
                // a little since we need a tiny delay before triggering a
                // withdrawal on the native side (after bridged in from tEVM)
                // TODO: Preferably handle retry in `withdrawFromBridgeIntoGame`
                // if it returns no requests found yet
                return this.waitForBlockConfirmations(20, trxID)
            })
            .then(trxID => {
                this.setState({isAmountApproved: false});
                return this.createAntelopeAccount(trxID);
            })
            .then(() => this.withdrawFromBridgeIntoGame())
            .then(() => {
                this.bridgingStartedAt = undefined;
                this.onTokensDeposited();
            })
            .catch(error => {
                this.showErrorMessage(error);

                // If the transfer was completed, but failure
                // happened elsewhere, close the modal
                if (!this.state.isAmountApproved) {
                    this.close();
                }
            })
            .finally(() => {
                this.setState({isTransacting: false, blockConfirmationsProgress: undefined});
            });
    };

    bridgeTokensFromTEVM() {
        const abi = ['function bridge(string) external'];
        const abiInterface = new Interface(abi);
        const data = abiInterface.encodeFunctionData('bridge', [this.evmSession.accountName]);
        const params = {to: EVM_BRIDGE_CONTRACT_ADDRESS, from: this.evmSession.address, data: data};

        this.bridgingStartedAt = new Date();

        this.evmSession.sendTransaction(params)
            .then(trxID => this.waitUntilTransactionMint(trxID))
            .then(trxID => {
                this.setState({isAmountApproved: false});
                return this.createAntelopeAccount(trxID);
            })
            .then(() => this.withdrawFromBridgeIntoGame())
            .then(() => {
                this.bridgingStartedAt = undefined;
                this.setState({isTransacting: false});
                this.onTokensDeposited();
            })
            .catch(error => {
                this.showErrorMessage(error);
                this.setState({isTransacting: false});

                // If the transfer was completed, but failure
                // happened elsewhere, close the modal
                if (!this.state.isAmountApproved) {
                    this.close();
                }
            });
    };

    // Fetching
    fetchData() {
        this.setState({isFetchingData: true});

        Promise.all([
            this.fetchBridgingFee(),
            this.fetchBananasBalance()
        ])
        .then(([bridgingFeeWeiAmount, bananasBalance]) => {
            this.setState({bridgingFeeWeiAmount, bananasBalance});
        })
        .catch(() => this.close())
        .finally(() => this.setState({isFetchingData: false}));
    };

    async fetchBananasBalance() {
        if (this.isRunningOnNative) {
            return this.rpc.fetchBananasBalance(this.session.accountName);
        }
        else {
            const session = (this.evmSession || this.solanaSession);

            // The user might already have some BANANA in his account
            // on the native side that they should be able to deposit
            // into the game so we need to check both balances
            if (session.antelopeAccountCreated) {
                const accountName = session.accountName;

                return Promise.all([
                    session.fetchBananasBalance(),
                    this.rpc.fetchBananasBalance(accountName)
                ])
                .then(([sideChainBalance, antelopeBalance]) => {
                    if (antelopeBalance > 0) {
                        this.setState({isDepositingFromNative: true});
                        return antelopeBalance;
                    }

                    return sideChainBalance;
                });
            }
            else {
                return session.fetchBananasBalance();
            }
        }
    };

    async fetchBridgingFee(amount = 0) {
        if (!this.evmSession?.isOFTBridgeBased) {
            return Promise.resolve();
        }

        return this.oftContract.estimateDepositFee(amount);
    };

    // ToastMessage
    showErrorMessage(error) {
        if (error.code === 'ACTION_REJECTED' || error.code === 4001) {
            return;
        }

        const errorMessage = (error.info?.error?.message || error.message)
        this.setState({errorMessage: errorMessage});
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    };

    // Components
    confirmTransferButtonTitle() {
        if (this.isRunningOnNative || this.state.isDepositingFromNative || this.solanaSession) {
            return t('common.confirm');
        }

        return (this.state.isAmountApproved ? t('modals.feedYourMonkey.deposit') : t('modals.feedYourMonkey.approve'));
    };
    
    loadingTitle() {
        if (this.state.isCompletingBridging) {
            return t('modals.feedYourMonkey.completingBridging');
        }

        if (this.state.isConfirmingTrx) {
            return t('modals.feedYourMonkey.confirmingTransaction');
        }
        
        if (this.state.isUnlockingFunds) {
            return t('modals.feedYourMonkey.unlockingFunds');
        }
    };

    // Misc
    onTokensDeposited() {
        const quantity = formatBananasTokenAmount(this.state.bananasAmount);

        // In case of a completely new player the monkey object won't be available
        if (this.props.monkey) {
            // Use the formatted amount in other case the Float type might have a lot bigger
            // precision affecting the resulting amount we're setting into the object
            const bananasDeposited = parseFloat(quantity);

            // Change it locally before getting back a confirmation
            // which might take more than 1 sec until data are current
            this.props.monkey.bananasAmount += bananasDeposited;
            this.props.onTokensDeposited(this.props.monkey);
        }
        else {
            const monkey = MonkeyAccount.from(this.session.accountName, quantity);
            this.props.onTokensDeposited(monkey);
        }

        this.close();
    };

    setBuyBananaModalShown(isShown) {
        this.close(); // The current modal must close

        setTimeout(() => {
            this.setState({isBuyBananaModalShown: isShown});
        }, 300);
    };

    setTipModalShown(isShown) {
        this.setState({isTipModalShown: isShown});
    };

    setSolanaBurningModalShown(isShown) {
        this.setState({isSolanaBurningModalShown: isShown});
    };

    async waitUntilTransactionMint(trxID) {
        const that = this;

        return new Promise((resolve, reject) => {
            function checkStatus() {
                that.evmSession.getTransactionReceipt(trxID)
                    .then(result => {
                        if (!result?.status) {
                            return setTimeout(checkStatus, 600);
                        }

                        const status = Number(result.status); // HEX
                        const isTrxMint = (status === 1);

                        isTrxMint ? resolve(trxID) : reject(new Error('Transaction Failed'));
                    })
                    .catch(reject);
            };
    
            setTimeout(checkStatus, 600);
        });
    };

    async waitForBlockConfirmations(confirmationsRequired, trxID) {
        const transactionReceipt = await this.evmSession.getTransactionReceipt(trxID);
        const trxBlockNumber = Number(transactionReceipt.blockNumber); // HEX to decimal
        const that = this;

        this.setState({blockConfirmationsProgress: 0});

        return new Promise((resolve, reject) => {
            function checkConfirmations() {
                that.evmSession.getBlockNumber()
                    .then(hexBlockNumber => {
                        const currentBlockNumber = Number(hexBlockNumber);
                        const confirmationsSubmitted = (currentBlockNumber - trxBlockNumber);
                        const enoughConfirmations = (confirmationsSubmitted >= confirmationsRequired);
                        const progressPerc = Math.min(Math.floor((confirmationsSubmitted / confirmationsRequired) * 100), 100);

                        that.setState({blockConfirmationsProgress: progressPerc});
                        enoughConfirmations ? resolve(trxID) : setTimeout(checkConfirmations, 2500);
                    })
                    .catch(error => {
                        that.setState({blockConfirmationsProgress: undefined});
                        reject(error);
                    });
            };
    
            setTimeout(checkConfirmations, 1000);
        });
    };

    async finishBridging() {
        if (!this.evmSession?.antelopeAccountCreated) {
            return;
        }

        this.setState({isCompletingBridging: true});

        // OFT bridging requires more time than tEVM to native
        // so we must avoid notifing the bridge too early
        if (this.evmSession?.isOFTBridgeBased) {
            if (this.secondsSinceBridgingStarted() < 70) {
                const secondsRemaining = (70 - this.secondsSinceBridgingStarted());
                this.finishBridgingTimeout = setTimeout(() => { this.finishBridging() }, secondsRemaining);
                return;
            }
        }

        try {
            // `isErrorSupressable` will skip showing an error modal which
            // isn't needed in a situation when for example there are no trxs
            // to be processed by the bridge and so we get an error back
            await this.trxAPI.notifyBridge({isErrorSupressable: true});
            this.bridgingStartedAt = undefined;
        }
        catch (error) { /* Just ignore errors */ }

        this.setState({isCompletingBridging: false});
    };

    useAllBalance() {
        this.setState({bananasAmount: this.state.bananasBalance});
    };

    secondsSinceBridgingStarted() {
        if (!this.bridgingStartedAt) {
            return;
        }

        return (new Date() - this.bridgingStartedAt) / 1000;
    };

    formatWeiToBNBAmount(amount) {
        // For whatever reason LZ returns and ether unit even
        // though BNB it supposed to have 8 decimals length
        const currency = EVM_BLOCKCHAINS['bsc'].nativeCurrency;
        const etherAmount = formatUnits(amount, 18);
        const formattedAmount = parseFloat(etherAmount).toFixed(currency.decimals);
        return `${formattedAmount} ${currency.symbol}`;
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'feedMonkey-modal'});

                if (this.bridgingStartedAt) {
                    this.finishBridging().then(() => this.fetchData());
                }
                else {
                    this.fetchData();
                }             
            }
            else if (!this.state.isAmountApproved) { // Keep the same amount in the textfield if interrupted
                clearTimeout(this.finishBridgingTimeout);

                this.setState({
                    bananasAmount: '',
                    bananasBalance: undefined,
                    bridgingFeeWeiAmount: undefined,
                    blockConfirmationsProgress: undefined,
                    isConfirmingTrx: false,
                    isUnlockingFunds: false,
                    isDepositingFromNative: false
                });
            }
        }
    }

    render() {
        const isTransacting = this.state.isTransacting;
        const isFetchingData = this.state.isFetchingData;
        const isCompletingBridging = this.state.isCompletingBridging;
        const isConfirmingTrx = this.state.isConfirmingTrx;
        const isUnlockingFunds = this.state.isUnlockingFunds;
        const isDepositingFromNative = this.state.isDepositingFromNative;
        const isBalanceAvailable = (this.state.bananasBalance !== undefined);
        const isInputLocked = (isTransacting || this.state.isAmountApproved);
        const isLoading = (isFetchingData || isCompletingBridging || isConfirmingTrx || isUnlockingFunds);
        const blockConfirmationsProgress = this.state.blockConfirmationsProgress;
        const isWaitingForBlockConfirmation = (isTransacting && blockConfirmationsProgress !== undefined);
        const isAmountInputReady = (!isLoading && !isCompletingBridging && !isWaitingForBlockConfirmation);
        
        return (
            <>
                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />

                <BuyBananaModal
                    isShown={this.state.isBuyBananaModalShown}
                    onCloseClicked={() => this.setBuyBananaModalShown(false)}
                />
                
                <TipToastMessage
                    isShown={this.state.isTipModalShown}
                    onCloseClicked={() => {
                        this.setTipModalShown(false);
                        this.depositTipRequired = false;
                        this.approveAmount(); // Repeat the action
                    }}
                />
                
                <SolanaBurningExplainedMessage
                    isShown={this.state.isSolanaBurningModalShown}
                    onCloseClicked={() => {
                        this.setSolanaBurningModalShown(false);
                        this.solanaBurningExplanationRequired = false;
                        this.bridgeTokensFromSolana(); // Repeat the action
                    }}
                />

                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title>{t('modals.feedYourMonkey.title')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        { isWaitingForBlockConfirmation &&
                            <Stack style={{alignItems: 'center'}} gap={3}>
                                <div style={{width: '33%'}}>
                                    <CircularProgressbar value={blockConfirmationsProgress} text={`${blockConfirmationsProgress}%`} strokeWidth="4"/>
                                </div>

                                <div style={{textAlign: 'center'}}>
                                    {t('modals.feedYourMonkey.waitingForConfirmations')}
                                </div>

                                <div style={{textAlign: 'center', fontWeight: 300, fontSize: '0.75rem'}}>
                                    {t('modals.feedYourMonkey.processInProgress')}
                                </div>
                            </Stack>
                        }

                        { isLoading &&
                            <Stack style={{alignItems: 'center', gap: '1rem'}}>
                                <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                                { this.loadingTitle() && <div style={{fontWeight: 300, textAlign: 'center'}}>{this.loadingTitle()}</div> }
                            </Stack>
                        }

                        { (isAmountInputReady && (!isBalanceAvailable || this.state.bananasBalance >= 1)) &&
                            <Form id="feedMonkeyForm" onSubmit={this.onTokenFormSubmitted}>
                                <Form.Group className="mb-3" controlId="bananasAmount">
                                    <Form.Label>{t('misc.amountOfBananas')}</Form.Label>
                                    <Form.Control type="number" step="any" min="1" disabled={isInputLocked} value={this.state.bananasAmount} onChange={e => { this.setState({bananasAmount: e.target.value})}} autoFocus required/>

                                    { (isBalanceAvailable && !isInputLocked) &&
                                        <Stack>
                                            <Form.Text className="text-muted" style={{cursor: 'pointer'}} onClick={this.useAllBalance}>
                                                {t('modals.feedYourMonkey.depositAll')}: 🍌 {formatAmount(this.state.bananasBalance)}
                                            </Form.Text>

                                            { (this.state.bridgingFeeWeiAmount && !isDepositingFromNative) &&
                                                <Form.Text className="text-muted mt-0">
                                                    {t('misc.bridgingFee')}: {this.formatWeiToBNBAmount(this.state.bridgingFeeWeiAmount)}
                                                </Form.Text>
                                            }
                                        </Stack>
                                    }
                                </Form.Group>
                            </Form>
                        }

                        { (this.state.bananasBalance < 1) &&
                            <p style={{textAlign: 'center', marginBottom: 0}}>
                                { (this.props.monkey?.bananasAmount > 0) ?
                                    <Trans i18nKey="modals.feedYourMonkey.hasBananaInGameButNotInWallet">
                                        Your monkey currently has:<br/><BananaAmount amount={this.props.monkey?.bananasAmount} style={{justifyContent: 'center'}}/><br/>but there isn't a single banana left in your wallet, sir.
                                        If more bananas is needed you can always get some <a href={getExchangeURL(this.sharedState)} target="_blank" rel="noreferrer">here</a>!
                                    </Trans>
                                    :
                                    <Trans i18nKey="modals.feedYourMonkey.noBananaAvailableInYourWallet">
                                        Sir, you don't even have 1 full banana 😢<br/><br/>
                                        But don't you worry! You can always<button className="buttonTextLink" onClick={() => this.setBuyBananaModalShown(true)}>click here</button> and
                                        get some! That would actually be highly advised too!
                                    </Trans>
                                }
                            </p>
                        }
                    </Modal.Body>

                    { (!isWaitingForBlockConfirmation && !isLoading) &&
                        <Modal.Footer>
                            { !isTransacting &&
                                <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                            }

                            { (!isFetchingData && !isCompletingBridging && this.state.bananasBalance >= 1) &&
                                <Button type="submit" form="feedMonkeyForm" variant="primary" disabled={isTransacting}>
                                    { isTransacting ?
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                            <span className="visually-hidden">{this.confirmTransferButtonTitle()}</span>
                                        </>
                                        :
                                        this.confirmTransferButtonTitle()
                                    }
                                </Button>
                            }
                        </Modal.Footer>
                    }
                </Modal>
            </>
        );
    };
};

export default commonContextsWrapper(FeedMonkeyModal);

// Helpes
function TipToastMessage(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="success" show={props.isShown} onClose={props.onCloseClicked} autohide>
                <Toast.Header closeButton={true}>
                    <h5 className="toast-header-title success me-auto">TIP</h5>
                </Toast.Header>

                <Toast.Body>If you would like to save on gas and avoid having to sign transactions then it's recommended to approve a larger amount.</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

function SolanaBurningExplainedMessage(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="success" show={props.isShown} onClose={props.onCloseClicked} autohide>
                <Toast.Header closeButton={true}>
                    <h5 className="toast-header-title success me-auto">$BANANA Burning</h5>
                </Toast.Header>

                <Toast.Body>
                    Depositing tokens into the game burns them on the chain. When you decide to withdraw, new tokens will be minted for you.
                    <br/><br/>
                    <span style={{color: 'var(--red)', textAlign: 'center'}}>Incorrect transaction flagging by Phantom has been reported. We apologize for the inconvenience and thank you for your patience.</span>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
}
