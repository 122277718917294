import React from 'react';
import { SharedStateContext } from './SharedState';
import { TransactionsAPIContext } from './TransactionsAPIContext';

function commonContextsWrapper(Component) {
    return function(props) {
        const sharedState = React.useContext(SharedStateContext);
        const trxAPI = React.useContext(TransactionsAPIContext);
        return <Component {...props} sharedState={sharedState} trxAPI={trxAPI}/>
    };
};

export default commonContextsWrapper;
