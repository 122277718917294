import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import CryptoJS from 'crypto-js';
import { PrivateKey, KeyType } from '@wharfkit/antelope';
import { Session } from '@wharfkit/session';

import fymAPI from '../../Helpers/FYMAPI';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import ToastMessage from '../../Components/ToastMessage';
import CreateFYMAccountModal from './CreateFYMAccountModal';
import FYMEVMSession from '../../Helpers/FYMEVMSession';
import EVMWalletAPI from '../../Helpers/EVMWalletAPI';
import { t } from '../../i18n';
import { EOSIO_BLOCKCHAINS, EVM_BLOCKCHAINS } from '../../config';

import './EVMLoginModal.css';

class EVMLoginModal extends React.Component {
    #encryptedKey;

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get logout() {
        return this.props.sharedState?.[1]?.logout;
    }

    get setAntelopeSession() {
        return this.props.sharedState?.[1]?.setAntelopeSession;
    }

    get session() {
        return this.sharedState?.evmSession;
    }

    get antelopeSession() {
        return this.sharedState?.session;
    }

    constructor(props) {
        super(props);

        this.state = {
            isSwitchingChains: false,
            isLoggingIn: false,
            isRequestingSignature: false,
            wasAccountNameSet: false,
            errorMessage: undefined
        };

        this.supportedChains = [{
            chainID: EVM_BLOCKCHAINS['bsc'].chainID,
            name: 'BSC',
            iconPath: `${process.env.PUBLIC_URL}BSC.png`,
        }, {
            chainID: EVM_BLOCKCHAINS['tevm'].chainID,
            name: 'Telos',
            iconPath: `${process.env.PUBLIC_URL}TELOS.png`,
        }];

        this.requestWalletSignature = this.requestWalletSignature.bind(this);
        this.onAccountsChanged = this.onAccountsChanged.bind(this);
        this.onChainChanged = this.onChainChanged.bind(this);
        this.onConnected = this.onConnected.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
    }

    // Actions
    switchChain(chainID) {
        if (this.state.isSwitchingChains) {
            return;
        }

        this.setState({isSwitchingChains: true});

        this.session.switchChain(chainID)
            .catch(error => {
                this.showErrorMessage(error);
                this.logout();
            })
            .finally(() => this.setState({isSwitchingChains: false}));
    };

    loginBackend() {
        if (this.state.isLoggingIn) {
            return;
        }

        this.setState({isLoggingIn: true});
        const {address, chainID} = this.session;

        fymAPI.getSiweMessage(address, chainID)
            .then(message => this.session.signData(message))
            .then(signature => fymAPI.authenticateEVM(signature))
            .then(response => {
                // accountName & encryptedKey might not be
                // available for a brand new user
                this.session.jwtToken = response.token;
                this.session.accountName = response.accountName;
                this.session.accountCreated = response.accountCreated;
                this.#encryptedKey = response.encryptedKey;
            })
            .catch(error => {
                this.showErrorMessage(error);
                this.logout();
            })
            .finally(() => this.setState({isLoggingIn: false}));
    };

    requestWalletSignature() {
        if (this.state.isRequestingSignature) {
            return;
        }

        this.setState({isRequestingSignature: true});
        const message = 'Your wallet signature is used to encrypt your game related data. Signing is safe, gas-less and does not give the game permission to perform any transactions with your wallet.\n\nSIGN THIS MESSAGE INSIDE FEED-YOUR-MONKEY GAME ONLY!';
        
        this.session.signData(message)
            .then(signature => {
                if (this.#encryptedKey) {
                    this.session.privateKey = this.decryptKey(this.#encryptedKey, signature);
                }
                else {
                    const {
                        privateKey,
                        publicKey,
                        encryptedKey
                    } = this.generateAccountKey(signature);

                    return fymAPI.setKey(encryptedKey, publicKey)
                        .then(() => { this.session.privateKey = privateKey; });
                }
            })
            .catch(error => {
                if (error.code !== 'ACTION_REJECTED') {
                    this.showErrorMessage(error);
                    this.logout();
                }
            })
            .finally(() => this.setState({isRequestingSignature: false}));
    };

    initAntelopeSession() {
        // A dummy session. We use the SessionKey
        // to actually sign any transactions
        const session = new Session({
            actor: this.session.accountName,
            permission: 'efym',
            chain: EOSIO_BLOCKCHAINS['telos']
        });

        this.setAntelopeSession(session);
    };

    // Components
    modalBodyContent() {
        if (!this.session) {
            return null;
        }

        if (!this.session.isNetworkSupported) {
            if (this.state.isSwitchingChains) {
                return (
                    <Stack style={{alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem'}}>
                        <Spinner animation="border" role="status" aria-hidden="true" style={{width: '2.75rem', height: '2.75rem'}}/>
                        <div style={{fontWeight: 300}}>{t('modals.evmLogin.switchingChains')}</div>
                    </Stack>
                );
            }

            return (
                <Stack style={{gap: '0.5rem'}}>
                    <p style={{textAlign: 'center', marginBottom: '1rem'}}>
                        {t('modals.evmLogin.notConnectedToSupportedNetwork')}:
                    </p>

                    <Stack direction="horizontal" style={{gap: '6px'}}>
                        { this.supportedChains.map(chain => {
                            return (
                                <Button className="chainButton" key={chain.chainID} onClick={() => this.switchChain(chain.chainID)}>
                                    <img src={chain.iconPath} alt={chain.name + '-icon'}/>{chain.name}
                                </Button>
                            );
                        })}
                    </Stack>
                </Stack>
            );
        }

        if (!this.session.isUserLoggedIn) {
            return (
                <Stack style={{alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '1rem'}}>
                    <Spinner animation="border" role="status" aria-hidden="true" style={{width: '2.75rem', height: '2.75rem'}}/>
                    <div style={{fontWeight: 300}}>{t('modals.evmLogin.loggingIn')}</div>
                </Stack>
            );
        }

        if (!this.session.isAccountKeySet) {
            return (
                <p style={{textAlign: 'center', marginBottom: 0}}>{t('modals.evmLogin.walletSignatureRequiredForEncryption')}</p>
            );
        }
    };

    modalTitle() {
        if (!this.session) {
            return null;
        }

        if (!this.session.isNetworkSupported) {
            return t('modals.evmLogin.updateNetworkTitle');
        }

        if (!this.session.isUserLoggedIn) {
            return t('modals.evmLogin.loginTitle');
        }

        if (!this.session.isAccountKeySet) {
            return t('modals.evmLogin.signatureRequestTitle');
        }
    };

    // Error Message Toast
    showErrorMessage(error) {
        if (error.code === 'ACTION_REJECTED') {
            return;
        }
        
        const errorMessage = (error.info?.error?.message || error.message)
        this.setState({errorMessage: errorMessage});
    };

    hideErrorMessage() {
        this.setState({errorMessage: undefined});
    };

    // Account Private Key
    decryptKey(encryptedKey, password) {
        const decrypted = CryptoJS.AES.decrypt(encryptedKey, password);
        return decrypted.toString(CryptoJS.enc.Utf8);
    };

    generateAccountKey(password) {
        const privateKey = PrivateKey.generate(KeyType.K1);
        const encryptedKey = CryptoJS.AES.encrypt(privateKey.toString(), password);
        
        return {
            privateKey: privateKey.toString(),
            publicKey: privateKey.toPublic().toString(),
            encryptedKey: encryptedKey.toString()
        };
    };

    // Misc
    onConnected() {
        const recentlyUsedAddress = (this.session?.address || FYMEVMSession.recentlyUsedAddress);

        // Make sure the user is logged in otherwise we'll be logging
        // out before the user is fully signed in (esp. when switching)
        if (recentlyUsedAddress && FYMEVMSession.isUserLoggedIn) {
            // The session might not exist yet
            const walletAPI = new EVMWalletAPI(window.ethereum);

            Promise.all([
                walletAPI.getSelectedAddress(),
                walletAPI.getChainID()
            ])
            .then(([selectedAddress, chainID]) => {
                const diffAddress = (selectedAddress !== recentlyUsedAddress);
                const diffChainID = (FYMEVMSession.recentlyUsedChainID !== chainID);

                // TODO: Preferably we shouldn't log the user out if
                // different chain used, but that requires refactoring
                // the way we use and persist chainID
                if (diffAddress || diffChainID) {
                    this.logout();
                }
            });
        }
    };

    onChainChanged(chainID) {
        if (this.session) {
            // As a workaround, must be updated in here. Subsequently,
            // if unsupported the modal will appear automatically
            this.session.chainID = chainID;
        }
    };

    onAccountsChanged(accounts) {
        if (this.session && this.session.address !== accounts[0]) {
            this.logout();
        }
    };

    // React
    componentDidMount() {
        window.ethereum?.on('connect', this.onConnected);
        window.ethereum?.on('chainChanged', this.onChainChanged);
        window.ethereum?.on('accountsChanged', this.onAccountsChanged);
    };

    componentWillUnmount() {
        window.ethereum?.removeListener('connect', this.onConnected);
        window.ethereum?.removeListener('chainChanged', this.onChainChanged);
        window.ethereum?.removeListener('accountsChanged', this.onAccountsChanged);
    };

    componentDidUpdate() {
        if (this.session && this.session.isNetworkSupported) {
            if (!this.session.isUserLoggedIn) {
                this.loginBackend();
            }
            else if (!this.antelopeSession && this.session.isFullyInitialized) {
                this.initAntelopeSession();
            }
        }
    };

    render() {
        const session = this.session;
        const isMainModalShown = (session && (!session?.isNetworkSupported || !session?.isAccountKeySet));
        const isCreateAccountModalShown = (session && (!isMainModalShown && !session.isAccountNameSet && !this.state.wasAccountNameSet));

        return (
            <>
                <ToastMessage.Error
                    isShown={!!this.state.errorMessage}
                    message={this.state.errorMessage}
                    onAutoClosed={this.hideErrorMessage}
                />
                
                <CreateFYMAccountModal
                    isShown={isCreateAccountModalShown}
                    onAccountNameSet={accountName => {
                        this.session.accountName = accountName;
                        this.setState({wasAccountNameSet: true});
                    }}
                    onCancel={() => {
                        this.logout();
                    }}
                    onError={error => {
                        // Could be a name taken error too
                        this.showErrorMessage(error);
                    }}
                />

                <Modal show={isMainModalShown} size="sm" backdrop="static" backdropClassName="modal-backdrop-blurred" centered>
                    <Modal.Header>
                        <Modal.Title>{this.modalTitle()}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {this.modalBodyContent()}
                    </Modal.Body>

                    { (session?.isNetworkSupported && session?.isUserLoggedIn && !session?.isAccountKeySet) &&
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.requestWalletSignature} disabled={this.state.isRequestingSignature}>
                                { this.state.isRequestingSignature ?
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                        <span className="visually-hidden">{t('modals.evmLogin.sign')}</span>
                                    </>
                                    :
                                    t('modals.evmLogin.sign')
                                }
                            </Button>
                        </Modal.Footer>
                    }
                </Modal>
            </>
        );
    };
}

export default commonContextsWrapper(EVMLoginModal);
