import { sha256 } from 'hash.js';
import inventoryAssetTypes from '../Helpers/InventoryAssetTypes';
import { GAME_CONSTANTS } from '../config';

const STARVE_PILL_ID = inventoryAssetTypes.Types.STARVE_PILL;

class MonkeyAccount {
    #DAY = 86400000;
    #wasUpdated = false; // Private - excluded from SHA256/JSON

    // Getters [Game Related]
    get isOnStarvePill() {
        const canStarveUntil = this.assetsUsed[STARVE_PILL_ID];
        return canStarveUntil ? (canStarveUntil.getTime() > Date.now()) : false;
    }

    get canFight() {
        return (this.losingStreak === 0);
    }

    get lostFight() {
        return (this.losingStreak > 0);
    }

    get didWinFight() {
        if (!this.lastWin) {
            return false;
        }

        return ((this.lastWin.getTime() + this.#DAY) > Date.now());
    }

    get wlRatio() {
        if (!this.losses) {
            return this.wins;
        }

        return (this.wins / this.losses);
    }

    // Locally Changeable Properties
    set bananasAmount(newAmount) {
        this._bananasAmount = newAmount;
        this.wasUpdated = true;
    }

    get bananasAmount() {
        return this._bananasAmount;
    }

    set bananaPeels(newAmount) {
        this._bananaPeels = newAmount;
        this.wasUpdated = true;
    }

    get bananaPeels() {
        return this._bananaPeels;
    }

    // Static
    static from(accountName, bananasAmount) {
        return new MonkeyAccount({
            'monkey': accountName,
            'bananas': bananasAmount,
            'banana_peels': '0.0000 PEEL',
            'status': '',
            'wins': 0,
            'losses': 0,
            'total_fights': 0,
            'losing_streak': -1,
            'xp': 0,
            'assets_used': [],
            'equip_used': []
        });
    }

    constructor(obj) {
        const assetsUsed = obj['assets_used'];
        const equipmentUsed = obj['equip_used'];

        this.name = obj['monkey'];
        this.bananasAmount = parseFloat(obj['bananas']);
        this.bananaPeels = parseFloat(obj['banana_peels']);
        this.status = obj['status'];
        this.lastWin = obj['last_win'] ? new Date(obj['last_win'] + 'Z') : null;
        this.wins = obj['wins'];
        this.losses = obj['losses'];
        this.totalFights = obj['total_fights'];
        this.losingStreak = obj['losing_streak'];
        this.xp = obj['xp'];
        this.dominanceOrder = undefined; // Will be set later according to other monkeys presetn

        this.assetsUsed = assetsUsed.reduce((assetsUsed, asset) => {
            assetsUsed[asset.first] = new Date(asset.second * 1000);
            return assetsUsed;
        }, {});

        this.equipmentUsed = equipmentUsed.map(equipment => {
            return {
                id: equipment.id,
                lifespan: parseFloat(equipment.lifespan)
            };
        });
        
        // It takes time (sometimes even up to 5 secs) until properties
        // changed in the contract table are reflected in responses from
        // nodes. This degrades the UX. Currently there is no easy way of
        // registering an event hooks. In order to prevent overriding with
        // an outdated data returned back, utilise hash comparison
        this.hash = sha256().update(JSON.stringify(this)).digest('hex');
    }

    isNewerThan(monkey) {
        if (!monkey) {
            return true;
        }

        if (this.wasUpdated && this.hash === monkey.hash) {
            return true;
        }

        return (this.hash === monkey.hash);
    }

    useAsset(asset) {
        const validityInterval = function() {
            switch (asset.id) {
                case STARVE_PILL_ID:
                    return GAME_CONSTANTS.STARVE_PILL_VALIDITY;
                default:
                    throw Error("The asset is not supported locally");
            }
        }();

        this.assetsUsed[asset.id] = new Date(Date.now() + validityInterval);
        this.wasUpdated = true;
    }

    useWeapons(weapons) {
        const equipmentUsed = this.equipmentUsed;

        const newEquipment = weapons.map(newWeapon => {
            const weaponInUse = equipmentUsed.find(weapon => weapon.id === newWeapon.id);

            // If used already, reassign it
            if (weaponInUse) {
                return weaponInUse;
            }
            
            return {
                id: newWeapon.id,
                lifespan: newWeapon.quantity
            };
        });

        this.equipmentUsed = newEquipment;
        this.wasUpdated = true;
    }

    invalidateStarvePill() {
        this.assetsUsed[STARVE_PILL_ID] = undefined;
        this.wasUpdated = true;
    }
}

export default MonkeyAccount;
