import React from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import { TransactionsAPIContext } from '../Helpers/TransactionsAPIContext';
import { SharedStateContext } from '../Helpers/SharedState';
import { transactionDetailsURL } from '../Helpers/Utils';
import { getExplorerURL } from '../config';
import { t, Trans } from '../i18n';
import './ToastMessage.css';

function GenericAlert(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="generic-alert" show={props.isShown} onClose={props.onCloseClicked}>
                <Toast.Header closeButton={true}>
                    <h5 className="toast-header-title generic-alert me-auto">{props.title}</h5>
                </Toast.Header>

                <Toast.Body>{props.message}</Toast.Body>
           </Toast>
       </ToastContainer>
   );
};

function Error(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="error" show={props.isShown} onClose={props.onAutoClosed} delay={3500} autohide>
                <Toast.Header closeButton={false}>
                    <h5 className="toast-header-title error">{t('common.error')}</h5>
                </Toast.Header>

                <Toast.Body>{props.message}</Toast.Body>
           </Toast>
       </ToastContainer>
   );
};

function InsufficientResources(props) {
    const trxAPI = React.useContext(TransactionsAPIContext);
    const purchasePowerUp = () => trxAPI.powerUp({showTransactionSucceeded: true});

    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="error" show={props.isShown} onClose={props.onCloseClicked}>
                <Toast.Header closeButton={true}>
                    <h5 className="toast-header-title error me-auto">{t('misc.insufficientResourcesTitle')}</h5>
                </Toast.Header>

                <Toast.Body>
                    <Trans i18nKey="misc.insufficientResourcesMessage">
                        Insufficient <b>CPU</b> or <b>NET</b>. You can PowerUp your account <button className="buttonTextLink" onClick={purchasePowerUp}>by clicking here</button> for ~0.0016 EOS, which allows you to do ~30 fights.
                    </Trans>
                </Toast.Body>
           </Toast>
       </ToastContainer>
   );
};

function MajorChangesUpdate(props) {
    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="success" show={props.isShown} onClose={props.onCloseClicked}>
                <Toast.Header closeButton={true}>
                    <h5 className="toast-header-title success me-auto">{t('misc.newUpdateTitle')}</h5>
                </Toast.Header>

                <Toast.Body>
                    1. You can now equip your monke with up to 2 weapons<br/>
                    2. The greenish fist button <b>does not guarantee</b> a win anymore. Be careful when choosing your opponent<br/>
                    3. Exploring may expose you randomly to 1 fight<br/>
                    4. XP lowers your exploring times<br/><br/>

                    Join us on <a href="https://t.me/feedyourmonkey" target="_blank" rel="noreferrer">Telegram</a> for more updates!
                </Toast.Body>
           </Toast>
       </ToastContainer>
   );
};

function TransactionSigned(props) {
    const [sharedState] = React.useContext(SharedStateContext);
    const chain = sharedState.chain;
    const trxDetailsURL = transactionDetailsURL(props.trxHash, chain);
    const explorerHost = getExplorerURL(chain).host;

    return (
        <ToastContainer className="p-4" position="middle-center">
            <Toast className="success" show={props.isShown} onClose={props.onAutoClosed} delay={3500} autohide>
                <Toast.Header closeButton={false}>
                    <h5 className="toast-header-title success">{t('common.success')}</h5>
                </Toast.Header>

                <Toast.Body>
                    <Trans i18nKey="misc.transactionSignedMessage" explorerHost={explorerHost}>
                        Transaction signed successfully! See on <a href={trxDetailsURL} target="_blank" rel="noreferrer">{{explorerHost}}</a>
                    </Trans>
                </Toast.Body>
           </Toast>
       </ToastContainer>
   );
};

const ToastMessageTypes = {GenericAlert, Error, InsufficientResources, MajorChangesUpdate, TransactionSigned};
export default ToastMessageTypes;
