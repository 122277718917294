import bs58 from 'bs58';
import EventEmitter from 'events';
import { PublicKey } from '@solana/web3.js';
import nacl, { randomBytes } from 'tweetnacl';
import { initUtils } from '@tma.js/sdk-react';
import { Buffer } from 'buffer';

import localSettings from './LocalSettings';
import { API_URL } from '../config';

// Extend `EventEmitter` to fake `window.solana.on()` listeners
class SolanaTMAProvider extends EventEmitter {
    #APP_URL = 'https://feedyourmonkey.today';
    #tmaUtils = initUtils();

    // Public (Phantom)
    get publicKey() {
        if (!localSettings.has('tma_pk')) {
            return undefined;
        }

        return new PublicKey(localSettings.get('tma_pk'));
    };

    set #publicKey(value) {
        localSettings.set('tma_pk', value.toString());
    };

    get isConnected() {
        // If we still have session, assume
        // we are also still connected
        return !!this.#session;
    };

    // Internal
    get redirectLink() {
        const url = new URL('/tma', API_URL);
        url.searchParams.append('userID', this.#userID);
        return url.toString();
    }

    get dataLink() {
        const url = new URL('/tma/data', API_URL);
        url.searchParams.append('userID', this.#userID);
        return url.toString();
    }

    get #userID() {
        if (localSettings.has('tma_user_id')) {
            return localSettings.get('tma_user_id');
        }

        const userID = this.#getRandomUserID();
        localSettings.set('tma_user_id', userID);
        return userID;
    }

    get #encryptionKey() {
        if (localSettings.has('tma_encr_key')) {
            const secretKey = localSettings.get('tma_encr_key');
            const secretKeyBytes = bs58.decode(secretKey);
            const keyPair = nacl.box.keyPair.fromSecretKey(secretKeyBytes);
            return keyPair;
        }

        const keyPair = this.#getKeyPair();
        localSettings.set('tma_encr_key', bs58.encode(keyPair.secretKey));
        return keyPair;
    }

    get #sharedSecret() {
        if (!localSettings.has('tma_shared_secret')) {
            throw new Error('Secret key not present');
        }

        const secretKey = localSettings.get('tma_shared_secret');
        const secretKeyBytes = bs58.decode(secretKey);
        return nacl.box.keyPair.fromSecretKey(secretKeyBytes).secretKey;
    }

    get #session() {
        return localSettings.get('tma_session');
    }

    set #session(value) {
        localSettings.set('tma_session', value);
    }

    // API
    async connect() {
        // This is also used when re-opening the app while
        // being already logged in. Since opening the link
        // doesn't work well on mobile platform, especially
        // when triggered automatically. Just send back the
        // last logged in data
        if (this.isConnected) {
            return {publicKey: this.publicKey};
        }

        const params = {
            dapp_encryption_public_key: bs58.encode(this.#encryptionKey.publicKey),
            app_url: this.#APP_URL,
            redirect_link: this.redirectLink
        };

        const url = this.#constructDeepLink('connect', params);
        this.#tmaUtils.openLink(url);

        const response = await this.#waitForResponse();
        const publicKey = new PublicKey(response['public_key']);

        this.#session = response['session'];
        this.#publicKey = publicKey;

        // Same as standard Phantom API response
        return {publicKey};
    };

    async signIn(siwe) {
        // Deeplinks don't support `signIn` so we need to
        // convert the SIWE object into a string that can
        // be signed: https://github.com/orgs/phantom/discussions/247
        const siweString = this.#prepareSiweMessage(siwe);
        return this.signMessage(siweString);
    };

    async signMessage(data) {
        // The default Phantom provider only accepts Bytes
        // like object but we also use this func for string
        if (data?.byteLength !== undefined) {
            data = new TextDecoder().decode(data);
        }

        const [nonce, encryptedPayload] = this.#encryptPayload({
            session: this.#session,
            message: bs58.encode(Buffer.from(data)),
        });
        
        const params = {
            dapp_encryption_public_key: bs58.encode(this.#encryptionKey.publicKey),
            redirect_link: this.redirectLink,
            nonce: bs58.encode(nonce),
            payload: bs58.encode(encryptedPayload)
        };

        const url = this.#constructDeepLink('signMessage', params);

        // It seems the window doesn't get open sometimes
        // try adding a little delay to see if there is any
        // difference
        await this.#sleep(333);
        this.#tmaUtils.openLink(url);

        const response = await this.#waitForResponse();
        const signature = bs58.decode(response.signature);

        return {signature};
    };

    async signAndSendTransaction(transaction) {
        const serializedTransaction = transaction.serialize({requireAllSignatures: false});
        const [nonce, encryptedPayload] = this.#encryptPayload({
            session: this.#session,
            transaction: bs58.encode(serializedTransaction),
        });
        
        const params = {
            dapp_encryption_public_key: bs58.encode(this.#encryptionKey.publicKey),
            redirect_link: this.redirectLink,
            nonce: bs58.encode(nonce),
            payload: bs58.encode(encryptedPayload)
        };

        const url = this.#constructDeepLink('signAndSendTransaction', params);
        this.#tmaUtils.openLink(url);

        const response = await this.#waitForResponse();
        const signature = response.signature;
        
        return {signature};
    };

    // Utils
    #getKeyPair() {
        return nacl.box.keyPair();
    };

    #getRandomUserID() {
        return Buffer.from(randomBytes(32)).toString("hex");
    };

    #initSharedSecret(phantomEncryptionPublicKey) {
        const sharedSecret = nacl.box.before(
            bs58.decode(phantomEncryptionPublicKey),
            this.#encryptionKey.secretKey
        );

        localSettings.set('tma_shared_secret', bs58.encode(sharedSecret));
    };

    #constructDeepLink(method, params) {
        const urlParams = new URLSearchParams(params);
        return `https://phantom.app/ul/v1/${method}?${urlParams.toString()}`;
    };

    #decryptPayload(data, nonce) {
        const decryptedData = nacl.box.open.after(
            bs58.decode(data),
            bs58.decode(nonce),
            this.#sharedSecret
        );

        if (!decryptedData) {
            throw new Error('Unable to decrypt data');
        }
        
        return JSON.parse(Buffer.from(decryptedData).toString('utf8'));
    };

    #encryptPayload(payload) {
        const nonce = nacl.randomBytes(24);
        const encryptedPayload = nacl.box.after(
            Buffer.from(JSON.stringify(payload)),
            nonce,
            this.#sharedSecret
        );
        return [nonce, encryptedPayload];
    };
    
    async #sleep(ms) {
        return new Promise(r => setTimeout(r, ms));
    };

    async #waitForResponse() {
        return new Promise((resolve, reject) => {
            const START_TIME = Date.now();
            const TIMEOUT = 30_000; // 30 secs
            const START_TIMEOUT = 3000; // 3 secs for the 1st request
            const URL = this.dataLink;

            const checkData = async () => {
                if ((START_TIME + TIMEOUT) < Date.now()) {
                    return reject(new Error('Checking data timeout'));
                }

                const response = await fetch(URL, {method: 'GET'});

                if (!response.ok) {
                    const errorMessage = (await response.json())?.errorMessage;
                    return reject(new Error(errorMessage || `Something went wrong (${response.status})`));
                }

                if (response.status === 204) {
                    return setTimeout(checkData, 1000); // Check every second
                }

                if (response.status === 200) {
                    const jsonData = await response.json();

                    if (jsonData.errorCode) {
                        const error = new Error(response.errorMessage);
                        error.code = jsonData.errorCode;
                        return reject(error);
                    }

                    const {data, nonce, phantomEncryptionPublicKey} = jsonData;

                    // Only provided on the 1st connect
                    if (phantomEncryptionPublicKey) {
                        this.#initSharedSecret(phantomEncryptionPublicKey);
                    }

                    const payload = this.#decryptPayload(data, nonce);
                    return resolve(payload);
                }

                reject(new Error(`Unknown response (${response.status})`));
            };

            // Wait before checking it the first time
            setTimeout(checkData, START_TIMEOUT);
        });
    };

    #prepareSiweMessage(obj) {
        const headerPrefx = obj.scheme ? `${obj.scheme}://${obj.domain}` : obj.domain;
        const header = `${headerPrefx} wants you to sign in with your Solana account:`;
        const uriField = `URI: ${obj.uri}`;
        let prefix = [header, obj.address].join('\n');
        const versionField = `Version: ${obj.version}`;
        const chainField = `Chain ID: ` + obj.chainId || '1';
        const nonceField = `Nonce: ${obj.nonce}`;
        const suffixArray = [uriField, versionField, chainField, nonceField];
    
        obj.issuedAt = (obj.issuedAt || new Date().toISOString());
        suffixArray.push(`Issued At: ${obj.issuedAt}`);
        
        if (obj.expirationTime) {
            const expiryField = `Expiration Time: ${obj.expirationTime}`;
            suffixArray.push(expiryField);
        }
    
        if (obj.notBefore) {
            suffixArray.push(`Not Before: ${obj.notBefore}`);
        }
    
        if (obj.requestId) {
            suffixArray.push(`Request ID: ${obj.requestId}`);
        }
    
        if (obj.resources) {
            suffixArray.push([`Resources:`, ...obj.resources.map(x => `- ${x}`)].join('\n'));
        }

        const suffix = suffixArray.join('\n');
        prefix = [prefix, obj.statement].join('\n\n');

        if (obj.statement) {
            prefix += '\n';
        }

        return [prefix, suffix].join('\n');
    };
};

export default SolanaTMAProvider;
