import React from 'react';
import ChooseWeaponModal from '../Modals/ChooseWeaponModal';
import equipmentAssetTypes from '../../Helpers/EquipmentAssetTypes';
import monkeySilhouetteImage from '../../Assets/MonkeySilhouette.png';
import { GAME_CONSTANTS } from '../../config';
import './MonkeySilhouette.css';

function MonkeySilhouette(props) {
    const [isChooseWeaponModalShown, setChooseWeaponModalShown] = React.useState(false);

    const monkey = props.monkey;
    const equipmentUsed = (monkey?.equipmentUsed || []); // The monkey might be `undefined`
    const emptySlotsCount = (GAME_CONSTANTS.MAX_WEAPONS_USED - equipmentUsed.length);
    
    return (
        <>
            <ChooseWeaponModal
                monkey={monkey}
                isShown={isChooseWeaponModalShown}
                onWeaponsChanged={props.onMonkeyDetailsChanged}
                onCloseClicked={() => setChooseWeaponModalShown(false) }
            />

            <div style={{position: 'relative'}}>
                <img src={monkeySilhouetteImage} className="monkeySilhouetteImage" alt="my-monkey-silhouette"/>
                
                {
                    equipmentUsed.map((equip, index) => 
                        <WeaponPreview key={index} index={index} equipment={equip} onClick={() => setChooseWeaponModalShown(true)}/>
                    )
                }

                {   
                    Array.from({length: emptySlotsCount}, (el, index) => 
                        <SetWeaponButton key={index} index={index} onClick={() => setChooseWeaponModalShown(true)}/>
                    )
                }
            </div>
        </>
    );
};

export default MonkeySilhouette;

// Components
function SetWeaponButton(props) {
    return <button className="setWeaponButton" onClick={props.onClick} style={{
        left: !props.index ? 0 : undefined,
        right: !props.index ? undefined : 0,
        bottom: !props.index ? '22%' : '28%'
    }}/>
};

function WeaponPreview(props) {
    const {id, lifespan} = props.equipment;
    const type = equipmentAssetTypes.typeFromEquipmentID(id);
    const image = equipmentAssetTypes.equipmentDetails(type).icon;

    const weaponScale = scaleForWeapon(id);
    const weaponPosition = positionForWeapon(id);

    const isShownOnRight = (props.index === 0);
    const shouldFlipImage = !isShownOnRight;
    const weaponLifespanPerc = ((lifespan / 1) * 100);

    if (!isShownOnRight) {
        weaponPosition.left = weaponPosition.right;
        weaponPosition.right = undefined;
        weaponPosition.bottom = (parseInt(weaponPosition.bottom) - 7) + "%"; // Left hand is a little lower
    }

    const imageStyle = {
        transform: `scale(${weaponScale}) scaleX(${shouldFlipImage ? -1 : 1})`
    };

    const weaponLifespanBarStyle = {
        right: isShownOnRight ? '36%' : undefined,
        left: isShownOnRight ? undefined : '34%',
        bottom: 0,
        backgroundColor: `hsl(${weaponLifespanPerc}, 100%, 50%)`
    };

    return (
        <div key={type} className="weaponContainer" style={{...weaponPosition}} onClick={props.onClick}>
            <img src={image} className="image" style={imageStyle} alt="weapon" draggable="false"/>
            <div className="weaponLifespanBar" style={weaponLifespanBarStyle}></div>
        </div>
    );
};

// Helpers
function scaleForWeapon(id) {
    const EquipmentTypes = equipmentAssetTypes.Types;

    switch (+id) {
        case EquipmentTypes.STICK:
            return 1.2;
        case EquipmentTypes.BASEBALL_BAT:
            return 1.25;
        case EquipmentTypes.BASEBALL_BAT_WITH_NAILS:
            return 1.25;
        case EquipmentTypes.SWORD:
            return 1.2;
        case EquipmentTypes.KNIFE:
            return 0.6;
        case EquipmentTypes.AXE:
            return 1;
        default:
            return 1;
    }
};

function positionForWeapon(id) {
    const EquipmentTypes = equipmentAssetTypes.Types;

    switch (+id) {
        case EquipmentTypes.STICK:
            return {right: '-42%', bottom: '35%'};
        case EquipmentTypes.BASEBALL_BAT:
            return {right: '-40%', bottom: '35%'};
        case EquipmentTypes.BASEBALL_BAT_WITH_NAILS:
            return {right: '-40%', bottom: '35%'};
        case EquipmentTypes.SWORD:
            return {right: '-42%', bottom: '35%'};
        case EquipmentTypes.KNIFE:
            return {right: '-28%', bottom: '26%'};
        case EquipmentTypes.AXE:
            return {right: '-39%', bottom: '32%'};
        default:
            return {right: '-40%', bottom: '35%'};
    }
};
