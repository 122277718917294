import { isHexString, toBeHex, Contract, BrowserProvider } from 'ethers';
import { EVM_BLOCKCHAINS } from '../config';

class EVMWalletAPI {
    constructor(provider) {
        // We must pass 'any' in otherwise switching chains will cause
        // 'network changed: x => y' error when trying to sign a trx
        this.provider = new BrowserProvider(provider, 'any');
    };

    async switchChain(chainID) {
        if (!isHexString(chainID)) {
            chainID = toBeHex(chainID);
        }

        await this.provider.send('wallet_switchEthereumChain', [{chainId: chainID}]);
    };

    async addChain(chainID) {
        if (!isHexString(chainID)) {
            chainID = toBeHex(chainID);
        }

        const blockchainDetails = this.#blockchainDetailsUsingID(chainID);
        
        await this.provider.send('wallet_addEthereumChain', [{
            chainName: blockchainDetails.name,
            chainId: toBeHex(blockchainDetails.chainID),
            nativeCurrency: blockchainDetails.nativeCurrency,
            rpcUrls: [blockchainDetails.rpcURL.toString()]
        }]);
    };

    async signData(data, address) {
        return await this.provider.send('personal_sign', [data, address]);
    };

    async sendTransaction({to, from, value, data}) {
        return await this.provider.send('eth_sendTransaction', [{to, from, value, data}]);
    };

    async getTransactionReceipt(trxID) {
        return await this.provider.send('eth_getTransactionReceipt', [trxID]);
    };
    
    async getBlockNumber() {
        return await this.provider.send('eth_blockNumber', []);
    };
    
    async getChainID() {
        const chainID = (await this.provider.getNetwork())?.chainId;
        return chainID ? Number(chainID) : undefined; // Seems to return BigNumber by default
    };

    async getSelectedAddress() {
        const accounts = await this.provider.send('eth_requestAccounts');
        return accounts?.[0];
    };
    
    async fetchBalanceOf(tokenAddress, ownerAddress) {
        const abi = ['function balanceOf(address) view returns (uint256)'];
        const tokenContract = new Contract(tokenAddress, abi, this.provider);
        return await tokenContract.balanceOf(ownerAddress);
    };
    
    #blockchainDetailsUsingID(chainID) {
        chainID = this.#hexToNumberString(chainID);
        return Object.values(EVM_BLOCKCHAINS).find(chain => (chain.chainID === chainID));
    };
    
    #hexToNumberString(hex) {
        return Number(hex).toString();
    };
}

export default EVMWalletAPI;