import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';
import { PrivateKey, KeyType } from '@wharfkit/antelope';
import secureLocalStorage from 'react-secure-storage';

import { t, Trans } from '../../i18n';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import { SESSION_KEY } from '../../config';

import './SetSessionKeyModal.css';

class SetSessionKeyModal extends React.Component {
    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    get rpc() {
        return this.sharedState.rpc;
    }

    constructor(props) {
        super(props);

        this.state = {
            isKeyValid: undefined,
            isPermissionSet: undefined,
            linkedActions: undefined,
            isTransacting: false,
            isFetchingData: false,
            showKeyActivedPage: false,
            showWarningPage: false
        };

        this.keyActionType = {
            UKNOWN: 'UNKNOWN',
            SET: 'SET',
            UPDATE: 'UPDATE',
            DELETE: 'DELETE'
        };

        this.showWarningPage = this.showWarningPage.bind(this);
        this.setSessionKey = this.setSessionKey.bind(this);
        this.deleteSessionKey = this.deleteSessionKey.bind(this);
        this.close = this.close.bind(this);
    };

    // Transactions
    setSessionKey() {
        this.setState({isTransacting: true});

        // We must still support previously enabled Session Key
        // with mutliple different actions added. The user must
        // remove their key manually to then only enable `*`
        const privateKey = PrivateKey.generate(KeyType.K1);
        const publicKey = privateKey.toPublic().toString();
        const isNew = (this.keyAction() === this.keyActionType.SET);
        const linkedActions = this.state.linkedActions;
        const allowedActions = (isNew || (linkedActions?.[0] === '*')) ? ['*'] : SESSION_KEY.SUPPORTED_ACTIONS;
        const missingActions = allowedActions.filter(item => !linkedActions.includes(item));

        this.trxAPI.setSessionKey(publicKey, missingActions)
            .then(() => {
                this.setKey(privateKey);

                this.setState({
                    isTransacting: false,
                    isPermissionSet: true,
                    isKeyValid: true,
                    showKeyActivedPage: true,
                    showWarningPage: false
                });

                ReactGA.event('session_key_set', {
                    'monkey': this.session.accountName
                });
            })
            .catch(() => this.setState({isTransacting: false}));
    };

    deleteSessionKey() {
        this.setState({isTransacting: true});

        this.trxAPI.deleteSessionKey(this.state.linkedActions)
            .then(() => {
                this.removeKey();

                this.setState({
                    isTransacting: false,
                    isKeyValid: false,
                    isPermissionSet: false,
                    linkedActions: []
                });

                ReactGA.event('session_key_deleted', {
                    'monkey': this.session.accountName
                });
            })
            .catch(() => this.setState({isTransacting: false}));
    };

    // Fetching
    fetchAccountPermissions() {
        this.setState({isFetchingData: true});

        const accountName = this.session.accountName;

        this.rpc.fetchEosioAccount(accountName)
            .then(account => {
                const fymPermission = account.permissions.find(perm => { return perm['perm_name'].toString() === SESSION_KEY.PERM_NAME });

                if (fymPermission) {
                    const linkedActions = fymPermission['linked_actions'].map(la => (la.action?.toString() || '*'));
                    const publicKey = fymPermission['required_auth'].keys[0].key;
                    const storedPublicKey = this.getKey()?.toPublic();
                    const isPublicKeyValid = (storedPublicKey?.toString() === publicKey?.toString());

                    // There could be a new key setup in other session
                    if (!isPublicKeyValid) {
                        this.removeKey();
                    }

                    this.setState({
                        isKeyValid: isPublicKeyValid,
                        isPermissionSet: true,
                        linkedActions: linkedActions
                    });
                }
                else {
                    // Just in case of if the permission was removed
                    // by the user manually but the key still exists
                    this.removeKey();

                    this.setState({
                        isKeyValid: false,
                        isPermissionSet: false,
                        linkedActions: []
                    });
                }
            })
            .catch(() => this.close())
            .finally(() => this.setState({isFetchingData: false}));
    };

    // Components
    modalBodyContent() {
        const action = this.keyAction();
        const actionType = this.keyActionType;

        if (this.state.isFetchingData) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Spinner animation="border" size="lg" role="status" aria-hidden="true"/>
                </div>
            );
        }

        if (this.state.showKeyActivedPage) {
            return (
                <p style={{textAlign: 'center', marginBottom: 0}}>
                    <Trans i18nKey="modals.setSessionKey.sessionKeyActivatedMessage">
                        The <b>Session Key</b> has been activated! Enjoy beating up monkeys without getting slowed down by signing transactions!
                    </Trans>
                </p>
            );
        }

        if (this.state.showWarningPage) {
            return (
                <div style={{textAlign: 'center', fontWeight: 300}}>
                    <Trans i18nKey="modals.setSessionKey.sessionKeyWarningMessage">
                        Setting up a <b>Session Key</b> requires adjusting your account's permissions. This is why
                        you might be warned about a <span className="dangerousActionText">Dangerous action</span> but
                        no worries! The only actions that will change are game related actions, which you can verify when
                        signing the transaction. Your account cannot be compromised.
                    </Trans>
                </div>
            );
        }
        else {
            if (action === actionType.SET) {
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        <Trans i18nKey="modals.setSessionKey.sessionKeyExplainedMessage">
                            Setting up a <b>Session Key</b> allows you to play the game without having to sign 
                            any game related transactions. The <b>Session Key</b> gets removed automatically on logout.
                        </Trans>
                    </p>
                );
            }

            if (action === actionType.UPDATE) {
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        <Trans i18nKey="modals.setSessionKey.sessionKeyDiscardedMessage">
                            The required permission seems to exist already, but the key has been discarded.
                            Update action only is therefore required to reactivate the <b>Session Key</b>.
                        </Trans>
                    </p>
                );
            }

            if (action === actionType.DELETE) {
                return (
                    <p style={{textAlign: 'center', marginBottom: 0}}>
                        <Trans i18nKey="modals.setSessionKey.deleteSessionKeyMessage">
                            The following action will deactivate the <b>Session Key</b>.
                        </Trans>
                    </p>
                );
            }
        }
    };

    modalFooterButtons() {
        const action = this.keyAction();
        const actionType = this.keyActionType;

        if (this.state.isFetchingData) {
            return null;
        }

        if (this.state.showKeyActivedPage) {
            return <Button variant="secondary" onClick={this.close}>{t('common.ok')}</Button>
        }

        if (this.state.showWarningPage || action === actionType.UPDATE || action === actionType.DELETE) {
            return (
                <>
                    <Button variant="secondary" onClick={this.close} disabled={this.state.isTransacting}>{t('common.cancel')}</Button>

                    { (action !== actionType.DELETE) &&
                        <Button variant="primary" onClick={this.setSessionKey} disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.setSessionKey.setUp')}</span>
                                </>
                                :
                                (action === actionType.UPDATE) ? t('modals.setSessionKey.update') : t('modals.setSessionKey.setUp')
                            }
                        </Button>
                    }

                    { (action === actionType.DELETE) &&
                        <Button variant="primary" onClick={this.deleteSessionKey} disabled={this.state.isTransacting}>
                            {this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('modals.setSessionKey.delete')}</span>
                                </>
                                :
                                t('modals.setSessionKey.delete')
                            }
                        </Button>
                    }
                </>
            );
        }
        else {
            return (
                <>
                    <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    <Button variant="primary" onClick={this.showWarningPage}>{t('modals.setSessionKey.next')}</Button>
                </>
            );
        }
    };

    // Key Storage Operations
    removeKey() {
        return secureLocalStorage.removeItem(SESSION_KEY.PK_NAME);
    };

    getKey() {
        const privateKeyString = secureLocalStorage.getItem(SESSION_KEY.PK_NAME);

        if (privateKeyString) {
            return PrivateKey.from(privateKeyString);
        }

        return null;
    };

    setKey(key) {
        return secureLocalStorage.setItem(SESSION_KEY.PK_NAME, key.toString());
    };

    // Misc
    keyAction() {
        if (this.state.isKeyValid === undefined || this.state.isPermissionSet === undefined) {
            return this.keyActionType.UNKNOWN;
        }

        if (this.state.isKeyValid && this.state.isPermissionSet) {
            return this.keyActionType.DELETE;
        }

        if (!this.state.isKeyValid && this.state.isPermissionSet) {
            return this.keyActionType.UPDATE;
        }

        return this.keyActionType.SET;
    }

    showWarningPage() {
        this.setState({showWarningPage: true});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'setSessionKey-modal'});
                this.fetchAccountPermissions();
            }
            else {
                // Make the modal disappear and then change
                // props which affect content that is shown
                setTimeout(() => {
                    this.setState({
                        isKeyValid: undefined,
                        isPermissionSet: undefined,
                        linkedActions: undefined,
                        showKeyActivedPage: false,
                        showWarningPage: false
                    });
                }, 800);
            }
        }
    };

    render() {
        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.setSessionKey.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.modalBodyContent()}
                </Modal.Body>

                <Modal.Footer>
                    {this.modalFooterButtons()}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default commonContextsWrapper(SetSessionKeyModal);
