import Stack from 'react-bootstrap/Stack';
import { formatAmountCompactly } from '../../Helpers/Utils';
import xpIcon from '../../Assets/XPIcon.png';

export default function XPAmount(props) {
    const amountFormatted = props.compactFormatting ? formatAmountCompactly(props.amount) : Intl.NumberFormat().format(props.amount);
    
    return (
        <Stack direction="horizontal" style={{...{gap: '4px'}, ...props.style}}>
            <img src={xpIcon} alt="xp-icon" style={{height: '1.3rem'}}/>
            <div style={props.textStyle}>{amountFormatted}</div>
        </Stack>
    );
}
