import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { BarLoader } from 'react-spinners';

import { t } from '../../i18n';
import BananaAmount from '../Widgets/BananaAmount';
import XPAmount from '../Widgets/XPAmount';
import monkeysDuelVideo from '../../Assets/MonkeysDuel.mp4';

class FightDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
    }

    close() {
        this.props.onCloseClicked();
    }

    getAmountRow(title, amount) {
        return (
            <Stack direction="horizontal" style={{gap: '2px', fontWeight: 300}}>
                <b>{title}:</b>
                <BananaAmount amount={amount} textStyle={{fontWeight: 300}}/>
            </Stack>
        );
    }

    modalContent() {
        const result = this.props.fightResult;

        if (result) {
            const fightStatus = result.status;

            function titleForStatus(status) {
                switch (status) {
                    case 'WIN': return t('modals.fightDetails.youWonHeading');
                    case 'LOSE': return t('modals.fightDetails.youLoseHeading');
                    case 'DRAW': return t('modals.fightDetails.drawHeading');
                    default: break;
                }
            }

            return (
                <Stack style={{alignItems: 'center', width: '100%', gap: '16px'}}>
                    <h1 style={{textAlign: 'center', fontWeight: 700}}>{titleForStatus(fightStatus)}</h1>

                    <div>
                        { fightStatus === 'WIN' &&
                            <>
                                <>{this.getAmountRow(t('modals.fightDetails.bananasGained'), result.opponentDamage)}</>
                                <>{this.getAmountRow(t('modals.fightDetails.energyUsed'), result.myEnergyUsed)}</>
                            </>
                        }

                        { fightStatus === 'LOSE' &&
                            <>{this.getAmountRow(t('modals.fightDetails.bananasLost'), result.myDamage)}</>
                        }
                        
                        <>{this.getAmountRow(t('modals.fightDetails.totalBananas'), result.totalBananas)}</>

                        { (result.xpEarned !== 0) && //  Might be a negative number too
                            <Stack direction="horizontal" style={{gap: '2px', fontWeight: 300}}>
                                <b>{t('modals.fightDetails.experienceEarned')}:</b>
                                <XPAmount amount={result.xpEarned} textStyle={{fontWeight: 300}}/>
                            </Stack>
                        }
                    </div>
                </Stack>
            );
        }
        else {
            const fightFailedReason = this.props.fightFailedReason;
            
            if (fightFailedReason === 'WEIGHT_MISMATCH') {
                return t('modals.fightDetails.opponentWeightChanged');
            }

            return (
                <>
                    <video src={monkeysDuelVideo} style={{width: '100%', borderRadius: '6px'}} alt="monkey-duel" autoPlay muted playsInline loop/>

                    <Stack direction="row" style={{marginTop: '1rem', gap: '0.5rem', alignItems: 'center'}}>
                        <BarLoader width="33%"/>
                        <div>{t('modals.fightDetails.fightPending')}</div>
                    </Stack>
                </>
            );
        }
    };

    render() {
        return (
            <Modal show={this.props.isShown} size="md" backdrop="static" backdropClassName="modal-backdrop-blurred" centered>
                <Modal.Header>
                    <Modal.Title>{t('modals.fightDetails.title')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>{this.modalContent()}</Modal.Body>

                { (this.props.fightFailedReason || this.props.fightResult) &&
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.close}>{t('common.ok')}</Button>
                    </Modal.Footer>
                }
            </Modal>
        );
    }
};

export default FightDetailsModal;
