import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import { PeelRangeSlider } from '../../Widgets/TokenRangeSlider';
import commonContextsWrapper from '../../../Helpers/commonContextsWrapper';
import { formatPeelsTokenAmount } from '../../../Helpers/Utils';
import { t } from '../../../i18n';

class FertilizeTreeModal extends React.Component {
    #MIN_PEEL_FERT = 1;
    #MAX_PEEL_FERT = 100;

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState?.session;
    }

    get trxAPI() {
        return this.props.trxAPI;
    }

    constructor(props) {
        super(props);

        this.state = {
            peelsAmount: 0,
            isTransacting: false
        };

        this.fertilizeTree = this.fertilizeTree.bind(this);
        this.setPeelAmount = this.setPeelAmount.bind(this);
        this.close = this.close.bind(this);
    }

    // Transactions
    fertilizeTree() {
        this.setState({isTransacting: true});

        const tree = this.props.tree;
        const peelsAmount = this.state.peelsAmount;
        const peelsAmountFormatted = formatPeelsTokenAmount(peelsAmount);

        this.trxAPI.fertilizeTree(tree.id, peelsAmountFormatted)
            .then(() => {
                this.props.monkey.bananaPeels -= peelsAmount;
                this.props.onTreeFertilized(tree, peelsAmount);
                this.close();
            })
            .finally(() => this.setState({isTransacting: false}));
    };

    // Misc
    setPeelAmount(amount) {
        this.setState({peelsAmount: amount});
    };

    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                ReactGA.send({hitType: 'pageview', page: 'fertilizeTree-modal'});
            }
            else {
                setTimeout(() => {
                    this.setState({peelsAmount: 0});
                }, 800);
            }
        }
    }

    render() {
        const bananaPeels = this.props.monkey?.bananaPeels;
        const hasEnoughPeels = (bananaPeels >= 1);
        const maxPeelsFertilizing = Math.min(this.#MAX_PEEL_FERT, Math.floor(bananaPeels));

        return (
            <Modal show={this.props.isShown} onHide={this.close} size="sm" backdropClassName="ModalWithTintedBackdrop" backdrop="static" centered>
                <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Modal.Title>{t('modals.farming.fertilizeTree.title')}</Modal.Title>
                    <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.fertilizeTree.subtitle')}</div>
                </Modal.Header>

                <Modal.Body>
                    { hasEnoughPeels &&
                        <Stack direction="vertical" style={{alignItems: 'center'}}>
                            <div style={{textAlign: 'center', fontWeight: '300'}}>{t('modals.farming.fertilizeTree.selectPeelsToBeFertilized')}:</div>
                            <PeelRangeSlider min={this.#MIN_PEEL_FERT} max={maxPeelsFertilizing} step="1" defaultValue={1} onValueChanged={this.setPeelAmount} style={{width: '80%', marginTop: '48px'}}/>
                        </Stack>
                    }

                    { !hasEnoughPeels &&
                        <p style={{textAlign: 'center', marginBottom: 0}}>{t('modals.farming.fertilizeTree.notEnoughPeels')}</p>
                    }
                </Modal.Body>

                <Modal.Footer>
                    { !this.state.isTransacting &&
                        <Button variant="secondary" onClick={this.close}>{t('common.cancel')}</Button>
                    }

                    { hasEnoughPeels &&
                        <Button variant="primary" onClick={this.fertilizeTree} disabled={this.state.isTransacting}>
                            { this.state.isTransacting ?
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                    <span className="visually-hidden">{t('common.confirm')}</span>
                                </>
                                :
                                t('common.confirm')
                            }
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default commonContextsWrapper(FertilizeTreeModal);
