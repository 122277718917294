import secureLocalStorage from 'react-secure-storage';
import fymAPI from './FYMAPI';
import abis from './abis';
import { Transaction, SignedTransaction, PermissionLevel, Action, Name, PrivateKey } from '@wharfkit/antelope';
import {SESSION_KEY, RES_MGR_CONTRACT_NAME, FYM_CONTRACT_NAME} from '../config';

class FYMSession {
    // We're comparing names when setting up and overriding the default
    // Session in the `SharedStateProvider`. Make sure it's hardcoded
    // as otherwise it gets minified and comparison fails
    static name = 'FYMSession';
    #cachedABIs = {};
    #session;

    get accountName() {
        return this.#session?.actor.toString();
    };

    get permissionLevel() {
        return this.#session.permissionLevel;
    };

    get client() {
        return this.#session.client;
    };

    get chain() {
        return this.#session.chain;
    };

    get isEVMBasedSession() {
        const permission = this.permissionLevel.permission.toString();
        return (permission === 'efym');
    };

    constructor(session) {
        this.#session = session;
    };

    // Transaction
    async transact(trx) {
        if (this.#isSessionKeySetup() && this.#isSessionKeySupportedTransaction(trx)) {
            try {
                const signedTransaction = await this.#signTransactionWithSessionKey(trx);

                // Try to keep the resulting object same as when signed by Wharfkit
                return {
                    response: signedTransaction,
                    transaction: signedTransaction
                };
            }
            catch (error) {
                // Gotta access the underlying error (contract assert messages)
                // as APIError is returning a very abstract message
                const underlyingError = error.response?.json?.error;
                const underlyingErrorMessage = underlyingError?.details?.[0].message;

                const isKeyInvalid = underlyingErrorMessage?.includes('does not have signatures for it under a provided delay');
                const isAuthInvalid = underlyingErrorMessage?.includes('action\'s authorizations include a non-existent permission'); // The key might exist, but the permission could be deleted
                const isIrrelevantAuthority = underlyingErrorMessage?.includes('action declares irrelevant authority'); // A new `fym` action could be added that has not been linked yet
                const hasInsufficientResources = (error.code === 3080004 || error.code === 3080002); // The resource manager's power up failed and so it has no CPU/NET
                const shouldRetryWithUserAccount = (isKeyInvalid || isAuthInvalid || isIrrelevantAuthority || hasInsufficientResources);

                // If for whatever reason the signing fails due to permissions
                // issue repeat signing with the user's permissions (wallet)
                if (!this.isEVMBasedSession && shouldRetryWithUserAccount) {
                    if (isKeyInvalid || isAuthInvalid || isIrrelevantAuthority) {
                        this.#removeKey();
                        this.#recordKeyRemoval(underlyingErrorMessage);
                    }

                    return this.#session.transact(trx);
                }
                else {
                    if (underlyingErrorMessage) {
                        const err = new Error(underlyingErrorMessage);
                        err.code = underlyingError.code;
                        throw err;
                    }

                    throw error;
                }
            }
        }
        else {
            return this.#session.transact(trx);
        }
    };

    async #signTransactionWithSessionKey(trx) {
        const info = await this.client.v1.chain.get_info();
        const header = info.getTransactionHeader();
        const actions = [];

        for (let i = 0; i < trx.actions.length; i++) {
            const action = trx.actions[i];
            const abi = await this.abiFor(action.account);
            actions.push(Action.from(action, abi));
        }

        // If using the standard SessionKey we must change the default
        // `active` permission to the `fym` one used by the key
        if (!this.isEVMBasedSession) {
            // Avoid changing permissions directly on the `trx` or the
            // `.permission` object as that affects (overrides) then all
            // `this.session.permissionLevel` causing them to have wrong
            // permission.
            actions.forEach(action => {
                const actor = this.permissionLevel.actor;
                const usedPermission = this.permissionLevel.permission;

                const index = action.authorization.findIndex(auth => {
                    // Must be used `toString` for comparison otherwise it won't work
                    return (auth.actor.toString() === actor.toString() && auth.permission.toString() === usedPermission.toString());
                });

                action.authorization[index] = new PermissionLevel({
                    actor: actor,
                    permission: Name.from(SESSION_KEY.PERM_NAME)
                });
            });
        }

        const transaction = Transaction.from({...header, actions: actions});
        const privateKey = this.#getKey();
        const resMgrSignature = await this.#signBilledTransaction(transaction);
        const userSignature = privateKey.signDigest(transaction.signingDigest(info.chain_id));
        const signatures = resMgrSignature ? [userSignature, resMgrSignature] : [userSignature];
        const signedTransaction = SignedTransaction.from({...transaction, signatures});
        const result = await this.client.v1.chain.push_transaction(signedTransaction);

        return result;
    };

    // Session Key
    #isSessionKeySetup() {
        // As long as the PK exists consider it set up. If the signing
        // fails, we remove the key and repeat it with the user's wallet
        return !!this.#getKey()
    };

    #isSessionKeySupportedTransaction(trx) {
        // Must accept all that comes in
        if (this.isEVMBasedSession) {
            return true;
        }

        // SessionKey doesn't for instance support banana token
        // transfers or permission changes and so the user must
        // sign it themselves
        const invalidAction = trx.actions.find(action => (action.account !== FYM_CONTRACT_NAME));
        
        return (invalidAction === undefined);
    };

    // Key Actions
    #getKey() {
        const privateKeyString = secureLocalStorage.getItem(SESSION_KEY.PK_NAME);

        if (privateKeyString) {
            return PrivateKey.from(privateKeyString);
        }

        return null;
    };

    #removeKey() {
        secureLocalStorage.removeItem(SESSION_KEY.PK_NAME);
    }

    // Misc
    async #signBilledTransaction(transaction) {
        const abi = await this.abiFor(RES_MGR_CONTRACT_NAME);
        
        transaction.actions.unshift(Action.from({
            account: RES_MGR_CONTRACT_NAME,
            name: 'noop',
            authorization: [{
                actor: RES_MGR_CONTRACT_NAME,
                permission: 'cosign'
            }],
            data: {}
        }, abi));
        
        try {
            // Get signature from the server
            return fymAPI.billResourcesSignature(transaction, this.chain);
        }
        catch (error) {
            // Remove the `noop` and let the trx to be
            // signed using the user's resources
            transaction.actions.shift();
        }
    };

    async abiFor(accountName) {
        if (abis[accountName]) {
            return abis[accountName];
        }

        if (this.#cachedABIs[accountName]) {
            return this.#cachedABIs[accountName];
        }

        const responseRawABI = await this.client.v1.chain.get_raw_abi(accountName);
        this.#cachedABIs[accountName] = responseRawABI.abi;

        return responseRawABI.abi;
    };

    #recordKeyRemoval(errMessage) {
        localStorage.setItem('sk_removal_reason', 'removed when trx signing. Error: "' + errMessage + '" at ' + new Date());
    };
};

export default FYMSession;
