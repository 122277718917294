import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CloseButton from 'react-bootstrap/CloseButton';
import Spinner from 'react-bootstrap/Spinner';
import ReactGA from 'react-ga4';

import TreePlotOptionsModal from './Farming/TreePlotOptionsModal';
import BananaTree from '../../Models/BananaTree';
import BananaTreePlot from '../../Models/BananaTreePlot';
import commonContextsWrapper from '../../Helpers/commonContextsWrapper';
import { calcTimeRemaining, formatAmount, formatPercentages } from '../../Helpers/Utils';
import { getBlockchainName } from '../../config';
import { t } from '../../i18n';

import bananaTreeYellow from '../../Assets/BananaTreeYellowTopDown.png';
import bananaTreeGreen from '../../Assets/BananaTreeGreenTopDown.png';
import emptyBananaTree from '../../Assets/BananaTreeEmptyTopDown.png';
import bananaTreeGround from '../../Assets/BananaTreeGround.png';
import bananaTreeGroundUnfertilized from '../../Assets/BananaTreeGroundUnfertilized.png';
import './FarmingModal.css';

class FarmingModal extends React.Component {
    #MAX_PLANTATIONS_SHOWN = 13;

    get sharedState() {
        return this.props.sharedState?.[0];
    }

    get session() {
        return this.sharedState.session;
    }

    get rpc() {
        return this.sharedState.rpc;
    }
    
    get isRunningOnEOS() {
        const chainName = getBlockchainName(this.sharedState.chain);
        return (chainName === 'EOS');
    }

    constructor(props) {
        super(props);
        
        this.state = {
            isFetchingData: false,
            treePlotSelected: undefined,
            plantations: {},
            stakingDetails: undefined
        };

        this.showTreePlotOptions = this.showTreePlotOptions.bind(this);
        this.hideTreePlotOptions = this.hideTreePlotOptions.bind(this);
        this.onTokensUnstaked = this.onTokensUnstaked.bind(this);
        this.onTreeHarvested = this.onTreeHarvested.bind(this);
        this.onTokensStaked = this.onTokensStaked.bind(this);
        this.onTreeFertilized = this.onTreeFertilized.bind(this);
        this.onTreePlanted = this.onTreePlanted.bind(this);
        this.onTreeDiggedUp = this.onTreeDiggedUp.bind(this);

        this.close = this.close.bind(this);
    };

    // Fetching
    fetchData() {
        this.setState({isFetchingData: true});

        Promise.all([
            this.fetchAllStakers(),
            this.fetchPlantationFields()
        ])
        .then(([stakers, plantations]) => {
            const accountName = this.session.accountName;
            const stakingDetails = stakers.find(s => (s.name === accountName));

            // The main user might not have any plantation yet
            plantations[accountName] = (plantations[accountName] || []);

            // Add growing plots for each staker/owner too
            Object.keys(plantations).forEach(plantationOwner => {
                const stakingDetails = stakers.find(s => (s.name === plantationOwner));

                if (stakingDetails) {
                    const treePlot = BananaTreePlot.withUngrownTree(stakingDetails.growthState);
                    plantations[plantationOwner].push(treePlot);
                }
                else if (plantationOwner === accountName) {
                    // Add an empty plot for the current user only
                    plantations[plantationOwner].push(BananaTreePlot.empty());
                }
            });

            this.setState({plantations, stakingDetails});
        })
        .catch(() => this.close())
        .finally(() => this.setState({isFetchingData: false}));
    };

    async fetchAllStakers() {
        // Only EOS supports staking
        if (!this.isRunningOnEOS) {
            return [];
        }

        // At this point it's more efficient to query all
        // stakers by 1 request than each individual one
        const stakers = await this.rpc.fetchAllStakers();
        return stakers.map(obj => new StakingDetails(obj));
    };

    async fetchPlantationFields() {
        const owners = await this.rpc.fetchPlantationFieldOwners();

        // Descending order with the currently logged in user at the top as
        // we are slicing the array to limit the number of accounts queried
        const accountName = this.session.accountName;
        owners.sort((a, b) => ((a.name === accountName) ? -1 : (b.count - a.count)));

        const topPlantationOwners = owners.slice(0, this.#MAX_PLANTATIONS_SHOWN).map(r => r.name);
        const plantations = await this.rpc.fetchBananaTrees(topPlantationOwners);

        Object
            .entries(plantations)
            .forEach(([name, trees]) => {
                plantations[name] = trees.map(obj => {
                    const tree = new BananaTree(obj);
                    return BananaTreePlot.withTree(tree);
                });
            });

        return plantations;
    };

    // Components
    plantationView() {
        const accountName = this.session?.accountName;
        const plantations = this.state.plantations;

        // Make sure the user will be the 1st shown and then
        // ascending based on the number of trees
        const plantationOwners = Object
            .keys(plantations)
            .sort((a, b) => (plantations[b].length - plantations[a].length))
            .sort((a, b) => ((a === accountName) ? -1 : 0));

        const BananaTreePlotView = (index, treePlot, shouldFlash, isSelectable) => {
            const tree = treePlot.tree;
            const isTreeGrown = treePlot.isTreeGrown;
            const isTreeGrowing = treePlot.isTreeGrowing;
            const hasBananas = tree?.hasBananas;
            const growingTreeSize = isTreeGrowing ? Math.max(20, (treePlot.treeGrowthState * 100)) : undefined; // Min. 20% size
            const cursorType = {cursor: isSelectable ? 'pointer' : 'default'};
            const subClass = shouldFlash ? 'flashing' : '';
            const isGroundFertilized = ((tree?.isFertilized && !hasBananas) || isTreeGrowing || treePlot.isEmpty);
            const onTreeClicked = () => { isSelectable && this.showTreePlotOptions(treePlot) };

            return (
                <Col className={`PlantationFieldCol ${subClass}`} key={index} onClick={onTreeClicked} style={cursorType}>
                    <img src={isGroundFertilized ? bananaTreeGround : bananaTreeGroundUnfertilized} width="100%" height="100%" alt="banana-tree-ground" draggable="false"/>

                    { isTreeGrowing &&
                        <>
                            <img src={emptyBananaTree} style={{width: `${growingTreeSize}%`}} className="EmptyBananaTreeIcon" alt="empty-banana-tree-icon" draggable="false"/>

                            { (treePlot.treeGrowthState > 0) &&
                                <div className="TreeStateInfo">{formatPercentages(treePlot.treeGrowthState)}</div>
                            }
                        </>
                    }

                    { (isTreeGrown && hasBananas) &&
                        <>
                            <img src={bananaTreeYellow} className="BananaTreeIcon" alt="banana-tree-yellow-icon" draggable="false"/>
                            <div className="TreeStateInfo">{formatAmount(tree.bananas)}</div>
                        </>
                    }

                    { (isTreeGrown && !hasBananas && tree.isFertilized) && 
                        <>
                            <img src={bananaTreeGreen} className="BananaTreeIcon" alt="banana-tree-green-icon" draggable="false"/>
                            <div className="TreeStateInfo">{calcTimeRemaining(tree.bananasHarvestableAt)}</div>
                        </>
                    }

                    { (isTreeGrown && !hasBananas && !tree.isFertilized) && 
                        <img src={bananaTreeGreen} className="BananaTreeIcon" alt="banana-tree-green-icon" draggable="false"/>
                    }
                </Col>
            );
        };

        const PlantationView = (owner) => {
            const myPlantation = (owner === accountName);
            const treePlots = plantations[owner];
            const shouldFlash = (myPlantation && treePlots[0].isEmpty); // If the user only has 1 (empty) plot

            return (
                <div key={owner}>
                    <div className="Plantation-ownerName">{myPlantation ? t('modals.farming.myPlantation') : owner}</div>

                    <Container className="PlantationFieldContainer">
                        <Row className="PlantationFieldRow">
                            { treePlots.map((treePlot, i) => BananaTreePlotView(i, treePlot, shouldFlash, myPlantation)) }
                        </Row>
                    </Container>
                </div>
            );
        };

        return (
            <Stack style={{gap: '1.5rem'}}>
                { plantationOwners.map(PlantationView) }
            </Stack>
        );
    };

    // Modals
    showTreePlotOptions(treePlot) {
        this.setState({treePlotSelected: treePlot});
    };

    hideTreePlotOptions() {
        this.setState({treePlotSelected: undefined});
    };

    // Handlers
    onTokensStaked(amount) {
        const plantations = {...this.state.plantations}; // Copy the object from state
        const stakingDetails = (this.state.stakingDetails || new StakingDetails());
        const myPlantation = plantations[this.session.accountName];
        const lastPlot = myPlantation[myPlantation.length - 1]; // The user could also just increase their stake

        // If it's first time staking and the last plot is empty
        if (lastPlot.isEmpty) {
            myPlantation[myPlantation.length - 1] = BananaTreePlot.withUngrownTree(0);
        }

        stakingDetails.lpStaked += amount;
        stakingDetails.stakeUpdated = new Date();

        this.setState({plantations, stakingDetails});
    };
    
    onTokensUnstaked() {
        const plantations = {...this.state.plantations}; // Copy the object from state
        const myPlantation = plantations[this.session.accountName];
        const lastPlot = myPlantation[myPlantation.length - 1]; // The user could also just increase their stake
        const isPlotSelected = (this.state.treePlotSelected === lastPlot);
        const emptyPlot = BananaTreePlot.empty();

        // Replace the "growing" tree plot with an empty one
        myPlantation[myPlantation.length - 1] = emptyPlot;

        this.setState({
            plantations: plantations,
            treePlotSelected: isPlotSelected ? emptyPlot : this.state.treePlotSelected,
            stakingDetails: undefined
        });
    };

    onTreePlanted(tree) {
        const plantations = {...this.state.plantations}; // Copy the object from state
        const myPlantation = plantations[this.session.accountName];
        const treePlot = BananaTreePlot.withTree(tree);

        // Insert just before the last plot, which is usually empty
        myPlantation.splice((myPlantation.length - 1), 0, treePlot);
        this.setState({plantations});
    };

    onTreeDiggedUp(tree) {
        const plantations = {...this.state.plantations}; // Copy the object from state
        const myPlantation = plantations[this.session.accountName];
        const treePlotIndex = myPlantation.findIndex(p => p.tree.id === tree.id);
        
        myPlantation.splice(treePlotIndex, 1);
        this.setState({plantations});
    };

    onTreeHarvested(tree) {
        const plantations = {...this.state.plantations}; // Copy the object from state
        const myPlantation = plantations[this.session.accountName];
        const treePlot = myPlantation.find(p => p.tree.id === tree.id);
        const bananasHarvested = treePlot.tree.bananas;

        // This is actually referencing the same tree
        // as was passed in, but well...
        treePlot.tree.fertilizer = 0;
        treePlot.tree.harvests += 1;
        treePlot.tree.cropHarvested += bananasHarvested;

        this.setState({plantations});
    };

    onTreeFertilized(tree, peelsAmount) {
        const plantations = {...this.state.plantations}; // Copy the object from state
        const myPlantation = plantations[this.session.accountName];
        const treePlot = myPlantation.find(p => p.tree.id === tree.id);

        // This is actually referencing the same tree
        // as was passed in, but well...
        treePlot.tree.fertilizer = peelsAmount;
        treePlot.tree.fertilizedAt = new Date();
        treePlot.tree.totalFertilizerUsed += peelsAmount;
        
        this.setState({plantations});
    };

    // Misc
    close() {
        this.props.onCloseClicked();
    };

    // React
    componentDidUpdate(prevProps, prevState) {
        // The component is inserted into the tree by default, but hidden
        // hence why we need to check props in the `componentDidUpdate`
        if (prevProps.isShown !== this.props.isShown) {
            if (this.props.isShown) {
                this.fetchData();
                ReactGA.send({hitType: 'pageview', page: 'farming-modal'});
            }
            else {
                setTimeout(() => {
                    this.setState({plantations: {}, stakingDetails: undefined});
                }, 800);
            }
        }
    };

    render() {
        return (
            <>
                {/* Options Modals */}
                <TreePlotOptionsModal
                    isShown={!!this.state.treePlotSelected}
                    monkey={this.props.monkey}
                    treePlot={this.state.treePlotSelected}
                    stakingDetails={this.state.stakingDetails}
                    onCloseClicked={this.hideTreePlotOptions}
                    onTokensUnstaked={this.onTokensUnstaked}
                    onTokensStaked={this.onTokensStaked}
                    onTreeFertilized={this.onTreeFertilized}
                    onTreeHarvested={this.onTreeHarvested}
                    onTreePlanted={this.onTreePlanted}
                    onTreeDiggedUp={this.onTreeDiggedUp}
                />

                {/* Main Modal */}
                <Modal show={this.props.isShown} onHide={this.close} size="lg" backdrop="static" centered>
                    <Modal.Header>
                        <Stack>
                            <Modal.Title>{t('modals.farming.title')}</Modal.Title>
                            <div style={{fontWeight: 300, fontSize: '0.8rem'}}>{t('modals.farming.subtitle')}</div>
                        </Stack>

                        <CloseButton onClick={this.close}/>
                    </Modal.Header>

                    <Modal.Body>
                        { this.state.isFetchingData &&
                            <div style={{textAlign: 'center'}}>
                                <Spinner animation="border" size="lg" role="status" aria-hidden="true" style={{marginTop: '3rem', marginBottom: '3rem'}}/>
                            </div>
                        }
                        
                        { !this.state.isFetchingData &&
                            this.plantationView()
                        }
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default commonContextsWrapper(FarmingModal);

// Helpers
class StakingDetails {
    #DAY = 86400000;

    get isStakeMature() {
        return ((this.stakeUpdated.getTime() + this.#DAY) < Date.now());
    }

    constructor(obj) {
        this.name = obj['monkey'];
        this.lpStaked = parseFloat(obj?.['lp_staked'] || 0);
        this.stakeUpdated = obj ? new Date(obj['stake_updated'] + 'Z') : new Date();
        this.growthState = parseFloat(obj?.['growth_state'] || 0);
    }
}