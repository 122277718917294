class Asset {
    // Getters
    get isBeingCrafted() {
        if (!this.craftingUntil) {
            return false;
        }

        return (this.craftingUntil.getTime() > Date.now());
    }

    get availableQuantity() {
        if (!this.isBeingCrafted) {
            return this.quantity;
        }

        return (this.quantity - this.craftedQuantity);
    }

    constructor(obj) {
        this.id = obj.id;
        this.type = obj.type;
        this.name = obj.name;
        this.icon = obj.icon;
        this.quantity = parseFloat(obj.quantity);

        if (obj.craftingDetails) {
            this.craftingUntil = new Date(obj.craftingDetails['first'] + 'Z');
            this.craftedQuantity = parseFloat(obj.craftingDetails['second']);
        }
    }
};

export default Asset;